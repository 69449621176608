import Icons from "assets/Icons";
import LottieFiles from "assets/LottieFiles";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";

import TableLeaderBoard from "components/Table/TableLeaderBoard";

import { Unsubscribe } from "firebase/firestore";
import { showToast } from "helpers/Toast";
import useContest from "hooks/contest.hook";

import Lottie from "lottie-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ContestLeaderboardProps, ToastTypes } from "types/Component";
import { CONTEST_STATUS } from "types/Functions";

function ContestLeaderboard({
  contestDetail,
  contestStatus,
}: ContestLeaderboardProps) {
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    fetchContestLeaderBoard,
    isContestLeaderBoardLoading,
    contestLeaderBoard,
    contestantDetails,
    contestContactListener,
    enrollToContest,
    setSelectedLogbook,
    isEnrollContestLoading,
    isContestantDetailLoading,
  } = useContest();

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    fetchContestLeaderBoard(searchParams.get("contest_id")!);
    unsubscribe = contestContactListener(searchParams.get("contest_id")!);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToLogContact = () => {
    if (contestStatus === CONTEST_STATUS.ACTIVE) {
      setSelectedLogbook({
        id: contestantDetails?.enrolledUser.logBookId,
        name: contestantDetails?.enrolledUser.logbookName,
        uid: contestantDetails?.enrolledUser.uid,
        contestId: contestDetail?.id,
        contest: {
          name: contestDetail?.name || "",
          startDate: contestDetail?.startDate,
          endDate: contestDetail?.endDate,
          image: contestDetail?.image || "",
          shortDescription: contestDetail?.shortDescription || "",
        },
      });
      setSearchParams((prev: any) => {
        return {
          ...prev,
          tab: "log_contest_contacts",
          contest_id: searchParams.get("contest_id"),
          "logbook-id": contestantDetails?.enrolledUser.logBookId,
          "logbook-name": contestantDetails?.enrolledUser.logbookName,
        };
      });
    } else {
      showToast({
        message: "Contest hasn't started yet.",
        type: ToastTypes.WARN,
      });
    }
  };

  return (
    <main className="">
      <div className="bg-[#010950] px-8 py-5 w-full mt-[60px]">
        <div className="flex justify-between items-center">
          <div className="flex">
            <div className="flex items-center">
              <div className="flex items-center">
                <img className="h-5 w-5 mr-3" src={Icons.TopChart} alt="icon" />
                <div className="w-[9px] h-[9px] bg-[#CC000A] rounded animate-shrink-grow"></div>
              </div>
              <p className="text-white font-[Play] font-bold text-base ml-[15px]">
                LIVE CONTEST LEADERS
              </p>
              {isContestLeaderBoardLoading ? (
                <div className={`w-${16} h-${16}`}>
                  <Lottie
                    animationData={LottieFiles.ActivityIndicator}
                    loop={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-[220px]">
            {contestStatus === CONTEST_STATUS.PASSED ? null : (
              <div className="w-full md:w-[220px]">
                <BorderButtonSecondary
                  className=""
                  height="h-11"
                  text={
                    contestantDetails?.isUserEnrolled
                      ? "Go To My Logbook"
                      : "Enroll Now for FREE!"
                  }
                  onClick={() => {
                    if (contestantDetails?.isUserEnrolled) {
                      goToLogContact();
                    } else {
                      enrollToContest(contestDetail);
                    }
                  }}
                  childClassName="text-sm md:text-base py-2 px-[10px]"
                  isLoading={
                    isEnrollContestLoading || isContestantDetailLoading
                  }
                />
              </div>
            )}
          </div>
        </div>

        <div className="mt-4 overflow-y-hidden">
          <div className="w-full">
            <TableLeaderBoard
              list={contestLeaderBoard}
              selectedTab={""}
              contest
              contestId={contestDetail?.id}
              contestName={contestDetail.name}
              contestPassed={contestStatus === CONTEST_STATUS.PASSED}
            />
          </div>
        </div>
      </div>
      <p className="text-white text-center my-20 font-[Play]">
        <span className="font-bold">Disclaimer</span> - Live contest scores are
        a live representation of current logging. All logs will undergo an
        extensive audit before winners are declared.
      </p>
    </main>
  );
}

export default ContestLeaderboard;
