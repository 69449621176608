import { PrimaryModalDataProps } from "types/State";

export const MENU_VISIBILITY_STATUS = "MENU_VISIBILITY_STATUS";
export const SET_PRIMARY_MODAL_DATA = "SET_PRIMARY_MODAL_DATA";
export const SET_SCREENSHOT_IMAGE = "SET_SCREENSHOT_IMAGE";
export const UPLOAD_SCREENSHOT_IMAGE = "UPLOAD_SCREENSHOT_IMAGE";

export interface ConfigActionPayload {
  type: string;
  payload: any;
}

export function setMenuVisibility(data: boolean): ConfigActionPayload {
  return {
    type: MENU_VISIBILITY_STATUS,
    payload: data,
  };
}

export function storeScreenShotImage(data: string): ConfigActionPayload {
  return {
    type: UPLOAD_SCREENSHOT_IMAGE,
    payload: data,
  };
}

export function setPrimaryModalData(
  data: PrimaryModalDataProps | null
): ConfigActionPayload {
  return {
    type: SET_PRIMARY_MODAL_DATA,
    payload: data,
  };
}

export const setScreenImage = (data: string) => {
  return {
    type: SET_SCREENSHOT_IMAGE,
    payload: data,
  };
};
