import { getCurrentDateString, getCurrentUTCtime } from "helpers/Date/Time";
import { FC, useEffect, useState } from "react";
import { QuickSettingItem } from "./QuickSettingItem";
import Icons from "assets/Icons";

export const TimeQuickSettingItem: FC<any> = ({onItemClick}) => {
  const [currentTime, setCurrentTime] = useState(getCurrentUTCtime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(getCurrentUTCtime());
    }, 1000);

    return () => {
        clearInterval(timer)
    };
  }, []);  

  return (
    <QuickSettingItem
      onItemClick={onItemClick}
      data={{
        id: 6,
        icon: Icons.ClockIcon,
        label: `${getCurrentDateString()} | ${currentTime} z`,
      }}
    />
  );
};
