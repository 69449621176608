import React, { useEffect, useState } from "react";
import InputWithButton from "components/Inputs/InputWithButton";
import TitledInput from "components/Inputs/TitledInput";
import TitledTimeInput from "components/Inputs/TitledTimeInput";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { AllBandsOption, Modes } from "constants/ArrayVariables";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import TitledInputGradient from "components/Inputs/TitledInputGradient";
import { useSelector } from "react-redux";
import { getContestDetailsSelector } from "store/Reducers/contest.reducer";
import { useSearchParams } from "react-router-dom";
import useLogbookSetting from "hooks/logbookSetting.hook";
import usePota from "hooks/pota.hook";
import { useDebouncedCallback } from "use-debounce";
import Icons from "assets/Icons";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import {
  convertBandValue,
  findBandAndModeForFrequency,
  getDxDataFromCountry,
  setTabIndexForTimeInputs,
} from "helpers/Utils";
import { Form, Formik } from "formik";
import { LocationMappings } from "constants/LocationMappings";
import SearchCountryPicker from "components/Inputs/SearchCountryPicker";
import TitledRegionPicker from "components/Inputs/TitledRegionPicker";
import moment from "moment";
import { TheirParkField } from "./TheirParksField";

const POTALog = ({
  setValues,
  values,
  errors,
  handleChange,
  touched,
  initialData,
  setTouched,
  onLogPress,
  display,
  callSignBlurPopupHandler,
  userMode,
  setUserMode,
  userBand,
  setUserBand,
  setUserCallSign,
  selectedLogBook,
  isSavingOnLoading,
  date,
  setDate,
  setTime,
  currentTime,
  getUserFromHamApiData,
  isCallSignDataLoading,
  callSignData,
  handleClearAddContactForm,
  setStopRealTimeChange,
  userTimeInput,
  setUserTimeInput,
  checkLocationPermission,
  theirPark,
  setTheirPark,
  callSignRef,
  getUserCountryFromGrid,
  selectedSecondaryField, 
  setSelectedSecondaryField
}: any) => {
  const contestDetail = useSelector(getContestDetailsSelector);
  let [searchParams] = useSearchParams();
  const [comments, setComments] = useState("");
  const { updateLogbookSettings } = useLogbookSetting();

  const {
    isParkReferenceDataLoading,
    parkReferenceData,
    getParkByReference,
    autoSpotData,
    setAutoSpotData,
    addPotaAutoSpot,
  } = usePota();

  useEffect(() => {
    if (autoSpotData?.frequency) {
      const { frequency, mode } = autoSpotData;

      const freqNumber = parseFloat(frequency);
      const bandMode = findBandAndModeForFrequency(freqNumber);

      if (bandMode) {
        setValues((prev: any) => {
          return {
            ...prev,
            userMode: mode || bandMode?.mode,
            band: bandMode?.band,
            frequency,
          };
        });
        setUserMode(mode || bandMode?.mode);
        setUserBand(bandMode?.band);
      }

      setAutoSpotData(null);
    }
  }, [autoSpotData]);

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) => getParkByReference(searchString),
    200
  );

  const onFrequencyBlur = () => {
    const freqNumber = parseFloat(values.frequency);
    const bandMode = findBandAndModeForFrequency(freqNumber);

    if (bandMode) {
      setValues((prev: any) => {
        return { ...prev, userMode: bandMode?.mode, band: bandMode?.band };
      });
      setUserMode(bandMode?.mode);
      setUserBand(bandMode?.band);
    } else {
      setUserMode("");
      setUserBand("");
    }
  };

  useEffect(() => {
    if (selectedSecondaryField === 1) {
      setTabIndexForTimeInputs("13", "14", "15");
    }
  }, [selectedSecondaryField]);

  const renderSecondaryFields = () => {
    if (selectedSecondaryField === 1) {
      return (
        <div className="flex items-center w-full gap-3">
          <TitledTimeInput
            setText={(e) => {
              setStopRealTimeChange(true);
              setUserTimeInput(e);
              setTime(e);
              setValues((prev: any) => {
                return { ...prev, time: e };
              });
            }}
            name="time"
            // title="Time (UTC)"
            placeHolder="00:00 AM"
            className="bg-white bg-opacity-10 h-[40px] mt-[0px]"
            width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
            type="time"
            value={userTimeInput ? values.time : currentTime}
            error={errors?.time && touched?.time ? errors?.time : undefined}
            containerClass={"mb-[22px]"}
          />
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return { ...prev, date: e.target.value };
              });
              setDate(e.target.value);
            }}
            name="date"
            // title="Date"
            placeHolder="dd/mm/yyyy"
            tabindex={16}
            width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
            type="date"
            value={date}
            className="bg-white bg-opacity-10 h-[40px] mt-[0px]"
            error={errors?.date && touched?.date ? errors?.date : undefined}
            props={
              {
                // max: new Date().toJSON().slice(0, 10),
              }
            }
            // containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            tabIndex={17}
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 2) {
      return (
        <div className="flex items-start w-full gap-3">
          {/* <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  theirPark: e.target.value.toUpperCase().trim(),
                };
              });
            }}
            name="theirPark"
            placeHolder="Their Park"
            onBlur={() => {
              if (values.theirPark) {
                debouncedGetParkByReference({
                  value: values.theirPark,
                  key: "theirPark",
                });
              }
            }}
            status={parkReferenceData?.theirPark ? "Park Match" : null}
            statusBg={
              parkReferenceData?.theirPark
                ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
                : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
            }
            isLoading={isParkReferenceDataLoading?.theirPark}
            width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
            value={values.theirPark}
            className="bg-white bg-opacity-10 h-[40px] mt-[0px]"
          /> */}
          <TheirParkField {...{values, setValues, touched, errors}} />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 3) {
      const addPotaAutoSpotHandler = () => {
        const parkReference = Object.keys(selectedLogBook?.myParks || {})?.[0];

        if (parkReference && values?.myCallSign && values?.frequency) {
          // add spot
          addPotaAutoSpot(
            {
              activator: values?.myCallSign,
              spotter: values?.myCallSign,
              reference: parkReference,
              comments: comments,
              frequency: `${parseFloat(values?.frequency) * 1000}`,
              mode: values?.userMode,
              band: values?.band,
            },
            selectedLogBook?.id
          );
        }

        setSelectedSecondaryField(0);
        setComments("");
      };

      return (
        <div>
          <div className="flex flex-row gap-x-3 items-center w-full flex-wrap">
            <InputWithButton
              setText={(e) => {
                handleChange(e);
                setValues((prev: any) => ({
                  ...prev,
                  frequency: e.target.value,
                }));
              }}
              title="Frequency"
              name="frequency"
              placeHolder="Frequency"
              onFieldBlur={onFrequencyBlur}
              width="w-[46%] sm:w-[20.5%] md:w-[20%]"
              value={values.frequency}
              btnTxt="MHz"
              fitBtn
              onBtnClick={() => {}}
              error={
                errors?.frequency && touched?.frequency
                  ? errors?.frequency
                  : undefined
              }
              className="bg-white bg-opacity-10 h-[40px]"
              btnHeight="h-[40px]"
              containerClass="-mt-[25px]"
            />
            <DropDownTitled
              onChange={(e) => {
                handleChange(e);
                setUserBand(e.target.value);
              }}
              name="band"
              info="The band you made the contact on."
              value={userBand ? userBand : ""}
              title="Band"
              placeHolder="Band"
              tabindex={5}
              className={"mb-1"}
              width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
              lists={AllBandsOption}
              getTitle={(item) => convertBandValue(item.name, item.unit)+item.unit}
              getValue={(item) => item.name}
              error={
                errors?.band && touched?.band ? errors?.band : undefined
              }
              containerClass=""
            />

            <DropDownTitled
              onChange={(e) => {
                handleChange(e);
                // setUserMode(e.target.value);
                setValues((prev: any) => ({
                  ...prev,
                  userMode: e.target.value,
                }));
              }}
              name="userMode"
              value={values.userMode}
              title="Mode"
              placeHolder="Mode"
              tabindex={6}
              width="w-[46%] sm:w-[20.5%] md:w-[15%]"
              lists={Modes}
              error={
                errors?.userMode && touched?.userMode
                  ? errors?.userMode
                  : undefined
              }
              containerClass="-mt-[25px]"
              className="bg-white bg-opacity-10 h-[40px]"
            />
            <TitledInput
              setText={(e) => {
                setComments(e.target.value);
              }}
              title="Comment"
              name="comments"
              placeHolder="Comments"
              onBlur={() => {}}
              width="w-[46%] sm:w-[30.5%] md:w-[30%]"
              value={comments}
              className="bg-white bg-opacity-10 h-[40px] mb-1"
            />
            <button
              type="button"
              className="flex items-center justify-center mb-[20px] md:mb-0 p-2 bg-blue-600  h-[40px] w-[40px]"
              onClick={addPotaAutoSpotHandler}
            >
              <img src={Icons.SendIcon} alt="" />
            </button>
            {selectedLogBook?.lastSpottedAt ? (
              <p className="text-white font-[Play] text-sm">
                POTA Auto Spot sent{" "}
                <span className="text-[#17F9DA]">
                  {moment(
                    new Date(selectedLogBook?.lastSpottedAt?.seconds * 1000)
                  ).fromNow()}
                </span>{" "}
                on{" "}
                <span className="text-[#17F9DA]">
                  {selectedLogBook?.lastSpottedFrequency}/
                  {selectedLogBook?.lastSpottedBand}
                </span>{" "}
                {selectedLogBook?.lastSpottedComment
                  ? selectedLogBook?.lastSpottedComment.substring(0, 10)
                  : ""}
              </p>
            ) : null}
          </div>
          {/* <div className="flex gap-1">
            <span className="text-white text-xs">Last AutoSpot sent</span>
            <span className="text-[#17F9DA] text-xs font-bold">
              5 min ago 14.250MHz SSB
            </span>
          </div> */}
        </div>
      );
    } else if (selectedSecondaryField === 4) {
      const powerSaveHandler = () => {
        updateLogbookSettings({ defaultPower: values?.power });
        setSelectedSecondaryField(0);
      };

      return (
        <div className="flex items-center w-full gap-3">
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  power: e.target.value,
                };
              });
            }}
            name="power"
            placeHolder="Power (W)"
            width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
            value={values.power}
            className="bg-white bg-opacity-10 h-[40px] mt-[0px]"
            tabindex={7}
            error={errors?.power && touched?.power ? errors?.power : undefined}
            containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={powerSaveHandler}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 5) {
      return (
        <div className="flex items-center w-full gap-3">
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  notes: e.target.value,
                };
              });
            }}
            name="notes"
            placeHolder="Notes"
            value={values.notes}
            width="w-[68.5%] sm:w-[50.5%] md:w-[50%] xl:w-[50%]"
            className="bg-white bg-opacity-10 h-[40px] mt-[0px]"
            tabindex={20}
            error={errors?.notes && touched?.notes ? errors?.notes : undefined}
            containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 7) {
      return (
        <div className="flex items-center w-full gap-3">
          <div className="flex flex-col lg:flex-row items-center gap-1 w-full">
            <Formik initialValues={{}} onSubmit={() => {}}>
              <Form className="flex flex-row gap-3 flex-wrap w-full">
                <SearchCountryPicker
                  name="country"
                  title="Their Country"
                  info="Country of the other operator."
                  tabindex={8}
                  value={values?.country}
                  countryCode={values?.countryCode}
                  placeHolder="Country"
                  dxccNumber={values?.dxccNumber || ""}
                  width={
                    values?.country && values?.country?.length
                      ? "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[19%]"
                      : "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[27.5%]"
                  }
                  containerClass={display ? "pointer-events-none" : ""}
                  setText={(val) => {
                    const dxccData = getDxDataFromCountry(val);
                    setValues((prev: any) => {
                      return {
                        ...prev,
                        country: val,
                        grid: val !== values?.country ? "" : values?.grid,
                        dxccNumber: dxccData?.dxccNumber || "",
                        continent: dxccData?.continent || "",
                        flagCode: dxccData?.flagCode || "",
                        countryCode: dxccData?.countryCode || "",
                        state: val !== values?.country ? "" : values?.state,
                      };
                    });
                  }}
                />

                {values?.country && values?.country.length ? (
                  <TitledRegionPicker
                    setText={(val) => {
                      setValues((prev: any) => {
                        return {
                          ...prev,
                          state: val,
                          grid: val !== values?.state ? "" : values?.grid,
                        };
                      });
                    }}
                    name="state"
                    title="Their State"
                    info="State of the other operator."
                    placeHolder="State"
                    tabindex={9}
                    width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
                    className={"mb-1 bg-[#23234C]"}
                    value={values?.state}
                    error={
                      errors?.state && touched?.state
                        ? errors?.state
                        : undefined
                    }
                    country={
                      LocationMappings[`${values?.country}`] || values?.country
                    }
                    containerClass={display ? "pointer-events-none" : ""}
                  />
                ) : null}
                <TitledInput
                  setText={(e) => {
                    const value = e.target.value;
                    setValues((prev: any) => ({
                      ...prev,
                      grid: value,
                    }));
                  }}
                  name="grid"
                  title="Their Grid"
                  value={values?.grid}
                  placeHolder="Grid"
                  className="bg-[#23234C]"
                  width="w-[46%] sm:w-[30.5%] md:w-[20%]"
                  containerClass=""
                  onBlur={() => {
                    if (values.grid) {
                      getUserCountryFromGrid(values.grid, setValues);
                    }
                  }}
                />
                <button
                  type="button"
                  className="flex items-center justify-center mt-[24px] p-2 bg-blue-600  h-[36px] w-[36px]"
                  onClick={() => setSelectedSecondaryField(-1)}
                >
                  <img src={Icons.SendIcon} alt="" />
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      );
    }

    return null;
  };

  const debouncedCallsignInformation = useDebouncedCallback((searchString) => {
    if (searchString) {
      getUserFromHamApiData(searchString, setValues, onFrequencyBlur);
    } else {
      handleClearAddContactForm(initialData, setTouched, setValues);
    }

    callSignBlurPopupHandler(searchString);
  }, 800);

  const theirCallSignChangeHandler = (e: any) => {
    const theirCallSign = e.target.value.toUpperCase().trim();
    setValues((prev: any) => {
      return {
        ...prev,
        theirCallsign: theirCallSign,
      };
    });
    debouncedCallsignInformation(theirCallSign);
  };

  const getTheirParkLabel = ()=>{
    const theirParks = Object.keys(values?.theirParks || {});
    if(theirParks){
      if(theirParks.length > 1){
        return "Multiple Parks"
      }else if(theirParks.length === 1){
        return theirParks[0];
      }
      return "Park to Park";
    }
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap">
        <TitledInput
          // setText={handleChange}
          setText={theirCallSignChangeHandler}
          name="theirCallsign"
          title="Their Callsign"
          placeHolder="Their Callsign"
          tabindex={1}
          // onBlur={() => {
          //   // if (values.theirCallsign) {
          //   //   getUserFromHamApiData(values.theirCallsign, setValues);
          //   // }
          //   // callSignBlurPopupHandler(values.theirCallsign);

          //   console.log("blurred");
          // }}
          status={
            callSignData !== "NOT_FOUND" && callSignData
              ? "Callsign Match"
              : null
          }
          statusBg={
            callSignData !== "NOT_FOUND" && callSignData
              ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
              : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
          }
          isLoading={isCallSignDataLoading}
          width="w-[48.5%] sm:w-[19.5%] md:w-[15%] xl:w-[13%]"
          value={values.theirCallsign}
          className={"mb-1.5"}
          error={
            errors?.theirCallsign && touched?.theirCallsign
              ? errors?.theirCallsign
              : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          props={{ innerRef: callSignRef }}
        />
        <TitledInput
          setText={handleChange}
          name="rstSent"
          title="RST Sent"
          info='Signal report that you gave to the other operator. "59" is a good signal.'
          placeHolder="RST Sent"
          value={values.rstSent}
          tabindex={2}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[15.5%] md:w-[12%] xl:w-[12%]"
          error={
            errors?.rstSent && touched?.rstSent ? errors?.rstSent : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <TitledInput
          setText={handleChange}
          name="rstRCVD"
          title="RST RCVD"
          info='Signal report that you received from the other operator. "59" would be a good signal.'
          placeHolder="RST RCVD"
          value={values.rstRCVD}
          tabindex={3}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[15.5%] md:w-[12%] xl:w-[12%]"
          error={
            errors?.rstRCVD && touched?.rstRCVD ? errors?.rstRCVD : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <InputWithButton
          setText={(e) => {
            handleChange(e);
            setValues((prev: any) => ({
              ...prev,
              frequency: e.target.value,
            }));
          }}
          title="Frequency"
          name="frequency"
          placeHolder="Frequency"
          onFieldBlur={onFrequencyBlur}
          width="w-[48.5%] sm:w-[21.5%] md:w-[12%] xl:w-[12%]"
          value={values.frequency}
          btnTxt="MHz"
          fitBtn
          onBtnClick={() => {}}
          error={
            errors?.frequency && touched?.frequency
              ? errors?.frequency
              : undefined
          }
          className="mb-1"
          btnHeight="h-8"
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          tabindex={4}
        />
        {/* <TitledInput
          setText={(e) => {
            const value = e.target.value;
            // Check if the value is a number
            if (!isNaN(value)) {
              setValues((prev: any) => {
                return { ...prev, band: value.trim() };
              });
              setUserBand(value.trim());
            }
          }}
          name="band"
          title="Band"
          info="The band you made the contact on."
          value={userBand}
          placeHolder="Band"
          tabindex={5}
          className={"mb-3"}
          width="w-[48.5%] sm:w-[15.5%] md:w-[12%] xl:w-[12%]"
          error={errors?.band && touched?.band ? errors?.band : undefined}
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        /> */}
        <DropDownTitled
          onChange={(e) => {
            handleChange(e);
            setUserBand(e.target.value);
          }}
          name="band"
          info="The band you made the contact on."
          value={userBand ? userBand : ""}
          title="Band"
          placeHolder="Band"
          tabindex={5}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%]"
          lists={AllBandsOption}
          getTitle={(item) => convertBandValue(item.name, item.unit)+item.unit}
          getValue={(item) => item.name}
          error={
            errors?.band && touched?.band ? errors?.band : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <DropDownTitled
          onChange={(e) => {
            handleChange(e);
            setUserMode(e.target.value);
            setValues((prev: any) => ({
              ...prev,
              userMode: e.target.value,
            }));
          }}
          name="userMode"
          info="The mode of operation you are using on your radio."
          value={userMode ? userMode : ""}
          title="Mode"
          placeHolder="Mode"
          tabindex={6}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[15.5%] md:w-[12%] xl:w-[12%]"
          lists={Modes}
          error={
            errors?.userMode && touched?.userMode ? errors?.userMode : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <div className="flex  items-end sm:items-end md:items-center w-[48.5%] sm:w-[19.5%] md:w-[15%] xl:w-[13%] mb-1 md:mb-2">
          <BorderButtonSecondary
            isLoading={isSavingOnLoading}
            text={"Log Contact"}
            onClick={() => {
              // setUserBand("");
              // setUserMode("");
              // setDate(getCurrentDate());
              onLogPress && onLogPress();
            }}
            childClassName="text-sm"
            className={display ? "pointer-events-none" : ""}
            tabIndex={7}
          />
        </div>
      </div>

      {/* POta secondary fields */}

      <SelectableButtonsGroup
        initialSelected={selectedSecondaryField}
        toggle
        disabled={display}
        actions={[
          {
            id: 1,
            icon: Icons.clockWhiteIcon,
            label: `${
              userTimeInput ? values.time : currentTime || "00:00:00"
            } z`,
            tabIndex: 8,
          },
          {
            id: 2,
            icon: Icons.parkToParkWhite,
            label: getTheirParkLabel(),
            tabIndex: 9,
          },
          {
            id: 3,
            icon: Icons.parkWhite,
            label: "POTA Autospot",
            tabIndex: 10,
          },
          {
            id: 7,
            icon: Icons.LocationPinIcon,
            label: values?.grid || "Their Location",
            tabIndex: 13,
          },
          {
            id: 4,
            icon: Icons.powerWhite,
            label: values.power ? `${values.power} Watts` : "Power",
            tabIndex: 11,
          },
          {
            id: 5,
            icon: Icons.notesWhite,
            label: "Notes",
            tabIndex: 12,
          },
          // Hide tags for now
          // {
          //   id: 6,
          //   icon: Icons.tagsWhite,
          //   label: "Tags",
          // },
        ]}
        onButtonChange={(action) => {
          if (action && (action.id as number) > 0) {
            setSelectedSecondaryField(action.id as number);
          } else {
            setSelectedSecondaryField(-1);
          }
        }}
      />

      {selectedSecondaryField > 0 ? (
        <div className="flex w-full bg-indigo-500 bg-opacity-10  mt-4 items-center p-4">
          {renderSecondaryFields()}
        </div>
      ) : null}

      <div className="flex mt-4">
        {searchParams.get("contest_id") ? (
          <div className="flex flex-wrap gap-6 w-full items-center">
            {contestDetail?.exchangeOne || contestDetail?.exchangeTwo ? (
              <p className="w-full md:w-[30%] xl:w-[27.5%] text-white font-[Play] text-base font-bold">
                CONTEST EXCHANGE
              </p>
            ) : null}

            {contestDetail?.exchangeOne ? (
              <TitledInputGradient
                setText={handleChange}
                name="exchangeOne"
                title={contestDetail?.exchangeOne}
                placeHolder="Enter Answer"
                value={values.exchangeOne}
                width={"w-full sm:w-[47.5%] md:w-[30%] xl:w-[27.5%]"}
                className={"mb-3 placeholder-[#000]"}
                tabindex={20}
                error={undefined}
              />
            ) : null}
            {contestDetail?.exchangeTwo ? (
              <TitledInputGradient
                setText={handleChange}
                name="exchangeTwo"
                title={contestDetail?.exchangeTwo}
                placeHolder="Enter Answer"
                value={values.exchangeTwo}
                width={"w-full sm:w-[47.5%] md:w-[30%] xl:w-[27.5%]"}
                className={"mb-3 placeholder-[#000]"}
                tabindex={21}
                error={undefined}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default POTALog;
