import NavItem from "components/NavItem";
import {
  navMenuLogIn,
  navMenuMembersDirectory,
  navMenuMembership,
  navMenuMembershipStatus,
  navMenus,
  navMenusSetting,
} from "constants/NavMenus";
import useAuth from "hooks/auth.hook";
import { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import gsap from "gsap";
import useConfig from "hooks/config.hook";
import HeaderNavBar from "components/Header/HeaderNavBar";
import { MY_PROFILE_ROUTE } from "constants/Links";
import useProfile from "hooks/profile.hook";
import { NavMenuProps } from "types/Navigation";
import CustomValidation from "helpers/Validation";

function NavBar() {
  const { logOut } = useAuth();
  const { userProfile } = useProfile();
  const navBarContainer = useRef(null);
  const [navMenusMain, setNavMenusMain] = useState<NavMenuProps[]>([]);
  const [navMenusMainOnResponsive, setNavMenusMainOnResponsive] =
    useState<NavMenuProps | null>(null);
  const navMenusSettings = userProfile
    ? navMenusSetting
    : [...navMenusSetting.slice(0, 1), navMenuLogIn];
  const {
    handleNavBarVisibility,
    handlePrimaryModal,
    primaryModalData,
    isMenuOpen,
  } = useConfig();

  const hideNav = () => {
    handleNavBarVisibility(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      gsap.to(navBarContainer.current, { right: 0 });
    } else if (!isMenuOpen) {
      gsap.to(navBarContainer.current, { right: -288 });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.subscriptionStatus === "active") {
        setNavMenusMain([...navMenus, navMenuMembersDirectory]);
        setNavMenusMainOnResponsive(navMenuMembershipStatus);
      } else {
        setNavMenusMain([
          ...navMenus,
          navMenuMembership,
          navMenuMembersDirectory,
        ]);
      }
    } else {
      setNavMenusMain([
        ...navMenus,
        navMenuMembership,
        navMenuMembersDirectory,
      ]);
    }
  }, [userProfile]);

  return (
    <section
      ref={navBarContainer}
      className="w-72 lg:w-1/5 z-50 absolute lg:relative glassmorphism overflow-x-hidden h-screen"
      style={{ right: 0 }}
    >
      <div
        className="right-0 absolute lg:hidden w-10 h-10 flex justify-center items-center cursor-pointer z-10"
        onClick={hideNav}
      >
        <IoMdClose className="text-white" size={"16px"} />
      </div>
      <HeaderNavBar />
      {navMenusMain.map((item, index) => {
        return (
          <NavItem
            key={index}
            color=""
            link={
              item?.link === MY_PROFILE_ROUTE && !userProfile
                ? "/register"
                : item?.link === MY_PROFILE_ROUTE
                ? `${item?.link}/${CustomValidation.encodeSlash(
                    userProfile?.callSign ? userProfile?.callSign : ""
                  )}?tab=Logbook&contact-tab=entries`
                : item.link
            }
            text={item.title}
            newFeature={item.new}
            icon={item.icon}
            redirect={item?.redirect}
            onPress={() => {}}
          />
        );
      })}
      {navMenusMainOnResponsive ? (
        <div className="block lg:hidden">
          <NavItem
            color=""
            link={navMenusMainOnResponsive.link}
            text={navMenusMainOnResponsive.title}
            icon={navMenusMainOnResponsive.icon}
            redirect={navMenusMainOnResponsive?.redirect}
            onPress={() => {}}
          />
        </div>
      ) : null}
      <div className="settingNavBar w-full">
        {navMenusSettings.map((item, index) => {
          return (
            <NavItem
              key={index}
              link={item?.link}
              color={item?.color}
              text={item.title}
              newFeature={item.new}
              icon={item.icon}
              redirect={item?.redirect}
              onPress={async () => {
                if (window.innerWidth < 1024) {
                  hideNav();
                }
                if (item.title === "Log out") {
                  if (primaryModalData) {
                    handlePrimaryModal(null);
                  } else {
                    handlePrimaryModal({
                      title: "Are you sure you want to sign out?",
                      type: "Signout",
                      onCancelPressed: () => handlePrimaryModal(null),
                      onSubmitPressed: () => {
                        logOut();
                        handlePrimaryModal(null);
                      },
                      submitText: "Signout",
                      cancelText: "Cancel",
                    });
                  }
                }
              }}
            />
          );
        })}
      </div>
    </section>
  );
}

export default NavBar;
