import React from "react";
import SpotItem from "components/SpotItem";
import { SpottingViewProps } from "types/Component";
import { useDispatch } from "react-redux";
import { SpotModel } from "types/Models";
import { setAddSpotModal } from "store/Actions/logBook.actions";

function SpottingGridView({ spotsData, onCopyPressed }: SpottingViewProps) {
  const dispatch = useDispatch();
  const onReSpotClicked = (item: SpotModel) => {
    dispatch(setAddSpotModal(item));
  };
  return (
    <div className="w-full flex flex-wrap items-center justify-center">
      {spotsData?.map((item) => {
        return (
          <SpotItem
            spot={item}
            key={`${item.id}`}
            onCopyPressed={onCopyPressed}
            onReSpotClicked={onReSpotClicked}
          />
        );
      })}
    </div>
  );
}

export default SpottingGridView;
