import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useMember from "hooks/member.hook";
import ReactResizeDetector from "react-resize-detector";
import Globe from "react-globe.gl";

import { htmlElementsData } from "./GlobeMarker";
import { useDebouncedCallback } from "use-debounce";
import { getGlobeMembers } from "store/Actions/members.actions";

const MembershipGlobe = () => {
  const { globeData } = useMember();
  const dispatch = useDispatch();
  const [isWebGLEnabled, setIsWebGLEnabled] = useState(false);

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const context =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    if (context) {
      setIsWebGLEnabled(true);
    }
  }, []);


  const debouncedZoomChangeListener = useDebouncedCallback(
    (event) => {
      if (event) {
        const lat = event.lat;
        const lng = event.lng;
        dispatch(
          getGlobeMembers({
            lat,
            lng,
          })
        );
      }
    },
    // delay in ms
    1000
  );

  return (
    <div className="w-full flex justify-center items-center">
      {isWebGLEnabled ? (
        <ReactResizeDetector handleWidth>
        {({ width }) => {
          return (
            <Globe
              globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
              htmlElementsData={globeData}
              onZoom={debouncedZoomChangeListener}
              backgroundColor="rgba(0,0,0,0)"
              width={width ? width : 650}
              height={width ? width : 650}
              htmlElement={htmlElementsData}
            />
          );
        }}
      </ReactResizeDetector>
      ) : (null)}
      
    </div>
  );
};

export default MembershipGlobe;
