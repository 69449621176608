import React, { useState, useEffect } from "react";
import Icons from "assets/Icons";
import StationItem from "components/StationItem";
import { useDispatch } from "react-redux";
import { fetchRadios, setAddRadioModal } from "store/Actions/logBook.actions";
import useLogbookSetting from "hooks/logbookSetting.hook";
import ActivityIndicator from "components/Loading/ActivityIndicator";

function MyRadios({analytics, loading}: {analytics: Record<string, number>; loading?: boolean}) {
  const dispatch = useDispatch();
  const { radios, logbookSettingLoading } = useLogbookSetting();

  return (
    <div className="flex flex-wrap w-full md:justify-start justify-center">
      {logbookSettingLoading ? (
        <ActivityIndicator size={28} />
      ) : (
        <div className="flex flex-wrap w-full md:justify-start justify-center">
          {radios.map((item: any, index) => (
            <StationItem
              data={{...item, numberOfQSO: analytics?.[item.id] || 0}}
              onClick={() => {
                dispatch(setAddRadioModal(item));
              }}
              type="Radio"
              key={index}
              loading={loading}
            />
          ))}
          <StationItem
            key={-1}
            add={true}
            buttonText="Add Radio"
            onClick={() => {
              dispatch(setAddRadioModal(true));
            }}
          />
        </div>
      )}
    </div>
  );
}

export default MyRadios;
