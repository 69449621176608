import { hasAccessTo } from "helpers/Utils";
import React, { useEffect } from "react";
import { AccessControlProps } from "types/Component";

function AccessControl({ permission, children }: AccessControlProps) {
  const [hasAccess, setHasAccess] = React.useState(false);

  useEffect(() => {
    checkPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission, children]);

  const checkPermission = async () => {
    const hasAccess = await hasAccessTo(permission);
    if (hasAccess) {
      setHasAccess(true);
    } else {
      setHasAccess(false);
    }
  };

  return <>{hasAccess ? children : null}</>;
}

export default AccessControl;
