export const SUBSCRIPTION_SPOTS_AVAILABLE = 5000;

export const stateFlags: any = {
  Alabama:
    "http://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Alabama.svg",
  Alaska:
    "http://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Alaska.svg",
  Arizona:
    "http://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arizona.svg",
  Arkansas:
    "http://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arkansas.svg",
  California:
    "http://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg",
  Colorado:
    "http://upload.wikimedia.org/wikipedia/commons/4/46/Flag_of_Colorado.svg",
  Connecticut:
    "http://upload.wikimedia.org/wikipedia/commons/9/96/Flag_of_Connecticut.svg",
  Delaware:
    "http://upload.wikimedia.org/wikipedia/commons/c/c6/Flag_of_Delaware.svg",
  Florida:
    "http://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Florida.svg",
  Georgia:
    "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_the_State_of_Georgia.svg",
  Hawaii:
    "http://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Hawaii.svg",
  Idaho: "http://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_Idaho.svg",
  Illinois:
    "http://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Illinois.svg",
  Indiana:
    "http://upload.wikimedia.org/wikipedia/commons/a/ac/Flag_of_Indiana.svg",
  Iowa: "http://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Iowa.svg",
  Kansas:
    "http://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Kansas.svg",
  Kentucky:
    "http://upload.wikimedia.org/wikipedia/commons/8/8d/Flag_of_Kentucky.svg",
  Louisiana:
    "http://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_Louisiana.svg",
  Maine: "http://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_Maine.svg",
  Maryland:
    "http://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Maryland.svg",
  Massachusetts:
    "http://upload.wikimedia.org/wikipedia/commons/f/f2/Flag_of_Massachusetts.svg",
  Michigan:
    "http://upload.wikimedia.org/wikipedia/commons/b/b5/Flag_of_Michigan.svg",
  Minnesota:
    "http://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Minnesota.svg",
  Mississippi:
    "http://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_Mississippi.svg",
  Missouri:
    "http://upload.wikimedia.org/wikipedia/commons/5/5a/Flag_of_Missouri.svg",
  Montana:
    "http://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_Montana.svg",
  Nebraska:
    "http://upload.wikimedia.org/wikipedia/commons/4/4d/Flag_of_Nebraska.svg",
  Nevada:
    "http://upload.wikimedia.org/wikipedia/commons/f/f1/Flag_of_Nevada.svg",
  "New Hampshire":
    "http://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_New_Hampshire.svg",
  "New Jersey":
    "http://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_New_Jersey.svg",
  "New Mexico":
    "http://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_New_Mexico.svg",
  "New York":
    "http://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_New_York.svg",
  "North Carolina":
    "http://upload.wikimedia.org/wikipedia/commons/b/bb/Flag_of_North_Carolina.svg",
  "North Dakota":
    "http://upload.wikimedia.org/wikipedia/commons/e/ee/Flag_of_North_Dakota.svg",
  Ohio: "http://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Ohio.svg",
  Oklahoma:
    "http://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Oklahoma.svg",
  Oregon:
    "http://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Oregon.svg",
  Pennsylvania:
    "http://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Pennsylvania.svg",
  "Rhode Island":
    "http://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Rhode_Island.svg",
  "South Carolina":
    "http://upload.wikimedia.org/wikipedia/commons/6/69/Flag_of_South_Carolina.svg",
  "South Dakota":
    "http://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_South_Dakota.svg",
  Tennessee:
    "http://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Tennessee.svg",
  Texas: "http://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Texas.svg",
  Utah: "http://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Utah.svg",
  Vermont:
    "http://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Vermont.svg",
  Virginia:
    "http://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Virginia.svg",
  Washington:
    "http://upload.wikimedia.org/wikipedia/commons/5/54/Flag_of_Washington.svg",
  "West Virginia":
    "http://upload.wikimedia.org/wikipedia/commons/2/22/Flag_of_West_Virginia.svg",
  Wisconsin:
    "http://upload.wikimedia.org/wikipedia/commons/2/22/Flag_of_Wisconsin.svg",
  Wyoming:
    "http://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Wyoming.svg",
  WashingtonDC:
    "http://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_Washington%2C_D.C..svg",
};

export const usStatesCoordinates = [
  {name: "Wisconsin", shortCode: "WI", lat: 44.5, lng: -89.5},
  {name: "West Virginia", shortCode: "WV", lat: 39.0, lng: -80.5},
  {name: "Vermont", shortCode: "VT", lat: 44.0, lng: -72.699997},
  {name: "Texas", shortCode: "TX", lat: 31.0, lng: -100.0},
  {name: "South Dakota", shortCode: "SD", lat: 44.5, lng: -100.0},
  {name: "Rhode Island", shortCode: "RI", lat: 41.742325, lng: -71.742332},
  {name: "Oregon", shortCode: "OR", lat: 44.0, lng: -120.5},
  {name: "New York", shortCode: "NY", lat: 43.0, lng: -75.0},
  {name: "New Hampshire", shortCode: "NH", lat: 44.0, lng: -71.5},
  {name: "Nebraska", shortCode: "NE", lat: 41.5, lng: -100.0},
  {name: "Kansas", shortCode: "KS", lat: 38.5, lng: -98.0},
  {name: "Mississippi", shortCode: "MS", lat: 33.0, lng: -90.0},
  {name: "Illinois", shortCode: "IL", lat: 40.0, lng: -89.0},
  {name: "Delaware", shortCode: "DE", lat: 39.0, lng: -75.5},
  {name: "Connecticut", shortCode: "CT", lat: 41.599998, lng: -72.699997},
  {name: "Arkansas", shortCode: "AR", lat: 34.799999, lng: -92.199997},
  {name: "Indiana", shortCode: "IN", lat: 40.273502, lng: -86.126976},
  {name: "Missouri", shortCode: "MO", lat: 38.573936, lng: -92.60376},
  {name: "Florida", shortCode: "FL", lat: 27.994402, lng: -81.760254},
  {name: "Nevada", shortCode: "NV", lat: 39.876019, lng: -117.224121},
  {name: "Maine", shortCode: "ME", lat: 45.367584, lng: -68.972168},
  {name: "Michigan", shortCode: "MI", lat: 44.182205, lng: -84.506836},
  {name: "Georgia", shortCode: "GA", lat: 33.247875, lng: -83.441162},
  {name: "Hawaii", shortCode: "HI", lat: 19.741755, lng: -155.844437},
  {name: "Alaska", shortCode: "AK", lat: 66.160507, lng: -153.369141},
  {name: "Tennessee", shortCode: "TN", lat: 35.860119, lng: -86.660156},
  {name: "Virginia", shortCode: "VA", lat: 37.926868, lng: -78.024902},
  {name: "New Jersey", shortCode: "NJ", lat: 39.833851, lng: -74.871826},
  {name: "Kentucky", shortCode: "KY", lat: 37.839333, lng: -84.27002},
  {name: "North Dakota", shortCode: "ND", lat: 47.650589, lng: -100.437012},
  {name: "Minnesota", shortCode: "MN", lat: 46.39241, lng: -94.63623},
  {name: "Oklahoma", shortCode: "OK", lat: 36.084621, lng: -96.921387},
  {name: "Montana", shortCode: "MT", lat: 46.96526, lng: -109.533691},
  {name: "Washington", shortCode: "WA", lat: 47.751076, lng: -120.740135},
  {name: "Utah", shortCode: "UT", lat: 39.41922, lng: -111.950684},
  {name: "Colorado", shortCode: "CO", lat: 39.113014, lng: -105.358887},
  {name: "Ohio", shortCode: "OH", lat: 40.367474, lng: -82.996216},
  {name: "Alabama", shortCode: "AL", lat: 32.31823, lng: -86.902298},
  {name: "Iowa", shortCode: "IA", lat: 42.032974, lng: -93.581543},
  {name: "New Mexico", shortCode: "NM", lat: 34.307144, lng: -106.018066},
  {name: "South Carolina", shortCode: "SC", lat: 33.836082, lng: -81.163727},
  {name: "Pennsylvania", shortCode: "PA", lat: 41.203323, lng: -77.194527},
  {name: "Arizona", shortCode: "AZ", lat: 34.048927, lng: -111.093735},
  {name: "Maryland", shortCode: "MD", lat: 39.045753, lng: -76.641273},
  {name: "Massachusetts", shortCode: "MA", lat: 42.407211, lng: -71.382439},
  {name: "California", shortCode: "CA", lat: 36.778259, lng: -119.417931},
  {name: "Idaho", shortCode: "ID", lat: 44.068203, lng: -114.742043},
  {name: "Wyoming", shortCode: "WY", lat: 43.07597, lng: -107.290283},
  {name: "North Carolina", shortCode: "NC", lat: 35.782169, lng: -80.793457},
  {name: "Louisiana", shortCode: "LA", lat: 30.39183, lng: -92.329102},
];

export const memberShipQuotas: any = {
  FREE: 3000000,
  MEMBER: 10000000,
  PREMIUM: 30000000,
  VIP: 150000000,
};

export const mapDownDown: any = [
  {
    subscriptionType: 100,
  },
  {
    subscriptionType: 200,
  },
  {
    subscriptionType: 300,
  },
  {
    subscriptionType: 400,
  },
  {
    subscriptionType: 500,
  },
];

export const mapImported: any = [
  {
    subscriptionType: "No",
  },
];

export const spottingBandList = [
  {
    color: "#AAAAAA",
    name: "2200",
    enabled: false,
  },
  {
    color: "#FFE000",
    name: "630",
    enabled: false,
  },
  {
    color: "#093F00",
    name: "160",
    enabled: false,
  },
  {
    color: "#777777",
    name: "80",
    enabled: false,
  },
  {
    color: "#FFA500",
    name: "60",
    enabled: false,
  },
  {
    color: "#FF0000",
    name: "40",
    enabled: false,
  },
  {
    color: "#800080",
    name: "30",
    enabled: false,
  },
  {
    color: "#0000FF",
    name: "20",
    enabled: false,
  },
  {
    color: "#444444",
    name: "17",
    enabled: false,
  },
  {
    color: "#00FFFF",
    name: "15",
    enabled: false,
  },
  {
    color: "#FF00FF",
    name: "12",
    enabled: false,
  },
  {
    color: "#FFC0CB",
    name: "10",
    enabled: false,
  },
  {
    color: "#A276FF",
    name: "6",
    enabled: false,
  },
  {
    color: "#92FF7F",
    name: "2",
    enabled: false,
  },
];

export const spottingModes = [
  {
    name: "CW",
    enabled: false,
  },
  {
    name: "SSB",
    enabled: false,
  },
  {
    name: "USB",
    enabled: false,
  },
  {
    name: "LSB",
    enabled: false,
  },
  {
    name: "DIG",
    enabled: false,
  },
  {
    name: "FM",
    enabled: false,
  },
  {
    name: "TV",
    enabled: false,
  },
  {
    name: "AM",
    enabled: false,
  },
  {
    name: "FT4",
    enabled: false,
  },
  {
    name: "FT8",
    enabled: false,
  },
  {
    name: "D-STAR",
    enabled: false,
  },
  {
    name: "Fusion",
    enabled: false,
  },
];

export const spottingSourceColor: any = {
  POTA: "#5AB25D",
  "WRL Logbook": "#DF8600",
  "WRL Spotting": "#007E05",
};

export const Templates = {
  DEFAULT_WRL: "Default WRL",
  N4ML: "The N4ML Logbook",
  POTA: "POTA",
  VIEW: "View Templates",
};

export const LogbookTemplatesOptions = [
  {
    label: Templates.DEFAULT_WRL,
    value: Templates.DEFAULT_WRL,
  },
  {
    label: Templates.N4ML,
    value: Templates.N4ML,
  },
  {
    label: Templates.POTA,
    value: Templates.POTA,
  },
  {
    label: Templates.VIEW,
    value: "view",
  },
];

export const DefaultLocationTypes = [
  {
    label: "Home",
    value: "Home",
  },
  {
    label: "Office",
    value: "Office",
  },
  {
    label: "Park",
    value: "Park",
  },
  {
    label: "Mobile",
    value: "Mobile",
  },
  {
    label: "Portable",
    value: "Portable",
  },
  {
    label: "Others",
    value: "Others",
  },
];
export const RadioBrands = [
  {
    label: "Icom",
    value: "Icom",
  },
  {
    label: "Yaesu",
    value: "Yaesu",
  },
  {
    label: "Elecraft",
    value: "Elecraft",
  },
  {
    label: "Flex",
    value: "Flex",
  },
  {
    label: "Kenwood",
    value: "Kenwood",
  },
  {
    label: "Alinco",
    value: "Alinco",
  },
  {
    label: "Xiegu",
    value: "Xiegu",
  },
  {
    label: "Ten - Tec",
    value: "Ten - Tec",
  },
  {
    label: "Heathkit",
    value: "Heathkit",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const AntennaTypes = [
  {
    label: "Dipole",
    value: "Dipole",
  },
  {
    label: "End-Fed Half Wave",
    value: "End-Fed Half Wave",
  },
  {
    label: "Yagi",
    value: "Yagi",
  },
  {
    label: "Hex Beam",
    value: "Hex Beam",
  },
  {
    label: "Vertical",
    value: "Vertical",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const CallSignTypes = [
  {
    label: "Individual",
    value: "Individual",
  },
  {
    label: "Club",
    value: "Club",
  },
  {
    label: "Special Event",
    value: "Special Event",
  },
];

export const IsAssistedOption = [
  {
    label: "Assisted",
    value: "Assisted",
  },
  {
    label: "Non-Assisted",
    value: "Non-Assisted",
  },
];

export const BandOption = [
  {
    label: "Single",
    value: "Single",
  },
  {
    label: "Multi",
    value: "Multi",
  },
];

export const TAGS = {
  POTA: "pota",
}