import {
  ContestModel,
  ContestantDetail,
  LogBookContactModel,
} from "types/Models";

export const SHOW_ADD_CONTEST_MODAL = "SHOW_ADD_CONTEST_MODAL";
export const STORE_CONTEST_BY_ADMIN = "STORE_CONTEST_BY_ADMIN";
export const CONTEST_LOADING_STATUS = "CONTEST_LOADING_STATUS";

export const FETCH_ACTIVE_CONTEST_DATA = "FETCH_CONTEST_DATA";
export const FETCH_UPCOMING_CONTEST_DATA = "FETCH_CONTEST_DATA";
export const FETCH_PASSED_CONTEST_DATA = "FETCH_CONTEST_DATA";

export const SET_ACTIVE_CONTEST = "SET_ACTIVE_CONTEST";
export const SET_UPCOMING_CONTEST = "SET_UPCOMING_CONTEST";
export const SET_PASSED_CONTEST = "SET_PASSED_CONTEST";

export const FETCH_CONTEST_DETAILS = "FETCH_CONTEST_DETAILS";

export const SET_CONTEST_DETAILS = "SET_CONTEST_DETAILS";

export const SET_ENROLL_SUCCESS = "SET_ENROLL_SUCCESS";

export const ENROLL_CONTEST = "ENROLL_CONTEST";

export const SET_CONTESTANT_DETAIL = "SET_CONTESTANT_DETAIL";

export const GET_USER_CONTEST_RANKING = "GET_USER_CONTEST_RANKING";

export const SAVE_USER_CONTEST_RANKING = "SAVE_USER_CONTEST_RANKING";

export const GET_CONTEST_LEADER = "GET_CONTEST_LEADER";

export const SET_CONTEST_LEADER_DATA = "SET_CONTEST_LEADER_DATA";

export const GET_CONTEST_LEADERBOARD = "GET_CONTEST_LEADERBOARD";
export const SET_CONTEST_LEADERBOARD = "SET_CONTEST_LEADERBOARD";

export const GET_CONTEST_CONTACTS = "GET_CONTEST_CONTACTS";

export const SET_CONTEST_CONTACTS = "SET_CONTEST_CONTACTS";

export const GET_CONTESTANT_DETAIL = "GET_CONTESTANT_DETAIL";

// export const SET_CONTESTANT_LOGS = "SET_CONTESTANT_LOGS";
// export const SET_CONTESTANT_RANKING = "SET_CONTESTANT_RANKING";
export const SET_USER_CONTESTANT_DETAILS = "SET_USER_CONTESTANT_DETAILS";
export const SET_CONTEST_ID_FOR_EDIT_MODAL = "SET_CONTEST_ID_FOR_EDIT_MODAL";
export const EDIT_CONTEST = "EDIT_CONTEST";

export const FETCH_AWARD_CONTEST = "FETCH_AWARD_CONTEST";
export const SET_AWARD_CONTEST = "SET_AWARD_CONTEST";

export const UPDATE_CONTEST = "UPDATE_CONTEST";

export interface ContestActionPayload {
  type: string;
  payload: any;
}

export function setShowAddContestModal(data: boolean): ContestActionPayload {
  return {
    type: SHOW_ADD_CONTEST_MODAL,
    payload: data,
  };
}

export function setEditContest(
  data: ContestModel,
  newImg: boolean
): ContestActionPayload {
  return {
    type: EDIT_CONTEST,
    payload: {
      data,
      newImg,
    },
  };
}

export function setContestIdForEditModel(
  data: ContestModel | null
): ContestActionPayload {
  return {
    type: SET_CONTEST_ID_FOR_EDIT_MODAL,
    payload: data,
  };
}

export function storeContestActionForAdmin(data: any): ContestActionPayload {
  return {
    type: STORE_CONTEST_BY_ADMIN,
    payload: data,
  };
}

export function fetchActiveContestData(): ContestActionPayload {
  return {
    type: FETCH_ACTIVE_CONTEST_DATA,
    payload: null,
  };
}

export function fetchUpcomingContestData(): ContestActionPayload {
  return {
    type: FETCH_UPCOMING_CONTEST_DATA,
    payload: null,
  };
}

export function fetchPassedContestData(): ContestActionPayload {
  return {
    type: FETCH_PASSED_CONTEST_DATA,
    payload: null,
  };
}

export function fetchContestDetails(data: any): ContestActionPayload {
  return {
    type: FETCH_CONTEST_DETAILS,
    payload: data,
  };
}

export function setContestDetails(contest: ContestModel | null): ContestActionPayload {
  return {
    type: SET_CONTEST_DETAILS,
    payload: contest,
  };
}

export function setEnrollDetails(enrollDetails: null): ContestActionPayload {
  return {
    type: SET_ENROLL_SUCCESS,
    payload: enrollDetails,
  };
}

export function enrollContest(contest: ContestModel): ContestActionPayload {
  return {
    type: ENROLL_CONTEST,
    payload: contest,
  };
}

export const getUserContestRanking = (data: any) => {
  return {
    type: GET_USER_CONTEST_RANKING,
    payload: data,
  };
};

export const getContestLeader = () => {
  return {
    type: GET_CONTEST_LEADER,
  };
};

export const getContestLeaderBoard = (contestId: string) => {
  return {
    type: GET_CONTEST_LEADERBOARD,
    payload: contestId,
  };
};
export const getContestContacts = (contestId: string) => {
  return {
    type: GET_CONTEST_CONTACTS,
    payload: contestId,
  };
};

export const setContestContactData = (data: LogBookContactModel[]) => {
  return {
    type: SET_CONTEST_CONTACTS,
    payload: data,
  };
};

export const setActiveContestData = (data: ContestModel[]) => {
  return {
    type: SET_ACTIVE_CONTEST,
    payload: data,
  };
};

export const fetchContestantDetail = (data: any) => {
  return {
    type: GET_CONTESTANT_DETAIL,
    payload: data,
  };
};

export const setUserContestDetail = (data: any) => {
  return {
    type: SET_USER_CONTESTANT_DETAILS,
    payload: data,
  };
};

export const setContestantDetail = (data: ContestantDetail | null) => {
  return {
    type: SET_CONTESTANT_DETAIL,
    payload: data,
  };
};

export const getAwardContest = () => {
  return {
    type: FETCH_AWARD_CONTEST,
  };
};

export const updateContest = (data: ContestModel) => {
  return {
    type: UPDATE_CONTEST,
    payload: data,
  };
};
