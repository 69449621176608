import { LogBookContactTable, UserDataTable } from "constants/Collections";
import { frequencyRanges } from "constants/FrequencyRanges";
import { db, auth, getZonesFromCoordinate } from "firebase-config";
import { PDFDocument, rgb } from "pdf-lib";
import ReactCountryFlag from "react-country-flag";

import fontkit from "@pdf-lib/fontkit";
import {
  collection,
  endBefore,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import moment from "moment";
import { CONTEST_STATUS, FindCode } from "types/Functions";
import {
  DXCCList,
  LogBookContactModel,
  UserProfile,
  UserAwards,
  ParkReferenceModel,
} from "types/Models";
import { SUPER_ADMIN } from "constants/Permissions";
import { CountryRegionData } from "react-country-region-selector";
import { usStatesCoordinates } from "constants/Config";

import { dxccCountryList } from "constants/DXCCCountryList";

const countryData = require("../../constants/CountryCodes.json");
var find = require("lodash.find");
var isEmpty = require("lodash.isempty");

export * from "./azimuth";

export const hasAccessTo = async (permission: string) => {
  //fetch user id token and verify if the have a custom claim for the permission
  try {
    const user = auth.currentUser;
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      if (
        idTokenResult.claims[permission] ||
        idTokenResult.claims[SUPER_ADMIN]
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    // console.log(e);
    return false;
  }
};

export const findBandAndModeForFrequency = (frequency: number) => {
  // First, check for an exact match (where start and end are the same)
  for (const item of frequencyRanges) {
    if (frequency === item.start && item.start === item.end) {
      return {
        band: item.band,
        mode: item.mode,
      };
    }
  }

  // If no exact match, check the broader ranges
  for (const item of frequencyRanges) {
    if (frequency >= item.start && frequency <= item.end) {
      return {
        band: item.band,
        mode: item.mode,
      };
    }
  }

  return null; // Return null if no match is found
};

export const getFormattedDate = (date: any) => {
  if (!date) {
    return "";
  }
  // Split the date string into [year, month, day]
  const parts = date.split("-");

  // Ensure the year, month, and day are in the correct format
  const year = parts[0];
  const month = parts[1].length === 1 ? `0${parts[1]}` : parts[1];
  const day = parts[2].length === 1 ? `0${parts[2]}` : parts[2];

  // Concatenate the year, month, and day into the ADIF format
  return `${year}-${month}-${day}`;
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "Complete":
      return "text-[#00FF87]";
    case "Failed":
      return "text-[#FF3737]";
    case "Complete With Errors":
      return "text-[#FFF500]";
    case "In Progress":
      return "text-[#fff]";
    default:
      return "text-[#fff]";
  }
};

export const getLogBookErrorMessages = (errorCode: string) => {
  if (errorCode === "QUOTA_EXCEEDED") {
    return "You have reached your import quota, please upgrade to import more contacts. Note that you can still add your contacts manually.";
  } else if (errorCode === "MISSING_REQUIRED_FIELDS") {
    return "We found some missing fields while importing your contacts, we still imported your contacts but you will need to manually add the missing fields from the table below. Required fields will be marked yellow.";
  } else if (errorCode === "UNKNOWN_ERROR") {
    return "An unknown error occurred while importing your contacts, please try again later.";
  }
};

export const getLogbookErrorColors = (errorCode: string) => {
  if (errorCode === "QUOTA_EXCEEDED" || errorCode === "UNKNOWN_ERROR") {
    return ["bg-[#FEF1F2]", "text-[#9C2624]", "text-[#B91C1B]"];
  } else if (errorCode === "MISSING_REQUIRED_FIELDS") {
    return ["bg-[#FEFCE8]", "text-[#844D0F]", "text-[#A16207]"];
  } else {
    return ["", "", ""];
  }
};

export const openInNewTab = (url: string | undefined) => {
  if (url) {
    window.open(url, "_blank", "noreferrer");
  }
};

export const getContactDateForAdif = (date: string | undefined) => {
  if (!date) {
    return "";
  }
  const dateStr = date.replaceAll("-", "");
  return dateStr;
};

export const getContactTimeForAdif = (time: string | undefined) => {
  if (!time) {
    return "";
  }
  const timeStr = time.replaceAll(":", "");
  return timeStr;
};

export const findCountryCode = (opts: FindCode) => {
  if (!isEmpty(opts)) {
    return find(countryData, opts);
  } else {
    return undefined;
  }
};

export const splitText = (character: string, text: string | null) => {
  if (text) {
    return text.split(character);
  } else {
    return [];
  }
};

export const formatFirebaseDate = (format: string, seconds?: number) => {
  if (seconds) {
    const miliSecs = seconds * 1000;
    return moment(new Date(miliSecs)).format(format);
  } else {
    return "";
  }
};

export const getStartOfWeek = () => {
  const today = new Date();
  const dayOfWeek = today.getUTCDay();
  const startOfWeek = new Date(today);
  startOfWeek.setUTCHours(0, 0, 0, 0);
  return startOfWeek.setUTCDate(today.getUTCDate() - dayOfWeek);
};

export const getUidWithMostRecords = (array: any[]): any => {
  let count: any = {};

  // Count each "uid" occurrence
  for (let obj of array) {
    if (obj.uid in count) {
      count[obj.uid]++;
    } else {
      count[obj.uid] = 1;
    }
  }

  // Find the most repeated "uid"
  let maxUID = null;
  let maxCount = -1;
  for (let uid in count) {
    if (count[uid] > maxCount) {
      maxCount = count[uid];
      maxUID = uid;
    }
  }

  return maxUID;
};

export const sanitizeData: any = (data: any) => {
  if (typeof data !== "object" || data === null) {
    // Base case: if data is not an object or is null, return it as is
    return data;
  }

  if (Array.isArray(data)) {
    // If it's an array, map over its elements and sanitize each one
    return data.map((item) => sanitizeData(item));
  }

  // If it's an object, iterate over its properties and sanitize each value
  const sanitizedObject: any = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const sanitizedValue: any = sanitizeData(data[key]);
      sanitizedObject[key] = sanitizedValue !== undefined ? sanitizedValue : "";
    }
  }

  return sanitizedObject;
};

export const savePDFAward = async (
  date: string,
  fullName: string,
  callsign: string,
  foundingNumber: string,
  download: boolean,
  award?: UserAwards
) => {
  const url =
    award?.name?.includes("Field Day") && award?.pdf
      ? award?.pdf
      : "/assets/FoundingAward.pdf";

  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  pdfDoc.registerFontkit(fontkit);

  // const boldFontBytes = await fetch(
  //   "/assets/fonts/DigitalSansBoldItalic.otf"
  // ).then((res) => res.arrayBuffer());
  // const lightFontBytes = await fetch("/assets/fonts/DigitalSansLight.otf").then(
  //   (res) => res.arrayBuffer()
  // );
  // const regularFontBytes = await fetch(
  //   "/assets/fonts/DigitalSansRegular.otf"
  // ).then((res) => res.arrayBuffer());

  // const boldFont = await pdfDoc.embedFont(boldFontBytes);
  // const regularFont = await pdfDoc.embedFont(regularFontBytes);
  // const lightFont = await pdfDoc.embedFont(lightFontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  if (award?.name?.includes("Field Day")) {
    firstPage.drawText(`${fullName} - ${callsign}`, {
      x: 50,
      y: 225,
      size: 30,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(foundingNumber, {
      x: 160,
      y: 80,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(date, {
      x: 182,
      y: 46,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });
  } else {
    firstPage.drawText(fullName, {
      x: 140,
      y: 295,
      size: 35,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });
    firstPage.drawText(callsign, {
      x: 510,
      y: 295,
      size: 35,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(foundingNumber, {
      x: 130,
      y: 135,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(date, {
      x: 170,
      y: 95,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });
  }
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  if (download) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = award?.name?.includes("Field Day")
      ? "WorldRadioLeagueFieldDayAward.pdf"
      : "WorldRadioLeagueFoundingMemberAward.pdf";
    link.click();
  } else {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");
    // Set the position offscreen instead of display: none;
    iframe.style.position = "fixed";
    iframe.style.right = "0";
    iframe.style.bottom = "0";
    iframe.style.width = "1px";
    iframe.style.height = "1px";
    iframe.style.border = "none";

    document.body.appendChild(iframe);

    iframe.onload = () => {
      // Add a delay before printing to ensure PDF is ready
      setTimeout(() => {
        if (iframe.contentWindow) {
          iframe.contentWindow.print();
        } else {
        }
      }, 500); // Delay for 500 milliseconds before printing
    };

    iframe.src = blobUrl;
  }
};

export const getISOWeek = () => {
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const startOfYearDay = startOfYear.getDay(); // Day of week the year starts on

  // Adjust so that the week starts on Monday (1) instead of Sunday (0)
  const dayAdjustment = startOfYearDay === 0 ? 6 : startOfYearDay - 1;
  const adjustedStartOfYear = new Date(startOfYear);
  adjustedStartOfYear.setDate(startOfYear.getDate() - dayAdjustment);

  const daysBetween = Math.floor(
    (currentDate.getTime() - adjustedStartOfYear.getTime()) /
      (24 * 60 * 60 * 1000)
  );
  const isoWeek = Math.ceil((daysBetween + 1) / 7);

  return isoWeek;
};

export const getStartAndEndDate = (weekNumber: number, year: number) => {
  var startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  var endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + 6);

  // Adjust for weeks that cross a year boundary
  if (startDate.getFullYear() < year) {
    startDate.setDate(
      startDate.getDate() + (year - startDate.getFullYear()) * 7
    );
    endDate.setDate(endDate.getDate() + (year - startDate.getFullYear()) * 7);
  }

  return {
    start: startDate,
    end: endDate,
  };
};

export const getEndOfWeekDate = () => {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // 0 for Sunday, 6 for Saturday

  // If it's already Sunday, return the current date (end of week)
  if (currentDayOfWeek === 0) {
    // Format as MM/DD/YY
    return (
      currentDate.getMonth() +
      1 +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear().toString().substr(-2)
    );
  }

  // If not Sunday, calculate days until end of week (next Sunday)
  const daysUntilEndOfWeek = 7 - currentDayOfWeek;

  // Add the required number of days to the current date
  currentDate.setDate(currentDate.getDate() + daysUntilEndOfWeek);

  // Format as MM/DD/YY
  return (
    currentDate.getMonth() +
    1 +
    "/" +
    currentDate.getDate() +
    "/" +
    currentDate.getFullYear().toString().substr(-2)
  );
};

export const getWeekRange = (weekNumber: number) => {
  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1); // January 1st of the current year
  const dayOfWeek = firstDayOfYear.getDay(); // Day of the week (0-6, Sunday-Saturday)

  // Days to the first Monday
  const daysToFirstMonday = dayOfWeek === 0 ? 1 : 8 - dayOfWeek;

  // Calculate the start (Monday) of the specified week
  const startOfWeek = new Date(firstDayOfYear);
  startOfWeek.setDate(
    startOfWeek.getDate() + daysToFirstMonday + (weekNumber - 1) * 7
  );

  // Calculate the end (Sunday) of the specified week
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);

  // Format the dates
  const formatDate = (date: any) =>
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return `Monday ${formatDate(startOfWeek)} to Sunday ${formatDate(endOfWeek)}`;
};

export const truncateString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const calculateMaidenhead = (param1: any, param2: any) => {
  let lat: any = 0.0;
  let lon: any = 0.0;

  // Utility function to convert and validate floating point numbers.
  function toNum(x: any) {
    if (typeof x === "number") {
      return x;
    }

    if (typeof x === "string") {
      return parseFloat(x);
    }

    // If the parameter was a function...
    // dont call a function property here because of binding issue
    return;
  }

  // support Chris Veness 2002-2012 LatLon library and
  // other objects with lat/lon properties
  // properties could be numbers, or strings
  if (typeof param1 === "object") {
    if (param1.length === 2) {
      // first parameter is an array `[lat, lon]`
      lat = toNum(param1[0]);
      lon = toNum(param1[1]);
      return gridForLatLon(lat, lon);
    }

    if ("lat" in param1 && "lon" in param1) {
      lat =
        typeof param1.lat === "function"
          ? toNum(param1.lat())
          : toNum(param1.lat);
      lon =
        typeof param1.lon === "function"
          ? toNum(param1.lon())
          : toNum(param1.lon);
      return gridForLatLon(lat, lon);
    }

    if ("latitude" in param1 && "longitude" in param1) {
      lat =
        typeof param1.latitude === "function"
          ? toNum(param1.latitude())
          : toNum(param1.latitude);
      lon =
        typeof param1.longitude === "function"
          ? toNum(param1.longitude())
          : toNum(param1.longitude);
      return gridForLatLon(lat, lon);
    }
    return;
  }

  lat = toNum(param1);
  lon = toNum(param2);
  return gridForLatLon(lat, lon);
};

export const getUsStateFullName = (
  lowerCaseCountry: string,
  usState: string
) => {
  let stateName = usState;
  if (
    lowerCaseCountry === "united states" ||
    lowerCaseCountry === "usa" ||
    lowerCaseCountry === "us" ||
    lowerCaseCountry === "united states of america"
  ) {
    //some states have the full name
    if (usState.length > 2) {
      stateName = usState;
    } else {
      // some state are coming like FL,US instead of just FL, thats why we are splitting it here with ,
      if (usState.split(",").length >= 1) {
        const stateData = usStatesCoordinates.filter(
          (state) =>
            state.shortCode.toLowerCase() ===
            usState.split(",")[0]?.toLowerCase()
        );
        if (stateData && stateData[0]) {
          const data = stateData[0];
          stateName = data.name;
        }
      }
    }
  }
  return stateName;
};

const gridForLatLon = (latitude: any, longitude: any) => {
  const UPPERCASE = "ABCDEFGHIJKLMNOPQRSTUVWX";
  const LOWERCASE = UPPERCASE.toLowerCase();
  var adjLat: number;
  var adjLon: number;
  let fieldLat;
  let fieldLon;
  let squareLat;
  let squareLon;
  let subLat;
  let subLon;
  let rLat;
  let rLon;

  // Parameter Validataion
  const lat = parseFloat(latitude);
  if (isNaN(lat)) {
    return;
  }

  if (Math.abs(lat) === 90.0) {
    return;
  }

  if (Math.abs(lat) > 90) {
    return;
  }

  const lon = parseFloat(longitude);
  if (isNaN(lon)) {
    return;
  }

  if (Math.abs(lon) > 180) {
    return;
  }

  // Latitude
  var adjLat = lat + 90;
  fieldLat = UPPERCASE[Math.trunc(adjLat / 10)];
  squareLat = "" + Math.trunc(adjLat % 10);
  rLat = (adjLat - Math.trunc(adjLat)) * 60;
  subLat = LOWERCASE[Math.trunc(rLat / 2.5)];

  // Logitude
  var adjLon = lon + 180;
  fieldLon = UPPERCASE[Math.trunc(adjLon / 20)];
  squareLon = "" + Math.trunc((adjLon / 2) % 10);
  rLon = (adjLon - 2 * Math.trunc(adjLon / 2)) * 60;
  subLon = LOWERCASE[Math.trunc(rLon / 5)];

  return fieldLon + fieldLat + squareLon + squareLat + subLon + subLat;
};

export const buildDynamicQuery = (conditions: any) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(collectionRef, orderBy("timestamp", "desc"), limit(50));

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicQueryForPaginationCount = (conditions: any) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(collectionRef, orderBy("timestamp", "desc"));

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc")
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc")
    );
  }

  return q;
};

export const createCallSignIndex = (text: string) => {
  let substrings = [];
  for (let i = 0; i < text.length; i++) {
    for (let j = i + 1; j <= text.length; j++) {
      substrings.push(text.substring(i, j));
    }
  }
  return substrings;
};

export const buildDynamicQueryForPaginationNext = (
  conditions: any,
  docRef: UserProfile
) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(
    collectionRef,
    orderBy("timestamp", "desc"),
    startAfter(docRef),
    limit(50)
  );

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicQueryForPaginationPrev = (
  conditions: any,
  docRef: UserProfile
) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(
    collectionRef,
    orderBy("timestamp", "desc"),
    endBefore(docRef),
    limitToLast(50)
  );

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  }

  return q;
};

export const buildDynamicContactQuery = (conditions: any) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(
    collectionRef,
    orderBy("contactTimeStamp", "desc"),
    limit(50)
  );

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicContactQueryForPaginationCount = (conditions: any) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(collectionRef, orderBy("contactTimeStamp", "desc"));

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc")
    );
  }

  return q;
};

export const buildDynamicContactQueryForPaginationNext = (
  conditions: any,
  docRef: LogBookContactModel
) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(
    collectionRef,
    orderBy("contactTimeStamp", "desc"),
    startAfter(docRef),
    limit(50)
  );

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicContactQueryForPaginationPrev = (
  conditions: any,
  docRef: LogBookContactModel
) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(
    collectionRef,
    orderBy("contactTimeStamp", "desc"),
    endBefore(docRef),
    limitToLast(50)
  );

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  }

  return q;
};

export const getRandomAvatar = () => {
  const randomImage = Math.floor(Math.random() * 13) + 1;
  return `../../assets/avatars/avatar_${randomImage}.jpg`;
};

export function findStateName(countryName: string, stateCode: string) {
  // Find the index of the country name in the array
  const index = CountryRegionData?.findIndex(
    (item) => item?.[0] === countryName
  );

  // If country name is found
  if (index !== -1) {
    // Get the region part of the data
    const regionData = CountryRegionData?.[index]?.[2];

    // Split the region data into an array of regions
    const regions = regionData?.split("|");

    // Iterate through the regions to find the matching region code
    for (const region of regions) {
      const [name, code] = region?.split("~");
      if (code === stateCode) {
        return name;
      }
    }
  }

  // If no matching region code is found, return null
  return null;
}

export const getCQAndITUZonesFromCoordinates = async (
  latitude: number,
  longitude: number
) => {
  const response: any = await getZonesFromCoordinate({
    latitude: latitude,
    longitude: longitude,
  });
  if (response && response.data) {
    return {
      cqZone: response.data?.cqZone || "",
      ituZone: response.data?.ituZone || "",
    };
  }

  return { cqZone: "", ituZone: "" };
};

export const latLonFromGrid = (grid: string) => {
  var lat = 0.0;
  var lon = 0.0;

  function lat4(g: any) {
    return (
      10 * (g.charCodeAt(1) - "A".charCodeAt(0)) + parseInt(g.charAt(3)) - 90
    );
  }

  function lon4(g: any) {
    return (
      20 * (g.charCodeAt(0) - "A".charCodeAt(0)) +
      2 * parseInt(g.charAt(2)) -
      180
    );
  }

  if (grid.length != 4 && grid.length != 6) {
    return null;
  }

  if (/^[A-Xa-x][A-Xa-x][0-9][0-9]$/.test(grid)) {
    // Decode 4-character grid square
    lat = lat4(grid.toUpperCase()) + 0.5;
    lon = lon4(grid.toUpperCase()) + 1;
  } else if (/^[A-Xa-x][A-Xa-x][0-9][0-9][A-Xa-x][A-Xa-x]$/.test(grid)) {
    // Decode 6-character grid square
    lat =
      lat4(grid.toUpperCase()) +
      (1.0 / 60.0) * 2.5 * (grid.charCodeAt(5) - "a".charCodeAt(0) + 0.5);
    lon =
      lon4(grid.toUpperCase()) +
      (1.0 / 60.0) * 5 * (grid.charCodeAt(4) - "a".charCodeAt(0) + 0.5);
  } else {
    return null;
  }

  return { latitude: lat, longitude: lon };
};

export function generateUniqueFileName(originalName: string) {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 15); // Generates a random string
  return `${timestamp}_${randomString}_${originalName}`;
}

export const getContestStatus = (contestDetail: any) => {
  const now = new Date();
  //determine if a contest is ongoing in the future or past based on fields from contestDetail.startDate and contestDetail.endDate
  const startDate = new Date(contestDetail?.startDate.seconds * 1000);
  const endDate = new Date(contestDetail?.endDate.seconds * 1000);
  if (contestDetail) {
    if (
      now.getTime() > startDate.getTime() &&
      now.getTime() < endDate.getTime()
    ) {
      return CONTEST_STATUS.ACTIVE;
    } else if (now.getTime() < startDate.getTime()) {
      return CONTEST_STATUS.UPCOMING;
    } else {
      return CONTEST_STATUS.PASSED;
    }
  }
};

export const setTabIndexForTimeInputs = (
  hour: string,
  minute: string,
  close: string
) => {
  const hourElement = document.querySelector(
    ".react-time-picker__inputGroup__hour"
  );
  const minuteElement = document.querySelector(
    ".react-time-picker__inputGroup__minute"
  );
  const clearButtonElement = document.querySelector(
    ".react-time-picker__clear-button"
  );

  if (hourElement) {
    hourElement.setAttribute("tabindex", hour);
  }

  if (minuteElement) {
    minuteElement.setAttribute("tabindex", minute);
  }

  if (clearButtonElement) {
    clearButtonElement.setAttribute("tabindex", close);
  }
};

export const getDxDataFromCountry = (country: string): DXCCList | null => {
  let dxData = dxccCountryList.find((x) => x.country === country);
  if (dxData) {
    return dxData;
  } else {
    return null;
  }
};

export function compareObjects(
  newObject: { [key: string]: any },
  prevObject: { [key: string]: any }
) {
  const addedProps: string[] = [];
  const removedProps: string[] = [];

  // Check for added properties
  for (const key in newObject) {
    if (!(key in prevObject)) {
      addedProps.push(key);
    }
  }

  // Check for removed properties
  for (const key in prevObject) {
    if (!(key in newObject)) {
      removedProps.push(key);
    }
  }

  return {
    added: addedProps,
    removed: removedProps,
  };
}

export const getStateShortCode = (state: string) => {
  let newState = state;
  if (state) {
    const stateShortName = usStatesCoordinates.find(
      (x) => x.name.toLowerCase() === state.toLowerCase()
    );
    if (stateShortName) {
      return stateShortName?.shortCode;
    }
  }
  return newState;
};

export const getStateLongName = (state: string) => {
  let newState = state;
  if (state) {
    const stateLongName = usStatesCoordinates.find(
      (x) => x.shortCode.toLowerCase() === state.toLowerCase()
    );
    if (stateLongName) {
      return stateLongName?.name;
    }
  }
  return newState;
};
export const createSearchIndex = (text: string, startFrom?: number) => {
  let substrings = [];
  for (let i = startFrom || 2; i <= text.length; i++) {
    substrings.push(text.substring(0, i));
  }
  return substrings;
};

export const correctUSCountryName = (country: string) => {
  if (country) {
    if (
      country.toLowerCase() === "united states of america" ||
      country.toLowerCase() === "united states" ||
      country.toLowerCase() === "usa" ||
      country.toLowerCase() === "us"
    ) {
      return "United States";
    }
  }
  return country || "";
};

export const suggestionsFormatter = (suggestion: ParkReferenceModel) => {
  let formatedReference = `${suggestion.reference} \u00A0|\u00A0 ${suggestion.name} \u00A0|\u00A0 ${suggestion.locationName} \u00A0|\u00A0 ${suggestion.referencePrefix}`;

  if (suggestion?.distanceInKm?.toFixed(2)) {
    formatedReference += ` \u00A0|\u00A0 ${suggestion?.distanceInKm?.toFixed(
      2
    )} km`;
  }

  return formatedReference;
}

// Generate a curve path between two points, it will iterate about 29 times for each pair of points
export const generateCurvePath = (start: any, end: any) => {
  // Calculate the distance between start and end points
  const distance = Math.sqrt(
    Math.pow(end.lat - start.lat, 2) + Math.pow(end.lng - start.lng, 2)
  );

  // Adjust control point based on distance
  const curveHeight = distance * 0.2; // Adjust this factor to control the steepness
  const controlPoint = {
    lat: (start.lat + end.lat) / 2 + curveHeight,
    lng: (start.lng + end.lng) / 2,
  };

  let curvePath = [];
  for (let t = 0; t <= 1; ) {
    const x = (1 - t) * (1 - t) * start.lng + 2 * (1 - t) * t * controlPoint.lng + t * t * end.lng;
    const y = (1 - t) * (1 - t) * start.lat + 2 * (1 - t) * t * controlPoint.lat + t * t * end.lat;
    curvePath.push({ lat: y, lng: x });

    // Smaller increments at the beginning and end, larger in the middle
    if (t < 0.1 || t > 0.9) {
      t += 0.02; // Fine increments near the start and end
    } else {
      t += 0.1; // Coarser increments in the middle
    }
  }

  return curvePath;
};

export const  convertBandValue = (value: string, unit: string): number => {
  const meterValue = parseFloat(value);
  
  switch (unit) {
    case "cm":
      return meterValue * 100; // Convert meters to centimeters
    case "mm":
      return meterValue * 1000; // Convert meters to millimeters
    default:
      return meterValue; // Return the original value if unit is meters or unknown
  }
}
