import ButtonGlow from "components/Button/ButtonGlow";
import { TabNavGlowProps } from "types/Component";

function TabNavGlow({
  selectedIndex,
  setSelectedIndex,
  tabs,
}: TabNavGlowProps) {
  return (
    <div className="flex justify-evenly px-11 mt-7">
      {tabs?.map((item: string, index: number) => {
        return (
          <ButtonGlow
            key={index}
            selected={selectedIndex === item}
            text={item}
            onClick={() => setSelectedIndex(item)}
          />
        );
      })}
    </div>
  );
}

export default TabNavGlow;
