import { DropDownProps, SubscriptionTypeArray } from "types/Component";

function DropDown({
  placeHolder,
  list,
  width,
  className,
  onChange,
}: DropDownProps) {
  return (
    <select
      className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 ${
        width ? width : "w-full"
      } ${className ? className : ""}`}
      onChange={onChange}
    >
      <option value="">{placeHolder}</option>
      {list.map((item: SubscriptionTypeArray, index) => {
        return (
          <option value={item?.subscriptionType} key={index}>
            {item?.subscriptionType}
          </option>
        );
      })}
    </select>
  );
}

export default DropDown;
