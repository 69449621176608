import Icons from "assets/Icons";
import Hint from "components/Hint";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { FC, Fragment, useState } from "react";
import { SavedLocation } from "types/Models";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { HorizontalDivider } from "components/Divider";
import CountrySelector from "components/Inputs/CountrySelector";
import { getStateLongName } from "helpers/Utils";

export const StreetLocation: FC<{
  locationForm: SavedLocation;
  setLocationForm: React.Dispatch<React.SetStateAction<SavedLocation>>;
  callback: (arg: string) => void;
  addressLoading?: boolean;
  mode?: string;
  resetForm?: () => void;
}> = ({
  locationForm,
  setLocationForm,
  callback,
  addressLoading,
  mode,
  resetForm,
}) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE,
      debounce: 500,
      sessionToken: true,
      options: {
        types: [],
      } as any,
    });

  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const fieldPrefixColor =
    mode === "light" ? "bg-[#ffffff] text-slate-900" : "bg-blue-600 text-white";
  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-row flex-wrap gap-3 items-end">
        <div className="flex flex-col relative w-full sm:w-[49%] md:w-[20.5%] xl:w-[40%]">
          <TitledInputDefault
            setText={(e) => {
              setLocationForm((prev) => ({
                ...prev,
                qth: e.target.value,
              }));
              getPlacePredictions({ input: e.target.value });

              if (!e.target.value && resetForm) {
                resetForm();
              }
            }}
            name="streetAddress"
            title="Street Address"
            placeHolder="Enter Street Address"
            value={locationForm.qth}
            width={"w-full"}
            className={"h-[36px] mt-[12px]"}
            tabindex={20}
            error={null}
            containerClass=""
            isLoading={isPlacePredictionsLoading || addressLoading}
            props={{
              onFocus: () => setShowSuggestions(true),
            }}
            onBlur={() => {
              setTimeout(() => {
                setShowSuggestions(false);
              }, 300);
            }}
          />
          {placePredictions?.length && showSuggestions ? (
            <div className="flex flex-col absolute top-[76px] bg-white z-50 w-full shadow-lg bg-gradient-to-br from-fuchsia-500 via-violet-600 to-violet-800 p-[1px] inline-flex">
              <div className="w-full bg-[#1b1c60] gap-4">
                {placePredictions.map((result, index: number) => (
                  <Fragment key={index}>
                    <div
                      key={index}
                      className="flex px-4 hover:bg-[#272869] cursor-pointer text-white bg-[#1b1c60]"
                      onClick={() => {
                        setLocationForm((prev) => ({
                          ...prev,
                          qth: result.description,
                        }));

                        setTimeout(() => callback(result.description), 100);
                      }}
                    >
                      <div className="flex flex-row gap-3 py-2 items-center text-xs">
                        <img src={Icons.PinIcon} alt="" />
                        <div>{result.description}</div>
                      </div>
                    </div>
                    <HorizontalDivider />
                  </Fragment>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <Hint
          text={
            "Enter the Street Address, and the other details will be pulled automatically"
          }
        />
      </div>

      <div className="flex flex-row flex-wrap">
        <CountrySelector
          setText={(val) => {
            setLocationForm((prev) => ({
              ...prev,
              country: val,
            }));
          }}
          name="country"
          title="Country"
          placeHolder="Country"
          value={locationForm.country}
          className={"h-[36px]"}
          tabindex={8}
          width={"w-full sm:w-[49%] md:w-[20.5%] xl:w-[30%]"}
          error={undefined}
          containerClass="mr-0 md:mr-3 sm:mr-3 mb-3"
          disabled
        />
        {locationForm?.country ? (
          <TitledDefaultRegionPicker
            setText={(val) => {
              setLocationForm((prev) => ({
                ...prev,
                state: val,
              }));
            }}
            name="state"
            title="State"
            placeHolder="State"
            tabindex={9}
            width="w-full sm:w-[23.5%] md:w-[18.7%] xl:w-[30%]"
            className={"mt-3 h-[36px]"}
            value={getStateLongName(locationForm.state || "")}
            error={undefined}
            country={locationForm.country}
            containerClass="mr-0 md:mr-3 sm:mr-3 mb-3"
            disabled
          />
        ) : null}

        <TitledInputDefault
          setText={(e) => {
            setLocationForm((prev) => ({
              ...prev,
              city: e.target.value,
            }));
          }}
          name="city"
          title="City"
          placeHolder="Enter City"
          value={locationForm.city}
          width={"w-full sm:w-[49%] md:w-[20.5%] xl:w-[30%]"}
          className={"h-[36px] mt-[12px] mr-0 md:mr-3 sm:mr-3"}
          tabindex={20}
          error={null}
          containerClass="mr-0 md:mr-5"
        />
      </div>

      <div className="flex items-center mt-4 justify-between flex-wrap">
        <div className="flex flex-wrap font-[Play] text-sm gap-3">
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px] pt-1.5`}>
              Lat.
            </div>
            <input
              className="bg-[#23234C] text-white px-2 h-[36px] md:w-[100px]"
              value={locationForm.lat}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  lat: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px] pt-1.5`}>
              Lon.
            </div>
            <input
              className="bg-[#23234C] text-white px-2 h-[36px] md:w-[100px]"
              value={locationForm.lng}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  lng: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
              Grid
            </div>
            <input
              className="bg-[#23234C] text-white px-2 h-[36px] md:w-[100px]"
              value={locationForm.gridsquare}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  gridsquare: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
              ITU
            </div>
            <input
              className="bg-[#23234C] text-white px-2 h-[36px] md:w-[100px]"
              value={locationForm.ituZone}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  ituZone: e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
              CQ
            </div>
            <input
              className="bg-[#23234C] text-white px-2 h-[36px] md:w-[100px]"
              value={locationForm.cqZone}
              disabled
              onChange={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  cqZone: e.target.value,
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
