import TableCol from "components/Table/Table/TableCol";
import { FaSort } from "react-icons/fa6";
import { TableCustomProps } from "types/Component";

function TableCustom({ list }: TableCustomProps) {
  return (
    <div className="w-full overflow-y-hidden">
      <table className="bg-white w-[655px] md:w-full">
        <tbody>
          <tr className="bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
              Name
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
              Import Status
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white flex items-center">
              Date Created <FaSort size={14} className="ml-4" />
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
              QSO #
            </th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white">
              Action
            </th>
          </tr>
          {list
            ? list.map((item, index) => {
                return (
                  <TableCol
                    key={index}
                    className={`${
                      index % 2 ? "bg-[#151536]" : "bg-[#1F1F3F]"
                    } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                    item={item}
                  />
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
}

export default TableCustom;
