import React, { FC, useEffect, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { Form, Formik } from "formik";
import TitledInput from "components/Inputs/TitledInput";
import usePota from "hooks/pota.hook";
import { useDebouncedCallback } from "use-debounce";
import { ParkReferenceModel } from "types/Models";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { Tooltip } from "components/Tooltip";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { Templates } from "constants/Config";
import { suggestionsFormatter } from "helpers/Utils";

type ModalProps = {
  onClose: () => void;
};

export const PotaModal: FC<ModalProps> = ({ onClose }) => {
  const { selectedLogbook, saveLogbookSettings } = useLogbookSetting();

  const {
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    getParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    setParkByReference,
    getNearbyParkReferences,
    nearbyParkReferencesData,
  } = usePota();

  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [parksAdded, setParksAdded] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(selectedLogbook?.myParks || {}).length && !parksAdded) {
      setFormData((prev: any) => ({
        ...prev,
        myParks: selectedLogbook?.myParks,
      }));
      setParksAdded(true);
    }
  }, [selectedLogbook]);

  const onSaveClickHandler = () => {
    saveLogbookSettings({
      myParks: formData?.myParks || {},
    });

    onClose();
  };

  const debouncedParkReferenceChangeHandler = useDebouncedCallback(
    (searchString) => getParReferenceSuggestions(searchString),
    1000
  );

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) => getParkByReference(searchString),
    200
  );

  const resetParkReferenceSuggestions = (key: string) => {
    setTimeout(() => setParReferenceSuggestions({ key, value: null }), 200);
  };

  const hideParkSuggestions = () => {
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const showParkSuggestions = (key: string, setValues: any) => {
    if (!showSuggestions) return {};

    return {
      suggestions: formData?.[key]
        ? parkReferenceSuggestionsData?.[key]
        : nearbyParkReferencesData,
      suggestionsFormatter,
      onSuggestionClick: (suggestion: ParkReferenceModel) => {
        // Check if myParks has more than 4 keys
        if (Object.keys(formData?.myParks || {}).length >= 5) {
          showToast({
            message: "A maximum of 5 parks are allowed",
            type: ToastTypes.ERROR,
          });
          return; // Stop further execution
        }

        // after setting value remove suggestions
        resetParkReferenceSuggestions(key);

        setValues((prev: any) => {
          return {
            ...prev,
            myParks: { ...prev.myParks, [suggestion?.reference]: suggestion },
            myPark: "",
          };
        });

        // set formdata
        setFormData((prev: any) => {
          return {
            ...prev,
            myParks: { ...prev.myParks, [suggestion?.reference]: suggestion },
            myPark: "",
          };
        });

        // hide suggestions
        hideParkSuggestions();
      },
    };
  };

  const findMyParkHandler = async () => {
    // TODO: pass these coordinates into getNearbyparksreference function
    const coor = await fetchCoordinates();

    if (coor?.latitude && coor?.longitude) {
      getNearbyParkReferences([coor?.latitude, coor?.longitude]);
      setShowSuggestions(true);
    }
  };

  const renderSelectedParks = (setValues: any) => {
    if (!Object.keys(formData?.myParks || {}).length) return null;

    const removePark = (key: string) => {
      //remove park from form data as well as key
      setFormData((prev: any) => {
        const { [key]: omit, ...rest } = prev.myParks;
        return { ...prev, myParks: rest };
      });

      // also update formit
      setValues((prev: any) => {
        const { [key]: omit, ...rest } = prev.myParks;
        return { ...prev, myParks: rest };
      });
    };

    return (
      <div className="flex flex-row text-white text-[11px] gap-2 -mt-[10px] flex-wrap">
        {Object.keys(formData.myParks).map((key, index) => (
          <div
            key={index}
            className="flex flex-row rounded-[10px] border border-neutral-400 gap-2 px-2 py-1 items-center bg-neutral-400 bg-opacity-25 "
          >
            <Tooltip
              message={`${formData.myParks[key]?.reference} - ${formData.myParks[key]?.parktypeDesc}`}
              className="w-[180px]"
            >
              <div>{`${formData.myParks[key]?.reference}`}</div>
            </Tooltip>

            <img
              src={Icons.CrossIcon}
              className="h-3 w-3"
              alt="close"
              onClick={() => removePark(key)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[14%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full ">
          <div className="flex flex-col w-full bg-[#151541] text-white font-[Play] relative mt-20 border border-[#451836] px-[10%] md:px-[6%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <div className="flex flex-col gap-4 w-full">
                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-4 w-full items-start mb-6">
                  <div className="w-full sm:w-[68%]">
                    <Formik
                      initialValues={{}}
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                      }}
                    >
                      <Form>
                        <TitledInput
                          setText={(e) => {
                            // setValues((prev: any) => {
                            //   return {
                            //     ...prev,
                            //     myPark: e.target.value.toUpperCase().trim(),
                            //   };
                            // });

                            setFormData((prev: any) => ({
                              ...prev,
                              myPark: e.target.value.toUpperCase().trim(),
                            }));

                            if (e.target.value.length > 1) {
                              debouncedParkReferenceChangeHandler({
                                key: "myPark",
                                value: e.target.value.toUpperCase().trim(),
                              });
                            }

                            // reset park data on change
                            setParkByReference({ key: "myPark", value: null });
                          }}
                          onBlur={() => {
                            if (formData.myPark) {
                              debouncedGetParkByReference({
                                value: formData.myPark,
                                key: "myPark",
                              });
                            }

                            // remove suggestions on blur
                            resetParkReferenceSuggestions("myPark");

                            // hide suggestions
                            hideParkSuggestions();
                          }}
                          onFocus={() => setShowSuggestions(true)}
                          // status={
                          //   parkReferenceData && parkReferenceData?.myPark
                          //     ? "Park Match"
                          //     : null
                          // }
                          // statusBg={
                          //   parkReferenceData && parkReferenceData?.myPark
                          //     ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
                          //     : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
                          // }
                          isLoading={!!isParkReferenceDataLoading?.myPark}
                          name="myPark"
                          title="Select Your Park(s):"
                          placeHolder="Type the name of the park here"
                          width="w-full"
                          tabindex={15}
                          value={formData?.myPark}
                          // error={
                          //   errors?.myParks && touched?.myParks
                          //     ? errors?.myParks
                          //     : undefined
                          // }
                          containerClass={"m-0"}
                          {...showParkSuggestions("myPark", setFormData)}
                          className={"h-10"}
                        />
                      </Form>
                    </Formik>

                    {renderSelectedParks(setFormData)}
                  </div>

                  <button
                    type="button"
                    onClick={findMyParkHandler}
                    className="text-play text-white rounded-full px-4 py-1.5 border-2 border-emerald-500 mt-6"
                  >
                    Find My Park
                  </button>
                </div>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                // isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
