import { Field } from "formik";
import { InputWithLabelProps } from "types/Component";

function InputWithLabel({
  multiline,
  label,
  placeholder,
  name,
  setText,
  error,
  onBlur,
}: InputWithLabelProps) {
  return (
    <div
      className={`w-full bg-[#FFFFFF0F] relative px-[15px] py-1 h-[55px] ${
        error ? "border border-orange-700 border-solid" : ""
      }`}
    >
      <p className="text-[#17F9DA] text-xs font-[Play] mb-1.5">{label}</p>
      {/* <input
        multiple={multiline}
        className={`w-full bg-transparent font-medium font-[Barlow] text-base text-white`}
        placeholder={placeholder ? placeholder : ""}
        type="text"
        name={name}
        id=""
        onChange={setText}
      /> */}
      <Field
        multiple={multiline}
        className={`w-full bg-transparent font-medium font-[Barlow] text-sm text-white`}
        onChange={setText}
        type={"text"}
        name={name}
        id=""
        onBlur={onBlur ? onBlur : () => {}}
        placeholder={placeholder}
        // {...props}
      />
    </div>
  );
}

export default InputWithLabel;
