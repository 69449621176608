import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { call, put, take } from "redux-saga/effects";
import UploadService from "services/upload.service";
import {
  SET_SCREENSHOT_IMAGE,
  UPLOAD_SCREENSHOT_IMAGE,
} from "store/Actions/config.actions";
import {
  LOADING_PROFILE_STATUS,
  LOADING_UPLOAD_STATUS,
} from "store/Actions/loading.action";
import { UPLOAD_PROFILE_IMG } from "store/Actions/uploadFiles.actions";

//Workers

export function* workerUploadImgFile(payload: any) {
  try {
    yield put({
      type: LOADING_PROFILE_STATUS,
      payload: true,
    });
    yield call(() => UploadService.handleUploadImage(payload));
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_PROFILE_STATUS,
    payload: false,
  });
}

export function* workerUploadScreenShotImage(payload: any) {
  try {
    yield put({
      type: LOADING_UPLOAD_STATUS,
      payload: true,
    });
    const downloadUrl: string = yield call(() =>
      UploadService.handleUploadImageString(payload)
    );
    // console.log("downloadUrlSaga: ", downloadUrl);
    yield put({
      type: SET_SCREENSHOT_IMAGE,
      payload: downloadUrl,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_UPLOAD_STATUS,
    payload: false,
  });
}

//Watchers
export function* watchUploadImgFile() {
  while (true) {
    const { payload } = yield take(UPLOAD_PROFILE_IMG);
    yield call(workerUploadImgFile, payload);
  }
}

export function* watchUploadScreenShotImage() {
  while (true) {
    const { payload } = yield take(UPLOAD_SCREENSHOT_IMAGE);
    yield call(workerUploadScreenShotImage, payload);
  }
}
