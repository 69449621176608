import { useField } from "formik";
import { TitledTimeInputProps } from "types/Component";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

function TitledTimeInput({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  title,
  error,
  props,
  value,
  tabindex,
  containerClass,
}: TitledTimeInputProps) {
  const [field] = useField({
    name: name ? name : "",
  });
  return (
    <div
      className={`${width ? width : "w-full"} ${
        containerClass ? containerClass : ""
      }`}
    >
      <label className="font-[Barlow] font-bold font-[Barlow] text-sm text-white">
        {title}
      </label>
      <div className="mt-0.5" tabIndex={tabindex}>
        <TimePicker
          maxTime="23:59:59"
          locale="sv-sv"
          {...field}
          className={`h-8 bg-[#23234C] text-white font-[Barlow] text-sm w-full ${
            className ? className : ""
          } ${error ? "border border-orange-700 border-solid" : "border-none"}`}
          name={name}
          onChange={(e: any) => {
            setText(e);
          }}
          value={value}
          disableClock={true}
          id=""
          clockClassName="hidden"
        />
      </div>
      {/* <Field
        className={`h-10 bg-[#232342] px-3 text-white mt-2.5 w-full ${
          className ? className : ""
        } ${error ? "border border-orange-700 border-solid" : ""}`}
        onChange={(e: any) => setText(e)}
        type={type ? type : "text"}
        name={name}
        id=""
        placeholder={placeHolder}
        // value={text}
        {...props}
        step="1"
        min="00:00"
        max="23:59"
      /> */}
    </div>
  );
}

export default TitledTimeInput;
