import useConfig from "hooks/config.hook";
import useLogBook from "hooks/logBook.hook";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import { TableDropDownProps } from "types/Component";

function SavedLocationTableDropDown({
  listItems,
  itemData,
  onClick,
}: TableDropDownProps) {
  const { handlePrimaryModal, primaryModalData } = useConfig();
  const { deleteUserSavedLocation } = useLogbookSetting();

  return (
    <div className="absolute bg-white shadow-md right-10 ">
      {listItems.map((item, index) => {
        return (
          <div
            className={`${
              item?.color ? item?.color : "text-white"
            } font-[Barlow] flex font-normal text-xs px-3 py-1.5 cursor-pointer bg-[#3C3C58] hover:bg-[#3C3C75]`}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
              if (item?.text === "Delete") {
                if (primaryModalData) {
                  handlePrimaryModal(null);
                } else {
                  handlePrimaryModal({
                    title:
                      "Are you sure you want to delete this saved location?",
                    type: "Delete Saved Location",
                    onCancelPressed: () => handlePrimaryModal(null),
                    onSubmitPressed: () => {
                      deleteUserSavedLocation(itemData.id);
                      handlePrimaryModal(null);
                    },
                    submitText: "Delete",
                    cancelText: "Cancel",
                  });
                }
              } else if (item?.link) {
                globalNavigate(item.link, {
                  state: { data: itemData, edit: true },
                });
              }
            }}
          >
            {item.text}
          </div>
        );
      })}
    </div>
  );
}

export default SavedLocationTableDropDown;
