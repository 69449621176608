import { call, put, take } from "redux-saga/effects";
import AuthService from "services/auth.service";
import {
  FACEBOOK_LOGIN,
  GOOGLE_LOGIN,
  LOG_IN_USER,
  LOG_USER_OUT,
  REGISTER_USER,
  DISCOURSE_AUTH,
  SEND_EMAIL_TO_VERIFY,
  APPLE_LOGIN
} from "store/Actions/authorization.actions";
import { DiscourseSSOParams, RegisterActionParams } from "types/State";

import { authErrorHandler } from "helpers/ErrorHandler/auth";
import {
  LOGGED_IN_USER,
  RESET_IN_USER,
  RESET_LOADING_STATUS,
  RESET_USER_PROFILE,
} from "constants/ActionTypes";
import { globalNavigate } from "routes/GlobalRoutes";
import { FunctionResponse } from "types/Models";
import {
  DISCOURSE_LOADING,
  LOADING_APPLE_AUTH,
  LOADING_AUTH_STATUS,
  LOADING_FACEBOOK_AUTH_STATUS,
  LOADING_GOOGLE_AUTH_STATUS,
} from "store/Actions/loading.action";
import { RESET_LOGBOOK_DATA } from "store/Actions/logBook.actions";

//Workers
export function* workerRegisterUser(payload: RegisterActionParams) {
  yield put({
    type: LOADING_AUTH_STATUS,
    payload: true,
  });
  try {
    yield call(() => AuthService.registerUser(payload));
    yield put({
      type: LOGGED_IN_USER,
      payload: {
        authFirstName: payload?.firstName,
        authLastName: payload?.lastName,
      },
    });
  } catch (e: any) {
    yield put({
      type: LOADING_AUTH_STATUS,
      payload: false,
    });
    authErrorHandler(e);
  }
}

export function* workerLoginUser(payload: RegisterActionParams) {
  yield put({
    type: LOADING_AUTH_STATUS,
    payload: true,
  });
  try {
    yield call(() => AuthService.loginUser(payload));
  } catch (e: any) {
    yield put({
      type: LOADING_AUTH_STATUS,
      payload: false,
    });

    authErrorHandler(e);
  }
}

export function* workerGoogleLogin() {
  yield put({
    type: LOADING_GOOGLE_AUTH_STATUS,
    payload: true,
  });
  try {
    yield call(() => AuthService.loginWithGmail());
  } catch (e: any) {
    yield put({
      type: LOADING_GOOGLE_AUTH_STATUS,
      payload: false,
    });
    authErrorHandler(e);
  }
}

export function* workerAppleLogin() {
  yield put({
    type: LOADING_APPLE_AUTH,
    payload: true,
  });
  try {
    yield call(() => AuthService.loginWithApple());
  } catch (e: any) {
    console.log("APPLE SIGNIN ERROR : ", e)
    yield put({
      type: LOADING_APPLE_AUTH,
      payload: false,
    });
    authErrorHandler(e);
  }
}

export function* workerFacebookLogin() {
  yield put({
    type: LOADING_FACEBOOK_AUTH_STATUS,
    payload: true,
  });
  try {
    yield call(() => AuthService.loginWithFacebook());
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* workerLogOutUser() {
  try {
    yield call(() => AuthService.logOut());

    yield put({ type: RESET_LOADING_STATUS });
    yield put({ type: RESET_LOGBOOK_DATA });
    yield put({ type: RESET_IN_USER });
    yield put({ type: RESET_USER_PROFILE });

    globalNavigate("/login");
  } catch (e: any) {
    authErrorHandler(e);
  }
}

export function* workerSendEmailToVerify(payload: string) {
  try {
    yield put({
      type: LOADING_AUTH_STATUS,
      payload: true,
    });
    yield call(() => AuthService.verifyUser(payload));
    authErrorHandler({
      code: "Successfully sent an email",
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_AUTH_STATUS,
    payload: false,
  });
}

export function* workerDiscourseAuth(payload: DiscourseSSOParams) {
  yield put({
    type: DISCOURSE_LOADING,
    payload: true,
  });
  try {
    const response: FunctionResponse = yield call(() =>
      AuthService.discourseAuth(payload)
    );
    if (response && response.data && response.data.redirectUrl) {
      yield put({
        type: DISCOURSE_LOADING,
        payload: true,
      });

      window.location.replace(response.data.redirectUrl);
    } else {
      yield put({
        type: DISCOURSE_LOADING,
        payload: false,
      });
      authErrorHandler(null);
    }
  } catch (e: any) {
    yield put({
      type: DISCOURSE_LOADING,
      payload: false,
    });
    authErrorHandler(e);
  }
}

//Watchers
export function* watchRegisterUser() {
  while (true) {
    const { payload } = yield take(REGISTER_USER);
    yield call(workerRegisterUser, payload);
  }
}

export function* watchLoginUser() {
  while (true) {
    const { payload } = yield take(LOG_IN_USER);
    yield call(workerLoginUser, payload.loggedInUser);
  }
}

export function* watchGoogleLogin() {
  while (true) {
    yield take(GOOGLE_LOGIN);
    yield call(workerGoogleLogin);
  }
}
export function* watchAppleLogin() {
  while (true) {
    yield take(APPLE_LOGIN);
    yield call(workerAppleLogin);
  }
}

export function* watchFacebookLogin() {
  while (true) {
    yield take(FACEBOOK_LOGIN);
    yield call(workerFacebookLogin);
  }
}

export function* watchSendEmailToVerify() {
  while (true) {
    const { payload } = yield take(SEND_EMAIL_TO_VERIFY);
    yield call(workerSendEmailToVerify, payload);
  }
}

export function* watchLogOutUser() {
  while (true) {
    yield take(LOG_USER_OUT);
    yield call(workerLogOutUser);
  }
}

export function* watchDiscourseAuth() {
  while (true) {
    const { payload } = yield take(DISCOURSE_AUTH);
    yield call(workerDiscourseAuth, payload);
  }
}
