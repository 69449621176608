import Icons from "assets/Icons";

import ActivityIndicator from "components/Loading/ActivityIndicator";

import React, { useEffect } from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import TodayLeaderItem from "./TodayLeaderItem";

import { useDispatch, useSelector } from "react-redux";
import { fetchDailyLeaders } from "store/Actions/logBook.actions";
import { getIsDailyLeadersLoadingSelector } from "store/Reducers/loading.reducer";
import { getDailyLeadersSelector } from "store/Reducers/logBook.reducer";
import { LeaderData } from "types/Models";

function TodayLeaders() {
  const dispatch = useDispatch();
  const isDailyLeadersLoading = useSelector(getIsDailyLeadersLoadingSelector);
  const dailyLeaders = useSelector(getDailyLeadersSelector);

  useEffect(() => {
    dispatch(fetchDailyLeaders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLeaderItemPress = (item: LeaderData) => {
    if (item.link) {
      globalNavigate(item.link);
    }
  };

  return dailyLeaders.length ? (
    <div className="w-full  px-3 lg:px-8 -mt-6">
      <div className="mb-20 pb-12 bg-[#0B0B38]">
        <div className="w-full border-b border-solid border-b-white flex items-center py-[20px] pl-[24px] mb-6">
          <img src={Icons.TopChart} alt="icon" />
          <p className="text-white font-[play] font-bold text-base ml-[15px]">
            TODAYS <span className="text-[#17F9DA]">LEADERS</span>
          </p>
        </div>
        <div className="px-3 lg:px-3 flex justify-between w-full flex-wrap">
          {isDailyLeadersLoading ? (
            <ActivityIndicator size={36} />
          ) : (
            dailyLeaders.map((data) => (
              <TodayLeaderItem data={data} onPress={onLeaderItemPress} />
            ))
          )}
        </div>
      </div>
    </div>
  ) : null;
}

export default TodayLeaders;
