import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGlobeMembersSelector,
  getMapMembersSelector,
  getMembersSelector,
} from "store/Reducers/members.reducer";
import {
  getMembersFromCollection,
  getMembersFromCollectionWithPagination,
} from "store/Actions/members.actions";
import {
  getIsGlobeMembersLoadingSelector,
  getIsMapMembersLoadingSelector,
  getIsMembersDataLoadingSelector,
} from "store/Reducers/loading.reducer";
import { MemberShipFilter } from "types/Component";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";

const useMember = () => {
  const dispatch = useDispatch();
  const members: any = useSelector(getMembersSelector);
  const mapMembers = useSelector(getMapMembersSelector);
  const globeMembers = useSelector(getGlobeMembersSelector);
  const [globeData, setGlobeData] = useState<any[]>([]);

  const memberMapLoading = useSelector(getIsMapMembersLoadingSelector);
  const memberGlobeLoading = useSelector(getIsGlobeMembersLoadingSelector);

  const isMembersDataLoading = useSelector(getIsMembersDataLoadingSelector);
  const [infoVisibile, setInfoVisibile] = useState(false);

  const [callSign, setCallSign] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [membershipStatus, setMembershipStatus] = useState("");
  const [filter, setFilters] = useState({});
  const userProfile = useSelector(getUserProfileSelector);
  const center = useMemo(
    () =>
      userProfile?.coordinates
        ? {
            lat: userProfile.coordinates.latitude,
            lng: userProfile.coordinates.longitude,
          }
        : { lat: 43.016888, lng: -95.866518 },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const getMembers = (conditions: MemberShipFilter) => {
    dispatch(getMembersFromCollection(conditions));
  };

  const getMembersWithPagination = (data: any) => {
    dispatch(getMembersFromCollectionWithPagination(data));
  };

  const onToggleOpen = (infoVisibile: boolean) => {
    setInfoVisibile(infoVisibile);
  };

  useEffect(() => {
    // console.log("globeMembers : ", globeMembers);
    const data: any[] = [];
    // eslint-disable-next-line array-callback-return
    globeMembers?.map((user) => {
      data.push({
        lat: user.coordinates?.latitude || 0,
        lng: user.coordinates?.longitude || 0,
        size: 7 + Math.random() * 30,
        color: ["red", "white", "blue", "green"][Math.round(Math.random() * 3)],
        data: user,
      });
    });
    setGlobeData(data);
  }, [globeMembers]);

  return {
    members,
    getMembers,
    getMembersWithPagination,
    isMembersDataLoading,
    callSign,
    setCallSign,
    country,
    setCountry,
    region,
    setRegion,
    membershipStatus,
    setMembershipStatus,
    filter,
    setFilters,
    userProfile,
    center,
    setInfoVisibile,
    infoVisibile,
    onToggleOpen,
    mapMembers,
    memberMapLoading,
    memberGlobeLoading,
    globeMembers,
    globeData,
  };
};

export default useMember;
