import React, { useState, useEffect } from "react";
import { SpotRefreshProps } from "types/Functions";

function SpotRefresh({ refreshSpots }: SpotRefreshProps) {
  const [timer, setTimer] = useState(60);
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          refreshSpots();
          return 60;
        }
      });
    }, 1000);

    return () => clearInterval(countdown); // Cleanup on unmount
  }, [refreshSpots]);
  return (
    <div className="flex w-full">
      <p className="font-[Play] text-white text-xs ml-1 bg-[#0B0B38] px-2">
        Spots will refresh in:{" "}
        <span className="text-[#17F9DA]">{timer} seconds</span>
      </p>
    </div>
  );
}

export default SpotRefresh;
