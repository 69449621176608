import { ParkReference } from "constants/Collections";
import { db } from "firebase-config";
import {
  collection,
  endAt,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { ParkReferenceModel, PotaAutoSpotPayload } from "types/Models";
import { geohashQueryBounds, distanceBetween } from "geofire-common";

interface PotaServiceType {
  getParkByReference(parkReference: string): Promise<Partial<ParkReferenceModel> | null>;
  getParkReferenceSuggestions(parkReference: string): Promise<Array<Partial<ParkReferenceModel>> | null>;
  getNearbyParks(center: [number, number], radiusInKm?: number): Promise<Array<Partial<ParkReferenceModel>> | null>;
  addPotaAutoSpot(payload: PotaAutoSpotPayload): Promise<void>;
}

const PotaService: PotaServiceType = {
  async getParkByReference(parkReference: string) {
    try {
      let q = query(
        collection(db, ParkReference),
        where("reference", "==", parkReference.toUpperCase())
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot) {
        const responseData: Partial<ParkReferenceModel>[] = [];
        querySnapshot.forEach((doc) => {
          let data: Partial<ParkReferenceModel> = {
            uid: doc.id,
            ...doc.data(),
          };
          responseData.push(data);
        });
        if (responseData.length) {
          return responseData[0];
        }

        return null;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
  async getParkReferenceSuggestions(parkReference: string) {
    try {
      let q = query(
        collection(db, ParkReference),
        or( 
          where("referenceSearchIndex", "array-contains", parkReference),
          where("nameSearchIndex", "array-contains", parkReference.toLowerCase()),
        ),
        orderBy("reference", "asc"),
        limit(5) 
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot) {
        const responseData: Partial<ParkReferenceModel>[] = [];
        querySnapshot.forEach((doc) => {
          
          let data = {
            uid: doc.id,
            ...doc.data(),
          };
          responseData.push(data);
        });
        if (responseData.length) {
          return responseData;
        }

        return null;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
  async getNearbyParks(center: [number, number] = [44.333765, -68.27342], radiusInKm: number = 40) {
    const radiusInM = radiusInKm * 1000;  
    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      let q = query(
        collection(db, "ParkReference"),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1])
      );
  
      promises.push(getDocs(q));
    }
  
    // Collect all the query results together into a single list
    const querySnapshot = await Promise.all(promises);
    const matchingDocs: Array<ParkReferenceModel> = [];
  
    for (const snap of querySnapshot) {
      for (const doc of snap.docs) {
        const lat = doc.get("latitude");
        const lng = doc.get("longitude");
  
        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = distanceBetween([lat, lng], center);
        const distanceInM = distanceInKm * 1000;
        if (distanceInM <= radiusInM) {
          matchingDocs.push({
            uid: doc.id,
            ...doc.data(),
            distanceInKm,
          } as any);
        }
      }
    }
  
    if (matchingDocs.length === 0) return null;

    // Sort by distance
    matchingDocs.sort((a, b) => (a.distanceInKm! - b.distanceInKm!));

    return matchingDocs;
  },
  async addPotaAutoSpot(payload: PotaAutoSpotPayload){
    try {
      const response = await fetch('https://api.pota.app/spot', {
        method: 'POST',
        headers: { 'User-Agent': `Web` },
        body: JSON.stringify({
          ...payload,
          source: 'Web',
        })
      })
      if (response.status === 200) {
        // const body = await response.text()
        // console.log(body)
      } else {
        const body = await response.text()
        console.log('POTA Spotter http error', response, body)
      }
    } catch (error) {
      console.log('POTA Spotter error', error)
    }
  }

};

export default PotaService;
