/* eslint-disable array-callback-return */
import {
  Awards,
  ContestTable,
  EnrolledUsers,
  LogBookContactTable,
  LogBookTable,
  UserDataTable,
} from "constants/Collections";
import { db } from "firebase-config";
import {
  DocumentData,
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { CONTEST_STATUS } from "types/Functions";
import {
  ContestLeadrBoard,
  ContestModel,
  ContestantDetail,
  ContestantUserDetail,
  EnrollSuccessData,
  LogBookContactModel,
  LogBookModel,
  UserAwards,
  UserProfile,
} from "types/Models";
var uniq = require("lodash.uniq");
interface ContestServiceType {
  storeContest(data: any): Promise<any>;
  fetchActiveContest(): Promise<ContestModel[] | null>;
  fetchUpcomingContest(): Promise<ContestModel[] | null>;
  fetchPassedContest(): Promise<ContestModel[] | null>;
  fetchContestDetail(id: string): Promise<ContestModel | null>;
  editContest(data: ContestModel, docId: string): Promise<boolean>;
  enrollContest(
    contest: ContestModel,
    user: UserProfile,
    locationDetails?: any
  ): Promise<EnrollSuccessData | null>;
  fetchContestantDetail(
    contestId: string,
    userId: string
  ): Promise<ContestantDetail | null>;
  fetchUserContestRanking(data: any): Promise<number | null>;
  // fetchContestLeader(contestIds: string[]): Promise<ContestLeader | null>;
  deleteContestById(id: string): Promise<boolean>;
  fetchContestLeaderBoard(
    contestId: string
  ): Promise<ContestLeadrBoard[] | null>;
  fetchContestContacts(
    contestId: string
  ): Promise<LogBookContactModel[] | null>;
  fetchContestantUserDetail(data: any): Promise<ContestantUserDetail | null>;
  fetchAwardContest(): Promise<UserAwards[] | null>;
  fetchAward(): Promise<UserAwards[] | null>;
  updateContest(data: ContestModel): Promise<boolean>;
}

const ContestService: ContestServiceType = {
  storeContest: async (data: any) => {
    try {
      const contactRef = collection(db, ContestTable);
      await addDoc(contactRef, data);
    } catch (e) {
      return null;
    }
  },
  editContest: async (data, docId) => {
    try {
      if (docId) {
        const contactRef = doc(db, ContestTable, docId);
        await setDoc(contactRef, data, { merge: true });
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  },
  fetchActiveContest: async () => {
    try {
      const contestData: ContestModel[] = [];
      const currentDate = Timestamp.fromDate(new Date());

      // Query Firestore to retrieve active contest
      const contestRef = collection(db, ContestTable);
      const q = query(
        contestRef,
        where("startDate", "<=", currentDate),
        orderBy("startDate", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        const endDate = new Date(data.endDate.toDate());
        const currentDate = new Date();
        if (endDate > currentDate) {
          const eventData = { id: doc.id, ...data };
          contestData.push(eventData as ContestModel);
        }
      });
      return contestData;
    } catch (e) {
      return null;
    }
  },
  fetchUpcomingContest: async () => {
    try {
      const contestData: ContestModel[] = [];
      const currentDate = Timestamp.fromDate(new Date());

      // Query Firestore to retrieve upcoming contest
      const contestRef = collection(db, ContestTable);
      const q = query(contestRef, where("startDate", ">=", currentDate));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const eventData = { id: doc.id, ...doc.data() };
        contestData.push(eventData as ContestModel);
      });
      return contestData;
    } catch (e) {
      return null;
    }
  },
  fetchPassedContest: async () => {
    try {
      const contestData: ContestModel[] = [];
      const currentDate = Timestamp.fromDate(new Date());

      // Query Firestore to retrieve passed contest
      const contestRef = collection(db, ContestTable);
      const q = query(
        contestRef,
        where("endDate", "<", currentDate),
        orderBy("endDate", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const eventData = { id: doc.id, ...doc.data() };
        contestData.push(eventData as ContestModel);
      });
      return contestData;
    } catch (e) {
      return null;
    }
  },
  fetchContestDetail: async (contestId) => {
    const contestRef = doc(db, ContestTable, contestId);
    const contestData: DocumentData = await getDoc(contestRef);
    if (contestData.exists()) {
      return {
        ...contestData.data(),
        id: contestId,
      } as ContestModel;
    } else {
      return null;
    }
  },
  enrollContest: async (contest, user, locationDetails) => {
    try {
      const logbookData = {
        name: `${user.callSign || user.firstName} - ${contest.name} Log`,
        contestId: contest.id,
        uid: user.uid,
        timestamp: serverTimestamp(),
        defaultCallSign: user.callSign || "",
        ...(locationDetails ? { defaultLocation: locationDetails } : {}),
        contest: {
          name: contest.name,
          image: contest.image,
          startDate: contest.startDate,
          endDate: contest.endDate,
          shortDescription: contest.shortDescription || "",
        },
      };
      const logBookRef = doc(collection(db, LogBookTable));
      await setDoc(logBookRef, logbookData);

      const contestRef = doc(db, ContestTable, `${contest.id}`);
      const enrolledUsersCollectionRef = collection(contestRef, EnrolledUsers);

      const contestEnrollRef = doc(enrolledUsersCollectionRef, `${user.uid}`);

      const enrollData = {
        ...user,
        logBookId: logBookRef.id,
        logbookName: logbookData.name,
        enrolledTimestamp: serverTimestamp(),
      };
      await setDoc(contestEnrollRef, enrollData);

      let contestStatus;
      const now = new Date();
      const startDate = new Date(contest?.startDate.seconds * 1000);
      const endDate = new Date(contest?.endDate.seconds * 1000);
      if (
        now.getTime() > startDate.getTime() &&
        now.getTime() < endDate.getTime()
      ) {
        contestStatus = CONTEST_STATUS.ACTIVE;
      } else if (now.getTime() < startDate.getTime()) {
        contestStatus = CONTEST_STATUS.UPCOMING;
      } else {
        contestStatus = CONTEST_STATUS.PASSED;
      }
      const enrollSuccess: EnrollSuccessData = {
        contestId: contest.id!,
        contestName: contest.name,
        logbookName: logbookData.name,
        logbookId: logBookRef.id,
        contestStatus: contestStatus,
        startDate: contest.startDate,
        endDate: contest.endDate,
      };
      const contestDocRef = doc(db, ContestTable, `${contest.id}`);
      await setDoc(
        contestDocRef,
        {
          contestantCount: increment(1),
        },
        { merge: true }
      );

      let userContests = user.contests || [];
      userContests.push(contest.id!);
      userContests = uniq(userContests);

      const userDocRef = doc(db, UserDataTable, `${user.uid}`);
      await setDoc(
        userDocRef,
        {
          contests: userContests,
        },
        { merge: true }
      );

      return enrollSuccess;
    } catch (e) {
      return null;
    }
  },
  fetchContestantDetail: async (contestId, userId) => {
    try {
      const contestantsRef = query(
        collection(db, ContestTable, contestId, EnrolledUsers),
        orderBy("enrolledTimestamp", "desc"),
        limit(50)
      );
      const userContestRef = doc(
        db,
        ContestTable,
        contestId,
        EnrolledUsers,
        `${userId || "GUEST"}`
      );
      const contestants = await getDocs(contestantsRef);
      const contestantsData: UserProfile[] = [];
      contestants.forEach((doc) => {
        const data = doc.data();
        const userData = {
          ...data,
          id: doc.id,
        } as UserProfile;
        contestantsData.push(userData);
      });

      const userContestData: DocumentData = await getDoc(userContestRef);

      const countQuery = query(
        collection(db, ContestTable, contestId, EnrolledUsers)
      );
      const contestantCount = await getCountFromServer(countQuery);

      const contestantDetail: ContestantDetail = {
        isUserEnrolled: userContestData.exists(),
        contestantCount: contestantCount.data().count,
        contestants: contestantsData,
        enrolledUser: userContestData.data(),
      };

      return contestantDetail;
    } catch (e) {
      return null;
    }
  },
  fetchUserContestRanking: async (data) => {
    try {
      // console.log("DATA : ", data);
      const { contestId, contactCount } = data;
      const contestContactRef = query(
        collection(db, LogBookTable),
        where("contestId", "==", contestId),
        where("contactCount", ">", contactCount)
      );
      const contactNumber = await getCountFromServer(contestContactRef);
      return contactNumber.data().count + 1;
    } catch (e) {
      return null;
    }
  },
  deleteContestById: async (id) => {
    try {
      const contestRef = doc(db, ContestTable, id);
      await deleteDoc(contestRef);
      return true;
    } catch (e) {
      return false;
    }
  },
  fetchContestLeaderBoard: async (contestId) => {
    try {
      const contestContactRef = query(
        collection(db, LogBookTable),
        where("contestId", "==", contestId),
        orderBy("contactCount", "desc"),
        limit(20)
      );
      const contestLogbook = await getDocs(contestContactRef);
      const contestLogbookData: LogBookModel[] = contestLogbook.docs.map(
        (doc) => {
          return { id: doc.id, ...doc.data() };
        }
      );

      const usersInContest = query(
        collection(db, UserDataTable),
        where("contests", "array-contains", contestId)
      );
      const usersInContestData = await getDocs(usersInContest);
      const usersInContestDataMap: UserProfile[] = usersInContestData.docs.map(
        (doc) => {
          return { ...doc.data() };
        }
      );

      const result: ContestLeadrBoard[] = [];

      contestLogbookData.forEach((logbook) => {
        const user = usersInContestDataMap.find(
          (user) => user.uid === logbook.uid
        );
        if (user) {
          result.push({
            ...user,
            numberOfPoints: logbook.contactCount || 0,
          });
        }
      });

      return result;
    } catch (e) {
      return null;
    }
  },
  fetchContestContacts: async (contestId) => {
    try {
      const contestContactRef = query(
        collection(db, LogBookContactTable),
        where("contestId", "==", contestId),
        orderBy("contactTimeStamp", "desc")
      );
      const contestContacts = await getDocs(contestContactRef);
      const contestContactsData: LogBookContactModel[] =
        contestContacts.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });

      return contestContactsData;
    } catch (e) {
      return null;
    }
  },
  fetchContestantUserDetail: async (data) => {
    try {
      const { contestId, userId } = data;

      // Parallel fetch for contestContacts, contestCount, and userProfile
      const [contestContactsRes, contestCountRes, userProfileRes] =
        await Promise.all([
          getDocs(
            query(
              collection(db, LogBookContactTable),
              where("contestId", "==", contestId),
              where("uid", "==", userId),
              orderBy("contactTimeStamp", "desc")
            )
          ),
          getCountFromServer(
            query(
              collection(db, LogBookContactTable),
              where("contestId", "==", contestId),
              where("uid", "==", userId)
            )
          ),
          getDoc(doc(db, UserDataTable, userId)),
        ]);

      const contestContactsData = contestContactsRes.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const contestCount = contestCountRes.data().count;
      const userProfileData = userProfileRes.data();

      // Fetch contestLogBook
      const contestLogBookRes = await getDocs(
        query(
          collection(db, LogBookTable),
          where("contestId", "==", contestId),
          where("uid", "==", userId),
          limit(1)
        )
      );

      let userContestLogBookCount = 0;
      contestLogBookRes.docs.forEach((doc) => {
        const data = doc.data();
        userContestLogBookCount = data.contactCount;
      });

      // Fetch userContestRank
      const userContestRankRes = await getCountFromServer(
        query(
          collection(db, LogBookTable),
          where("contestId", "==", contestId),
          where("contactCount", ">", userContestLogBookCount)
        )
      );

      const userContestRank = userContestRankRes.data().count + 1;

      // Construct and return the user detail
      return {
        user: userProfileData,
        userLogs: contestContactsData,
        totalQso: contestCount,
        contestRanking: userContestRank,
      } as ContestantUserDetail;
    } catch (e) {
      return null;
    }
  },
  fetchAwardContest: async () => {
    try {
      const contestAwardRef = query(
        collection(db, Awards),
        where("type", "==", "contest")
      );
      const contestAward = await getDocs(contestAwardRef);
      const contestAwardData: any[] = [];
      contestAward.docs.map((doc) => {
        contestAwardData.push({ id: doc.id, ...doc.data() });
      });
      return contestAwardData as UserAwards[];
    } catch (e) {
      return null;
    }
  },
  fetchAward: async () => {
    try {
      const contestAwardRef = query(collection(db, Awards));
      const contestAward = await getDocs(contestAwardRef);
      const contestAwardData: any[] = [];
      contestAward.docs.map((doc) => {
        contestAwardData.push({ id: doc.id, ...doc.data() });
      });
      return contestAwardData as UserAwards[];
    } catch (e) {
      return null;
    }
  },
  updateContest: async (data) => {
    try {
      if (data.id) {
        const contactRef = doc(db, ContestTable, data.id);
        await setDoc(
          contactRef,
          {
            ...data,
          },
          { merge: true }
        );
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  },
};

export default ContestService;
