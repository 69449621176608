import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  editContactLogBookDataAction,
  selectEditContactModalData,
} from "store/Actions/logBook.actions";
import { getIsLogBookLoadingSelector, getIsLogBookSavingLoadingSelector } from "store/Reducers/loading.reducer";
import {
  getEditContactModalDataSelector,
  getLogBookListSelector,
} from "store/Reducers/logBook.reducer";
import { LogBookContactModel, LogBookModel } from "types/Models";

export const useEditContact = () => {
  const dispatch = useDispatch();
  const editContactModalData = useSelector(getEditContactModalDataSelector);
  const isLoading = useSelector(getIsLogBookLoadingSelector);
  const isSavingOnLoading = useSelector(getIsLogBookSavingLoadingSelector);
  const logBooksList: LogBookModel[] = useSelector(getLogBookListSelector);

  const setEditContactModalData = (contactData: LogBookContactModel | null) => {
    dispatch(selectEditContactModalData(contactData));
  };

  const editLogBookContact = async (
    values: LogBookContactModel,
    prevValues: LogBookContactModel
  ) => {
    let contactData: LogBookContactModel = values;
    dispatch(editContactLogBookDataAction(contactData, prevValues));
  };

  return {
    editContactModalData,
    setEditContactModalData,
    logBooksList,
    editLogBookContact,
    isLoading,
    isSavingOnLoading,
  };
};
