import Header from "components/Header";
import { WrlProfile } from "./WrlProfile";
import { useEffect } from "react";
import { useCallsign } from "hooks/callsign.hook";

export const ProfileModes = {
  WRL: "wrl",
  CALLSIGN: "callsign",
  EMPTY: "empty",
};

function Profile() {
  const { setCallsignSuggestionsData, setCallsignSearchKey } = useCallsign();

  useEffect(() => {
    return () => {
      setCallsignSuggestionsData(null);
      setCallsignSearchKey("");
    };
  }, []);

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Profile" />
      <WrlProfile />
    </main>
  );
}

export default Profile;
