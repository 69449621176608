import React, { useState } from "react";
import Icons from "assets/Icons";

import { useDispatch, useSelector } from "react-redux";
import {
  addStations,
  deleteStations,
  setAddStationsModal,
} from "store/Actions/logBook.actions";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";

import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

import useLogbookSetting from "hooks/logbookSetting.hook";
import { getAddStationModalSelector } from "store/Reducers/logBook.reducer";
import UploadService from "services/upload.service";
import { Stations } from "types/Models";
const AddStationModal = () => {
  const dispatch = useDispatch();
  const addStationModal = useSelector(getAddStationModalSelector);

  const [stationName, setStationName] = useState(addStationModal?.name || "");
  const [stationImage, setStationImage] = useState<any>(null);
  const [description, setDescription] = useState(
    addStationModal?.description || ""
  );
  const [loading, setLoading] = useState(false);
  const { logbookSettingLoading } = useLogbookSetting();

  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const saveStation = async () => {
    try {
      if (stationName) {
        if (description) {
          setLoading(true);

          let imageUrl;
          if (stationImage && stationImage.length > 0) {
            imageUrl = await UploadService.handleUploadContestImage(
              stationImage[0],
              "stations"
            );
          }

          let stationData: Stations = {
            name: stationName,
            photoUrl: imageUrl || addStationModal?.photoUrl || "",
            description: description,
          };
          if (addStationModal?.id) {
            stationData.id = addStationModal.id;
          }

          dispatch(addStations(stationData));
        } else {
          toast("Please enter station description");
        }
      } else {
        toast("Please enter station name");
      }
    } catch (e) {
      console.log("error", e);
    }
    setLoading(false);
  };

  const deleteStation = () => {
    if (addStationModal?.id) {
      //confirm delete with native browser confirmation alert
      if (window.confirm("Are you sure you want to delete this station?")) {
        dispatch(deleteStations(addStationModal.id));
      }
    }
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[25%] lg:px-[25%] overflow-scroll">
      <div className=" w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] px-6 py-5 text-white font-[Play] relative mt-10 md:mt-0 border border-[#451836]">
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={() => {
                dispatch(setAddStationsModal(false));
              }}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
            <h5 className="font-[Play] font-bold text-white py-2 ml-3 w-full text-lg">
              Add Stations
            </h5>
            <div className="px-1 md:px-14 mt-10 w-full">
              <TitledInputDefault
                setText={(e) => {
                  setStationName(e.target.value);
                }}
                name="name"
                title="Name"
                placeHolder="Enter station name"
                value={stationName}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setDescription(e.target.value);
                }}
                name="name"
                title="Description"
                placeHolder="Enter station description"
                value={description}
                multiple
                width={"w-[100%]"}
                className={"h-[200px] mt-[12px] mr-3 pt-2"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setStationImage(e.target.files);
                }}
                name="name"
                title="Upload:"
                placeHolder="Enter station name"
                type="file"
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
            </div>
            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={saveStation}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
              {addStationModal?.id ? (
                <BorderButtonSecondary
                  isLoading={loading || logbookSettingLoading}
                  text={"Delete"}
                  onClick={deleteStation}
                  childClassName="text-sm"
                  className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3 ml-2"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddStationModal;
