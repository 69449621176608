import BorderButtonSecondary from "components/Button/BorderButtonSecondary";

const PageError = () => {
  return (
    <div className="relative w-screen h-screen flex justify-center items-center custom-bg font-[Play] text-white px-5">
      <div className="flex flex-col items-center">
        <h1 className="font-bold text-white text-[50px] mb-5">
          Oops, Something went wrong!
        </h1>
        <p className="text-[20px] w-[100%] md:w-[50%] text-center mb-10">
          Oops! It seems like we've hit a bit of interference. Our technicians
          are notified and tuning the frequencies to get us back on the air.
          Please contact{" "}
          <span className="text-[#17F9DA]">support@worldradioleague.com</span>
        </p>
        <div className="flex justify-center w-full mt-10">
          <div className="w-[220px]">
            <BorderButtonSecondary
              text={`Go Back Home`}
              onClick={() => {
                window.location.href = "https://app.worldradioleague.com/";
              }}
              className="sm:mb-0 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageError;
