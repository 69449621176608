import { dxccCountryList } from "constants/DXCCCountryList";
import { useMemo } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { TitledCountryPickerProps } from "types/Component";

function CountryDropDownPicker({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  title,
  error,
  props,
  value,
  tabindex,
  info,
}: TitledCountryPickerProps) {
  const transformData = (data: any) => {
    return data.map((item: any) => ({
      value: item.country,
      label: item.country,
    }));
  };

  const options = useMemo(
    () => transformData(dxccCountryList),
    [dxccCountryList]
  );
  return (
    <div className={`${width ? width : "w-full"} relative`}>
      <select
        className={`h-10 bg-[#23234C] px-3 text-sm font-[Barlow] text-white w-full ${
          className ? className : ""
        } ${error ? "border border-orange-700 border-solid" : ""}`}
        onChange={(e) => setText(e.target.value)}
      >
        <option value="" label="Select Country" />
        {options.map((item: any, index: number) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CountryDropDownPicker;
