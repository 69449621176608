import CustomBorderButton from "components/Button/CustomBorderButton";

import useConfig from "hooks/config.hook";
import React from "react";
import { MemberShipPillProps } from "types/Component";

function MemberShipPill({ status }: MemberShipPillProps) {
  const { getSubscriptionLevelButtonColor, getSubscriptionLevelButtonBgColor } =
    useConfig();
  return (
    <CustomBorderButton
      text={status}
      onClick={() => {}}
      height="h-[24px]"
      className=""
      childHeight="h-[57px]"
      childClassName="text-[12px] font-bold px-2"
      fromBg={getSubscriptionLevelButtonColor("from", status)}
      viaBg={getSubscriptionLevelButtonColor("via", status)}
      toBg={getSubscriptionLevelButtonColor("to", status)}
      childBgColor={getSubscriptionLevelButtonBgColor(status)}
    />
  );
}

export default MemberShipPill;
