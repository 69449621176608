import Lottie from "lottie-react";
import LottieFiles from "assets/LottieFiles";
import { ActivityIndicatorProps } from "types/Component";

function ActivityIndicator({ size, className }: ActivityIndicatorProps) {
  return (
    <section
      className={
        className
          ? className
          : "relative w-full h-full flex justify-center items-center"
      }
    >
      <div className={`w-${size} h-${size}`}>
        <Lottie animationData={LottieFiles.ActivityIndicator} loop={true} />
      </div>
    </section>
  );
}

export default ActivityIndicator;
