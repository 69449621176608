import Icons from "assets/Icons";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import Header from "components/Header";
import InputWithLabel from "components/Inputs/InputWithLabel/InputWithLabel";
import SocialInput from "components/Inputs/SocialInput";
import TextAreaWithLabel from "components/Inputs/TextAreaWithLabel";
import { Form, Formik } from "formik";
import CustomValidation from "helpers/Validation";
import useProfile from "hooks/profile.hook";
import useUpload from "hooks/upload.hook";
import { PiInstagramLogoFill } from "react-icons/pi";
import { RiFacebookFill } from "react-icons/ri";
import { SiLinkedin } from "react-icons/si";
import { globalNavigate } from "routes/GlobalRoutes";
import { BsPersonCircle } from "react-icons/bs";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CountyPickerWithLabel from "components/Inputs/CountyPickerWithLabel/CountyPickerWithLabel";
import RegionPickerWithLabel from "components/Inputs/RegionPickerWithLabel";
import { MY_PROFILE_ROUTE } from "constants/Links";
import { allErrorHandler } from "helpers/ErrorHandler";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-international";
import "react-phone-input-international/lib/style.css";
import { UserAvatar } from "components/UserAvatar";
import { NavigateBackButton } from "../LogBook/NavigateBackButton";
import { getStateLongName, getStateShortCode } from "helpers/Utils";

function EditProfile() {
  const {
    firstName,
    lastName,
    callSign,
    country,
    setCountry,
    state,
    setState,
    phoneNumber,
    bio,
    facebook,
    linkedin,
    twitter,
    instagram,
    handleUpdateUserData,
    userProfile,
    isProfileLoading,
    email,
    profilePic,
    isCallSignAvailable,
    checkCallSignAvailabilityForAuth,
    isFetchingCallSignAvailability,
  } = useProfile();
  const {
    handleImageFileUpload,
    inputRef,
    handleButtonClick,
    imgFile,
    percent,
  } = useUpload();

  useEffect(() => {
    // console.log("userProfile: ", userProfile);
  }, [userProfile]);

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Edit Profile" />
      <div className="px-4 py-8 sm:p-8 lg:p-14 dashboardWrapper overflow-x-hidden">
        <NavigateBackButton />
        <Formik
          initialValues={{
            facebook: facebook ? facebook : "",
            linkedin: linkedin ? linkedin : "",
            twitter: twitter ? twitter : "",
            instagram: instagram ? instagram : "",
            firstName: firstName ? firstName : "",
            lastName: lastName ? lastName : "",
            callSign: callSign ? callSign : "",
            country: country ? country : "",
            state: state ? state : "",
            phoneNumber: phoneNumber ? phoneNumber : "",
            bio: bio ? bio : "",
            profilePic: profilePic ? profilePic : "",
            email: email ? email : "",
            stateLongName: state ? getStateLongName(state) : "",
          }}
          validationSchema={CustomValidation.EditProfileValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const exludesSpecialCharacters =
              CustomValidation.validateSpecialCharacters(values.callSign);
            if (exludesSpecialCharacters) {
              if (
                (isCallSignAvailable &&
                  values.callSign !== userProfile?.callSign) ||
                values.callSign === userProfile?.callSign
              ) {
                let val = values;
                if (country) {
                  val = { ...values, country: country };
                }
                if (state) {
                  val = {
                    ...val,
                    state: getStateShortCode(state),
                    stateLongName: getStateLongName(state),
                  };
                }
                if (imgFile) {
                  handleUpdateUserData({
                    user: { ...val },
                    profilePic: imgFile,
                  });
                } else {
                  handleUpdateUserData({ user: val, profilePic: undefined });
                }
              }
            } else {
              allErrorHandler("special words not allowed");
            }
          }}
        >
          {({
            errors,
            values,
            setValues,
            touched,
            resetForm,
            handleChange,
          }) => {
            return (
              <Form className="rounded-[20px] sm:rounded-[25px] md:rounded-[50px] bg-[#0B0B39] flex flex-col px-6 pb-10">
                <div className="flex justify-between flex-col sm:flex-row w-full relative">
                  <div className="relative my-6 flex flex-col items-center w-full sm:w-[35%] md:w-[27%] profileEditUserPhoto">
                    <div className="absolute w-[70%] h-full flex justify-center">
                      <img
                        className="w-full object-fill"
                        src={Icons.GradientRectanglePic}
                        alt="bg"
                      />
                    </div>
                    <div className="bg-[#161638] h-full w-[100px] sm:w-full rounded-[24px] flex justify-center items-center relative my-3">
                      {imgFile || (userProfile && userProfile?.profilePic) ? (
                        <img
                          className="w-[60%] h-[60%] rounded-full object-cover"
                          src={
                            imgFile
                              ? URL.createObjectURL(imgFile!)
                              : userProfile && userProfile?.profilePic
                              ? userProfile?.profilePic
                              : ""
                          }
                          alt="profile pic"
                        />
                      ) : (
                        <UserAvatar className="w-[60%] h-[60%]" isCurrentUser />
                      )}
                      {percent !== 0 ? (
                        <div className="bg-black w-[60%] h-[60%] rounded-full absolute bg-opacity flex items-center justify-center">
                          <div className="h-[50%] w-[50%]">
                            <CircularProgressbar
                              value={percent}
                              text={`${percent}%`}
                            />
                          </div>
                        </div>
                      ) : null}
                      <input
                        ref={inputRef}
                        className="hidden"
                        type="file"
                        onChange={handleImageFileUpload}
                      />
                      <div
                        className="bg-[#0243DD] h-[45px] w-[45px] rounded-full flex justify-center items-center absolute bottom-[21%] right-[21%] border-4 border-[#161638] border-solid cursor-pointer"
                        onClick={handleButtonClick}
                      >
                        {/* {imgFile ? (
                          <AiOutlineCloudUpload size={20} color="#fff" />
                        ) : ( */}
                        <img src={Icons.EditPencil} alt="" />
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  <div className="mt-0 mb-10 sm:mb-0 sm:mt-14 w-full sm:w-[62%] md:w-[46%]">
                    <div className="flex flex-col sm:flex-row justify-start sm:justify-between items-center">
                      <SocialInput
                        icon={
                          <RiFacebookFill
                            color="#17F9DA"
                            className="mx-3 w-4 h-4"
                          />
                        }
                        name="facebook"
                        setText={handleChange}
                        placeHolder="@facebook"
                        error={errors.facebook}
                      />
                      <SocialInput
                        icon={
                          <SiLinkedin
                            color="#17F9DA"
                            className="mx-3 w-4 h-4"
                          />
                        }
                        name="linkedin"
                        setText={handleChange}
                        placeHolder="linkedin"
                        error={errors.linkedin}
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row justify-start sm:justify-between items-center mt-6">
                      <SocialInput
                        icon={
                          <img
                            src={Icons.TwitterX}
                            className="ml-3 mr-3"
                            alt=""
                          />
                        }
                        name="twitter"
                        setText={handleChange}
                        placeHolder="@X"
                        error={errors.twitter}
                      />
                      <SocialInput
                        icon={
                          <PiInstagramLogoFill
                            color="#17F9DA"
                            className="mx-3 w-4 h-4"
                          />
                        }
                        name="instagram"
                        setText={handleChange}
                        placeHolder="@instagram"
                        error={errors.instagram}
                      />
                    </div>
                    {/* <button className="flex justify-center items-center bg-[#FFFFFF0F] h-[41px] text-white mt-6 w-full font-[Play] text-sm">
                      <div className="bg-[#FFFFFF33] h-[25px] w-[25px] rounded-full flex items-center justify-center mr-3.5">
                        <FaPlus color="#fff" />
                      </div>
                      Add socials
                    </button> */}
                  </div>
                  <div className="w-[18%] pt-16 hidden md:block">
                    <div>
                      <BorderButtonPrimary
                        text="Save"
                        type="submit"
                        onClick={() => {}}
                        isLoading={
                          isFetchingCallSignAvailability || isProfileLoading
                        }
                        height="h-9"
                      />
                    </div>
                    <div>
                      <BorderButtonPrimary
                        text="Cancel"
                        onClick={() =>
                          globalNavigate(
                            `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                              userProfile?.callSign ? userProfile.callSign : ""
                            )}?tab=Logbook&contact-tab=entries`,
                            {
                              state: userProfile?.uid,
                            }
                          )
                        }
                        height="h-9"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col sm:flex-row justify-start sm:justify-between mb-6">
                    <div className="w-full sm:w-[48.5%]">
                      <InputWithLabel
                        label="First Name"
                        placeholder="Please input new information"
                        name="firstName"
                        setText={handleChange}
                        error={errors.firstName}
                      />
                    </div>
                    <div className="mt-6 sm:mt-0 w-full sm:w-[48.5%]">
                      <InputWithLabel
                        label="Last Name"
                        placeholder="Please input new information"
                        name="lastName"
                        setText={handleChange}
                        error={errors.lastName}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-start sm:justify-between sm:mb-6">
                    <div className="w-full sm:w-[48.5%]">
                      <InputWithLabel
                        label="Call Sign"
                        placeholder="Please input new information"
                        name="callSign"
                        setText={(e) => {
                          setValues((prev: any) => {
                            return {
                              ...prev,
                              callSign: e.target.value.toUpperCase().trim(),
                            };
                          });
                        }}
                        onBlur={(e) => {
                          // console.log(userProfile?.callSign, e.target.value);
                          if (userProfile?.callSign !== e.target.value) {
                            checkCallSignAvailabilityForAuth(e.target.value);
                          }
                        }}
                        error={errors.callSign}
                      />
                    </div>
                    <div className="w-full sm:w-[48.5%] mt-6 sm:mt-0">
                      {/* <InputWithLabel
                        label="Phone Number"
                        placeholder="Please input new information"
                        name="phoneNumber"
                        setText={handleChange}
                        error={errors.phoneNumber}
                      /> */}
                      <div
                        className={`w-full bg-[#FFFFFF0F] relative px-[15px] py-1 h-[55px]`}
                      >
                        <p className="text-[#17F9DA] text-xs font-[Play] mb-1.5">
                          Phone Number
                        </p>

                        <PhoneInput
                          containerClass={"mb-5 h-10"}
                          inputClass={"text-white"}
                          value={phoneNumber}
                          onChange={(phone) => {
                            setValues((prev: any) => {
                              return {
                                ...prev,
                                phoneNumber: phone,
                              };
                            });
                          }}
                          containerStyle={{
                            width: "100%",
                            borderRadius: 0,
                            borderWidth: 0,
                            marginTop: -10,
                            // borderColor: "#fff",
                          }}
                          inputStyle={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                            borderRadius: 0,
                            borderColor: "#fff",
                            borderWidth: 0,
                          }}
                          buttonStyle={{
                            backgroundColor: "transparent",
                            borderRadius: 0,
                            borderWidth: 0,
                            // borderColor: "#fff",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-start sm:justify-between mb-6">
                    <div className="mt-6 sm:mt-0 w-full sm:w-[48.5%]">
                      <CountyPickerWithLabel
                        label="Country"
                        placeholder="Please input new information"
                        name="country"
                        value={country}
                        setText={(val) => {
                          handleChange(val);
                          setCountry(val);
                        }}
                        error={errors.country}
                      />
                    </div>
                    <div className="mt-6 sm:mt-0 w-full sm:w-[48.5%]">
                      <RegionPickerWithLabel
                        label="State"
                        placeholder="Please input new information"
                        name="state"
                        country={country}
                        value={getStateLongName(state)}
                        setText={(val) => {
                          handleChange(val);
                          setState(val);
                          setValues((prev) => ({ ...prev, state: val }));
                        }}
                        error={errors.country}
                      />
                    </div>
                  </div>
                  {userProfile?.provideId === "password" ? (
                    <div className="mt-6 sm:mt-0 w-full mb-6">
                      <InputWithLabel
                        label="Email"
                        placeholder="Please input new information"
                        name="email"
                        setText={handleChange}
                        error={errors.email}
                      />
                    </div>
                  ) : null}
                  <TextAreaWithLabel
                    label="Bio"
                    placeholder="Please input new information"
                    multiline
                    name="bio"
                    setText={handleChange}
                    error={errors.bio}
                  />
                </div>
                <div className="w-full pt-10 flex justify-between md:hidden">
                  <div className="w-[48%]">
                    <BorderButtonPrimary
                      type="submit"
                      text="Save"
                      onClick={() => {}}
                      isLoading={
                        isFetchingCallSignAvailability || isProfileLoading
                      }
                      height="h-9"
                    />
                  </div>
                  <div className="w-[48%]">
                    <BorderButtonPrimary
                      text="Cancel"
                      onClick={() =>
                        globalNavigate(
                          `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                            userProfile?.callSign ? userProfile.callSign : ""
                          )}?tab=Logbook&contact-tab=entries`,
                          {
                            state: userProfile?.uid,
                          }
                        )
                      }
                      height="h-9"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </main>
  );
}

export default EditProfile;
