import { useDispatch, useSelector } from "react-redux";
import {
  fetchAwards,
  addAward as addAwardAction,
  setShowAwardModal as setShowAwardModalAction,
} from "store/Actions/award.actions";

import {
  getAwardsLoadingSelector,
  getAwardsSelector,
} from "store/Reducers/award.reducer";
import { Award } from "types/Models";

export const useAwards = () => {
  const dispatch = useDispatch();
  const awards = useSelector(getAwardsSelector);
  const isLoading = useSelector(getAwardsLoadingSelector);

  const getAwards = () => {
    dispatch(fetchAwards());
  };

  const addAward = (data: Award) => {
    dispatch(addAwardAction(data));
  };

  const setShowAwardModal = (show: boolean)=>{
    dispatch(setShowAwardModalAction(show));
  }

  return {
    awards,
    isLoading,
    getAwards,
    addAward,
    setShowAwardModal,
  };
};
