import { useDispatch, useSelector } from "react-redux";
import { fetchCallsignFromFirestoreDB, fetchCallsignFromHamDB, setCallSignData, setCallSignSearchKey, setCallSignSuggestionsData } from "store/Actions/callsign.actions";
import {
  getCallsignDataLoadingSelector,
  getCallsignDataSelector,
  getCallsignSearchKeySelector,
  getCallsignSuggestionsDataSelector,
} from "store/Reducers/callsign.reducer";
import { useDebouncedCallback } from "use-debounce";

export const useCallsign = () => {
  const dispatch = useDispatch();

  const callsignData = useSelector(getCallsignDataSelector);
  const callsignSuggestionsData = useSelector(getCallsignSuggestionsDataSelector);
  const callsignDataLoading = useSelector(getCallsignDataLoadingSelector);
  const callsignSearchKey = useSelector(getCallsignSearchKeySelector);

  const fetchCallSignData = (callSign: string, type: "FirestoreDB" | "HamDB") => {
    if (type === "FirestoreDB") {
      dispatch(fetchCallsignFromFirestoreDB(callSign));
    }
    else if (type === "HamDB"){
      dispatch(fetchCallsignFromHamDB(callSign));
    }
  };

  const debouncedFetchCallSignData = useDebouncedCallback(
    (callsign, type) => fetchCallSignData(callsign, type),
    1000
  );

  const setCallsignData = (data: any) => {
    dispatch(setCallSignData(data));
  }

  const setCallsignSuggestionsData = (data: any) => {
    dispatch(setCallSignSuggestionsData(data));
  }

  const setCallsignSearchKey = (searchKey: string) => {
    dispatch(setCallSignSearchKey(searchKey));
  }

  return {
    callsignData,
    callsignDataLoading,
    callsignSearchKey,
    fetchCallSignData,
    debouncedFetchCallSignData,
    setCallsignData,
    setCallsignSearchKey,
    callsignSuggestionsData,
    setCallsignSuggestionsData,
  };
};
