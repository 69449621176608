import { DXCCList } from "types/Models";

export const dxccCountryList: DXCCList[] = [
  {
    country: "Afghanistan",
    continent: "AS",
    dxccNumber: 3,
    flagCode: "AF",
    countryCode: "AF",
  },
  {
    country: "Agalega & St. Brandon",
    continent: "AF",
    dxccNumber: 4,
    flagCode: "MP",
    countryCode: "MP",
  },
  {
    country: "Åland Islands",
    continent: "EU",
    dxccNumber: 5,
    flagCode: "AX",
    countryCode: "AX",
  },
  {
    country: "Alaska",
    continent: "NA",
    dxccNumber: 6,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Albania",
    continent: "EU",
    dxccNumber: 7,
    flagCode: "AL",
    countryCode: "AL",
  },
  {
    country: "Algeria",
    continent: "AF",
    dxccNumber: 400,
    flagCode: "DZ",
    countryCode: "DZ",
  },
  {
    country: "American Samoa",
    continent: "OC",
    dxccNumber: 9,
    flagCode: "AS",
    countryCode: "AS",
  },
  {
    country: "Amsterdam & St. Paul Is.",
    continent: "AF",
    dxccNumber: 10,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Andaman & Nicobar Is.",
    continent: "AS",
    dxccNumber: 11,
    flagCode: "IN",
    countryCode: "IN",
  },
  {
    country: "Andorra",
    continent: "EU",
    dxccNumber: 203,
    flagCode: "AD",
    countryCode: "AD",
  },
  {
    country: "Angola",
    continent: "AF",
    dxccNumber: 401,
    flagCode: "AO",
    countryCode: "AO",
  },
  {
    country: "Anguilla",
    continent: "NA",
    dxccNumber: 12,
    flagCode: "AI",
    countryCode: "AI",
  },
  {
    country: "Annobon Island",
    continent: "AF",
    dxccNumber: 195,
    flagCode: "GQ",
    countryCode: "GQ",
  },
  {
    country: "Antarctica",
    continent: "SA",
    dxccNumber: 13,
    flagCode: "AQ",
    countryCode: "AQ",
  },
  {
    country: "Antigua & Barbuda",
    continent: "NA",
    dxccNumber: 94,
    flagCode: "AG",
    countryCode: "AG",
  },
  {
    country: "Argentina",
    continent: "SA",
    dxccNumber: 100,
    flagCode: "AR",
    countryCode: "AR",
  },
  {
    country: "Armenia",
    continent: "AS",
    dxccNumber: 14,
    flagCode: "AM",
    countryCode: "AM",
  },
  {
    country: "Aruba",
    continent: "SA",
    dxccNumber: 91,
    flagCode: "AW",
    countryCode: "AW",
  },
  {
    country: "Ascension Island",
    continent: "AF",
    dxccNumber: 205,
    flagCode: "KY",
    countryCode: "UK",
  },
  {
    country: "Asiatic Russia",
    continent: "AS",
    dxccNumber: 15,
    flagCode: "RU",
    countryCode: "RU",
  },
  {
    country: "Turkey",
    continent: "AS",
    dxccNumber: 390,
    flagCode: "TR",
    countryCode: "TR",
  },
  {
    country: "Australia",
    continent: "OC",
    dxccNumber: 150,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Austria",
    continent: "EU",
    dxccNumber: 206,
    flagCode: "AT",
    countryCode: "AT",
  },
  {
    country: "Aves Island",
    continent: "NA",
    dxccNumber: 17,
    flagCode: "VE",
    countryCode: "VE",
  },
  {
    country: "Azerbaijan",
    continent: "AS",
    dxccNumber: 18,
    flagCode: "AZ",
    countryCode: "AZ",
  },
  {
    country: "Azores",
    continent: "EU",
    dxccNumber: 149,
    flagCode: "PZ",
    countryCode: "PT",
  },
  {
    country: "Austral Islands",
    continent: "OC",
    dxccNumber: 508,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Bahamas",
    continent: "NA",
    dxccNumber: 60,
    flagCode: "BS",
    countryCode: "BS",
  },
  {
    country: "Bahrain",
    continent: "AS",
    dxccNumber: 304,
    flagCode: "BH",
    countryCode: "BH",
  },
  {
    country: "Baker & Howland Islands",
    continent: "OC",
    dxccNumber: 20,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Balearic Islands",
    continent: "EU",
    dxccNumber: 21,
    flagCode: "ES",
    countryCode: "ES",
  },
  {
    country: "Banaba Island",
    continent: "OC",
    dxccNumber: 490,
    flagCode: "KI",
    countryCode: "KI",
  },
  {
    country: "Bangladesh",
    continent: "AS",
    dxccNumber: 305,
    flagCode: "BD",
    countryCode: "BD",
  },
  {
    country: "Barbados",
    continent: "NA",
    dxccNumber: 62,
    flagCode: "BB",
    countryCode: "BB",
  },
  {
    country: "Belarus",
    continent: "EU",
    dxccNumber: 27,
    flagCode: "BY",
    countryCode: "BY",
  },
  {
    country: "Belgium",
    continent: "EU",
    dxccNumber: 209,
    flagCode: "BE",
    countryCode: "BE",
  },
  {
    country: "Belize",
    continent: "NA",
    dxccNumber: 66,
    flagCode: "BZ",
    countryCode: "BZ",
  },
  {
    country: "Benin",
    continent: "AF",
    dxccNumber: 416,
    flagCode: "BJ",
    countryCode: "BJ",
  },
  {
    country: "Bermuda",
    continent: "NA",
    dxccNumber: 64,
    flagCode: "BM",
    countryCode: "BM",
  },
  {
    country: "Bhutan",
    continent: "AS",
    dxccNumber: 306,
    flagCode: "BT",
    countryCode: "BT",
  },
  {
    country: "Bolivia",
    continent: "SA",
    dxccNumber: 104,
    flagCode: "BO",
    countryCode: "BO",
  },
  {
    country: "Bonaire",
    continent: "SA",
    dxccNumber: 520,
    flagCode: "BQ1",
    countryCode: "",
  },
  {
    country: "Bosnia-Herzegovina",
    continent: "EU",
    dxccNumber: 501,
    flagCode: "BA",
    countryCode: "BA",
  },
  {
    country: "Botswana",
    continent: "AF",
    dxccNumber: 402,
    flagCode: "BW",
    countryCode: "BW",
  },
  {
    country: "Brazil",
    continent: "SA",
    dxccNumber: 108,
    flagCode: "BR",
    countryCode: "BR",
  },
  {
    country: "British Virgin Islands",
    continent: "NA",
    dxccNumber: 65,
    flagCode: "VG",
    countryCode: "VG",
  },
  {
    country: "Brunei Darussalam",
    continent: "OC",
    dxccNumber: 345,
    flagCode: "BN",
    countryCode: "BN",
  },
  {
    country: "Bulgaria",
    continent: "EU",
    dxccNumber: 212,
    flagCode: "BG",
    countryCode: "BG",
  },
  {
    country: "Burkina Faso",
    continent: "AF",
    dxccNumber: 480,
    flagCode: "BF",
    countryCode: "BF",
  },
  {
    country: "Burundi",
    continent: "AF",
    dxccNumber: 404,
    flagCode: "BI",
    countryCode: "BI",
  },
  {
    country: "Bouvet Island",
    continent: "AF",
    dxccNumber: 24,
    flagCode: "NO",
    countryCode: "NO",
  },
  {
    country: "Clipperton Island",
    continent: "NA",
    dxccNumber: 36,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Cambodia",
    continent: "AS",
    dxccNumber: 312,
    flagCode: "KH",
    countryCode: "KH",
  },
  {
    country: "Cameroon",
    continent: "AF",
    dxccNumber: 406,
    flagCode: "CM",
    countryCode: "CM",
  },
  {
    country: "Canada",
    continent: "NA",
    dxccNumber: 1,
    flagCode: "CA",
    countryCode: "CA",
  },
  {
    country: "Canary Islands",
    continent: "AF",
    dxccNumber: 29,
    flagCode: "ES",
    countryCode: "ES",
  },
  {
    country: "Cape Verde",
    continent: "AF",
    dxccNumber: 409,
    flagCode: "CV",
    countryCode: "CV",
  },
  {
    country: "Cayman Islands",
    continent: "NA",
    dxccNumber: 69,
    flagCode: "KY",
    countryCode: "KY",
  },
  {
    country: "Central African Republic",
    continent: "AF",
    dxccNumber: 408,
    flagCode: "CF",
    countryCode: "CF",
  },
  {
    country: "Central Kiribati",
    continent: "OC",
    dxccNumber: 31,
    flagCode: "KI",
    countryCode: "KI",
  },
  {
    country: "Ceuta & Melilla",
    continent: "AF",
    dxccNumber: 32,
    flagCode: "ES",
    countryCode: "ES",
  },
  {
    country: "Chad",
    continent: "AF",
    dxccNumber: 410,
    flagCode: "TD",
    countryCode: "TD",
  },
  {
    country: "Chagos Islands",
    continent: "AF",
    dxccNumber: 33,
    flagCode: "GB",
    countryCode: "GB",
  },
  {
    country: "Chatham Islands",
    continent: "OC",
    dxccNumber: 34,
    flagCode: "NZ",
    countryCode: "NZ",
  },
  {
    country: "Chile",
    continent: "SA",
    dxccNumber: 112,
    flagCode: "CL",
    countryCode: "CL",
  },
  {
    country: "China",
    continent: "AS",
    dxccNumber: 318,
    flagCode: "CN",
    countryCode: "CN",
  },
  {
    country: "Christmas Island",
    continent: "OC",
    dxccNumber: 35,
    flagCode: "CX",
    countryCode: "CX",
  },
  {
    country: "Cocos (Keeling) Islands",
    continent: "OC",
    dxccNumber: 38,
    flagCode: "CC",
    countryCode: "CC",
  },
  {
    country: "Cocos Island",
    continent: "NA",
    dxccNumber: 37,
    flagCode: "CR",
    countryCode: "CR",
  },
  {
    country: "Colombia",
    continent: "SA",
    dxccNumber: 116,
    flagCode: "CO",
    countryCode: "CO",
  },
  {
    country: "Comoros",
    continent: "AF",
    dxccNumber: 411,
    flagCode: "KM",
    countryCode: "KM",
  },
  {
    country: "Corsica",
    continent: "EU",
    dxccNumber: 214,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Costa Rica",
    continent: "NA",
    dxccNumber: 308,
    flagCode: "CR",
    countryCode: "CR",
  },
  {
    country: "Cote d'Ivoire",
    continent: "AF",
    dxccNumber: 428,
    flagCode: "CI",
    countryCode: "CI",
  },
  {
    country: "Crete",
    continent: "EU",
    dxccNumber: 40,
    flagCode: "GR",
    countryCode: "GR",
  },
  {
    country: "Croatia",
    continent: "EU",
    dxccNumber: 497,
    flagCode: "HR",
    countryCode: "HR",
  },
  {
    country: "Crozet Island",
    continent: "AF",
    dxccNumber: 41,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Cuba",
    continent: "NA",
    dxccNumber: 70,
    flagCode: "CU",
    countryCode: "CU",
  },
  {
    country: "Curacao",
    continent: "SA",
    dxccNumber: 517,
    flagCode: "CW",
    countryCode: "AN",
  },
  {
    country: "Cyprus",
    continent: "AS",
    dxccNumber: 215,
    flagCode: "CY",
    countryCode: "CY",
  },
  {
    country: "Czech Republic",
    continent: "EU",
    dxccNumber: 503,
    flagCode: "CZ",
    countryCode: "CZ",
  },
  {
    country: "Chesterfield Islands",
    continent: "OC",
    dxccNumber: 512,
    flagCode: "NC",
    countryCode: "NC",
  },
  {
    country: "Conway Reef",
    continent: "OC",
    dxccNumber: 489,
    flagCode: "FJ",
    countryCode: "FJ",
  },
  {
    country: "Ducie Island",
    continent: "OC",
    dxccNumber: 513,
    flagCode: "ZE",
    countryCode: "GB",
  },
  {
    country: "Dem. Rep. of the Congo",
    continent: "AF",
    dxccNumber: 414,
    flagCode: "CD",
    countryCode: "CD",
  },
  {
    country: "Denmark",
    continent: "EU",
    dxccNumber: 221,
    flagCode: "DK",
    countryCode: "DK",
  },
  {
    country: "Desecheo Island",
    continent: "NA",
    dxccNumber: 43,
    flagCode: "PR",
    countryCode: "PR",
  },
  {
    country: "Djibouti",
    continent: "AF",
    dxccNumber: 382,
    flagCode: "DJ",
    countryCode: "DJ",
  },
  {
    country: "Dodecanese",
    continent: "EU",
    dxccNumber: 45,
    flagCode: "GR",
    countryCode: "GR",
  },
  {
    country: "Dominica",
    continent: "NA",
    dxccNumber: 95,
    flagCode: "DM",
    countryCode: "DM",
  },
  {
    country: "Dominican Republic",
    continent: "NA",
    dxccNumber: 72,
    flagCode: "DO",
    countryCode: "DO",
  },
  {
    country: "DPR of Korea",
    continent: "AS",
    dxccNumber: 344,
    flagCode: "KP",
    countryCode: "KP",
  },
  {
    country: "East Malaysia",
    continent: "OC",
    dxccNumber: 46,
    flagCode: "MY",
    countryCode: "MY",
  },
  {
    country: "Easter Island",
    continent: "SA",
    dxccNumber: 47,
    flagCode: "CL",
    countryCode: "CL",
  },
  {
    country: "Eastern Kiribati",
    continent: "OC",
    dxccNumber: 48,
    flagCode: "KI",
    countryCode: "KI",
  },
  {
    country: "Ecuador",
    continent: "SA",
    dxccNumber: 120,
    flagCode: "EC",
    countryCode: "EC",
  },
  {
    country: "Egypt",
    continent: "AF",
    dxccNumber: 478,
    flagCode: "EG",
    countryCode: "EG",
  },
  {
    country: "El Salvador",
    continent: "NA",
    dxccNumber: 74,
    flagCode: "SV",
    countryCode: "SV",
  },
  {
    country: "England",
    continent: "EU",
    dxccNumber: 223,
    flagCode: "ZE",
    countryCode: "GB",
  },
  {
    country: "Equatorial Guinea",
    continent: "AF",
    dxccNumber: 49,
    flagCode: "GQ",
    countryCode: "GQ",
  },
  {
    country: "Eritrea",
    continent: "AF",
    dxccNumber: 51,
    flagCode: "ER",
    countryCode: "ER",
  },
  {
    country: "Estonia",
    continent: "EU",
    dxccNumber: 52,
    flagCode: "EE",
    countryCode: "EE",
  },
  {
    country: "Ethiopia",
    continent: "AF",
    dxccNumber: 53,
    flagCode: "ET",
    countryCode: "ET",
  },
  {
    country: "European Russia",
    continent: "EU",
    dxccNumber: 54,
    flagCode: "RU",
    countryCode: "RU",
  },
  {
    country: "Falkland Islands",
    continent: "SA",
    dxccNumber: 141,
    flagCode: "FK",
    countryCode: "FK",
  },
  {
    country: "Faroe Islands",
    continent: "EU",
    dxccNumber: 222,
    flagCode: "FO",
    countryCode: "FO",
  },
  {
    country: "Fed. Rep. of Germany",
    continent: "EU",
    dxccNumber: 230,
    flagCode: "DE",
    countryCode: "DE",
  },
  {
    country: "Fernando de Noronha",
    continent: "SA",
    dxccNumber: 56,
    flagCode: "BR",
    countryCode: "BR",
  },
  {
    country: "Fiji",
    continent: "OC",
    dxccNumber: 176,
    flagCode: "FJ",
    countryCode: "FJ",
  },
  {
    country: "Finland",
    continent: "EU",
    dxccNumber: 224,
    flagCode: "FI",
    countryCode: "FI",
  },
  {
    country: "France",
    continent: "EU",
    dxccNumber: 227,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Franz Josef Land",
    continent: "EU",
    dxccNumber: 61,
    flagCode: "RU",
    countryCode: "RU",
  },
  {
    country: "French Guiana",
    continent: "SA",
    dxccNumber: 63,
    flagCode: "FR",
    countryCode: "GF",
  },
  {
    country: "French Polynesia",
    continent: "OC",
    dxccNumber: 175,
    flagCode: "PF",
    countryCode: "PF",
  },
  {
    country: "Gabon",
    continent: "AF",
    dxccNumber: 420,
    flagCode: "GA",
    countryCode: "GA",
  },
  {
    country: "Galapagos Islands",
    continent: "SA",
    dxccNumber: 71,
    flagCode: "EC",
    countryCode: "EC",
  },
  {
    country: "Georgia",
    continent: "AS",
    dxccNumber: 75,
    flagCode: "GE",
    countryCode: "GE",
  },
  {
    country: "Ghana",
    continent: "AF",
    dxccNumber: 424,
    flagCode: "GH",
    countryCode: "GH",
  },
  {
    country: "Gibraltar",
    continent: "EU",
    dxccNumber: 233,
    flagCode: "GI",
    countryCode: "GI",
  },
  {
    country: "Glorioso Islands",
    continent: "AF",
    dxccNumber: 99,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Greece",
    continent: "EU",
    dxccNumber: 236,
    flagCode: "GR",
    countryCode: "GR",
  },
  {
    country: "Greenland",
    continent: "NA",
    dxccNumber: 237,
    flagCode: "GL",
    countryCode: "GL",
  },
  {
    country: "Grenada",
    continent: "NA",
    dxccNumber: 77,
    flagCode: "GD",
    countryCode: "GD",
  },
  {
    country: "Guadeloupe",
    continent: "NA",
    dxccNumber: 79,
    flagCode: "FR",
    countryCode: "GP",
  },
  {
    country: "Guam",
    continent: "OC",
    dxccNumber: 103,
    flagCode: "GU",
    countryCode: "GU",
  },
  {
    country: "Guantanamo Bay",
    continent: "NA",
    dxccNumber: 105,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Guatemala",
    continent: "NA",
    dxccNumber: 76,
    flagCode: "GT",
    countryCode: "GT",
  },
  {
    country: "Guernsey",
    continent: "EU",
    dxccNumber: 106,
    flagCode: "GG",
    countryCode: "GG",
  },
  {
    country: "Guinea",
    continent: "AF",
    dxccNumber: 107,
    flagCode: "GN",
    countryCode: "GN",
  },
  {
    country: "Guinea-Bissau",
    continent: "AF",
    dxccNumber: 109,
    flagCode: "GW",
    countryCode: "GW",
  },
  {
    country: "Guyana",
    continent: "SA",
    dxccNumber: 129,
    flagCode: "GY",
    countryCode: "GY",
  },
  {
    country: "Haiti",
    continent: "NA",
    dxccNumber: 78,
    flagCode: "HT",
    countryCode: "HT",
  },
  {
    country: "Hawaii",
    continent: "OC",
    dxccNumber: 110,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Honduras",
    continent: "NA",
    dxccNumber: 80,
    flagCode: "HN",
    countryCode: "HN",
  },
  {
    country: "Hong Kong",
    continent: "AS",
    dxccNumber: 321,
    flagCode: "HK",
    countryCode: "HK",
  },
  {
    country: "Hungary",
    continent: "EU",
    dxccNumber: 239,
    flagCode: "HU",
    countryCode: "HU",
  },
  {
    country: "Heard Island",
    continent: "AF",
    dxccNumber: 111,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Itu HQ",
    continent: "EU",
    dxccNumber: 117,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Iceland",
    continent: "EU",
    dxccNumber: 242,
    flagCode: "IS",
    countryCode: "IS",
  },
  {
    country: "India",
    continent: "AS",
    dxccNumber: 324,
    flagCode: "IN",
    countryCode: "IN",
  },
  {
    country: "Indonesia",
    continent: "OC",
    dxccNumber: 327,
    flagCode: "ID",
    countryCode: "ID",
  },
  {
    country: "Iran",
    continent: "AS",
    dxccNumber: 330,
    flagCode: "IR",
    countryCode: "IR",
  },
  {
    country: "Iraq",
    continent: "AS",
    dxccNumber: 333,
    flagCode: "IQ",
    countryCode: "IQ",
  },
  {
    country: "Ireland",
    continent: "EU",
    dxccNumber: 245,
    flagCode: "IE",
    countryCode: "IE",
  },
  {
    country: "Isle of Man",
    continent: "EU",
    dxccNumber: 114,
    flagCode: "IM",
    countryCode: "IM",
  },
  {
    country: "Israel",
    continent: "AS",
    dxccNumber: 336,
    flagCode: "IL",
    countryCode: "IL",
  },
  {
    country: "Italy",
    continent: "EU",
    dxccNumber: 248,
    flagCode: "IT",
    countryCode: "IT",
  },
  {
    country: "Jamaica",
    continent: "NA",
    dxccNumber: 82,
    flagCode: "JM",
    countryCode: "JM",
  },
  {
    country: "Jan Mayen",
    continent: "EU",
    dxccNumber: 118,
    flagCode: "NO",
    countryCode: "SJ",
  },
  {
    country: "Japan",
    continent: "AS",
    dxccNumber: 339,
    flagCode: "JP",
    countryCode: "JP",
  },
  {
    country: "Jersey",
    continent: "EU",
    dxccNumber: 122,
    flagCode: "JE",
    countryCode: "JE",
  },
  {
    country: "Johnston Island",
    continent: "OC",
    dxccNumber: 123,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Jordan",
    continent: "AS",
    dxccNumber: 342,
    flagCode: "JO",
    countryCode: "JO",
  },
  {
    country: "Juan de Nova, Europa",
    continent: "AF",
    dxccNumber: 124,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Juan Fernandez Islands",
    continent: "SA",
    dxccNumber: 125,
    flagCode: "CL",
    countryCode: "CL",
  },
  {
    country: "Kaliningrad",
    continent: "EU",
    dxccNumber: 126,
    flagCode: "RU",
    countryCode: "RU",
  },
  {
    country: "Kazakhstan",
    continent: "AS",
    dxccNumber: 130,
    flagCode: "KZ",
    countryCode: "KZ",
  },
  {
    country: "Kenya",
    continent: "AF",
    dxccNumber: 430,
    flagCode: "KE",
    countryCode: "KE",
  },
  {
    country: "Kerguelen Islands",
    continent: "AF",
    dxccNumber: 131,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Kermadec Islands",
    continent: "OC",
    dxccNumber: 133,
    flagCode: "NZ",
    countryCode: "NZ",
  },
  {
    country: "Kingdom of Eswatini",
    continent: "AF",
    dxccNumber: 468,
    flagCode: "SZ",
    countryCode: "SZ",
  },
  {
    country: "Kure Island",
    continent: "OC",
    dxccNumber: 138,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Kuwait",
    continent: "AS",
    dxccNumber: 348,
    flagCode: "KW",
    countryCode: "KW",
  },
  {
    country: "Kyrgyzstan",
    continent: "AS",
    dxccNumber: 135,
    flagCode: "KG",
    countryCode: "KG",
  },
  {
    country: "Lakshadweep Islands",
    continent: "AS",
    dxccNumber: 142,
    flagCode: "IN",
    countryCode: "IN",
  },
  {
    country: "Laos",
    continent: "AS",
    dxccNumber: 143,
    flagCode: "LA",
    countryCode: "LA",
  },
  {
    country: "Latvia",
    continent: "EU",
    dxccNumber: 145,
    flagCode: "LV",
    countryCode: "LV",
  },
  {
    country: "Lebanon",
    continent: "AS",
    dxccNumber: 354,
    flagCode: "LB",
    countryCode: "LB",
  },
  {
    country: "Lesotho",
    continent: "AF",
    dxccNumber: 432,
    flagCode: "LS",
    countryCode: "LS",
  },
  {
    country: "Liberia",
    continent: "AF",
    dxccNumber: 434,
    flagCode: "LR",
    countryCode: "LR",
  },
  {
    country: "Libya",
    continent: "AF",
    dxccNumber: 436,
    flagCode: "LY",
    countryCode: "LY",
  },
  {
    country: "Liechtenstein",
    continent: "EU",
    dxccNumber: 251,
    flagCode: "LI",
    countryCode: "LI",
  },
  {
    country: "Lithuania",
    continent: "EU",
    dxccNumber: 146,
    flagCode: "LT",
    countryCode: "LT",
  },
  {
    country: "Lord Howe Island",
    continent: "OC",
    dxccNumber: 147,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Luxembourg",
    continent: "EU",
    dxccNumber: 254,
    flagCode: "LU",
    countryCode: "LU",
  },
  {
    country: "Macao",
    continent: "AS",
    dxccNumber: 152,
    flagCode: "MO",
    countryCode: "MO",
  },
  {
    country: "Madagascar",
    continent: "AF",
    dxccNumber: 438,
    flagCode: "MG",
    countryCode: "MG",
  },
  {
    country: "Madeira Islands",
    continent: "AF",
    dxccNumber: 256,
    flagCode: "X2",
    countryCode: "PT",
  },
  {
    country: "Malawi",
    continent: "AF",
    dxccNumber: 440,
    flagCode: "MW",
    countryCode: "MW",
  },
  {
    country: "Maldives",
    continent: "AS",
    dxccNumber: 159,
    flagCode: "MV",
    countryCode: "MV",
  },
  {
    country: "Mali",
    continent: "AF",
    dxccNumber: 442,
    flagCode: "ML",
    countryCode: "ML",
  },
  {
    country: "Malpelo Island",
    continent: "SA",
    dxccNumber: 161,
    flagCode: "CO",
    countryCode: "CO",
  },
  {
    country: "Malta",
    continent: "EU",
    dxccNumber: 257,
    flagCode: "MT",
    countryCode: "MT",
  },
  {
    country: "Mariana Islands",
    continent: "OC",
    dxccNumber: 166,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Market Reef",
    continent: "EU",
    dxccNumber: 167,
    flagCode: "AX",
    countryCode: "SE",
  },
  {
    country: "Marshall Islands",
    continent: "OC",
    dxccNumber: 168,
    flagCode: "MH",
    countryCode: "MH",
  },
  {
    country: "Martinique",
    continent: "NA",
    dxccNumber: 84,
    flagCode: "MQ",
    countryCode: "MQ",
  },
  {
    country: "Mauritania",
    continent: "AF",
    dxccNumber: 444,
    flagCode: "MR",
    countryCode: "MR",
  },
  {
    country: "Mauritius",
    continent: "AF",
    dxccNumber: 165,
    flagCode: "MU",
    countryCode: "MU",
  },
  {
    country: "Mayotte",
    continent: "AF",
    dxccNumber: 169,
    flagCode: "YT",
    countryCode: "YT",
  },
  {
    country: "Mellish Reef",
    continent: "OC",
    dxccNumber: 171,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Mexico",
    continent: "NA",
    dxccNumber: 50,
    flagCode: "MX",
    countryCode: "MX",
  },
  {
    country: "Micronesia",
    continent: "OC",
    dxccNumber: 173,
    flagCode: "FM",
    countryCode: "FM",
  },
  {
    country: "Midway Island",
    continent: "OC",
    dxccNumber: 174,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Moldova",
    continent: "EU",
    dxccNumber: 179,
    flagCode: "MD",
    countryCode: "MD",
  },
  {
    country: "Monaco",
    continent: "EU",
    dxccNumber: 260,
    flagCode: "MC",
    countryCode: "MC",
  },
  {
    country: "Mongolia",
    continent: "AS",
    dxccNumber: 363,
    flagCode: "MN",
    countryCode: "MN",
  },
  {
    country: "Montenegro",
    continent: "EU",
    dxccNumber: 514,
    flagCode: "ME",
    countryCode: "ME",
  },
  {
    country: "Montserrat",
    continent: "NA",
    dxccNumber: 96,
    flagCode: "MS",
    countryCode: "MS",
  },
  {
    country: "Morocco",
    continent: "AF",
    dxccNumber: 446,
    flagCode: "MA",
    countryCode: "MA",
  },
  {
    country: "Mozambique",
    continent: "AF",
    dxccNumber: 181,
    flagCode: "MZ",
    countryCode: "MZ",
  },
  {
    country: "Myanmar",
    continent: "AS",
    dxccNumber: 309,
    flagCode: "MM",
    countryCode: "MM",
  },
  {
    country: "Macquarie Island",
    continent: "OC",
    dxccNumber: 153,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Minami Torishima",
    continent: "OC",
    dxccNumber: 177,
    flagCode: "JP",
    countryCode: "JP",
  },
  {
    country: "Mount Athos",
    continent: "EU",
    dxccNumber: 180,
    flagCode: "GR",
    countryCode: "GR",
  },
  {
    country: "Marquesas Islands",
    continent: "OC",
    dxccNumber: 509,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "North Cook Islands",
    continent: "OC",
    dxccNumber: 191,
    flagCode: "NZ",
    countryCode: "NZ",
  },
  {
    country: "N.Z. Subantarctic Is.",
    continent: "OC",
    dxccNumber: 16,
    flagCode: "NZ",
    countryCode: "NZ",
  },
  {
    country: "Namibia",
    continent: "AF",
    dxccNumber: 464,
    flagCode: "NA",
    countryCode: "NA",
  },
  {
    country: "Nauru",
    continent: "OC",
    dxccNumber: 157,
    flagCode: "NR",
    countryCode: "NR",
  },
  {
    country: "Navassa Island",
    continent: "NA",
    dxccNumber: 182,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Nepal",
    continent: "AS",
    dxccNumber: 369,
    flagCode: "NP",
    countryCode: "NP",
  },
  {
    country: "Netherlands",
    continent: "EU",
    dxccNumber: 263,
    flagCode: "NL",
    countryCode: "NL",
  },
  {
    country: "New Caledonia",
    continent: "OC",
    dxccNumber: 162,
    flagCode: "NC",
    countryCode: "NC",
  },
  {
    country: "New Zealand",
    continent: "OC",
    dxccNumber: 170,
    flagCode: "NZ",
    countryCode: "NZ",
  },
  {
    country: "Nicaragua",
    continent: "NA",
    dxccNumber: 86,
    flagCode: "NI",
    countryCode: "NI",
  },
  {
    country: "Niger",
    continent: "AF",
    dxccNumber: 187,
    flagCode: "NE",
    countryCode: "NE",
  },
  {
    country: "Nigeria",
    continent: "AF",
    dxccNumber: 450,
    flagCode: "NG",
    countryCode: "NG",
  },
  {
    country: "Niue",
    continent: "OC",
    dxccNumber: 188,
    flagCode: "NU",
    countryCode: "NU",
  },
  {
    country: "Norfolk Island",
    continent: "OC",
    dxccNumber: 189,
    flagCode: "NF",
    countryCode: "NF",
  },
  {
    country: "North Macedonia",
    continent: "EU",
    dxccNumber: 502,
    flagCode: "MK",
    countryCode: "MK",
  },
  {
    country: "Northern Ireland",
    continent: "EU",
    dxccNumber: 265,
    flagCode: "GB",
    countryCode: "GB",
  },
  {
    country: "Norway",
    continent: "EU",
    dxccNumber: 266,
    flagCode: "NO",
    countryCode: "NO",
  },
  {
    country: "Ogasawara",
    continent: "AS",
    dxccNumber: 192,
    flagCode: "JP",
    countryCode: "JP",
  },
  {
    country: "Oman",
    continent: "AS",
    dxccNumber: 370,
    flagCode: "OM",
    countryCode: "OM",
  },
  {
    country: "Pakistan",
    continent: "AS",
    dxccNumber: 372,
    flagCode: "PK",
    countryCode: "PK",
  },
  {
    country: "Palau",
    continent: "OC",
    dxccNumber: 22,
    flagCode: "PW",
    countryCode: "PW",
  },
  {
    country: "Palestine",
    continent: "AS",
    dxccNumber: 510,
    flagCode: "PS",
    countryCode: "PS",
  },
  {
    country: "Palmyra & Jarvis Islands",
    continent: "OC",
    dxccNumber: 197,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Panama",
    continent: "NA",
    dxccNumber: 88,
    flagCode: "PA",
    countryCode: "PA",
  },
  {
    country: "Papua New Guinea",
    continent: "OC",
    dxccNumber: 163,
    flagCode: "PG",
    countryCode: "PG",
  },
  {
    country: "Paraguay",
    continent: "SA",
    dxccNumber: 132,
    flagCode: "PY",
    countryCode: "PY",
  },
  {
    country: "Peru",
    continent: "SA",
    dxccNumber: 136,
    flagCode: "PE",
    countryCode: "PE",
  },
  {
    country: "Philippines",
    continent: "OC",
    dxccNumber: 375,
    flagCode: "PH",
    countryCode: "PH",
  },
  {
    country: "Pitcairn Island",
    continent: "OC",
    dxccNumber: 172,
    flagCode: "PN",
    countryCode: "PN",
  },
  {
    country: "Poland",
    continent: "EU",
    dxccNumber: 269,
    flagCode: "PL",
    countryCode: "PL",
  },
  {
    country: "Portugal",
    continent: "EU",
    dxccNumber: 272,
    flagCode: "PT",
    countryCode: "PT",
  },
  {
    country: "Pr. Edward & Marion Is.",
    continent: "AF",
    dxccNumber: 201,
    flagCode: "ZA",
    countryCode: "ZA",
  },
  {
    country: "Pratas Island",
    continent: "AS",
    dxccNumber: 505,
    flagCode: "TW",
    countryCode: "TW",
  },
  {
    country: "Puerto Rico",
    continent: "NA",
    dxccNumber: 202,
    flagCode: "PR",
    countryCode: "PR",
  },
  {
    country: "Peter 1 Island",
    continent: "AN",
    dxccNumber: 199,
    flagCode: "NO",
    countryCode: "NO",
  },
  {
    country: "Qatar",
    continent: "AS",
    dxccNumber: 376,
    flagCode: "QA",
    countryCode: "QA",
  },
  {
    country: "Republic of Korea",
    continent: "AS",
    dxccNumber: 137,
    flagCode: "KR",
    countryCode: "KR",
  },
  {
    country: "Republic of Kosovo",
    continent: "EU",
    dxccNumber: 522,
    flagCode: "ZK",
    countryCode: "",
  },
  {
    country: "Republic of South Sudan",
    continent: "AF",
    dxccNumber: 521,
    flagCode: "SS",
    countryCode: "SS",
  },
  {
    country: "Republic of the Congo",
    continent: "AF",
    dxccNumber: 412,
    flagCode: "CG",
    countryCode: "CG",
  },
  {
    country: "Reunion Island",
    continent: "AF",
    dxccNumber: 453,
    flagCode: "FR",
    countryCode: "RE",
  },
  {
    country: "Revillagigedo",
    continent: "NA",
    dxccNumber: 204,
    flagCode: "MX",
    countryCode: "MX",
  },
  {
    country: "Rodriguez Island",
    continent: "AF",
    dxccNumber: 207,
    flagCode: "MU",
    countryCode: "MU",
  },
  {
    country: "Romania",
    continent: "EU",
    dxccNumber: 275,
    flagCode: "RO",
    countryCode: "RO",
  },
  {
    country: "Rwanda",
    continent: "AF",
    dxccNumber: 454,
    flagCode: "RW",
    countryCode: "RW",
  },
  {
    country: "Rotuma",
    continent: "OC",
    dxccNumber: 460,
    flagCode: "FJ",
    countryCode: "FJ",
  },
  {
    country: "Saba & St. Eustatius",
    continent: "NA",
    dxccNumber: 519,
    flagCode: "AN",
    countryCode: "AN",
  },
  {
    country: "Sable Island",
    continent: "NA",
    dxccNumber: 211,
    flagCode: "CA",
    countryCode: "CA",
  },
  {
    country: "Samoa",
    continent: "OC",
    dxccNumber: 190,
    flagCode: "WS",
    countryCode: "WS",
  },
  {
    country: "San Andres & Providencia",
    continent: "NA",
    dxccNumber: 216,
    flagCode: "CO",
    countryCode: "NI",
  },
  {
    country: "San Felix & San Ambrosio",
    continent: "SA",
    dxccNumber: 217,
    flagCode: "CL",
    countryCode: "CL",
  },
  {
    country: "San Marino",
    continent: "EU",
    dxccNumber: 278,
    flagCode: "SM",
    countryCode: "SM",
  },
  {
    country: "Sao Tome & Principe",
    continent: "AF",
    dxccNumber: 219,
    flagCode: "ST",
    countryCode: "ST",
  },
  {
    country: "Sardinia",
    continent: "EU",
    dxccNumber: 225,
    flagCode: "IT",
    countryCode: "IT",
  },
  {
    country: "Saudi Arabia",
    continent: "AS",
    dxccNumber: 378,
    flagCode: "SA",
    countryCode: "SA",
  },
  {
    country: "Scotland",
    continent: "EU",
    dxccNumber: 279,
    flagCode: "ZC",
    countryCode: "GB",
  },
  {
    country: "Senegal",
    continent: "AF",
    dxccNumber: 456,
    flagCode: "SN",
    countryCode: "SN",
  },
  {
    country: "Serbia",
    continent: "EU",
    dxccNumber: 296,
    flagCode: "RS",
    countryCode: "RS",
  },
  {
    country: "Seychelles",
    continent: "AF",
    dxccNumber: 379,
    flagCode: "SC",
    countryCode: "SC",
  },
  {
    country: "Sierra Leone",
    continent: "AF",
    dxccNumber: 458,
    flagCode: "SL",
    countryCode: "SL",
  },
  {
    country: "Singapore",
    continent: "AS",
    dxccNumber: 381,
    flagCode: "SG",
    countryCode: "SG",
  },
  {
    country: "Sint Maarten",
    continent: "NA",
    dxccNumber: 518,
    flagCode: "SX",
    countryCode: "",
  },
  {
    country: "Slovak Republic",
    continent: "EU",
    dxccNumber: 504,
    flagCode: "SK",
    countryCode: "SK",
  },
  {
    country: "Slovenia",
    continent: "EU",
    dxccNumber: 499,
    flagCode: "SI",
    countryCode: "SI",
  },
  {
    country: "Solomon Islands",
    continent: "OC",
    dxccNumber: 185,
    flagCode: "SB",
    countryCode: "SB",
  },
  {
    country: "Somalia",
    continent: "AF",
    dxccNumber: 232,
    flagCode: "SO",
    countryCode: "SO",
  },
  {
    country: "South Africa",
    continent: "AF",
    dxccNumber: 462,
    flagCode: "ZA",
    countryCode: "ZA",
  },
  {
    country: "South Cook Islands",
    continent: "OC",
    dxccNumber: 234,
    flagCode: "GS",
    countryCode: "GS",
  },
  {
    country: "South Georgia Island",
    continent: "SA",
    dxccNumber: 235,
    flagCode: "GS",
    countryCode: "GS",
  },
  {
    country: "South Sandwich Islands",
    continent: "SA",
    dxccNumber: 240,
    flagCode: "GS",
    countryCode: "GS",
  },
  {
    country: "South Shetland Islands",
    continent: "SA",
    dxccNumber: 241,
    flagCode: "GB",
    countryCode: "GB",
  },
  {
    country: "Sov Mil Order of Malta",
    continent: "EU",
    dxccNumber: 246,
    flagCode: "X1",
    countryCode: "MT",
  },
  {
    country: "Spain",
    continent: "EU",
    dxccNumber: 281,
    flagCode: "ES",
    countryCode: "ES",
  },
  {
    country: "Spratly Islands",
    continent: "AS",
    dxccNumber: 247,
    flagCode: "PH",
    countryCode: "PH",
  },
  {
    country: "Sri Lanka",
    continent: "AS",
    dxccNumber: 315,
    flagCode: "LK",
    countryCode: "LK",
  },
  {
    country: "St. Barthelemy",
    continent: "NA",
    dxccNumber: 516,
    flagCode: "FR",
    countryCode: "",
  },
  {
    country: "St. Helena",
    continent: "AF",
    dxccNumber: 250,
    flagCode: "SH",
    countryCode: "SH",
  },
  {
    country: "St. Kitts & Nevis",
    continent: "NA",
    dxccNumber: 249,
    flagCode: "KN",
    countryCode: "KN",
  },
  {
    country: "St. Lucia",
    continent: "NA",
    dxccNumber: 97,
    flagCode: "LC",
    countryCode: "LC",
  },
  {
    country: "St. Martin",
    continent: "NA",
    dxccNumber: 213,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "St. Paul Island",
    continent: "NA",
    dxccNumber: 252,
    flagCode: "CA",
    countryCode: "CA",
  },
  {
    country: "St. Peter & St. Paul",
    continent: "SA",
    dxccNumber: 253,
    flagCode: "BR",
    countryCode: "BR",
  },
  {
    country: "St. Pierre & Miquelon",
    continent: "NA",
    dxccNumber: 277,
    flagCode: "PM",
    countryCode: "CA",
  },
  {
    country: "St. Vincent",
    continent: "NA",
    dxccNumber: 98,
    flagCode: "VC",
    countryCode: "VC",
  },
  {
    country: "Sudan",
    continent: "AF",
    dxccNumber: 466,
    flagCode: "SD",
    countryCode: "SD",
  },
  {
    country: "Suriname",
    continent: "SA",
    dxccNumber: 140,
    flagCode: "SR",
    countryCode: "SR",
  },
  {
    country: "Svalbard",
    continent: "EU",
    dxccNumber: 259,
    flagCode: "NO",
    countryCode: "SJ",
  },
  {
    country: "Sweden",
    continent: "EU",
    dxccNumber: 284,
    flagCode: "SE",
    countryCode: "SE",
  },
  {
    country: "Switzerland",
    continent: "EU",
    dxccNumber: 287,
    flagCode: "CH",
    countryCode: "CH",
  },
  {
    country: "Syria",
    continent: "AS",
    dxccNumber: 384,
    flagCode: "SY",
    countryCode: "SY",
  },
  {
    country: "South Orkney Islands",
    continent: "SA",
    dxccNumber: 238,
    flagCode: "AR",
    countryCode: "AR",
  },
  {
    country: "Scarborough Reef",
    continent: "AS",
    dxccNumber: 506,
    flagCode: "PH",
    countryCode: "PH",
  },
  {
    country: "Swains Island",
    continent: "OC",
    dxccNumber: 515,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Taiwan",
    continent: "AS",
    dxccNumber: 386,
    flagCode: "TW",
    countryCode: "TW",
  },
  {
    country: "Tajikistan",
    continent: "AS",
    dxccNumber: 262,
    flagCode: "TJ",
    countryCode: "TJ",
  },
  {
    country: "Tanzania",
    continent: "AF",
    dxccNumber: 470,
    flagCode: "TZ",
    countryCode: "TZ",
  },
  {
    country: "Temotu Province",
    continent: "OC",
    dxccNumber: 507,
    flagCode: "SB",
    countryCode: "SB",
  },
  {
    country: "Thailand",
    continent: "AS",
    dxccNumber: 387,
    flagCode: "TH",
    countryCode: "TH",
  },
  {
    country: "The Gambia",
    continent: "AF",
    dxccNumber: 422,
    flagCode: "GM",
    countryCode: "GM",
  },
  {
    country: "Timor - Leste",
    continent: "OC",
    dxccNumber: 511,
    flagCode: "TL",
    countryCode: "TL",
  },
  {
    country: "Togo",
    continent: "AF",
    dxccNumber: 483,
    flagCode: "TG",
    countryCode: "TG",
  },
  {
    country: "Tokelau Islands",
    continent: "OC",
    dxccNumber: 270,
    flagCode: "TK",
    countryCode: "TK",
  },
  {
    country: "Tonga",
    continent: "OC",
    dxccNumber: 160,
    flagCode: "TO",
    countryCode: "TO",
  },
  {
    country: "Trindade & Martim Vaz",
    continent: "SA",
    dxccNumber: 273,
    flagCode: "BR",
    countryCode: "BR",
  },
  {
    country: "Trinidad & Tobago",
    continent: "SA",
    dxccNumber: 90,
    flagCode: "TT",
    countryCode: "TT",
  },
  {
    country: "Tristan da Cunha & Gough Islands",
    continent: "AF",
    dxccNumber: 274,
    flagCode: "GB",
    countryCode: "GB",
  },
  {
    country: "Tromelin Island",
    continent: "AF",
    dxccNumber: 276,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Tunisia",
    continent: "AF",
    dxccNumber: 474,
    flagCode: "TN",
    countryCode: "TN",
  },
  {
    country: "Turkmenistan",
    continent: "AS",
    dxccNumber: 280,
    flagCode: "TM",
    countryCode: "TM",
  },
  {
    country: "Turks & Caicos Islands",
    continent: "NA",
    dxccNumber: 89,
    flagCode: "TC",
    countryCode: "TC",
  },
  {
    country: "Tuvalu",
    continent: "OC",
    dxccNumber: 282,
    flagCode: "TV",
    countryCode: "TV",
  },
  {
    country: "Uganda",
    continent: "AF",
    dxccNumber: 286,
    flagCode: "UG",
    countryCode: "UG",
  },
  {
    country: "UK Base Areas on Cyprus",
    continent: "AS",
    dxccNumber: 283,
    flagCode: "CY",
    countryCode: "CY",
  },
  {
    country: "Ukraine",
    continent: "EU",
    dxccNumber: 288,
    flagCode: "UA",
    countryCode: "UA",
  },
  {
    country: "United Arab Emirates",
    continent: "AS",
    dxccNumber: 391,
    flagCode: "AE",
    countryCode: "AE",
  },
  {
    country: "United States",
    continent: "NA",
    dxccNumber: 291,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Uruguay",
    continent: "SA",
    dxccNumber: 144,
    flagCode: "UY",
    countryCode: "UY",
  },
  {
    country: "US Virgin Islands",
    continent: "NA",
    dxccNumber: 285,
    flagCode: "VI",
    countryCode: "VI",
  },
  {
    country: "Uzbekistan",
    continent: "AS",
    dxccNumber: 292,
    flagCode: "UZ",
    countryCode: "UZ",
  },
  {
    country: "United Nations HQ",
    continent: "NA",
    dxccNumber: 289,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Vanuatu",
    continent: "OC",
    dxccNumber: 158,
    flagCode: "VU",
    countryCode: "VU",
  },
  {
    country: "Vatican City",
    continent: "EU",
    dxccNumber: 295,
    flagCode: "VA",
    countryCode: "VA",
  },
  {
    country: "Venezuela",
    continent: "SA",
    dxccNumber: 148,
    flagCode: "VE",
    countryCode: "VE",
  },
  {
    country: "Vietnam",
    continent: "AS",
    dxccNumber: 293,
    flagCode: "VN",
    countryCode: "VN",
  },
  {
    country: "Wake Island",
    continent: "OC",
    dxccNumber: 297,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Wales",
    continent: "EU",
    dxccNumber: 294,
    flagCode: "ZD",
    countryCode: "GB",
  },
  {
    country: "Wallis & Futuna Islands",
    continent: "OC",
    dxccNumber: 298,
    flagCode: "WF",
    countryCode: "WF",
  },
  {
    country: "West Malaysia",
    continent: "AS",
    dxccNumber: 299,
    flagCode: "MY",
    countryCode: "MY",
  },
  {
    country: "Western Kiribati",
    continent: "OC",
    dxccNumber: 301,
    flagCode: "KI",
    countryCode: "KI",
  },
  {
    country: "Western Sahara",
    continent: "AF",
    dxccNumber: 302,
    flagCode: "EH",
    countryCode: "EH",
  },
  {
    country: "Willis Island",
    continent: "OC",
    dxccNumber: 303,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Yemen",
    continent: "AS",
    dxccNumber: 492,
    flagCode: "YE",
    countryCode: "YE",
  },
  {
    country: "Zambia",
    continent: "AF",
    dxccNumber: 482,
    flagCode: "ZM",
    countryCode: "ZM",
  },
  {
    country: "Zimbabwe",
    continent: "AF",
    dxccNumber: 452,
    flagCode: "ZW",
    countryCode: "ZW",
  },
  {
    country: "Bouvet Island",
    continent: "AF",
    dxccNumber: 24,
    flagCode: "NO",
    countryCode: "NO",
  },
  {
    country: "Clipperton Island",
    continent: "NA",
    dxccNumber: 36,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Heard Island",
    continent: "AF",
    dxccNumber: 111,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Itu Hq",
    continent: "EU",
    dxccNumber: 117,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Macquarie Island",
    continent: "OC",
    dxccNumber: 153,
    flagCode: "AU",
    countryCode: "AU",
  },
  {
    country: "Minami Torishima",
    continent: "OC",
    dxccNumber: 177,
    flagCode: "JP",
    countryCode: "JP",
  },
  {
    country: "Mount Athos",
    continent: "EU",
    dxccNumber: 180,
    flagCode: "GR",
    countryCode: "GR",
  },
  {
    country: "North Cook Islands",
    continent: "OC",
    dxccNumber: 191,
    flagCode: "NZ",
    countryCode: "NZ",
  },
  {
    country: "Peter 1 Island",
    continent: "AN",
    dxccNumber: 199,
    flagCode: "NO",
    countryCode: "NO",
  },
  {
    country: "South Orkney Islands",
    continent: "SA",
    dxccNumber: 238,
    flagCode: "AR",
    countryCode: "AR",
  },
  {
    country: "United Nations Hq",
    continent: "NA",
    dxccNumber: 289,
    flagCode: "US",
    countryCode: "US",
  },
  {
    country: "Rotuma",
    continent: "OC",
    dxccNumber: 460,
    flagCode: "FJ",
    countryCode: "FJ",
  },
  {
    country: "Conway Reef",
    continent: "OC",
    dxccNumber: 489,
    flagCode: "FJ",
    countryCode: "FJ",
  },
  {
    country: "Scarborough Reef",
    continent: "AS",
    dxccNumber: 506,
    flagCode: "PH",
    countryCode: "PH",
  },
  {
    country: "Austral Islands",
    continent: "OC",
    dxccNumber: 508,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Marquesas Islands",
    continent: "OC",
    dxccNumber: 509,
    flagCode: "FR",
    countryCode: "FR",
  },
  {
    country: "Chesterfield Islands",
    continent: "OC",
    dxccNumber: 512,
    flagCode: "NC",
    countryCode: "NC",
  },
  {
    country: "Ducie Island",
    continent: "OC",
    dxccNumber: 513,
    flagCode: "ZE",
    countryCode: "GB",
  },
  {
    country: "Swains Island",
    continent: "OC",
    dxccNumber: 515,
    flagCode: "US",
    countryCode: "US",
  },
];
