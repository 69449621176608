/* eslint-disable array-callback-return */
import { all, call, fork, put, select, take } from "redux-saga/effects";
import {
  NewLogBook,
  RegisterActionParams,
  UserContactPaginationProp,
  UserProfileContactsWithPagination,
  RecentContactData,
  EditLogBook,
  LogbookContactPaginationProp,
  AddContactLogPopupSummary,
} from "types/State";

import { authErrorHandler } from "helpers/ErrorHandler/auth";
import {
  ADD_NEW_LOGBOOK,
  DELETE_LOGBOOK_CONTACT_DATA,
  EDIT_LOGBOOK_CONTACT_DATA,
  GET_All_CONTACT_DATA,
  GET_FILTERED_LOGBOOK_DATA,
  GET_LOGBOOK_CONTACT_DATA,
  SAVE_ALL_CONTACTS_DATA,
  SAVE_CONTACTS_DATA,
  SAVE_LOGBOOKS_DATA,
  SAVE_LOGBOOK_CONTACT_DATA,
  SELECTED_LOGBOOK_DATA,
  FETCH_CALLSIGN_DATA,
  SET_CALLSIGN_DATA,
  GET_LOGBOOK_DATA,
  EDIT_CONTACT_MODAL_DATA,
  GET_ALL_USER_CONTACTS,
  SAVE_ALL_USER_CONTACTS_DATA,
  SAVE_WORKED_USER_COUNTRIES,
  SAVE_WORKED_USER_GRID_SQUARE,
  GET_PAGINATED_CONTACTS,
  GET_ALL_USER_CONTACTS_FOR_PAGINATION,
  SAVE_ALL_PAGINATED_USER_CONTACTS_DATA,
  GET_PAGINATED_USER_CONTACTS_FOR_PAGINATION,
  SAVE_ALL_CONTACTS_PAGINATED_DATA,
  GET_PAGINATED_CONTACTS_WITH_PAGINATION,
  DELETE_USER_LOGBOOK,
  GET_LOGBOOK_CONTACT_FOR_ADIF_EXPORT,
  SAVE_LOGBOOK_COORDINATES,
  HANDLE_ADIF_FILE,
  GET_PAGINATED_LOGBOOK_CONTACTS,
  SAVE_ALL_PAGINATED_LOGBOOK_CONTACTS_DATA,
  FETCH_CONTACT_DETAIL,
  SET_CONTACT_DETAIL,
  SEARCH_LOGBOOK,
  SET_SEARCH_RESULTS,
  FETCH_DAILY_LEADERS,
  SET_DAILY_LEADERS,
  FETCH_COUNTRY_DATA_GRID,
  EDIT_LOGBOOK,
  FETCH_SAVED_LOCATION,
  SET_SAVED_LOCATION,
  ADD_SAVED_LOCATION,
  DELETE_SAVED_LOCATION,
  FETCH_ANTENNAS,
  FETCH_RADIOS,
  ADD_ANTENNAS,
  ADD_RADIOS,
  DELETE_ANTENNAS,
  DELETE_RADIOS,
  SET_ANTENNAS,
  SET_RADIOS,
  ADD_RADIO_MODAL,
  ADD_ANTENNA_MODAL,
  SAVE_CONTACT_POPUP_SUMMARY,
  GET_CONTACT_POPUP_SUMMARY,
  FETCH_STATIONS,
  SET_STATIONS,
  DELETE_STATIONS,
  ADD_STATIONS_MODAL,
  ADD_STATIONS,
} from "store/Actions/logBook.actions";
import LogBookService from "services/logBook.service";
import {
  Antenna,
  CallSignLookUpData,
  LeaderData,
  LogBookContactModel,
  LogBookModel,
  Radios,
  SavedLocation,
  SpotModel,
  UserProfile,
  Stations,
} from "types/Models";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { globalNavigate } from "routes/GlobalRoutes";
import {
  ADI_UPLOAD_LOADING,
  DAILY_LEADER_LOADING,
  LOADING_CALLSIGN_DATA,
  LOADING_LOGBOOK_CONTACTS_FOR_ADIF_EXPORT_STATUS,
  LOADING_LOGBOOK_SAVEING_STATUS,
  LOADING_LOGBOOK_STATUS,
  LOADING_STATUS_FOR_GETTING_LOCATION,
  LOGBOOK_SETTING_LOADING,
} from "store/Actions/loading.action";
import {
  getAllContactsListPaginatedSelector,
  getAllPaginatedLogBookContactsDataSelector,
  getAllPaginatedUserContactsDataSelector,
  getSelectedLogBookSelector,
} from "store/Reducers/logBook.reducer";
import {
  calculateMaidenhead,
  findBandAndModeForFrequency,
  getDxDataFromCountry,
  getISOWeek,
  getStateShortCode,
  getUsStateFullName,
  sanitizeData,
} from "helpers/Utils";
import moment from "moment";
import UploadService from "services/upload.service";
import {
  auth,
  deleteLogbookRecords,
  getCountryAndStateFromGrid,
  getCountryFromCallSign,
  exportUserContacts,
} from "firebase-config";
import { serverTimestamp } from "firebase/firestore";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import Images from "assets/Images";
import { getSpotSelector } from "store/Reducers/spotting.reducer";
import SpottingService from "services/spotting.service";
import { usStatesCoordinates } from "constants/Config";
import ProfileService from "services/profile.service";
import { STORE_USER_PROFILE } from "constants/ActionTypes";
const states = require("us-state-converter");
//Workers
export function* workerGetLogBook(payload: RegisterActionParams) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  const userProfile: UserProfile = yield select(getUserProfileSelector);
  const uid = userProfile?.uid || "";
  try {
    const userLogBook: LogBookModel[] | string = yield call(() =>
      LogBookService.getUserLogBooks(uid)
    );
    if (userLogBook) {
      yield put({
        type: SAVE_LOGBOOKS_DATA,
        payload: userLogBook,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerSaveUserLogBook(payload: NewLogBook) {
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: true,
  });
  try {
    const res: null | LogBookModel = yield call(() =>
      LogBookService.createUserLogBook(payload)
    );
    if (res) {
      yield put({
        type: SELECTED_LOGBOOK_DATA,
        payload: res,
      });
      globalNavigate(
        `/log-contacts/add-contact?logbook-id=${res?.id}&logbook-name=${res?.name}`
      );
    } else {
      authErrorHandler("Something went wrong!");
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: false,
  });
}

export function* workerGetUserLogBookContactData(payload: any) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  const userProfile: UserProfile = yield select(getUserProfileSelector);
  const uid = userProfile?.uid || "";
  try {
    const userLogBookContacts: LogBookContactModel[] = yield call(() =>
      LogBookService.getUserLogBookContacts(uid, payload)
    );

    if (userLogBookContacts) {
      yield put({
        type: SAVE_CONTACTS_DATA,
        payload: userLogBookContacts,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerGetUserLogBookContactForAdifExport(payload: {
  logbookId: string;
  options?: { parkReference?: string };
}) {
  yield put({
    type: LOADING_LOGBOOK_CONTACTS_FOR_ADIF_EXPORT_STATUS,
    payload: true,
  });
  const userProfile: UserProfile = yield select(getUserProfileSelector);
  const uid = userProfile?.uid || "";
  try {
    const { logbookId, options } = payload;
    const userADIFile: { data: any } = yield call(() =>
      exportUserContacts({
        logBookId: logbookId,
        userId: uid,
        callSign: userProfile?.callSign,
        ...(options?.parkReference
          ? { parkReference: options.parkReference }
          : {}),
        formate: "adif",
      })
    );

    if (userADIFile && userADIFile.data) {
      const anchor = document.createElement("a");
      anchor.href = userADIFile.data;
      anchor.download =
        `${userProfile?.callSign}_WorldRadioLeague_${moment().format(
          "YYYY-MM-DD"
        )}.adi` || "download";

      // Append it to the body, click it and then remove it
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      showToast({
        message: "ADI file download started",
        type: ToastTypes.SUCCESS,
      });
    } else {
      showToast({
        message: "Sorry, something went wrong. Please try again later.",
        type: ToastTypes.ERROR,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_CONTACTS_FOR_ADIF_EXPORT_STATUS,
    payload: false,
  });
}

export function* workerGetUserLogBookData(payload: string) {
  try {
    const userLogBook: LogBookModel = yield call(() =>
      LogBookService.getUserLogBook(payload)
    );
    if (userLogBook) {
      yield put({
        type: SELECTED_LOGBOOK_DATA,
        payload: userLogBook,
      });
    }
  } catch (e: any) {}
}

export function* workerGetAllUserContacts(payload: any) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  try {
    const allUserContacts: LogBookContactModel[] = yield call(() =>
      LogBookService.getAllUserContactsById(payload)
    );
    let workedUserCountries: string[] = [];
    let workedUserGridSquare: string[] = [];
    allUserContacts.map((contact: LogBookContactModel) => {
      if (
        contact?.country &&
        contact?.country.length &&
        !workedUserCountries.includes(contact?.country)
      ) {
        workedUserCountries.push(contact?.country);
      }
      if (
        contact?.grid &&
        contact?.grid.length &&
        !workedUserGridSquare.includes(contact?.grid)
      ) {
        workedUserGridSquare.push(contact?.grid);
      }
    });

    yield put({
      type: SAVE_WORKED_USER_COUNTRIES,
      payload: workedUserCountries,
    });

    yield put({
      type: SAVE_WORKED_USER_GRID_SQUARE,
      payload: workedUserGridSquare,
    });
    yield put({
      type: SAVE_ALL_USER_CONTACTS_DATA,
      payload: allUserContacts,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerGetAllUserContactsWithPagination(uid: string) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  try {
    const allUserContacts: UserProfileContactsWithPagination = yield call(() =>
      LogBookService.getAllUserContactsByIdForPagination(uid)
    );
    let workedUserCountries: string[] = [];
    let workedUserGridSquare: string[] = [];
    allUserContacts?.contacts.map((contact: LogBookContactModel) => {
      if (
        contact?.country &&
        contact?.country.length &&
        !workedUserCountries.includes(contact?.country)
      ) {
        workedUserCountries.push(contact?.country);
      }
      if (
        contact?.grid &&
        contact?.grid.length &&
        !workedUserGridSquare.includes(contact?.grid)
      ) {
        workedUserGridSquare.push(contact?.grid);
      }
    });

    yield put({
      type: SAVE_WORKED_USER_COUNTRIES,
      payload: workedUserCountries,
    });

    yield put({
      type: SAVE_WORKED_USER_GRID_SQUARE,
      payload: workedUserGridSquare,
    });

    yield put({
      type: SAVE_ALL_PAGINATED_USER_CONTACTS_DATA,
      payload: allUserContacts,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerGetAllUserContactsWithPaginate(
  conditions: UserContactPaginationProp
) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  try {
    const allUserContacts: UserProfileContactsWithPagination = yield call(() =>
      LogBookService.getPaginatedUserContactsById(conditions)
    );

    yield put({
      type: SAVE_ALL_PAGINATED_USER_CONTACTS_DATA,
      payload: allUserContacts,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerGetAllUserContactData() {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  const userProfile: UserProfile = yield select(getUserProfileSelector);
  const uid = userProfile?.uid || "";
  try {
    const userLogBookContacts: LogBookContactModel[] = yield call(() =>
      LogBookService.getAllUserContacts(uid)
    );
    if (userLogBookContacts) {
      yield put({
        type: SAVE_ALL_CONTACTS_DATA,
        payload: userLogBookContacts,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerGetPaginatedContacts(conditions: any) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  try {
    const logBookContacts: LogBookContactModel[] = yield call(() =>
      LogBookService.getGetPaginatedContacts(conditions)
    );

    // // if (logBookContacts) {
    yield put({
      type: SAVE_ALL_CONTACTS_PAGINATED_DATA,
      payload: logBookContacts,
    });
    // // }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerGetPaginatedContactsWithPagination(conditions: any) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  try {
    const prevMembersData: RecentContactData | null = yield select(
      getAllContactsListPaginatedSelector
    );
    let data = {
      doc: prevMembersData,
      page: conditions.page,
      move: conditions.move,
      conditions: conditions.conditions,
    };
    const logBookContacts: RecentContactData | null = yield call(() =>
      LogBookService.getGetPaginatedContactsWithPagination(data)
    );

    yield put({
      type: SAVE_ALL_CONTACTS_PAGINATED_DATA,
      payload: logBookContacts,
    });
  } catch (e: any) {}
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerSaveUserLogBookContactData({
  data,
  resetHandler,
}: {
  data: LogBookContactModel;
  resetHandler: () => void;
}) {
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: true,
  });
  try {
    const res: LogBookModel[] = yield call(() =>
      LogBookService.createUserLogBookContactData(data)
    );
    if (res) {
      // yield put({
      //   type: GET_LOGBOOK_CONTACT_DATA,
      //   payload: data?.logBookId,
      // });
      yield put({
        type: GET_PAGINATED_LOGBOOK_CONTACTS,
        payload: {
          logbookId: data?.logBookId,
        },
      });

      yield put({
        type: GET_LOGBOOK_DATA,
        payload: data?.logBookId,
      });
      if (resetHandler) {
        resetHandler();
      }
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: false,
  });
}

export function* workerEditUserLogBookContactData({
  data,
  prevValues,
}: {
  data: LogBookContactModel;
  prevValues: LogBookContactModel;
}) {
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: true,
  });
  try {
    const selectedLogBook: LogBookModel = yield select(
      getSelectedLogBookSelector
    );
    // getAllPaginatedLogBookContactsDataSelector;
    const paginatedData: UserProfileContactsWithPagination = yield select(
      getAllPaginatedLogBookContactsDataSelector
    );
    const userProfileContactPaginatedData: UserProfileContactsWithPagination =
      yield select(getAllPaginatedUserContactsDataSelector);
    const res: LogBookModel[] = yield call(() =>
      LogBookService.editUserLogBookContactData(
        sanitizeData(data),
        prevValues,
        selectedLogBook
      )
    );
    if (res) {
      // yield put({
      //   type: GET_LOGBOOK_CONTACT_DATA,
      //   payload: prevValues?.logBookId,
      // });
      yield put({
        type: GET_LOGBOOK_DATA,
        payload: prevValues?.logBookId,
      });
      yield put({
        type: GET_PAGINATED_LOGBOOK_CONTACTS,
        payload: {
          logbookId: prevValues?.logBookId,
          ...paginatedData,
          move: 0,
        },
      });
      yield put({
        type: GET_PAGINATED_USER_CONTACTS_FOR_PAGINATION,
        payload: {
          ...userProfileContactPaginatedData,
          move: 0,
        },
      });
      yield put({
        type: FETCH_CONTACT_DETAIL,
        payload: prevValues?.id,
      });
      yield put({
        type: EDIT_CONTACT_MODAL_DATA,
        payload: null,
      });
    }
  } catch (e: any) {
    console.log("EDIT CONTACT ERROR e : ", e);
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: false,
  });
}

export function* workerDeleteUserLogBookContactData(payload: any) {
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: true,
  });
  try {
    const { contactId, logBookId, contact } = payload;
    const userProfile: UserProfile = yield select(getUserProfileSelector);

    const res: LogBookModel[] = yield call(() =>
      LogBookService.deleteUserLogBookContactData(
        contactId,
        logBookId,
        userProfile,
        contact
      )
    );
    const selectedLogBook: LogBookModel = yield select(
      getSelectedLogBookSelector
    );
    const paginatedData: UserProfileContactsWithPagination = yield select(
      getAllPaginatedLogBookContactsDataSelector
    );

    const userProfileContactPaginatedData: UserProfileContactsWithPagination =
      yield select(getAllPaginatedUserContactsDataSelector);
    if (res) {
      // yield put({
      //   type: GET_LOGBOOK_CONTACT_DATA,
      //   payload: selectedLogBook?.id,
      // });
      yield put({
        type: GET_PAGINATED_LOGBOOK_CONTACTS,
        payload: {
          logbookId: selectedLogBook?.id,
          ...paginatedData,
          move: 0,
        },
      });
      yield put({
        type: GET_PAGINATED_USER_CONTACTS_FOR_PAGINATION,
        payload: {
          ...userProfileContactPaginatedData,
          move: 0,
        },
      });
      yield put({
        type: GET_LOGBOOK_DATA,
        payload: selectedLogBook?.id,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: false,
  });
}

export function* workerDeleteUserLogBookData(logBookId: any) {
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: true,
  });
  try {
    yield call(() => LogBookService.deleteUserLogBookData(logBookId));
    yield put({
      type: GET_FILTERED_LOGBOOK_DATA,
    });

    yield put({
      type: SELECTED_LOGBOOK_DATA,
      payload: null,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_SAVEING_STATUS,
    payload: false,
  });
}

export function* workerFetchCallSignData(payload: any) {
  yield put({
    type: LOADING_CALLSIGN_DATA,
    payload: true,
  });
  try {
    const [callSignRes, countryResponse]: any[] = yield all([
      call(LogBookService.fetchCallSignData, payload.callsign),
      call(getCountryFromCallSign, payload.callsign),
    ]);
    if (callSignRes) {
      if (callSignRes.hamdb.messages) {
        if (callSignRes.hamdb.messages.status === "NOT_FOUND") {
          if (countryResponse && countryResponse.data) {
            if (countryResponse.data.Country) {
              const setValues = payload?.setValues;
              setValues((prev: any) => {
                const { theirCoordinates, ...rest } = prev;
                const dxccData = getDxDataFromCountry(
                  countryResponse.data.Country
                );

                let data = {
                  ...rest,
                  country: countryResponse.data.Country,
                  grid:
                    calculateMaidenhead(
                      countryResponse.data.Latitude,
                      countryResponse.data.Longitude
                    ) || "",
                  theirName: "",
                  qth: "",
                  state: "",
                  dxccNumber: dxccData?.dxccNumber || "",
                  continent: dxccData?.continent || "",
                  flagCode: dxccData?.flagCode || "",
                  countryCode: dxccData?.countryCode || "",
                };
                return data;
              });
            }
          } else {
            const setValues = payload?.setValues;
            setValues((prev: any) => {
              const { theirCoordinates, ...rest } = prev;
              let data = {
                ...rest,
                country: "",
                grid: "",
                theirName: "",
                qth: "",
                state: "",
              };
              return data;
            });
          }

          yield put({
            type: SET_CALLSIGN_DATA,
            payload: "NOT_FOUND",
          });
        } else {
          const setValues = payload?.setValues;
          setValues((prev: any) => {
            let data = {
              ...prev,
            };
            if (
              callSignRes.hamdb?.callsign?.country &&
              callSignRes.hamdb?.callsign?.country !== "NOT_FOUND"
            ) {
              const dxccData = getDxDataFromCountry(
                countryResponse?.data?.Country ||
                  callSignRes.hamdb?.callsign?.country
              );
              data.country =
                countryResponse?.data?.Country ||
                callSignRes.hamdb?.callsign?.country;
              data.dxccNumber = dxccData?.dxccNumber || "";
              data.continent = dxccData?.continent || "";
              data.flagCode = dxccData?.flagCode || "";
              data.countryCode = dxccData?.countryCode || "";
            }
            if (
              callSignRes.hamdb?.callsign?.state &&
              callSignRes.hamdb?.callsign?.state !== "NOT_FOUND"
            ) {
              const state = callSignRes.hamdb?.callsign?.state;
              const wiscObject = states(state.toUpperCase());
              data.state = wiscObject ? wiscObject.name : state || "";
            }
            if (
              callSignRes.hamdb?.callsign?.grid &&
              callSignRes.hamdb?.callsign?.grid !== "NOT_FOUND"
            ) {
              data.grid = callSignRes.hamdb?.callsign?.grid;
            } else {
              data.grid = "";
            }
            if (
              callSignRes.hamdb?.callsign?.lon &&
              callSignRes.hamdb?.callsign?.lon !== "NOT_FOUND" &&
              callSignRes.hamdb?.callsign?.lat &&
              callSignRes.hamdb?.callsign?.lat !== "NOT_FOUND"
            ) {
              try {
                data.theirCoordinates = {
                  latitude: parseFloat(callSignRes.hamdb?.callsign?.lat),
                  longitude: parseFloat(callSignRes.hamdb?.callsign?.lon),
                };
              } catch (e) {
                data.theirCoordinates = {
                  latitude: callSignRes.hamdb?.callsign?.lat,
                  longitude: callSignRes.hamdb?.callsign?.lon,
                };
              }
            }
            if (
              callSignRes.hamdb?.callsign?.name &&
              callSignRes.hamdb?.callsign?.name !== "NOT_FOUND" &&
              callSignRes.hamdb?.callsign?.fname &&
              callSignRes.hamdb?.callsign?.fname !== "NOT_FOUND"
            ) {
              data.theirName = `${callSignRes?.hamdb?.callsign?.fname} ${callSignRes?.hamdb?.callsign?.name}`;
            } else if ((callSignRes as any).hamdb?.callsign?.fullname) {
              data.theirName = (callSignRes as any).hamdb?.callsign?.fullname;
            }
            if (
              callSignRes?.hamdb?.callsign?.addr1 ||
              callSignRes?.hamdb?.callsign?.addr2
            ) {
              data.qth = `${callSignRes?.hamdb?.callsign?.addr2 || ""}, ${
                callSignRes?.hamdb?.callsign?.state || ""
              }`;
            }
            return data;
          });
          yield put({
            type: LOADING_CALLSIGN_DATA,
            payload: false,
          });
          if (countryResponse?.data?.Country) {
            callSignRes.hamdb.callsign.country = countryResponse?.data?.Country;
          }
          yield put({
            type: SET_CALLSIGN_DATA,
            payload: callSignRes,
          });
        }
      }
    } else {
      yield put({
        type: LOADING_CALLSIGN_DATA,
        payload: false,
      });
      yield put({
        type: SET_CALLSIGN_DATA,
        payload: "NOT_FOUND",
      });
    }

    // yield put({
    //   type: LOADING_CALLSIGN_DATA,
    //   payload: true,
    // });

    let userProfile: UserProfile = yield select(getUserProfileSelector);
    let spottingRes: SpotModel[] = [];
    try {
      spottingRes = yield call(() =>
        SpottingService.fetchSpot({
          userCoordinates: userProfile?.coordinates,
          filters: [],
          userCountry: userProfile.country || "",
          myCallSign: userProfile.callSign,
        })
      );
    } catch (e) {
      console.log("spottingRes ERROR e : ", e);
    }
    const isCallSignFoundInSpots = spottingRes?.find(
      (spot) =>
        spot?.theirCallSign === payload.callsign &&
        moment().diff(moment(spot?.lastSeen), "minutes") < 90
    );
    if (spottingRes?.length && isCallSignFoundInSpots) {
      //set form data based on isCallSignFoundInSpots
      const setValues = payload?.setValues;
      let stateName = "";
      if (isCallSignFoundInSpots?.theirCountry) {
        const lowerCaseCountry =
          isCallSignFoundInSpots?.theirCountry?.toLowerCase();
        stateName = getUsStateFullName(
          lowerCaseCountry,
          isCallSignFoundInSpots?.theirState || ""
        );
      }
      let userBand = "";
      let userMode = "";
      if (
        payload?.setUserBand &&
        payload?.setUserMode &&
        isCallSignFoundInSpots?.frequency &&
        setValues
      ) {
        try {
          const freqNumber = parseFloat(isCallSignFoundInSpots?.frequency);
          const bandMode = findBandAndModeForFrequency(freqNumber);
          if (bandMode) {
            userBand = bandMode.band;
            userMode = bandMode.mode;
            payload.setUserBand(bandMode.band);
            payload.setUserMode(bandMode.mode);
          } else {
            payload.setUserBand("");
            payload.setUserMode("");
          }
        } catch (e) {}
      }
      setValues((prev: any) => {
        let data = {
          ...prev,
        };
        data.country = isCallSignFoundInSpots?.theirCountry || "";
        data.grid = isCallSignFoundInSpots?.theirGrid || "";
        data.theirCoordinates = {
          latitude: isCallSignFoundInSpots?.theirCoordinates?.latitude || "",
          longitude: isCallSignFoundInSpots?.theirCoordinates?.longitude || "",
        };
        data.theirName = isCallSignFoundInSpots?.theirName || "";
        data.qth = isCallSignFoundInSpots?.parkNumber || "";
        data.state = stateName;
        data.theirState = stateName;
        data.theirPark = isCallSignFoundInSpots?.parkNumber || "";
        data.parkNumber = isCallSignFoundInSpots?.parkNumber || "";
        data.parkName = isCallSignFoundInSpots?.parkName || "";
        data.frequency = isCallSignFoundInSpots?.frequency || "";
        data.band = userBand;
        data.userMode = userMode;

        return data;
      });

      // set values as per callSignRes from below
      yield put({
        type: SET_CALLSIGN_DATA,
        payload: {
          hamdb: {
            callsign: {
              country: isCallSignFoundInSpots?.theirCountry || "",
              state: stateName,
              grid: isCallSignFoundInSpots?.theirGrid || "",
              lon: isCallSignFoundInSpots?.theirCoordinates?.longitude || "",
              lat: isCallSignFoundInSpots?.theirCoordinates?.latitude || "",
              name: isCallSignFoundInSpots?.theirName || "",
            },
          },
        },
      });
    }

    yield put({
      type: LOADING_CALLSIGN_DATA,
      payload: false,
    });
  } catch (e: any) {
    console.log("FETCH_CALLSIGN_DATA e : ", e);
  }
  yield put({
    type: LOADING_CALLSIGN_DATA,
    payload: false,
  });
}

export function* workerFetchCountryData(payload: any) {
  // yield put({
  //   type: LOADING_CALLSIGN_DATA,
  //   payload: true,
  // });
  try {
    const countryResponse: { data: any } = yield call(() =>
      getCountryAndStateFromGrid({ grid: payload.grid })
    );
    if (countryResponse && countryResponse.data) {
      const setValues = payload?.setValues;

      setValues((prev: any) => {
        let data = {
          ...prev,
        };
        data.country = countryResponse.data.country;
        data.state = countryResponse.data.state;
        return data;
      });
    }
  } catch (e: any) {}
  yield put({
    type: LOADING_CALLSIGN_DATA,
    payload: false,
  });
}

export function* workerEditUserLogBook(data: EditLogBook) {
  try {
    yield call(() =>
      LogBookService.editUserLogBookData(data?.newLogBookData, data?.logBookId)
    );
    yield put({
      type: GET_FILTERED_LOGBOOK_DATA,
    });
    yield put({
      type: LOADING_STATUS_FOR_GETTING_LOCATION,
      payload: false,
    });
    yield put({
      type: GET_LOGBOOK_DATA,
      payload: data?.logBookId,
    });
    if (data.noToast !== true) {
      showToast({
        message: "Update successfully.",
        type: ToastTypes.SUCCESS,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_STATUS_FOR_GETTING_LOCATION,
    payload: false,
  });
}

export function* workerHandleAdifFile(data: any) {
  try {
    yield put({
      type: ADI_UPLOAD_LOADING,
      payload: true,
    });

    const { file, logBookName, defaultLocation } = data;
    const fileName = `${new Date().getTime()}_${file.name}`;
    const fileLinks: { original: string; duplicate: string } | null =
      yield call(() => UploadService.handleAdifFile(file, fileName));

    if (fileLinks) {
      const uid: string | undefined = auth.currentUser?.uid;
      let logBookData: any = {
        name: logBookName,
        uid: uid,
        timestamp: serverTimestamp(),
        adiFile: fileLinks?.original,
        adiFileCopy: fileLinks?.duplicate,
        importStatus: "In Progress",
        fileName: fileName,
        adiImported: true,
        locked: false,
        importDate: serverTimestamp(),
      };
      if (defaultLocation) {
        logBookData.defaultLocation = defaultLocation;
      }
      const existingAdiLogbooks: LogBookModel[] = yield call(() =>
        LogBookService.getAdiLogbooks()
      );
      if (existingAdiLogbooks) {
        const existingAdiLogbookCount = existingAdiLogbooks.length;
        if (existingAdiLogbookCount === 0) {
          logBookData.firstImport = true;
        }
      }
      const res: LogBookModel = yield call(() =>
        LogBookService.createUserLogBook(logBookData)
      );
      yield put({
        type: GET_FILTERED_LOGBOOK_DATA,
      });
      console.log("SELECTED_LOGBOOK_DATA res : ", res);
      yield put({
        type: SELECTED_LOGBOOK_DATA,
        payload: res,
      });

      showToast({
        message:
          "ADI File uploaded successfully, we will notify you once we are done processing your contacts!",
        type: ToastTypes.SUCCESS,
      });
      globalNavigate("/log-contacts/import-summary");
    } else {
      showToast({
        message: "Something went wrong, Please try again later.",
        type: ToastTypes.ERROR,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: ADI_UPLOAD_LOADING,
    payload: false,
  });
}

export function* workerGetLogbookContactsPaginated(
  conditions: LogbookContactPaginationProp
) {
  if (conditions.loading === undefined) {
    yield put({
      type: LOADING_LOGBOOK_STATUS,
      payload: true,
    });
  }

  try {
    const userProfile: UserProfile = yield select(getUserProfileSelector);

    const allUserContacts: UserProfileContactsWithPagination = yield call(() =>
      LogBookService.getPaginatedLogbookContactsById(
        conditions,
        userProfile?.uid || ""
      )
    );
    yield put({
      type: SAVE_ALL_PAGINATED_LOGBOOK_CONTACTS_DATA,
      payload: allUserContacts,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerFetchContactDetail(payload: any) {
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: true,
  });
  try {
    const contactDetail: LogBookContactModel = yield call(() =>
      LogBookService.fetchContactDetail(payload)
    );
    yield put({
      type: SET_CONTACT_DETAIL,
      payload: contactDetail,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOADING_LOGBOOK_STATUS,
    payload: false,
  });
}

export function* workerSearchContact(payload: any) {
  // yield put({
  //   type: LOADING_LOGBOOK_STATUS,
  //   payload: true,
  // });
  try {
    const searchResults: LogBookContactModel = yield call(() =>
      LogBookService.searchLogbookContacts(payload)
    );
    yield put({
      type: SET_SEARCH_RESULTS,
      payload: searchResults,
    });
  } catch (e: any) {
    // authErrorHandler(e);
  }
  // yield put({
  //   type: LOADING_LOGBOOK_STATUS,
  //   payload: false,
  // });
}

export function* workerGetDailyLeaders() {
  yield put({
    type: DAILY_LEADER_LOADING,
    payload: true,
  });
  try {
    const dailyLeaders: { data: any } = yield call(() =>
      LogBookService.getHomeDailyLeaders()
    );

    const leadersData: LeaderData[] = [];
    if (dailyLeaders && dailyLeaders.data) {
      if (dailyLeaders.data.userId) {
        leadersData.push({
          title: "TOP",
          titleColor: "OPERATOR",
          image:
            dailyLeaders.data.userId.userData.profilePic ||
            Images.CongratulationBg,
          firstText: `${
            dailyLeaders?.data?.userId?.userData?.callSign || ""
          } - ${dailyLeaders?.data?.userId?.userData?.country || ""}`,
          secondText: `${dailyLeaders?.data?.userId?.QSOs || 0} QSOs`,
          link: `/profile/${dailyLeaders.data.userId.userData.callSign}`,
        });
      }

      if (dailyLeaders.data.country) {
        leadersData.push({
          title: "TOP",
          titleColor: "COUNTRY",
          country: dailyLeaders.data.country.key,
          firstText: dailyLeaders.data.country.key,
          secondText: `${dailyLeaders.data.country.QSOs} QSOs`,
          link: `/leaderboard/detail?country=${
            dailyLeaders.data.country.key
          }&show=alltime&week=${getISOWeek()}`,
        });
      }

      if (dailyLeaders.data.state) {
        leadersData.push({
          title: "TOP",
          titleColor: "STATE",
          state: dailyLeaders.data.state.key,
          firstText: dailyLeaders.data.state.key,
          secondText: `${dailyLeaders.data.state.QSOs} QSOs`,
          link: `/leaderboard/detail?state=${getStateShortCode(
            dailyLeaders.data.state.key
          )}&show=alltime&week=${getISOWeek()}`,
        });
      }

      if (dailyLeaders.data.longestDistance) {
        leadersData.push({
          title: "LONGEST",
          titleColor: "DX",
          image: Images.DX,
          firstText: `${dailyLeaders.data.longestDistance.myCountry} to ${
            dailyLeaders.data.longestDistance.theirCountry ||
            dailyLeaders.data.longestDistance.country
          }`,
          secondText: `${dailyLeaders.data.longestDistance.distance.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }
          )} KM`,
          link: `/contact?contactId=${dailyLeaders.data.longestDistance.id}`,
          distance: true,
          fromCountry: dailyLeaders.data.longestDistance.myCountry,
          toCountry:
            dailyLeaders.data.longestDistance.theirCountry ||
            dailyLeaders.data.longestDistance.country,
        });
      }

      if (dailyLeaders.data.band) {
        leadersData.push({
          title: "TOP",
          titleColor: "BAND",
          band: `${dailyLeaders.data.band.key}M`,
          secondText: `${dailyLeaders.data.band.QSOs} QSOs`,
          link: `/leaderboard`,
        });
      }

      if (dailyLeaders.data.userMode) {
        leadersData.push({
          title: "TOP",
          titleColor: "MODE",
          image: Images.Mode,
          firstText: dailyLeaders.data.userMode.key,
          secondText: `${dailyLeaders.data.userMode.QSOs} QSOs`,
          link: `/leaderboard`,
        });
      }
    }
    yield put({
      type: SET_DAILY_LEADERS,
      payload: leadersData,
    });
  } catch (e: any) {
    // authErrorHandler(e);
  }
  yield put({
    type: DAILY_LEADER_LOADING,
    payload: false,
  });
}

export function* workerEditLogBook(data: LogBookModel) {
  try {
    yield put({
      type: LOADING_LOGBOOK_STATUS,
      payload: true,
    });
    yield call(() => LogBookService.editUserLogBookData(data, data?.id || ""));
    yield put({
      type: GET_FILTERED_LOGBOOK_DATA,
    });
    yield put({
      type: LOADING_LOGBOOK_STATUS,
      payload: false,
    });
    yield put({
      type: GET_LOGBOOK_DATA,
      payload: data?.id,
    });
    showToast({
      message: "Updated successfully",
      type: ToastTypes.SUCCESS,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
}
export function* workerFetchAddContactLogPopupSummary(conditions: any) {
  try {
    const summary: AddContactLogPopupSummary = yield call(() =>
      LogBookService.fetchAddContactLogPopupSummary(conditions)
    );
    yield put({
      type: SAVE_CONTACT_POPUP_SUMMARY,
      payload: summary,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
}

//// FETCH_SAVED_LOCATION
export function* workerGetAllUserSavedLocation() {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });
  try {
    const userProfile: UserProfile = yield select(getUserProfileSelector);
    const uid = userProfile?.uid || "";
    const userSavedLocation: LogBookContactModel[] = yield call(() =>
      LogBookService.fetchSavedLocations(uid)
    );
    if (userSavedLocation) {
      yield put({
        type: SET_SAVED_LOCATION,
        payload: userSavedLocation,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

//ADD_SAVED_LOCATION
export function* workerSaveUserSavedLocation(data: SavedLocation) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() =>
      LogBookService.addSavedLocation(data)
    );
    console.log("addSavedLocation res", res);

    if (res) {
      yield put({
        type: FETCH_SAVED_LOCATION,
      });
      if (data.id) {
        showToast({
          message: "Location updated successfully",
          type: ToastTypes.SUCCESS,
        });
      } else {
        showToast({
          message: "New location added successfully",
          type: ToastTypes.SUCCESS,
        });
      }

      const {
        setAsHome,
        qth,
        city,
        state,
        country,
        cqZone,
        ituZone,
        gridsquare,
        lat,
        lng,
        isOnboarding,
      } = data;

      let savedLocationPayload = {
        address: qth,
        city,
        state,
        country,
        cqZone,
        ituZone,
        gridSquare: gridsquare,
        coordinates: {
          latitude: Number(lat),
          longitude: Number(lng),
        },
        savedLocationId: "",
      };

      if (typeof res === "string" && res) {
        savedLocationPayload.savedLocationId = res;
      }

      if (data.id) {
        savedLocationPayload.savedLocationId = data.id;
      }

      if (isOnboarding) {
        (savedLocationPayload as any).needsLocationOnboarding = false;
      }

      if (setAsHome) {
        const response: boolean = yield call(() =>
          ProfileService.editUserProfileData(savedLocationPayload)
        );
        if (response === true) {
          const uid = auth.currentUser?.uid || "";
          const userProfile: UserProfile | null = yield call(() =>
            ProfileService.getUserProfileData(uid)
          );
          if (userProfile) {
            yield put({
              type: STORE_USER_PROFILE,
              payload: userProfile,
            });
          }
        }
      }

      globalNavigate("/log-contacts/saved-locations");
    }
  } catch (e: any) {
    console.log("ERROR", e);
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerDeleteUserSavedLocation;
export function* workerDeleteUserSavedLocation(data: string) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() =>
      LogBookService.deleteSavedLocation(data)
    );
    if (res) {
      yield put({
        type: FETCH_SAVED_LOCATION,
      });
      showToast({
        message: "Location deleted successfully",
        type: ToastTypes.SUCCESS,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerAddRadios
export function* workerAddRadios(data: Radios) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() => LogBookService.addRadio(data));
    if (res) {
      yield put({
        type: FETCH_RADIOS,
      });
      if (data.id) {
        showToast({
          message: "Radio updated successfully",
          type: ToastTypes.SUCCESS,
        });
      } else {
        showToast({
          message: "New radio added successfully",
          type: ToastTypes.SUCCESS,
        });
      }
      yield put({
        type: ADD_RADIO_MODAL,
        payload: false,
      });

      globalNavigate("/log-contacts/my-station?tab=My+Radios");
    }
  } catch (e: any) {
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}
// workerAddAntennas;

export function* workerAddAntennas(data: Antenna) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() => LogBookService.addAntenna(data));
    if (res) {
      yield put({
        type: FETCH_ANTENNAS,
      });
      if (data.id) {
        showToast({
          message: "Antenna updated successfully",
          type: ToastTypes.SUCCESS,
        });
      } else {
        showToast({
          message: "New antenna added successfully",
          type: ToastTypes.SUCCESS,
        });
      }
      yield put({
        type: ADD_ANTENNA_MODAL,
        payload: false,
      });

      globalNavigate("/log-contacts/my-station?tab=My+Antennas");
    }
  } catch (e: any) {
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerGetAllUserRadios;
export function* workerGetAllUserRadios() {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });
  try {
    const userProfile: UserProfile = yield select(getUserProfileSelector);
    const uid = userProfile?.uid || "";
    const userRadios: Radios[] = yield call(() =>
      LogBookService.fetchRadios(uid)
    );
    if (userRadios) {
      yield put({
        type: SET_RADIOS,
        payload: userRadios,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerGetAllUserAntennas
export function* workerGetAllUserAntennas() {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });
  try {
    const userProfile: UserProfile = yield select(getUserProfileSelector);
    const uid = userProfile?.uid || "";
    const userAntennas: Antenna[] = yield call(() =>
      LogBookService.fetchAntennas(uid)
    );
    if (userAntennas) {
      yield put({
        type: SET_ANTENNAS,
        payload: userAntennas,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerDeleteUserRadio;
export function* workerDeleteUserRadio(data: string) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() => LogBookService.deleteRadios(data));
    if (res) {
      yield put({
        type: FETCH_RADIOS,
      });
      showToast({
        message: "Radio deleted successfully",
        type: ToastTypes.SUCCESS,
      });
      yield put({
        type: ADD_RADIO_MODAL,
        payload: null,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerDeleteUserAntenna;
export function* workerDeleteUserAntenna(data: string) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() => LogBookService.deleteAntenna(data));
    if (res) {
      yield put({
        type: FETCH_ANTENNAS,
      });
      showToast({
        message: "Antenna deleted successfully",
        type: ToastTypes.SUCCESS,
      });
      yield put({
        type: ADD_ANTENNA_MODAL,
        payload: null,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

//addStations
export function* workerAddStations(data: Stations) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() => LogBookService.addStation(data));
    if (res) {
      yield put({
        type: FETCH_STATIONS,
      });
      if (data.id) {
        showToast({
          message: "Station updated successfully",
          type: ToastTypes.SUCCESS,
        });
      } else {
        showToast({
          message: "New station added successfully",
          type: ToastTypes.SUCCESS,
        });
      }
      yield put({
        type: ADD_STATIONS_MODAL,
        payload: false,
      });

      globalNavigate("/log-contacts/my-station?tab=Station+Photos");
    }
  } catch (e: any) {
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerGetAllUserStations;
export function* workerGetAllUserStations() {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });
  try {
    const userProfile: UserProfile = yield select(getUserProfileSelector);
    const uid = userProfile?.uid || "";
    const userStations: Stations[] = yield call(() =>
      LogBookService.fetchStations(uid)
    );
    console.log("userStations", userStations);
    if (userStations) {
      yield put({
        type: SET_STATIONS,
        payload: userStations,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

// workerDeleteUserStation;
export function* workerDeleteUserStation(data: string) {
  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: true,
  });

  try {
    const res: boolean = yield call(() => LogBookService.deleteStation(data));
    if (res) {
      yield put({
        type: FETCH_STATIONS,
      });
      showToast({
        message: "Station deleted successfully",
        type: ToastTypes.SUCCESS,
      });
      yield put({
        type: ADD_STATIONS_MODAL,
        payload: null,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
  }

  yield put({
    type: LOGBOOK_SETTING_LOADING,
    payload: false,
  });
}

//Watchers
export function* watchGetLogBookUser() {
  while (true) {
    const { payload } = yield take(GET_FILTERED_LOGBOOK_DATA);
    yield call(workerGetLogBook, payload);
  }
}

export function* watchGetAllUserContacts() {
  while (true) {
    const { payload } = yield take(GET_ALL_USER_CONTACTS);
    yield call(workerGetAllUserContacts, payload);
  }
}

export function* watchGetAllUserContactsWithPagination() {
  while (true) {
    const { payload } = yield take(GET_ALL_USER_CONTACTS_FOR_PAGINATION);
    yield call(workerGetAllUserContactsWithPagination, payload);
  }
}

export function* watchGetAllUserContactsWithPaginate() {
  while (true) {
    const { payload } = yield take(GET_PAGINATED_USER_CONTACTS_FOR_PAGINATION);
    yield call(workerGetAllUserContactsWithPaginate, payload);
  }
}

export function* watchSaveUserLogBook() {
  while (true) {
    const { payload } = yield take(ADD_NEW_LOGBOOK);
    yield call(workerSaveUserLogBook, payload);
  }
}

export function* watchGetUserLogBookContactData() {
  while (true) {
    const { payload } = yield take(GET_LOGBOOK_CONTACT_DATA);
    yield call(workerGetUserLogBookContactData, payload);
  }
}

export function* watchGetUserLogBookContactForAdifExport() {
  while (true) {
    const { payload } = yield take(GET_LOGBOOK_CONTACT_FOR_ADIF_EXPORT);
    yield call(workerGetUserLogBookContactForAdifExport, payload);
  }
}

export function* watchGetUserLogBookData() {
  while (true) {
    const { payload } = yield take(GET_LOGBOOK_DATA);
    yield call(workerGetUserLogBookData, payload);
  }
}

export function* watchGetAllUserContactData() {
  while (true) {
    yield take(GET_All_CONTACT_DATA);
    yield call(workerGetAllUserContactData);
  }
}

export function* watchSaveUserLogBookContactData() {
  while (true) {
    const { payload } = yield take(SAVE_LOGBOOK_CONTACT_DATA);
    yield call(workerSaveUserLogBookContactData, payload);
  }
}

export function* watchEditUserLogBookContactData() {
  while (true) {
    const { payload } = yield take(EDIT_LOGBOOK_CONTACT_DATA);
    yield call(workerEditUserLogBookContactData, payload);
  }
}

export function* watchDeleteUserLogBookContactData() {
  while (true) {
    const { payload } = yield take(DELETE_LOGBOOK_CONTACT_DATA);
    yield call(workerDeleteUserLogBookContactData, payload);
  }
}

export function* watchDeleteUserLogBookData() {
  while (true) {
    const { payload } = yield take(DELETE_USER_LOGBOOK);
    yield call(workerDeleteUserLogBookData, payload);
  }
}

export function* watchFetchCallSignData() {
  while (true) {
    const { payload } = yield take(FETCH_CALLSIGN_DATA);
    yield fork(workerFetchCallSignData, payload);
  }
}
export function* watchCountryDataGrid() {
  while (true) {
    const { payload } = yield take(FETCH_COUNTRY_DATA_GRID);
    yield call(workerFetchCountryData, payload);
  }
}
export function* watchGetPaginatedContacts() {
  while (true) {
    const { payload } = yield take(GET_PAGINATED_CONTACTS);
    yield call(workerGetPaginatedContacts, payload);
  }
}

export function* watchGetPaginatedContactsWithPagination() {
  while (true) {
    const { payload } = yield take(GET_PAGINATED_CONTACTS_WITH_PAGINATION);
    yield call(workerGetPaginatedContactsWithPagination, payload);
  }
}

export function* watchSaveCoordinatesOnLogBook() {
  while (true) {
    const { payload } = yield take(SAVE_LOGBOOK_COORDINATES);
    yield call(workerEditUserLogBook, payload);
  }
}

export function* watchHandleAdifContact() {
  while (true) {
    const { payload } = yield take(HANDLE_ADIF_FILE);
    yield call(workerHandleAdifFile, payload);
  }
}

export function* watchGetPaginatedLogbookContacts() {
  while (true) {
    const { payload } = yield take(GET_PAGINATED_LOGBOOK_CONTACTS);
    yield call(workerGetLogbookContactsPaginated, payload);
  }
}

export function* watchFetchContactDetail() {
  while (true) {
    const { payload } = yield take(FETCH_CONTACT_DETAIL);
    yield call(workerFetchContactDetail, payload);
  }
}

export function* watchSearchLogbook() {
  while (true) {
    const { payload } = yield take(SEARCH_LOGBOOK);
    yield call(workerSearchContact, payload);
  }
}

export function* watchFetchDailyLeaders() {
  while (true) {
    yield take(FETCH_DAILY_LEADERS);
    yield call(workerGetDailyLeaders);
  }
}

export function* watchEditLogBook() {
  while (true) {
    const { payload } = yield take(EDIT_LOGBOOK);
    yield call(workerEditLogBook, payload);
  }
}

// FETCH_SAVED_LOCATION
export function* watchFetchSavedLocation() {
  while (true) {
    yield take(FETCH_SAVED_LOCATION);
    yield call(workerGetAllUserSavedLocation);
  }
}

// ADD_SAVED_LOCATION;
export function* watchAddSavedLocation() {
  while (true) {
    const { payload } = yield take(ADD_SAVED_LOCATION);
    yield call(workerSaveUserSavedLocation, payload);
  }
}
export function* watchDeleteSavedLocation() {
  while (true) {
    const { payload } = yield take(DELETE_SAVED_LOCATION);
    yield call(workerDeleteUserSavedLocation, payload);
  }
}

// ADD_RADIOS
export function* watchAddRadios() {
  while (true) {
    const { payload } = yield take(ADD_RADIOS);
    yield call(workerAddRadios, payload);
  }
}
// FETCH_RADIOS;
export function* watchFetchRadios() {
  while (true) {
    yield take(FETCH_RADIOS);
    yield call(workerGetAllUserRadios);
  }
}

// FETCH_ANTENNAS;

export function* watchFetchAntennas() {
  while (true) {
    yield take(FETCH_ANTENNAS);
    yield call(workerGetAllUserAntennas);
  }
}

// ADD_ANTENNAS;
export function* watchAddAntennas() {
  while (true) {
    const { payload } = yield take(ADD_ANTENNAS);
    yield call(workerAddAntennas, payload);
  }
}

// DELETE_RADIOS;
export function* watchDeleteRadios() {
  while (true) {
    const { payload } = yield take(DELETE_RADIOS);
    yield call(workerDeleteUserRadio, payload);
  }
}

// DELETE_ANTENNAS;
export function* watchDeleteAntennas() {
  while (true) {
    const { payload } = yield take(DELETE_ANTENNAS);
    yield call(workerDeleteUserAntenna, payload);
  }
}
export function* watchFetchAddContactLogPopupSummary() {
  while (true) {
    const { payload } = yield take(GET_CONTACT_POPUP_SUMMARY);
    yield call(workerFetchAddContactLogPopupSummary, payload);
  }
}

//ADD STATIONS
export function* watchAddStations() {
  while (true) {
    const { payload } = yield take(ADD_STATIONS);
    yield call(workerAddStations, payload);
  }
}

export function* watchGetAllUserStations() {
  while (true) {
    yield take(FETCH_STATIONS);
    yield call(workerGetAllUserStations);
  }
}

export function* watchDeleteUserStation() {
  while (true) {
    const { payload } = yield take(DELETE_STATIONS);
    yield call(workerDeleteUserStation, payload);
  }
}
