import { NotificationState } from "types/State";
import {
  CONTACT_MAP_MODAL_STATUS,
  NotificationActionPayload,
  SET_USER_NOTIFICATION,
  SET_USER_NORMAL_NOTIFICATIONS,
} from "store/Actions/notification.actions";
import { RootState } from "store/Reducers";
const defaultState: NotificationState = {
  userNotification: null,
  contactMapModalStatus: false,
  userNormalNotifications: [],
};

export default function notificationReducer(
  state = defaultState,
  action: NotificationActionPayload
) {
  switch (action.type) {
    case SET_USER_NOTIFICATION:
      return {
        ...state,
        userNotification: action.payload,
        authLastName: action.payload.authLastName,
      };
    case CONTACT_MAP_MODAL_STATUS:
      return {
        ...state,
        contactMapModalStatus: action.payload,
      };
    case SET_USER_NORMAL_NOTIFICATIONS:
      return {
        ...state,
        userNormalNotifications: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getUserNotificationSelector = (state: RootState) => {
  return state.notification.userNotification;
};

export const getContactMapModalStatus = (state: RootState) => {
  return state.notification.contactMapModalStatus;
};

export const getUserNormalNotificationsSelector = (state: RootState) => {
  return state.notification.userNormalNotifications;
};
