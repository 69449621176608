// import BorderButtonSecondary from "components/Button/BorderButtonSecondary";

import BorderDisplayComp from "components/Button/BorderDisplayComp";
import GlobeMap from "components/GlobeMap";
// import GlobeToggle from "components/GlobeToggle";

import Header from "components/Header";

import ContactMap from "components/Map/ContactMap";

import useGlobe from "hooks/globe.hook";
import useLogBook from "hooks/logBook.hook";
import { useEffect } from "react";

import { HiOutlineGlobeAsiaAustralia } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { fetchFeaturedMembers } from "store/Actions/profile.actions";

function Home() {
  const { selectedGlobe, setSelectedGlobe, hoverArc, setHoverArc } = useGlobe();

  const { getAllContactData } = useLogBook();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllContactData();
    dispatch(fetchFeaturedMembers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="relative w-screen lg:w-4/5 h-full flex flex-col items-center">
      <Header title="WRL" />
      {selectedGlobe ? (
        <GlobeMap
          setSelectedGlobe={setSelectedGlobe}
          hoverArc={hoverArc}
          setHoverArc={setHoverArc}
        />
      ) : (
        <div className="h-full w-full relative dashboardWrapper">
          <ContactMap
            allContactMode
            className="w-full h-full"
            containerClassName="h-full w-full relative"
            setHoverArc={setHoverArc}
            limit={30}
          />
          {/* <MapNavButton onClick={() => handleNavBarVisibility(!isMenuOpen)} /> */}
          <div
            className="absolute z-[10] bottom-4 left-3 w-fit bg-white p-[11px] rounded flex items-center cursor-pointer"
            onClick={() => setSelectedGlobe(true)}
          >
            <HiOutlineGlobeAsiaAustralia size={18} className="text-[#1F1F3F]" />
            <p className="text-[#1F1F3F] text-base font-[play] font-bold ml-2">
              Globe
            </p>
          </div>
        </div>
      )}
      {hoverArc ? (
        <BorderDisplayComp
          contactDetail={hoverArc}
          selectedGlobe={selectedGlobe}
          recentGlobeStyle="bottom-[90px] left-[150px]"
        />
      ) : null}
    </main>
  );
}

export default Home;
