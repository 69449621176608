import React, { useState, useEffect } from "react";
import { getCurrentUTCtime } from "helpers/Date/Time";

function CurrentUTCTime() {
  const [currentTime, setCurrentTime] = useState(getCurrentUTCtime);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentUTCtime);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  return (
    <div className="mb-3 flex justify-between">
      <div className="flex items-center">
        <div className="bg-[#0B0B38] text-white text-base lg:text-lg font-bold mr-3 px-2 flex justify-between">
          <div className="font-Play text-base">Current Time:</div>
          <div className="text-[#17F9DA] text-base font-[Play] font-normal ml-1 w-[112px] flex justify-around">
            <span>{currentTime}</span> <span>UTC</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurrentUTCTime;
