import { findCountryCode } from "helpers/Utils";

import React from "react";
import { RecentContactTableColProps } from "types/Component";
import ReactCountryFlag from "react-country-flag";
import { CONTACT, MY_PROFILE_ROUTE } from "constants/Links";
import { globalNavigate } from "routes/GlobalRoutes";
import CustomValidation from "helpers/Validation";
import { UserAvatar } from "components/UserAvatar";
import { useDispatch } from "react-redux";
import { setContactDetails } from "store/Actions/logBook.actions";
import { IoEyeOutline } from "react-icons/io5";
import CountryDxccFlag from "components/CountryDxccFlag";

function TableCol({ item, className }: RecentContactTableColProps) {
  const dispatch = useDispatch();

  return (
    <tr className={`${className ? className : ""} relative`}>
      <td
        className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal cursor-pointer w-[140px]"
        onClick={() => {
          globalNavigate(
            `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
              item?.profileCallSign
                ? item.profileCallSign
                : item.myCallSign
                ? item.myCallSign
                : ""
            )}?tab=Logbook&contact-tab=entries`,
            {
              state: item?.uid,
            }
          );
        }}
      >
        <div className="flex items-center min-w-[140px]">
          <div className="flex items-center gap-2">
              {item.myProfilePic ? (
                <img
                  className="w-7 h-7 rounded-full object-cover"
                  src={item?.myProfilePic}
                  alt=""
                />
              ) : (
                <UserAvatar className="w-7 h-7" />
              )}
            <p>{item?.myCallSign}</p>
            <div className="flex min-w-[40px]">
              <CountryDxccFlag
                countryCode={item?.myCountry || ""}
                flagCode={item?.myFlagCode || ""}
              />
            </div>

          </div>
        </div>
      </td>
      <td className="text-white pl-4 text-xs font-[Barlow] py-3 cursor-default w-[140px]">
        <div className="flex justify-between items-center min-w-[140px]">
          <div className="flex items-center gap-2">

              {item?.theirProfilePic ? (
                <img
                  className="w-7 h-7 rounded-full object-cover"
                  src={item?.theirProfilePic}
                  alt=""
                />
              ) : (
                <UserAvatar className="w-7 h-7" />
              )}
            <p>{item?.theirCallsign}</p>
            <div className="flex min-w-[40px]">
              <CountryDxccFlag
                countryCode={item?.theirCountry || item?.country || ""}
                flagCode={item?.flagCode || ""}
              />
            </div>
          </div>
        </div>
      </td>
      <td className="text-white pl-4 text-xs font-[Barlow] py-3 w-[30px] cursor-default">
        <p className="w-[30px]">{item?.band}</p>
      </td>
      <td className="text-white pl-4 text-xs font-[Barlow] py-3 w-[30px] cursor-default">
        <p className="w-[30px]">{item?.userMode}</p>
      </td>
      <td className="text-white pl-4 text-xs font-[Barlow] py-3 w-[100px] cursor-default">
        <div className="flex">
          <p className="w-[80px]">
            {item?.distance
              ? `${item?.distance.toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })} KM`
              : "--"}
          </p>
          <div
            className="flex justify-center items-center text-white w-12 cursor-pointer "
            onClick={(e) => {
              dispatch(setContactDetails({}));
              globalNavigate(`${CONTACT}?contactId=${item?.id}`, {
                state: { item },
              });
            }}
          >
            <IoEyeOutline size={20} />
          </div>
        </div>
      </td>
    </tr>
  );
}

export default TableCol;
