import DropDown from "components/DropDown/DropDown";
import { mapDownDown, mapImported } from "constants/Config";
import { ContactMapControlsProps } from "types/Component";

function ContactMapControls({
  setShowImported,
  setShowLimit,
}: ContactMapControlsProps) {
  return (
    <div className="flex items-center justify-end my-3">
      <div className="flex">
        <div className="flex mr-3">
          <p className="text-[#17F9DA] font-[Play] font-sm">Show Contacts</p>
          <DropDown
            placeHolder={"50"}
            list={mapDownDown}
            onChange={(e: any) => {
              setShowLimit(e.target.value ? parseInt(e.target.value) : 50);
            }}
            width={"w-[47.5%] sm:w-[23.5%] md:w-[100px]"}
            className="ml-3"
          />
        </div>
        <div className="flex">
          <p className="text-[#17F9DA] font-[Play] font-sm">Show Imported</p>
          <DropDown
            placeHolder={"Yes"}
            list={mapImported}
            onChange={(e: any) => {
              setShowImported(e.target.value ? false : true);
            }}
            width={"w-[47.5%] sm:w-[23.5%] md:w-[100px]"}
            className="ml-3"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactMapControls;
