import { TextDescProps } from "types/Component";

function TextDesc({ text }: TextDescProps) {
  const multiline = true;
  return (
    <div className="mb-7">
      <p
        className={`text-sm text-white font-[Barlow] ${
          multiline ? "" : "line-clamp-3"
        }`}
      >
        {text}
      </p>
      {/* <button
        className="font-[Play] font-bold underline text-white"
        onClick={() => setMultiline(!multiline)}
      >
        {multiline ? "hide" : "more"}
      </button> */}
    </div>
  );
}

export default TextDesc;
