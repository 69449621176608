import { SubscriptionState } from "types/State";
import { RootState } from "store/Reducers";
import {
  SubscriptionActionPayload,
  SET_SUBSCRIPTION_PRODUCTS,
  SET_SUBSCRIPTION_STATUS,
  SET_FOUNDING_MEMBER_SPOTS,
} from "store/Actions/subscription.actions";
const defaultState: SubscriptionState = {
  subscriptionProducts: [],
  subscriptionStatus: null,
  foundingMemberSpotsLeft: "",
};

export default function SubscriptionReducer(
  state = defaultState,
  action: SubscriptionActionPayload
) {
  switch (action.type) {
    case SET_SUBSCRIPTION_PRODUCTS:
      return {
        ...state,
        subscriptionProducts: action.payload,
      };
    case SET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    case SET_FOUNDING_MEMBER_SPOTS:
      return {
        ...state,
        foundingMemberSpotsLeft: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getSubscriptionProductsSelector = (state: RootState) => {
  return state.subscription.subscriptionProducts || [];
};
export const getSubscriptionStatusSelector = (state: RootState) => {
  return state.subscription.subscriptionStatus;
};

export const getFoundingMemberSpotsLeftSelector = (state: RootState) => {
  return state.subscription.foundingMemberSpotsLeft;
};
