/* eslint-disable array-callback-return */
import { all, call, fork, put, select, take } from "redux-saga/effects";
import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { Award } from "types/Models";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import {
  ADD_AWARD,
  FETCH_AWARDS_DATA,
  setAwardLoading,
  setAwardsData,
  fetchAwards,
  setShowAwardModal,
} from "store/Actions/award.actions";
import AwardService from "services/award.service";

export function* workerAddAward(data: Award) {
  yield put(setAwardLoading(true));

  try {
    const res: boolean = yield call(() => AwardService.addAward(data));
    if (res) {
      showToast({
        message: "New award added successfully",
        type: ToastTypes.SUCCESS,
      });

      yield put(fetchAwards());
      yield put(setShowAwardModal(false));
    }

    yield put(setAwardLoading(false));
  } catch (e: any) {
    authErrorHandler(e);
    yield put(setAwardLoading(false));
  }
}

export function* watchFetchAwards() {
  while (true) {
    yield take(FETCH_AWARDS_DATA);
    yield call(workerFetchAwards);
  }
}

export function* workerFetchAwards() {
  yield put(setAwardLoading(true));
  try {
    const awards: Award[] = yield call(() => AwardService.fetchAwards());
    if (awards && awards.length > 0) {
      yield put(setAwardsData(awards));
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put(setAwardLoading(false));
}

export function* watchAddAward() {
  while (true) {
    const { payload } = yield take(ADD_AWARD);
    yield call(workerAddAward, payload);
  }
}
