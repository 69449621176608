import { call, put, take } from "redux-saga/effects";
import NotificationService from "services/notification.service";

import {
  FETCH_USER_NORMAL_NOTIFICATIONS,
  FETCH_USER_NOTIFICATIONS,
  MARK_USER_NORMAL_NOTIFICATIONS,
  SET_USER_NORMAL_NOTIFICATIONS,
  SET_USER_NOTIFICATION,
  UPDATE_USER_NOTIFICATIONS,
} from "store/Actions/notification.actions";
import { UserNotification } from "types/Models";

//Workers
export function* workerGetUserNotification() {
  try {
    const userNotification: UserNotification[] | null = yield call(
      NotificationService.getUserNotification
    );
    if (userNotification && userNotification[0]) {
      yield put({
        type: SET_USER_NOTIFICATION,
        payload: userNotification[0],
      });
    } else {
      yield put({
        type: SET_USER_NOTIFICATION,
        payload: null,
      });
    }
  } catch (e: any) {}
}

export function* workerFetchUserNormalNotifications() {
  try {
    const userNotification: UserNotification[] | null = yield call(
      NotificationService.fetchUserNormalNotification
    );
    yield put({
      type: SET_USER_NORMAL_NOTIFICATIONS,
      payload: userNotification,
    });
  } catch (e: any) {}
}

export function* workerUpdateUserNotification(payload: UserNotification) {
  try {
    yield call(() => NotificationService.updateUserNotification(payload));
  } catch (e: any) {}
}

export function* workerMarkUserNormalNotifications() {
  try {
    yield call(() => NotificationService.markReadUserNormalNotifications());
  } catch (e: any) {}
}

//Watchers
export function* watchGetUserNotification() {
  while (true) {
    yield take(FETCH_USER_NOTIFICATIONS);

    yield call(workerGetUserNotification);
  }
}

export function* watchUpdateUserNotification() {
  while (true) {
    const { payload } = yield take(UPDATE_USER_NOTIFICATIONS);
    yield call(workerUpdateUserNotification, payload);
  }
}
export function* watchFetchUserNormalNotification() {
  while (true) {
    yield take(FETCH_USER_NORMAL_NOTIFICATIONS);
    yield call(workerFetchUserNormalNotifications);
  }
}

export function* watchMarkUserNormalNotifications() {
  while (true) {
    yield take(MARK_USER_NORMAL_NOTIFICATIONS);
    yield call(workerMarkUserNormalNotifications);
  }
}
