import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { useAwards } from "hooks/useAwards.hook";
import AwardItem from "components/AwardItem";

function Awards() {
  const { awards, isLoading, getAwards, setShowAwardModal } = useAwards();
  
  useEffect(() => {
    getAwards();
  }, []);

  return (
    <div className="flex flex-wrap w-full md:justify-start justify-center">
      {isLoading ? (
        <ActivityIndicator size={28} />
      ) : (
        <div className="flex flex-wrap w-full md:justify-start justify-center">
          {awards.map((item, index) => (
            <AwardItem
              data={item}
              // onClick={() => {
              //   dispatch(setAddRadioModal(item));
              // }}
              type="Award"
            />
          ))}
          <AwardItem
            add={true}
            buttonText="Add Award"
            onClick={() => {
             setShowAwardModal(true);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Awards;
