import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

export const authErrorHandler = (error: any) => {
  const errorCode = error?.code;

  if (errorCode === "auth/invalid-email") {
    showToast({
      message: "Invalid email, please try again.",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "auth/user-disabled") {
    showToast({
      message:
        "Your account has been disabled temporarily, please contact us at support@worldradioleague.com",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "auth/email-already-in-use") {
    showToast({
      message: "Email is already in use, please use another.",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "auth/weak-password") {
    showToast({
      message: "Weak password, please choose a stronger password.",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "auth/wrong-password") {
    showToast({
      message: "Password incorrect, please provide the correct password.",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "auth/user-not-found") {
    showToast({
      message: "User not found, please provide a registered email.",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "auth/requires-recent-login") {
    showToast({
      message: "Verify your email, please login again to verify email.",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "Empty Fields") {
    showToast({
      message: "Invalid data, please fill all fields.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Check Checkbox") {
    showToast({
      message: "Terms of use, Please accept our terms of use.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Invalid CallSign") {
    showToast({
      message: "Invalid CallSign, please provide your callsign.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Successfully sent an email") {
    showToast({
      message: "Successful, please check your email.",
      type: ToastTypes.SUCCESS,
    });
  } else if (errorCode === "First Name is required") {
    showToast({
      message: "First Name is Required, please provide your first name.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Last Name is required") {
    showToast({
      message: "Last Name is Required, please provide your last name.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Email is required") {
    showToast({
      message: "Email is Required, please provide an email.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Password is required") {
    showToast({
      message: "Password is Required, please provide a password.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Bio is Required") {
    showToast({
      message: "Bio is Required, please provide your bio.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Callsign is Required") {
    showToast({
      message: "Callsign is Required, please provide your callsign.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Username is Required") {
    showToast({
      message: "Username is Required, please provide your username.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "Country is Required") {
    showToast({
      message: "Country is Required, please provide your country.",
      type: ToastTypes.WARN,
    });
  } else if (errorCode === "State is Required") {
    showToast({
      message: "State is Required, please provide your state.",
      type: ToastTypes.WARN,
    });
  } else {
    showToast({
      message: "Something went wrong, please try again.",
      type: ToastTypes.ERROR,
    });
  }
};
