import { UserAvatar } from "components/UserAvatar";
import { CONTACT, CONTEST, MY_PROFILE_ROUTE } from "constants/Links";
import { auth } from "firebase-config";
import CustomValidation from "helpers/Validation";
import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { globalNavigate } from "routes/GlobalRoutes";
import { setUserContestDetail } from "store/Actions/contest.actions";
import { setContactDetails } from "store/Actions/logBook.actions";
import { TableLeaderBoardColProps } from "types/Component";

function TableLeaderBoardCol({
  userData,
  index,
  selectedTab,
  contest,
  className,
  contestId,
  contestName,
  contestPassed,
  distance,
  weekly,
}: TableLeaderBoardColProps) {
  const isSameUser = userData ? userData.uid === auth.currentUser?.uid : false;
  const dispatch = useDispatch();
  const navigateToProfile = () => {
    globalNavigate(
      `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
        userData?.callSign ? userData?.callSign : ""
      )}?tab=Logbook&contact-tab=entries`
    );
  };

  const getUserCount = () => {
    if (distance) {
      if (userData?.distance) {
        return (
          userData?.distance.toLocaleString(undefined, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }) + " KM"
        );
      } else {
        return "-- KM";
      }
    } else if (
      selectedTab &&
      userData?.modes &&
      userData?.modes[`${selectedTab}`]
    ) {
      if (weekly) {
        return userData?.leaderBoardCount;
      }
      return userData?.modes[`${selectedTab}`];
    } else if (contest) {
      return userData?.numberOfPoints;
    } else {
      if (weekly) {
        return userData?.leaderBoardCount;
      }
      return userData?.numberOfContacts;
    }
  };

  return (
    <tr
      className={`${className} border-0 border-b border-slate-300 border-solid`}
    >
      <td className="text-xs text-white font-[Barlow] font-normal py-3 w-[50px]">
        <p className="pl-4">{index + 1}</p>
      </td>
      <td
        className="text-xs text-white font-[Barlow] font-normal py-2.5 w-[40px]  cursor-pointer"
        onClick={() => {
          navigateToProfile();
        }}
      >
        <div className="w-[40px] h-full flex justify-center items-center">
          {userData?.profilePic ? (
            <img
              className="w-7 h-7 rounded-full object-cover"
              src={userData.profilePic}
              alt=""
            />
          ) : (
            <UserAvatar className="w-7 h-7" />
          )}
        </div>
      </td>
      <td
        className="text-xs text-white font-[Barlow] font-normal py-3 w-[70px]  cursor-pointer"
        onClick={() => {
          navigateToProfile();
        }}
      >
        <p className="pl-2 w-[70px]">{userData?.callSign}</p>
      </td>
      <td
        className="text-xs text-white font-[Barlow] font-normal py-3 w-[150px]  cursor-pointer"
        onClick={() => {
          navigateToProfile();
        }}
      >
        <p className={`pl-2 ${isSameUser ? "font-bold" : ""}`}>
          {userData?.firstName} {userData?.lastName} {isSameUser ? "(You)" : ""}
        </p>
      </td>
      <td className="text-xs text-white font-[Barlow] font-normal py-3 w-[157px]">
        <div className="flex">
          <p className="pl-2">{getUserCount()}</p>
          {distance && (userData as any)?.contactId ? (
            <div
              className="flex justify-center items-center text-white w-12 cursor-pointer "
              onClick={(e) => {
                dispatch(setContactDetails({}));
                globalNavigate(`${CONTACT}?contactId=${(userData as any)?.contactId}`, {
                  // state: { item },
                });
              }}
            >
              <IoEyeOutline size={20} />
            </div>
          ) : null}
        </div>
      </td>
      {contest ? (
        <td className="py-3">
          <span
            className="text-[#17F9DA] cursor-pointer font-[Barlow] font-normal text-xs"
            onClick={() => {
              dispatch(setUserContestDetail(null));
              globalNavigate(
                `${CONTEST}/user-logbook-detail?contest_name=${contestName}&contest_id=${contestId}&user_id=${userData.uid}&tab=entries`,
                {
                  state: {
                    contestPassed: contestPassed,
                  },
                }
              );
            }}
          >
            View Logbook
          </span>
        </td>
      ) : null}
    </tr>
  );
}

export default TableLeaderBoardCol;
