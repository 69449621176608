import { SpottingState } from "types/State";
import { RootState } from "store/Reducers";
import { SpottingPayload, SET_SPOT } from "store/Actions/spotting.action";
const defaultState: SpottingState = {
  spots: [],
};

export default function spottingReducer(
  state = defaultState,
  action: SpottingPayload
) {
  switch (action.type) {
    case SET_SPOT:
      return {
        ...state,
        spots: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getSpotSelector = (state: RootState) => {
  return state.spotting.spots || [];
};
