import { call, put, take } from "redux-saga/effects";
import GuestService from "services/guest.service";
import {
  GET_USER_DATA_BY_CALLSIGN,
  STORE_USER_DATA_BY_CALLSIGN,
} from "store/Actions/guest.action";
import { LOADING_GUEST_USER_PROFILE_STATUS } from "store/Actions/loading.action";
import { GET_ALL_USER_CONTACTS } from "store/Actions/logBook.actions";
import { UserProfile } from "types/Models";
import { FetchProfileProps } from "types/State";

//Workers
export function* workerGetUserProfileByCallSign(payload: FetchProfileProps) {
  yield put({
    type: LOADING_GUEST_USER_PROFILE_STATUS,
    payload: true,
  });
  try {
    // console.log("GUES USER PAYLOAD : ", payload);
    const userProfile: UserProfile | null = yield call(() =>
      GuestService.getUserProfileDataByCallSign(payload)
    );
    // console.log("GUES USER PROFILE : ", userProfile);
    if (userProfile) {
      yield put({
        type: STORE_USER_DATA_BY_CALLSIGN,
        payload: userProfile,
      });
      yield put({
        type: GET_ALL_USER_CONTACTS,
        payload: userProfile?.uid,
      });
    } else {
      // SHOW TOAST "Sorry we couldn't find userprofile"
      // set user profile to null if not found
      yield put({
        type: STORE_USER_DATA_BY_CALLSIGN,
        payload: null,
      });
    }
  } catch (e: any) {}
  yield put({
    type: LOADING_GUEST_USER_PROFILE_STATUS,
    payload: false,
  });
}

//Watchers
export function* watchGetUserProfileByCallSign() {
  while (true) {
    const { payload } = yield take(GET_USER_DATA_BY_CALLSIGN);
    yield call(workerGetUserProfileByCallSign, payload);
  }
}
