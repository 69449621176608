import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import ButtonRounded from "components/Button/ButtonRounded";
import SolidBorderButton from "components/Button/SolidBorderButton";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import TableCustom from "components/Table/Table";
import { TAGS } from "constants/Config";
import { globalNavigate } from "routes/GlobalRoutes";
import { LogBookListProps } from "types/State";

function LogBooksList({ logBooksList, isLoading }: LogBookListProps) {
  return (
    <main className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
      <div className="h-full w-full overflow-x-hidden">
        <div className="glassmorphism w-full h-fit pb-6">
          <div className="px-3 md:px-7 py-5 flex items-center justify-between border-b border-slate-400 border-solid">
            <div className="flex flex-col sm:flex-row gap-2 md:gap-10 lg:gap-10 xl:gap-10 2xl:gap-10">
              <p className="font-[play] font-bold text-lg text-white">
                LOGBOOKS
              </p>
              <button
                className="text-[#17F9DA] font-[Play] cursor-pointer"
                onClick={() => globalNavigate("/log-contacts/import-contact")}
              >
                Import .ADI Log
              </button>
            </div>
            <div className="w-[50%]">
              <div className="flex justify-end flex-col gap-3 sm:flex-row">
                <div className="sm:w-60">
                  <BorderButtonSecondary
                    text={`+ New Logbook`}
                    onClick={() => globalNavigate("/log-contacts/add-logbook")}
                    className="sm:mb-0"
                  />
                </div>
                <div className="sm:w-60">
                  <SolidBorderButton
                    text={`New POTA Activation`}
                    onClick={() =>
                      globalNavigate(
                        `/log-contacts/add-logbook?tag=${TAGS.POTA}`
                      )
                    }
                    className="sm:mb-0 border border-emerald-500"
                    icon={
                      <img
                        className="h-5 w-5 mr-2"
                        alt="pota icon"
                        src={Icons.parkWhite}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 mb-10 px-5 md:px-7 h-fit">
            {isLoading ? (
              <ActivityIndicator size={36} />
            ) : (
              <TableCustom list={logBooksList} />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default LogBooksList;
