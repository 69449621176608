import { toast, ToastOptions } from "react-toastify";
import { ShowToastProps, ToastTypes } from "types/Component";

export const showToast = ({ message, type }: ShowToastProps) => {
  const toastOptions: ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: "light",
  };
  if (type === ToastTypes.ERROR) {
    toast.error(message, toastOptions);
  } else if (type === ToastTypes.SUCCESS) {
    toast.success(message, toastOptions);
  } else {
    toast.warn(message, toastOptions);
  }
};
