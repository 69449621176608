import RouteLoading from "components/Loading/RouteLoading";
import {
  CONTACT,
  CONTEST,
  LEADERS_BOARD,
  MY_PROFILE_ROUTE,
  SETTING_ROUTE,
} from "constants/Links";
import MainLayout from "layout/MainLayout";
import ForgotPassword from "pages/Auth/Login/ForgotPassword";
import ContactDetail from "pages/Dashboard/Contact";
import Contest from "pages/Dashboard/Contest";
import ContestDetail from "pages/Dashboard/Contest/ContestDetail";
import Home from "pages/Dashboard/Home";
import LeaderBoard from "pages/Dashboard/LeaderBoard";
import UserLogbookDetail from "pages/Dashboard/LogBook/UserLogbookDetail";
import Profile from "pages/Dashboard/Profile";
import Pricing from "pages/Dashboard/Setting/Pricing";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PricingList from "pages/Dashboard/Setting/PricingList";
import Setting from "pages/Dashboard/Setting";

const Login = lazy(() => import("../pages/Auth/Login"));
const Register = lazy(() => import("../pages/Auth/Register"));
const AdditionalInfo = lazy(
  () => import("../pages/Auth/Register/AdditionalInfo")
);

const LocationSettings = lazy(
  () => import("../pages/Auth/Register/LocationSettings")
);

function AuthRoute() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Suspense fallback={<RouteLoading />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/discourseLogin"
        element={
          <Suspense fallback={<RouteLoading />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/register"
        element={
          <Suspense fallback={<RouteLoading />}>
            <Register />
          </Suspense>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Suspense fallback={<RouteLoading />}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path="/register/additional-info"
        element={
          <Suspense fallback={<RouteLoading />}>
            <AdditionalInfo />
          </Suspense>
        }
      />
      <Route
        path="/register/location-settings"
        element={
          <Suspense fallback={<RouteLoading />}>
            <LocationSettings />
          </Suspense>
        }
      />
      <Route
        element={
          <Suspense fallback={<RouteLoading />}>
            <MainLayout />
          </Suspense>
        }
      >
        <Route index element={<Home />} />
        <Route
          path={`${MY_PROFILE_ROUTE}/:callSignFromParam`}
          element={<Profile />}
        />
        <Route path={LEADERS_BOARD} element={<LeaderBoard />} />
        <Route path={CONTACT}>
          <Route index element={<ContactDetail />} />
        </Route>
        <Route path={CONTEST}>
          <Route index element={<Contest />} />
          <Route path="contest-detail" element={<ContestDetail />} />
          <Route path="user-logbook-detail" element={<UserLogbookDetail />} />
        </Route>
        <Route path={SETTING_ROUTE}>
          <Route index element={<Setting />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="pricingList" element={<PricingList />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <Suspense fallback={<RouteLoading />}>
            <Navigate to="/login" replace={true} />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default AuthRoute;
