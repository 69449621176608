import React, { useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import { ContactMarkerProps } from "types/Component";
import { findCountryCode } from "helpers/Utils";
import ReactCountryFlag from "react-country-flag";
import Icons from "assets/Icons";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";

const SpotMarker = ({ position, index }: ContactMarkerProps) => {
  const [infoVisibile, setInfoVisibile] = useState(false);
  return (
    <MarkerF
      position={position}
      onMouseOver={(e) => {
        setInfoVisibile(true);
      }}
      onMouseOut={(e) => {
        setInfoVisibile(false);
      }}
      icon={{
        url: Icons.MarkerIcon,
        scaledSize: new window.google.maps.Size(20, 20),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(10,20),
      }}
    >
      {infoVisibile ? (
        <InfoWindowF
          onCloseClick={() => {
            setInfoVisibile(false);
          }}
          options={{ disableAutoPan: true }}
          position={position}
        >
          {position.contactInfo ? (
            <div className=" h-[55px] rounded-sm bg-[#fff] py-1 pr-6 pl-2 flex">
              <div className="relative">
                {position.contactInfo?.image ? (
                  <img
                    className="w-[40px] h-[40px] mr-3 rounded-full bg-[#f2f2f2]"
                    src={position.contactInfo?.image}
                    alt=""
                  />
                ) : (
                  <UserAvatar className="h-8 w-8 mr-3" />
                )}
                <div className="absolute top-[25px] left-[25px]">
                  <CountryDxccFlag
                    countryCode={position.contactInfo.country || ""}
                    flagCode={position.contactInfo.flagCode || ""}
                  />
                </div>
              </div>
              <div className="">
                <p className="font-[Play] font-normal text-[#010A41]">
                  {position.contactInfo.callSign} -{" "}
                  {position.contactInfo.country}
                </p>
                <p className="font-[Play] font-normal text-[#010A41]">
                  {position.contactInfo.lastSeen}
                </p>
                <div className="flex">
                  <img
                    src={Icons.Frequency}
                    alt=""
                    className="w-[13px] h-[13px]"
                  />
                  <p className="font-[Play] text-[11px] text-[#010A41]">
                    {position.contactInfo.frequency} MHz{" "}
                    {`(${position.contactInfo.mode})`}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </InfoWindowF>
      ) : null}
    </MarkerF>
  );
};

export default SpotMarker;
