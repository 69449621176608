import React from "react";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Header from "components/Header";
import { globalNavigate } from "routes/GlobalRoutes";
import { FileUploader } from "react-drag-drop-files";
import Icons from "assets/Icons";
import TextInput from "components/Inputs/TextInput";
import useAdifImport from "hooks/adifImport.hook";
import { VerticalDivider } from "components/Divider";
import TitledInput from "components/Inputs/TitledInput";
import TitledDefaultCountryPicker from "components/Inputs/TitledDefaultCountryPicker";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import { Form, Formik, useFormikContext } from "formik";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { LocationSettings } from "./LogBookSettings/LocationSettings";
import { LogbookSettingsForm, SavedLocation } from "types/Models";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";

function ImportContact() {
  const fileTypes = ["ADI", "ADIF"];
  const initialValues: LogbookSettingsForm = {
    name: "",
    description: "",
    logbookStyle: "",
    defaultLocation: "",
    radio: "",
    antenna: "",
    defaultCallSign: "",
    callSignType: "",
    defaultPower: "",
    contestPower: "",
    numberOfTransmitters: "",
    contestBand: "",
    assisted: "",
    operator: "",
  };
  const {
    setFile,
    file,
    submitForm,
    setLogBookName,
    logBookName,
    isAdiUploadLoading,
    country,
    setCountry,
    state,
    setState,
    lat,
    setLat,
    long,
    setLong,
    grid,
    setGrid,
    logbookAdiLocation,
    setLogbookAdiLocation,
  } = useAdifImport();

  const {
    setLocationOption,
    locationOption,
    savedLocationOptions,
    savedLocations,
  } = useLogbookSetting();
  // const { values, setFormValue } = useLogBookSettingsForm();

  const handleChange = (file: any) => {
    setFile(file);
  };
  return (
    <main className="w-full dashboardWrapper flex flex-col items-center relative">
      <Header title="Import .ADI Files" />

      <div className="mt-10 flex justify-center items-center flex-col lg:block">
        <div className="w-11/12 sm:w-5/6 md:w-[690px]  flex flex-col relative bg-[#151541] py-[35px] px-[50px]">
          {file ? (
            <>
              <div>
                <h3 className="font-['Barlow'] text-base text-white mb-2.5 font-bold mt-2">
                  Logbook Name
                </h3>
                <TextInput
                  text={logBookName}
                  setText={(text) => {
                    setLogBookName(text);
                  }}
                  placeHolder="Logbook Name *"
                  name="logbookName"
                  className="mb-2.5 bg-[#73738D] border-1 border-[#a7a7a7] text-sm h-[40px]"
                />
              </div>
              <div className="mb-3">
                <h3 className="font-['Barlow'] text-base text-white  font-bold mt-2">
                  Logbook Location
                </h3>
                <p className="font-[Play] text-sm text-[#17F9DA] mb-3">
                  Set default location for import when Operator location is not
                  found on the log file
                </p>

                <div className="flex flex-col w-full py-4 pb-4">
                  <SelectableButtonsGroup
                    initialSelected={1}
                    actions={[
                      {
                        id: 1,
                        label: "Saved Location",
                      },
                      {
                        id: 2,
                        label: "Do Not Set / Leave Blank",
                      },
                    ]}
                    onButtonChange={(action) => {
                      setLocationOption(action?.id || 0);
                      if (action?.id === 2) {
                        setLogbookAdiLocation(null);
                      }
                    }}
                  />
                  <div className="flex flex-row flex-wrap mt-3">
                    {locationOption === 1 ? (
                      <LogbookSettingDropdown
                        list={savedLocationOptions}
                        title="Saved Locations:"
                        value={logbookAdiLocation?.id || ""}
                        setValue={(value) => {
                          if (value === "add") {
                            globalNavigate("/log-contacts/saved-locations");
                          } else {
                            setLogbookAdiLocation(
                              savedLocations.find(
                                (location: SavedLocation) =>
                                  location.id === value
                              ) || null
                            );
                          }
                        }}
                        className={"w-[99.9%] sm:w-[99.9%] md:w-[99.9%]"}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <p className="font-[Barlow] font-bold text-white">Upload Files</p>
          <div className="mt-2">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              required={true}
              classes="h-[200px]"
            >
              <div className="w-full border-dashed border h-[205px] flex items-center justify-between px-[32px] cursor-pointer">
                <img
                  className="w-[48px] h-[48px] object-contain"
                  alt="upload"
                  src={Icons.UploadIcon}
                />
                <div className="justify-center">
                  <p className="font-[Barlow] text-white">
                    Select a file or drag and drop here
                  </p>
                  <div className="flex flex-col">
                    <span className="font-[Barlow] text-[#73738D] text-xs">
                      Select .ADI or .ADIF file
                    </span>
                    <span className="font-[Barlow] text-[#844D0F] text-xs mt-3">
                      Imported contacts do not affect leaderboards or recent
                      contacts list
                    </span>
                  </div>
                </div>
                <button className="text-xs w-[90px] h-[36px] bg-[#0243DD] font-[Barlow] text-white">
                  SELECT FILE
                </button>
              </div>
            </FileUploader>
          </div>
          {file ? (
            <>
              <p className="font-[Barlow] text-white mt-6 mb-3">File Added</p>
              <div className="flex w-full h-[58px] bg-[#2A2852] items-center px-6 justify-between">
                <div className="flex items-center">
                  <img
                    src={Icons.FileIcon}
                    alt="file-icon"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="text-white font-[Barlow] text-[12px] ml-4">
                    {file.name}
                  </span>
                </div>
                <span className="text-white font-[Barlow] text-[12px] ml-4">
                  {(file.size / 1024).toFixed(1)} KB
                </span>
              </div>
            </>
          ) : null}
        </div>
        {file ? (
          <div className="w-full  flex items-center justify-between mb-8 mt-10 sm:mt-10 px-10">
            <div className="flex items-end w-[40%]">
              <BorderButtonSecondary
                isLoading={isAdiUploadLoading}
                text={"Upload"}
                onClick={() => {
                  submitForm();
                }}
                childClassName="text-sm"
              />
            </div>
            <div className="flex items-end w-[40%]">
              <BorderButtonSecondary
                isLoading={false}
                text={"Cancel"}
                onClick={() => {
                  globalNavigate("/log-contacts");
                }}
                childClassName="text-sm"
              />
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
}

export default ImportContact;
