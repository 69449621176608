import React, { useState, useEffect } from "react";
import Icons from "assets/Icons";
import StationItem from "components/StationItem";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { useDispatch } from "react-redux";
import { setAddStationsModal } from "store/Actions/logBook.actions";
import useLogbookSetting from "hooks/logbookSetting.hook";
import ActivityIndicator from "components/Loading/ActivityIndicator";

function StationPhotos() {
  const dispatch = useDispatch();
  const { logbookSettingLoading, stations } = useLogbookSetting();

  return (
    <div className="flex flex-wrap w-full md:justify-start justify-center px-5 md:px-24">
      {logbookSettingLoading ? (
        <ActivityIndicator size={28} />
      ) : (
        <div className="flex flex-wrap w-full md:justify-start justify-center">
          {stations?.map((item, index) => (
            <StationItem
              data={item}
              onClick={() => {
                dispatch(setAddStationsModal(item));
              }}
              type="Station"
            />
          ))}
          <StationItem
            add={true}
            buttonText="Add Station"
            onClick={() => {
              dispatch(setAddStationsModal(true));
            }}
          />
        </div>
      )}

      {/* <div className="w-full flex justify-center">
        <div className="w-[220px] mt-10">
          <BorderButtonSecondary
            text={`Save Station`}
            onClick={() => {}}
            className="sm:mb-0 cursor-pointer"
            height="h-8"
            childClassName="text-xs"
          />
        </div>
      </div> */}
    </div>
  );
}

export default StationPhotos;
