import moment from "moment";

export const convertSecondToDate = (second: number) => {
  const date = moment(new Date(second * 1000)).format("MM/DD/YYYY");
  return date;
};

export const convertSecondToDateAndTime = (second: number) => {
  const date = moment(new Date(second * 1000)).format("YYYY-MM-DD h:mm:ss");
  return date;
};
