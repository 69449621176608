import React, { useState, useEffect } from "react";
import StationItem from "components/StationItem";
import { useDispatch } from "react-redux";
import {
  setAddAntennaModal,
} from "store/Actions/logBook.actions";
import useLogbookSetting from "hooks/logbookSetting.hook";
import ActivityIndicator from "components/Loading/ActivityIndicator";

function MyAntennas({analytics, loading}: {analytics: Record<string, number>; loading?: boolean}) {
  const dispatch = useDispatch();
  const { antennas, logbookSettingLoading } = useLogbookSetting();

  return (
    <div className="flex flex-wrap w-full md:justify-start justify-center">
      {logbookSettingLoading ? (
        <ActivityIndicator size={28} />
      ) : (
        <div className="flex flex-wrap w-full md:justify-start justify-center">
          {antennas.map((item: any, index) => (
            <StationItem
              data={{...item, numberOfQSO: analytics?.[item.id] || 0}}
              onClick={() => {
                dispatch(setAddAntennaModal(item));
              }}
              type="Antenna"
              key={index}
              loading={loading}
            />
          ))}
          <StationItem
            key={-1}
            add={true}
            buttonText="Add Antenna"
            onClick={() => {
              dispatch(setAddAntennaModal(true));
            }}
          />
        </div>
      )}
    </div>
  );
}

export default MyAntennas;
