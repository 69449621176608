import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import TitledDefaultCountryPicker from "components/Inputs/TitledDefaultCountryPicker";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import { LocationMappings } from "constants/LocationMappings";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC, useState } from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import ReactCountryFlag from "react-country-flag";
import { findCountryCode } from "helpers/Utils";
import { VerticalDivider } from "components/Divider";
import { ParkReferenceModel, SavedLocation } from "types/Models";
import { LocationSource } from "components/DefaultLocationSettings";
import TitledInput from "components/Inputs/TitledInput";
import { Form, Formik } from "formik";
import Icons from "assets/Icons";
import ActivityIndicator from "components/Loading/ActivityIndicator";

export const LocationSettings: FC<{
  locationForm: any;
  setLocationForm: any;
  findLocation: any;
  locationOption: number;
  setLocationOption: any;
  locationLoading?: boolean;
  importUI?: boolean;
  setLogbookAdiLocation?: (value: any) => void;
}> = ({
  locationForm,
  setLocationForm,
  findLocation,
  locationOption,
  setLocationOption,
  locationLoading,
  importUI,
  setLogbookAdiLocation,
}) => {
  const { savedLocationOptions, selectedLogbook, savedLocations } =
    useLogbookSetting();
  const { values, setFormValue } = useLogBookSettingsForm();

  const [canEdit, setCanEdit] = useState(true);

  const onEditClickHandler = () => {
    setCanEdit(false);
  };

  const setLocationFromPark = (park: ParkReferenceModel) => {
    setLocationForm((prev: any) => ({
      ...prev,
      locationCountry: park?.entityName || "",
      locationState: park?.locationName || "",
      locationGrid: park?.grid6 || "",
      locationLat: park?.latitude ? `${park?.latitude}` : "",
      locationLong: park?.longitude ? `${park?.longitude}` : "",
    }));
    setFormValue("locationSource", LocationSource.PARK);
  };

  return (
    <div className="flex flex-col text-white gap-4">
      {importUI ? null : (
        <div className="font-bold font-Play text-xl">Location Settings</div>
      )}

      <div className="flex flex-col gap-4">
        <SelectableButtonsGroup
          initialSelected={selectedLogbook?.defaultLocation?.isCustom ? 2 : 1}
          actions={[
            {
              id: 1,
              label: "Saved Location",
            },
            {
              id: 2,
              label: "Custom Location",
            },
          ]}
          onButtonChange={(action) => {
            setLocationOption(action?.id || 1);
            setFormValue("isCustomLocation", action?.id === 2);
          }}
        />
        <div className="flex flex-row flex-wrap">
          {locationOption === 1 ? (
            <LogbookSettingDropdown
              list={savedLocationOptions}
              title="Saved Locations:"
              value={values?.defaultLocation || ""}
              setValue={(value) => {
                if (value === "add") {
                  globalNavigate("/log-contacts/saved-locations");
                } else {
                  setFormValue("defaultLocation", value);
                  if (setLogbookAdiLocation) {
                    setLogbookAdiLocation(
                      savedLocations.find(
                        (location: SavedLocation) => location.id === value
                      ) || null
                    );
                  }
                }
              }}
              className={`"w-[99.9%] sm:w-[99.9%] md:w-[99.9%]"`}
            />
          ) : null}

          {locationOption === 2 ? (
            <>
              {canEdit ? (
                <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px] w-[80%]">
                  <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
                    <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                      <div className="flex flex-row items-center gap-1">
                        <div>
                          {locationForm?.locationCountry ? (
                            <ReactCountryFlag
                              countryCode={
                                findCountryCode({
                                  name: locationForm?.locationCountry || "",
                                })?.alpha2
                              }
                              svg
                              style={{
                                width: "3em",
                                height: "1.2em",
                              }}
                              title={locationForm?.locationCountry || ""}
                            />
                          ) : null}
                        </div>
                        <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                          <div className="font-bold">Country:</div>
                          <div className=" max-w-[100px]">
                            {locationForm?.locationCountry}
                          </div>
                        </div>
                      </div>
                      <VerticalDivider />
                      <div className="flex flex-row items-center gap-3">
                        <div>
                          <img src={Icons.MapIcon} alt="" />
                        </div>
                        <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                          <div className="font-bold">State:</div>
                          <div>{locationForm?.locationState}</div>
                        </div>
                      </div>
                      <VerticalDivider />
                      <div className="flex flex-row items-center gap-3">
                        <div>
                          <img src={Icons.GridsIcon} alt="" />
                        </div>
                        <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                          <div className="font-bold">Grid:</div>
                          <div>{locationForm?.locationGrid}</div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <VerticalDivider />
                      <img
                        className="cursor-pointer"
                        src={Icons.EditPenIcon}
                        alt=""
                        onClick={onEditClickHandler}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`flex flex-col w-[${importUI ? "100" : "80"}%]`}
                >
                  <div className="flex flex-row text-white px-3 py-1 bg-[#0D1E5D] gap-6  overflow-x-auto whitespace-nowrap">
                    <div
                      className="flex flex-row gap-2 cursor-pointer"
                      onClick={() => findLocation()}
                    >
                      <img src={Icons.MyLocation} alt="" />
                      <div className="text-sm font-Play">Find My Location</div>
                    </div>

                    <div className="flex flex-row gap-2">
                      <img src={Icons.MapCyan} alt="" />
                      <div className="text-sm font-Play">Set on Map</div>
                    </div>

                    {Object.values(selectedLogbook?.myParks || {}).map(
                      (park, index) => (
                        <div
                          className="flex flex-row gap-2 cursor-pointer"
                          key={index}
                          onClick={() => setLocationFromPark(park)}
                        >
                          <img src={Icons.ParkCyan} alt="" />
                          <div className="text-sm font-Play">
                            {park?.reference}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px] w-full">
                    <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
                      <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                        <div className="flex flex-col lg:flex-row items-center gap-1">
                          <TitledDefaultCountryPicker
                            setText={(val) => {
                              setLocationForm((prev: any) => ({
                                ...prev,
                                locationCountry: val,
                              }));
                              setFormValue(
                                "locationSource",
                                LocationSource.CUSTOM
                              );
                            }}
                            name="country"
                            title="Country"
                            placeHolder="Country"
                            value={
                              LocationMappings[
                                `${locationForm?.locationCountry}`
                              ] || locationForm?.locationCountry
                            }
                            className={"bg-white bg-opacity-10 h-[36px]"}
                            tabindex={8}
                            width="w-[80%] lg:w-[22%]"
                            error={undefined}
                            containerClass="mb-[20px]"
                          />
                          {locationForm?.locationCountry ? (
                            <TitledDefaultRegionPicker
                              setText={(val) => {
                                setLocationForm((prev: any) => ({
                                  ...prev,
                                  locationState: val,
                                }));
                                setFormValue(
                                  "locationSource",
                                  LocationSource.CUSTOM
                                );
                              }}
                              name="state"
                              title="State"
                              placeHolder="State"
                              tabindex={9}
                              width="w-[80%] lg:w-[22%]"
                              className={"bg-white bg-opacity-10 h-[36px]"}
                              value={locationForm?.locationState}
                              error={undefined}
                              country={
                                LocationMappings[
                                  `${locationForm?.locationCountry}`
                                ] || locationForm?.locationCountry
                              }
                              containerClass="mb-[20px]"
                            />
                          ) : null}
                          <TitledInput
                            setText={(e) => {
                              const value = e.target.value;
                              setLocationForm((prev: any) => ({
                                ...prev,
                                locationGrid: value,
                              }));
                              setFormValue(
                                "locationSource",
                                LocationSource.CUSTOM
                              );
                            }}
                            name="grid"
                            title="Grid"
                            value={locationForm?.locationGrid}
                            placeHolder="Grid"
                            className="bg-white bg-opacity-10 h-[36px]"
                            width="w-[80%] lg:w-[15%]"
                            // error={
                            //   errors?.band && touched?.band ? errors?.band : undefined
                            // }
                            containerClass=""
                          />
                          <TitledInput
                            setText={(e) => {
                              const value = e.target.value;
                              setLocationForm((prev: any) => ({
                                ...prev,
                                locationLat: value,
                              }));
                              setFormValue(
                                "locationSource",
                                LocationSource.CUSTOM
                              );
                            }}
                            name="latitude"
                            title="Latitude"
                            value={locationForm?.locationLat}
                            placeHolder="latitude"
                            className="bg-white bg-opacity-10 h-[36px]"
                            width="w-[80%] lg:w-[16%]"
                            // error={
                            //   errors?.band && touched?.band ? errors?.band : undefined
                            // }
                            containerClass=""
                          />
                          <TitledInput
                            setText={(e) => {
                              const value = e.target.value;
                              setLocationForm((prev: any) => ({
                                ...prev,
                                locationLong: value,
                              }));
                              setFormValue(
                                "locationSource",
                                LocationSource.CUSTOM
                              );
                            }}
                            name="longitude"
                            title="Longitude"
                            value={locationForm?.locationLong}
                            placeHolder="longitude"
                            className="bg-white bg-opacity-10 h-[36px]"
                            width="w-[80%] lg:w-[16%]"
                            // error={
                            //   errors?.band && touched?.band ? errors?.band : undefined
                            // }
                            containerClass=""
                          />
                        </div>
                        <div className="flex items-center justify-center gap-4">
                          <VerticalDivider />

                          {locationLoading ? (
                            <ActivityIndicator size={16} />
                          ) : (
                            <img
                              className="cursor-pointer"
                              src={Icons.SaveIcon}
                              alt=""
                              onClick={() => {
                                setCanEdit(true);
                                if (setLogbookAdiLocation) {
                                  setLogbookAdiLocation(locationForm);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
