import { TableDropDownItemsProps } from "types/Component";

export const LogBookTableDropDowns: TableDropDownItemsProps[] = [
  {
    text: "Add Contact",
    link: "/log-contacts/add-contact",
  },
  {
    text: "Export ADIF",
  },
  {
    text: "Delete",
    color: "text-red",
  },
];

export const SavedLocationTableDropDowns: TableDropDownItemsProps[] = [
  {
    text: "Edit",
    link: "/log-contacts/add-new-location",
  },
  {
    text: "Delete",
    color: "text-red",
  },
];

export const ADILogBookTableDropDowns: TableDropDownItemsProps[] = [
  {
    text: "Add Contact",
    link: "/log-contacts/add-contact",
  },
  {
    text: "Export ADIF",
  },
  {
    text: "View Import Progress",
  },
  {
    text: "Delete",
    color: "text-red",
  },
];

export const Modes: string[] = [
  "CW",
  "SSB",
  "USB",
  "LSB",
  "DIG",
  "FM",
  "TV",
  "AM",
  "FT4",
  "FT8",
  "D-STAR",
  "Fusion",
  "RTTY",
  "DMR"
];
export const ModesFilter: any[] = [
  { value: "", label: "--" },
  { value: "CW", label: "CW" },
  { value: "SSB", label: "SSB" },
  { value: "USB", label: "USB" },
  { value: "LSB", label: "LSB" },
  { value: "DIG", label: "DIG" },
  { value: "FM", label: "FM" },
  { value: "TV", label: "TV" },
  { value: "AM", label: "AM" },
  { value: "FT4", label: "FT4" },
  { value: "FT8", label: "FT8" },
  { value: "D-STAR", label: "D-STAR" },
  { value: "Fusion", label: "Fusion" },
  { value: "RTTY", label: "RTTY" },
];

export const AllBandsOption = [
  { name: "2200", unit:"m", id: 1 },
  { name: "2190", unit:"m", id: 2 },
  { name: "630", unit:"m", id: 3 },
  { name: "560", unit:"m", id: 4 },
  { name: "160", unit:"m", id: 5 },
  { name: "80", unit:"m", id: 6 },
  { name: "60", unit:"m", id: 7 },
  { name: "40", unit:"m", id: 8 },
  { name: "30", unit:"m", id: 9 },
  { name: "20", unit:"m", id: 10 },
  { name: "17", unit:"m", id: 11 },
  { name: "15", unit:"m", id: 12 },
  { name: "12", unit:"m", id: 13 },
  { name: "10", unit:"m", id: 14 },
  { name: "6", unit:"m", id: 15 },
  { name: "4", unit:"m", id: 16 },
  { name: "2", unit:"m", id: 17 },
  { name: "1.25", unit:"m", id: 18 },
  { name: "0.7", unit:"cm", id: 19 },
  { name: "0.33", unit:"cm", id: 20 },
  { name: "0.23", unit:"cm", id: 21 },
  { name: "0.13", unit:"cm", id: 22 },
  { name: "0.09", unit:"cm", id: 23 },
  { name: "0.06", unit:"cm", id: 24 },
  { name: "0.03", unit:"cm", id: 25 },
  { name: "0.0125", unit:"cm", id: 26 },
  { name: "0.006", unit:"mm", id: 27 },
  { name: "0.004", unit:"mm", id: 28 },
  { name: "0.0025", unit:"mm", id: 29 },
  { name: "0.002", unit:"mm", id: 30 },
  { name: "0.001", unit:"mm", id: 31 },
];

export const AllModesOption = [
  { name: "CW", id: 1 },
  { name: "SSB", id: 2 },
  { name: "USB", id: 3 },
  { name: "LSB", id: 4 },
  { name: "DIG", id: 5 },
  { name: "FM", id: 6 },
  { name: "TV", id: 7 },
  { name: "AM", id: 8 },
  { name: "FT4", id: 9 },
  { name: "FT8", id: 10 },
  { name: "D-STAR", id: 11 },
  { name: "Fusion", id: 12 },
];

export const ModesChartColor: string[] = [
  "#CCFF00", // Bright Lime
  "#00FFA3", // Aquamarine
  "#00C2FF", // Light Blue
  "#F812A6", // Magenta
  "#597BF5", // Periwinkle Blue (5th)
  "#5e3d9c", // Light Steel Blue (New color for 6th)
  "#B682A9", // Light Mauve
  "#a68732", // Bronze
  "#00C2FF", // Light Blue (repeated)
];

export const AdifCountries = [
  "CANADA",
  "ABU AIL IS",
  "AFGHANISTAN",
  "AGALEGA & ST BRANDON",
  "ALAND IS",
  "ALASKA",
  "ALBANIA",
  "ALDABRA",
  "AMERICAN SAMOA",
  "AMSTERDAM & ST PAUL",
  "ANDAMAN & NICOBAR IS",
  "ANGUILLA",
  "ANTARCTICA",
  "ARMENIA",
  "ASIATIC RUSSIA",
  "AUCKLAND & CAMPBELL",
  "AVES ISLAND",
  "AZERBAIJAN",
  "BAJO NUEVO",
  "BAKER, HOWLAND IS",
  "BALEARIC IS",
  "BELAU (T8)",
  "BLENHEIM REEF",
  "BOUVET",
  "BRITISH N. BORENO",
  "BRITISH SOMALI",
  "BELARUS",
  "CANAL ZONE",
  "CANARY IS",
  "CELEBE/MOLUCCA IS",
  "C KIRIBATI",
  "CEUTA & MELILLA",
  "CHAGOS",
  "CHATHAM IS",
  "CHRISTMAS IS",
  "CLIPPERTON IS",
  "COCOS ISLAND",
  "COCOS-KEELING IS",
  "COMOROS (FB8)",
  "CRETE",
  "CROZET",
  "DAMAO, DUI",
  "DESECHEO IS",
  "DESROCHES",
  "DODECANESE",
  "EAST MALAYSIA",
  "EASTER IS",
  "EASTERN KIRIBATI",
  "EQUATORIAL GUINEA",
  "MEXICO",
  "ERITREA",
  "ESTONIA",
  "ETHIOPIA",
  "EUROPEAN RUSSIA",
  "FARQUHAR",
  "FERNANDO DE NORONHA",
  "FRENCH EQ. AFRICA",
  "FRENCH INDO-CHINA",
  "FRENCH WEST AFRICA",
  "BAHAMAS",
  "FRANZ JOSEF LAND",
  "BARBADOS",
  "FRENCH GUIANA",
  "BERMUDA",
  "BRITISH VIRGIN IS",
  "BELIZE",
  "FRENCH INDIA",
  "SAUDI/KUWAIT N.Z.",
  "CAYMAN ISLANDS",
  "CUBA ADIF:CM",
  "GALAPAGOS",
  "DOMINICAN REPUBLIC",
  "EL SALVADOR",
  "GEORGIA",
  "GUATEMALA",
  "GRENADA",
  "HAITI",
  "GUADELOUPE",
  "HONDURAS",
  "GERMANY",
  "JAMAICA",
  "MARTINIQUE",
  "BONAIRE,CURACAO",
  "NICARAGUA",
  "PANAMA",
  "TURKS & CAICOS IS",
  "TRINIDAD & TOBAGO",
  "ARUBA",
  "GEYSER REEF",
  "ANTIGUA & BARBUDA",
  "DOMINICA",
  "MONTSERRAT",
  "ST LUCIA",
  "ST VINCENT",
  "GLORIOSO IS",
  "ARGENTINA",
  "GOA",
  "GOLD COAST/TOGOLND",
  "GUAM",
  "BOLIVIA",
  "GUANTANAMO BAY",
  "GUERNSEY",
  "REPUBLIC OF GUINEA",
  "BRAZIL",
  "GUINEA-BISSAU",
  "HAWAII",
  "HEARD IS",
  "CHILE",
  "IFNI",
  "ISLE OF MAN",
  "ITALIAN SOMALI",
  "COLOMBIA",
  "ITU GENEVA",
  "JAN MAYEN",
  "JAVA",
  "ECUADOR",
  "JERSEY",
  "JOHNSTON IS",
  "JUAN DE NOVA",
  "JUAN FERNANDEZ",
  "KALININGRAD",
  "KAMARAN IS",
  "KARELO-FINN REP",
  "GUYANA",
  "KAZAKHSTAN",
  "KERGUELEN",
  "PARAGUAY",
  "KERMADEC",
  "KINGMAN REEF",
  "KYRGYZSTAN",
  "PERU",
  "SOUTH KOREA",
  "KURE ISLAND",
  "KURIA MURIA IS",
  "SURINAME",
  "FALKLAND IS",
  "LACCADIVE ISLANDS",
  "LAOS",
  "URUGUAY",
  "LATVIA",
  "LITHUANIA",
  "LORD HOWE IS",
  "VENEZUELA",
  "AZORES",
  "AUSTRALIA",
  "MALYJ VYSOTSKI IS (R1MV)",
  "MACAO",
  "MACQUARIE IS",
  "YEMEN ARAB REP",
  "MALAYA",
  "NAURU",
  "VANUATU",
  "MALDIVE IS",
  "TONGA",
  "MALPELO IS",
  "NEW CALEDONIA",
  "PAPUA NEW GUINEA",
  "MANCHURIA",
  "MAURITIUS IS",
  "MARIANA IS",
  "MARKET REEF",
  "MARSHALL IS",
  "MAYOTTE",
  "NEW ZEALAND",
  "MELLISH REEF",
  "PITCAIRN IS",
  "MICRONESIA",
  "MIDWAY IS",
  "FRENCH POLYNESIA",
  "FIJI",
  "MINAMI TORISHIMA",
  "MINERVA REEF",
  "MOLDAVIA",
  "MT ATHOS (SY)",
  "MOZAMBIQUE",
  "NAVASSA IS",
  "DUTCH BORNEO",
  "NETHER N. GUNIEA",
  "SOLOMON ISLANDS",
  "NEWFOUNDLAND/LAB",
  "NIGER",
  "NIUE",
  "NORFOLK IS",
  "WESTERN SAMOA",
  "N COOK IS",
  "OGASAWARA",
  "OKINAWA",
  "OKINO TORI-SHIMA",
  "PAGALU (ANNOBAR IS)",
  "PALESTINE",
  "PALMYRA & JARVIS IS",
  "PAPUA TERR",
  "PETER I IS",
  "PORTUGUESE TIMOR",
  "PRINCE EDWARD & MARION",
  "PUERTO RICO",
  "ANDORRA",
  "REVILLA GIGEDO",
  "ASCENSION ISLAND",
  "AUSTRIA",
  "RODRIGUEZ IS",
  "RUANDA-URUNDI",
  "BELGIUM",
  "SAINT MARTIN (FJ)",
  "CORSICA",
  "CYPRUS",
  "SAN ANDREAS & PROVIDENCIA",
  "SAN FELIX",
  "CZECHOSLOVAKIA",
  "SAO TOME & PRINCIPE",
  "SARAWAK",
  "DENMARK",
  "FAROE IS",
  "ENGLAND",
  "FINLAND",
  "SARDINIA",
  "SAUDI/IRAQ N.Z.",
  "FRANCE",
  "SERRANA BANK",
  "GERMAN DEM. REP.",
  "FED REP OF GERMANY",
  "SIKKIM",
  "SOMALI",
  "GIBRALTAR",
  "S COOK IS",
  "SOUTH GEORGIA IS",
  "GREECE",
  "GREENLAND",
  "SOUTH ORKNEY IS",
  "HUNGARY",
  "SOUTH SANDWICH ISLANDS",
  "SOUTH SHETLAND ISLANDS ",
  "ICELAND",
  "DEM REP OF YEMEN",
  "SOUTHERN SUDAN",
  "IRELAND",
  "MALTA, SOVERIGN",
  "SPRATLY IS",
  "ITALY",
  "ST KITTS & NEVIS",
  "ST HELENA IS",
  "LIECHTENSTEIN",
  "ST PAUL ISLAND",
  "ST PETER & ST PAUL RKS",
  "LUXEMBOURG",
  "ST MAARTEN",
  "MADEIRA IS",
  "MALTA",
  "SUMATRA",
  "SVALBARD IS",
  "MONACO",
  "SWAN ISLAND",
  "TADZHIKISTAN",
  "NETHERLANDS",
  "TANGIER",
  "NORTHERN IRELAND",
  "NORWAY",
  "TERR NEW GUINEA",
  "TIBET",
  "POLAND",
  "TOKELAU IS",
  "TRIESTE",
  "PORTUGAL",
  "TRINDADE & MARTIN",
  "TRISTAN DA CUNHA",
  "ROMANIA",
  "TROMELIN",
  "ST PIERRE & MIQUELON",
  "SAN MARINO",
  "SCOTLAND",
  "TURKMENISTAN",
  "SPAIN",
  "TUVALU",
  "UK BASES ON CYPRUS",
  "SWEDEN",
  "VIRGIN IS",
  "UGANDA",
  "SWITZERLAND",
  "UKRAINE",
  "HQ UNITED NATIONS",
  "UNITED STATES",
  "UZBEKISTAN",
  "VIETNAM",
  "WALES",
  "VATICAN",
  "YUGOSLAVIA",
  "WAKE IS",
  "WALLIS & FUTUNA",
  "WEST MALAYSIA",
  "W KIRIBATI",
  "WESTERN SAHARA",
  "WILLIS IS",
  "BAHRAIN",
  "BANGLADESH ",
  "BHUTAN",
  "ZANZIBAR",
  "COSTA RICA",
  "MYANMAR",
  "KAMPUCHEA (CAMBODIA) ",
  "SRI LANKA",
  "CHINA",
  "HONG KONG",
  "INDIA",
  "INDONESIA",
  "IRAN",
  "IRAQ",
  "ISRAEL",
  "JAPAN",
  "JORDAN",
  "BRUNEI",
  "KUWAIT",
  "LEBANON",
  "MONGOLIA",
  "NEPAL",
  "OMAN",
  "PAKISTAN",
  "PHILIPPINES",
  "QATAR",
  "SAUDI ARABIA",
  "SEYCHELLES",
  "SINGAPORE",
  "DJIBOUTI",
  "SYRIA",
  "TAIWAN",
  "THAILAND ",
  "TURKEY",
  "UNITED ARAB EMIRATES",
  "ALGERIA",
  "ANGOLA",
  "BOTSWANA ",
  "BURUNDI",
  "CAMEROON ",
  "CENTRAL AFRICAN ",
  "CAPE VERDE",
  "CHAD",
  "COMOROS (D6)",
  "CONGO",
  "ZAIRE",
  "BENIN",
  "GABON",
  "THE GAMBIA ",
  "GHANA",
  "IVORY COAST ",
  "KENYA",
  "LESOTHO",
  "NORTH KOREA (HP)",
  "LIBERIA",
  "LIBYA",
  "MADAGASCAR ",
  "MALAWI",
  "MALI",
  "MAURITANIA ",
  "MOROCCO",
  "NIGERIA",
  "ZIMBABWE ",
  "REUNION",
  "RWANDA",
  "SENEGAL",
  "SIERRA LEONE",
  "ROTUMA IS",
  "SOUTH AFRICA ",
  "NAMIBIA",
  "SUDAN",
  "SWAZILAND ",
  "TANZANIA",
  "TUNISIA",
  "EGYPT",
  "BURKINA-FASO ",
  "ZAMBIA",
  "TOGO",
  "WALVIS BAY",
  "CONWAY REEF",
  "BANABA ISLAND",
  "YEMEN",
  "PENGUIN ISLANDS",
  "CROATIA",
  "SLOVENIA ",
  "BOSNIA-HERZEGOVINA",
  "MACEDONIA",
  "CZECH REPUBLIC ",
  "SLOVAK REPUBLIC",
  "PRATAS IS",
  "SCARBOROUGH REEF",
  "TEMOTU IS",
  "AUSTRAL IS",
  "MARQUESAS IS",
  "PALESTINE (E4)",
  "Ducie Is (VP6) ",
  "Chesterfield Is (TX0)",
  "East Timor (4W)",
];
