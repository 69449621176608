import { MultiSelectDropDownProps } from "types/Component";
import Multiselect from "multiselect-react-dropdown";

function MultiSelectDropDown({
  options,
  value,
  onSelectValue,
  onRemoveValue,
  placeholder,
  className,
  width,
}: MultiSelectDropDownProps) {
  return (
    <Multiselect
      options={options}
      selectedValues={value}
      onSelect={(selectedList, selectedItem) => {
        onSelectValue(selectedList);
      }}
      onRemove={(selectedList, selectedItem) => {
        onRemoveValue(selectedList);
      }}
      placeholder={placeholder}
      displayValue="name"
      className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 ml-3 mb-2 border-0 ${
        className ? className : ""
      }`}
      style={{
        multiselectContainer: {
          background: "#23234C",
          border: "none",
          width: width || "210px",
        },
        optionContainer: {
          border: "none",
          background: "#23234C",
          margin: 5,
        },
        searchBox: {
          border: "none",
        },
        chips: {
          background: "#0243DD",
        },
      }}
    />
  );
}

export default MultiSelectDropDown;
