import { useField } from "formik";
import { useState } from "react";
import { RegionDropdown } from "react-country-region-selector";
import { RiInformationLine } from "react-icons/ri";
import { TitledRegionPickerProps } from "types/Component";

function TitledDefaultRegionPicker({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  title,
  error,
  props,
  value,
  tabindex,
  country,
  info,
  containerClass,
  disabled
}: TitledRegionPickerProps) {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div
      className={`${width ? width : "w-full"} ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="flex items-center">
        <label className="font-[Barlow] font-bold text-sm text-white truncate">
          {title}
        </label>

        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      <RegionDropdown
        name={name}
        country={country ? country : ""}
        value={value ? value : ""}
        onChange={(txt) => setText(txt)}
        classes={`h-8 bg-[#23234C] px-3 text-sm font-[Barlow] text-white mt-1.5 w-full ${
          className ? className : ""
        } ${error ? "border border-orange-700 border-solid" : ""}`}
        disabled={disabled ? disabled : false}
      />
      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default TitledDefaultRegionPicker;
