import { IoCreateOutline, IoEyeOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { CONTACT, MY_PROFILE_ROUTE } from "constants/Links";
import { TableEntColProps } from "types/Component";
import { globalNavigate } from "routes/GlobalRoutes";
import useLogBook from "hooks/logBook.hook";
import useConfig from "hooks/config.hook";
import { useEffect } from "react";
import CustomValidation from "helpers/Validation";
import Icons from "assets/Icons";
import { findCountryCode } from "helpers/Utils";
import ReactCountryFlag from "react-country-flag";
import { setContactDetails } from "store/Actions/logBook.actions";
import { useDispatch } from "react-redux";
import { UserAvatar } from "components/UserAvatar";
import { Templates } from "constants/Config";
import CountryDxccFlag from "components/CountryDxccFlag";

function TableEntCol({
  item,
  className,
  editable,
  missingFields,
  guestUser,
  showExchange,
}: TableEntColProps) {
  const {
    editLogBookContact,
    notes,
    setNotes,
    deleteContactData,
    setEditContactModalData,
  } = useLogBook();
  const { handlePrimaryModal } = useConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    setNotes(item.notes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openEditModal = (value: any) => {
    if (missingFields && !value) {
      setEditContactModalData(item);
    }
  };

  const hasTheirPark =
    typeof item?.theirPark === "object"
      ? Object.keys(item?.theirPark).length > 0
      : item?.theirPark?.length > 0;

  return (
    <tr className={`${className ? className : ""}`}>
      <td className="text-xs text-white font-[Barlow] font-normal py-3">
        <div className="flex">
          <p className="pl-2">
            {item?.time}{" "}
            <span className="text-[#9D9D9D] ml-1">{item?.date}</span>
          </p>
          {item.duplicate && !guestUser ? (
            <div className="bg-[#978a27] px-3 rounded-full font-[Play] ml-1">
              DUPE
            </div>
          ) : null}
        </div>
      </td>
      <td
        className="cursor-pointer"
        onClick={() => {
          window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
            item?.theirCallsign
          )}?tab=Logbook&contact-tab=entries`;
        }}
      >
        <div className="h-full flex justify-center items-center">
          {item?.theirProfilePic ? (
            <img
              className="w-7 h-7 rounded-full object-cover"
              src={item.theirProfilePic}
              alt=""
            />
          ) : (
            <UserAvatar className="w-7 h-7" />
          )}
        </div>
      </td>
      <td
        className="pl-2 py-3 text-white text-xs font-[Barlow] font-normal cursor-pointer"
        onClick={() => {
          if (item.theirCallsign) {
            window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
              item?.theirCallsign
            )}?tab=Logbook&contact-tab=entries`;
          } else {
            if (missingFields && !item.theirCallsign) {
              setEditContactModalData(item);
            }
          }
        }}
      >
        <div className="flex items-center">
          {missingFields && !item.theirCallsign ? (
            <p className=" text-[#A16207]">Click to add</p>
          ) : (
            <p className="mr-2">{item?.theirCallsign}</p>
          )}

          <CountryDxccFlag
            countryCode={item?.theirCountry || item?.country || ""}
            flagCode={item?.flagCode || ""}
          />
          <p className=" text-white ml-2">
            {item?.theirState || item?.state || item?.theirCity}
          </p>
        </div>
      </td>
      {/* <td className="pl-4 text-xs ">
      </td> */}
      <td
        className="pl-4 text-xs text-[#6D6D8D] font-[Barlow] py-3 "
        onClick={() => openEditModal(item?.rstSent)}
      >
        {missingFields && !item.rstSent ? (
          <p className=" text-[#A16207] cursor-pointer">Click to add</p>
        ) : (
          <p className="">{item?.rstSent}</p>
        )}
      </td>
      <td
        className="pl-4 text-xs text-[#6D6D8D] font-[Barlow] py-3 "
        onClick={() => openEditModal(item?.rstRCVD)}
      >
        {missingFields && !item.rstRCVD ? (
          <p className=" text-[#A16207] cursor-pointer">Click to add</p>
        ) : (
          <p className="">{item?.rstRCVD}</p>
        )}
      </td>
      <td
        className="pl-4 text-xs text-white font-[Barlow] py-3 "
        onClick={() => openEditModal(item?.frequency)}
      >
        {missingFields && !item?.frequency ? (
          <p className=" text-[#A16207] cursor-pointer">Click to add</p>
        ) : (
          <p className="">{item?.frequency}</p>
        )}
      </td>
      <td
        className="pl-4 text-xs text-white font-[Barlow] py-3 "
        onClick={() => openEditModal(item?.userMode)}
      >
        {missingFields && !item?.userMode ? (
          <p className=" text-[#A16207] cursor-pointer">Click to add</p>
        ) : (
          <p className="">{item?.userMode}</p>
        )}
      </td>
      <td className="pl-4 text-xs text-white font-[Barlow] py-3 ">
        <p className="">
          {item?.distance
            ? `${item?.distance.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })} KM`
            : "--"}
        </p>
      </td>
      {showExchange ? (
        <>
          <td className="pl-4 text-xs text-white font-[Barlow] py-3 ">
            <p className="">{item?.exchangeOne ? item?.exchangeOne : ""}</p>
          </td>
          <td className="pl-4 text-xs text-white font-[Barlow] py-3 ">
            <p className="">{item?.exchangeTwo ? item?.exchangeTwo : ""}</p>
          </td>
        </>
      ) : null}

      {editable ? (
        <>
          <td className="pl-2 text-xs text-white font-[Barlow] py-3 ">
            <div className="">
              <input
                className="w-full bg-transparent text-white"
                type="text"
                name="notes"
                id="notes"
                value={notes}
                onChange={(e: any) => setNotes(e.target.value)}
                onBlur={(e: any) => {
                  if (item.notes !== e.target.value) {
                    editLogBookContact({ notes: e.target.value }, item);
                  }
                }}
                placeholder="Put your notes here"
              />
            </div>
          </td>
        </>
      ) : null}
      <td className="pl-2 text-xs text-white font-[Barlow] py-3 ">
        {item?.tags?.length &&
        item?.tags?.includes(Templates.POTA) &&
        hasTheirPark ? (
          <img src={Icons.parkToParkWhite} alt="pota icon" />
        ) : item?.tags?.length &&
          item?.tags?.includes(Templates.POTA) &&
          !hasTheirPark ? (
          <img src={Icons.parkWhite} alt="pota icon" />
        ) : null}
      </td>
      <td className="pl-4 text-xs text-white font-[Barlow] py-3 ">
        {item?.confirmed ? (
          <img src={Icons.Confirmed} alt="confirmed icon" />
        ) : (
          <p>--</p>
        )}
      </td>
      <td className="py-3.5 ">
        <div
          className="flex justify-center items-center text-white  cursor-pointer"
          onClick={(e) => {
            dispatch(setContactDetails({}));
            globalNavigate(`${CONTACT}?contactId=${item?.id}`, {
              state: item,
            });
          }}
        >
          <IoEyeOutline size={20} />
        </div>
      </td>
      {editable && !guestUser ? (
        <>
          <td className="py-3.5 ">
            <div
              className="flex justify-center items-center text-white  cursor-pointer ml-3"
              onClick={(e) => {
                // globalNavigate(`${LOG_CONTACT_ROUTE}/edit-contact`, {
                //   state: item,
                // })
                setEditContactModalData(item);
              }}
            >
              {/* <IoSettingsSharp size={20} /> */}
              <IoCreateOutline size={20} />
            </div>
          </td>
          <td className="py-3.5 ">
            <div
              className="flex justify-center items-center text-white  cursor-pointer ml-3"
              onClick={() => {
                handlePrimaryModal({
                  title: "Are you sure you want to delete contact?",
                  type: "Delete Contact",
                  onCancelPressed: () => handlePrimaryModal(null),
                  onSubmitPressed: () => {
                    deleteContactData(item.id, item);
                    handlePrimaryModal(null);
                  },
                  submitText: "Yes",
                  cancelText: "No",
                });
              }}
            >
              {/* <IoSettingsSharp size={20} /> */}
              <AiOutlineDelete size={20} />
            </div>
          </td>
        </>
      ) : (
        <>
          <td></td>
          <td></td>
        </>
      )}
    </tr>
  );
}

export default TableEntCol;
