/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const AddContactValidationSchema = Yup.object({
  theirCallsign: Yup.string()
    .required("Required")
    .matches(/^[^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]+$/),
  time: Yup.string().required("Required"),
  // date: Yup.date().max(new Date().toJSON().slice(0, 10)).required("Required"),
  date: Yup.date().required("Required"),
  operator: Yup.string(),
  qth: Yup.string(),
  rstSent: Yup.string().required("Required"),
  rstRCVD: Yup.string().required("Required"),
  notes: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  grid: Yup.string(),
  frequency: Yup.string(),
  band: Yup.string(),
  userQth: Yup.string(),
  userMode: Yup.string(),
  myCallSign: Yup.string()
    .required("Required")
    .matches(/^[^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]+$/),
  userGrid: Yup.string(),
  power: Yup.string(),
  theirName: Yup.string(),
  myRadio: Yup.string(),
  myAntenna: Yup.string(),
});

const AddPOTAContactValidationSchema = Yup.object({
  theirCallsign: Yup.string()
    .required("Required")
    .matches(/^[^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]+$/),
  time: Yup.string().required("Required"),
  // date: Yup.date().max(new Date().toJSON().slice(0, 10)).required("Required"),
  date: Yup.date().required("Required"),
  operator: Yup.string(),
  qth: Yup.string(),
  rstSent: Yup.string().required("Required"),
  rstRCVD: Yup.string().required("Required"),
  notes: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  grid: Yup.string(),
  frequency: Yup.string(),
  band: Yup.string(),
  userQth: Yup.string(),
  userMode: Yup.string(),
  myCallSign: Yup.string()
    .required("Required")
    .matches(/^[^`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]+$/),
  userGrid: Yup.string(),
  power: Yup.string(),
  theirName: Yup.string(),
  myRadio: Yup.string(),
  myAntenna: Yup.string(),
});

const EditProfileValidationSchema = Yup.object({
  facebook: Yup.string(),
  linkedin: Yup.string(),
  twitter: Yup.string(),
  instagram: Yup.string(),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  callSign: Yup.string().required("Required"),
  country: Yup.string(),
  phoneNumber: Yup.string(),
  bio: Yup.string().required("Required"),
});

const PotaActivationValidationSchema = Yup.object({
  myParks: Yup.object().test(
    'has-properties',
    'At least one park is required',
    (value) => {
      return Object.keys(value).length > 0;
    }
  ).test(
    'max-properties',
    'A maximum of 4 parks are allowed',
    (value) => {
      return Object.keys(value).length <= 4;
    }
  ).required("myParks is required"),
});

export const validateSpecialCharacters = (str: string) => {
  // Create a regular expression that matches all special characters
  const specialCharactersRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>?~]/;

  // Check if the string contains any special characters
  if (str.match(specialCharactersRegex)) {
    // Return false if the string contains any special characters
    return false;
  }

  // Return true if the string does not contain any special characters
  return true;
};

// Encode and decode "/"
const encodeSlash = (str: string) => {
  // Replace all "/" characters with "%2F"
  return str.replace("/", "%2F");
};

const decodeSlash = (str: string) => {
  // Replace all "%2F" characters with "/"
  return str.replace("%2F", "/");
};

const CustomValidation = {
  AddContactValidationSchema: AddContactValidationSchema,
  EditProfileValidationSchema: EditProfileValidationSchema,
  AddPOTAContactValidationSchema: AddPOTAContactValidationSchema,
  validateSpecialCharacters: validateSpecialCharacters,
  encodeSlash: encodeSlash,
  decodeSlash: decodeSlash,
  PotaActivationValidationSchema,
};

export default CustomValidation;
