import { dxccCountryList } from "constants/DXCCCountryList";
import { useMemo, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { RiInformationLine } from "react-icons/ri";
import { TitledCountryPickerProps } from "types/Component";

function TitledDefaultCountryPicker({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  title,
  error,
  props,
  value,
  tabindex,
  info,
  containerClass,
  disabled,
}: TitledCountryPickerProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  const transformData = (data: any) => {
    return data.map((item: any) => ({
      value: item.country,
      label: item.country,
    }));
  };

  const options = useMemo(
    () => transformData(dxccCountryList),
    [dxccCountryList]
  );

  return (
    <div
      className={`${width ? width : "w-full"} relative ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="flex items-center">
        <label className="font-[Barlow] font-bold text-sm text-white truncate">
          {title}
        </label>

        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      <select
        value={value ? value : ""}
        name={name}
        disabled={disabled ? disabled : false}
        className={`h-8 bg-transparent px-3  text-[#999] font-[Barlow] text-sm mt-1.5 w-full ${
          className ? className : ""
        } ${error ? "border border-orange-700 border-solid" : ""}`}
        onChange={(e) => setText(e.target.value)}
      >
        <option value="" label="Select Country" />
        {options.map((item: any, index: number) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>

      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default TitledDefaultCountryPicker;
