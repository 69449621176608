import { db } from "firebase-config";
import { UserProfile } from "types/Models";
import { UserDataTable } from "constants/Collections";
import { collection, getDocs, query, where } from "firebase/firestore";
import { FetchProfileProps } from "types/State";

interface GuestServiceType {
  getUserProfileDataByCallSign(
    payload: FetchProfileProps
  ): Promise<UserProfile | null>;
}

const GuestService: GuestServiceType = {
  getUserProfileDataByCallSign: async (payload) => {
    if (payload) {
      try {
        // console.log("payload:", payload);
        const arr: UserProfile[] = [];
        let q = query(
          collection(db, UserDataTable),
          where("callSign", "==", payload?.callSign.toUpperCase())
        );
        if (payload?.userId) {
          q = query(
            collection(db, UserDataTable),
            where("uid", "==", payload?.userId)
          );
        }
        const querySnapshot = await getDocs(q);
        if (querySnapshot !== null || querySnapshot !== undefined) {
          querySnapshot.forEach((doc) => {
            let data: UserProfile = {
              uid: doc.id,
              ...doc.data(),
            };
            arr.push(data);
          });
          if (arr.length) {
            return arr[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  },
};

export default GuestService;
