import React, { useEffect } from "react";
import Icons from "assets/Icons";
import PricingList from "components/PricingList";
import FoundingMemberCountDown from "components/Time/FoundingMemberCountDown";
import { splitText } from "helpers/Utils";
import useSubscription from "hooks/subscription.hook";

const Pricing = () => {
  const {
    startCheckoutSession,
    subscriptionProducts,
    foundingMemberSpotsLeft,
    fetchSubscriptionProducts,
  } = useSubscription();

  useEffect(() => {
    fetchSubscriptionProducts();
  }, []);

  return (
    <main className="relative w-full h-full">
      <img
        src={Icons.PricingBg}
        className="absolute top-0 left-0 h-full w-full object-cover z-0"
        alt="bg"
      />
      <section className="flex flex-col items-center dashboardWrapper w-full relative px-4 sm:px-8 lg:px-14 overflow-x-hidden">
        {/* <div
          className="absolute top-5 right-5 cursor-pointer"
          onClick={() => globalNavigate(SETTING_ROUTE)}
        >
          <img src={Icons.GlowingClose} alt="close" />
        </div> */}
        {foundingMemberSpotsLeft ? (
          <FoundingMemberCountDown
            foundingMemberSpotsLeft={foundingMemberSpotsLeft}
          />
        ) : null}

        <div className="w-full flex flex-col md:flex-row justify-start md:justify-center pb-20">
          {subscriptionProducts.map((item, index) => {
            return (
              <PricingList
                key={`${index}`}
                icon={item.images[0]}
                month={item.metadata.month as number}
                subscriptionType={item.metadata.name as string}
                benefits={splitText(",", item.description)}
                YearlyPrice={
                  item.prices && item.prices[0] && item.prices[0].unit_amount
                    ? item.prices[0].unit_amount / 100
                    : 0
                }
                btnTxt={"Subscribe"}
                priceId={item.prices && item.prices[0] && item.prices[0].id}
                onPress={startCheckoutSession}
              />
            );
          })}
        </div>
      </section>
    </main>
  );
};

export default Pricing;
