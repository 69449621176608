import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSpot } from 'store/Actions/spotting.action';
import { SortConfig } from 'types/Models';
import { SpotFilters } from 'types/Models';

export const useSortAndFilter = (initialSortConfig: SortConfig, initialFilters: SpotFilters[] = []) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>(initialSortConfig);
  const [filters, setFilters] = useState<SpotFilters[]>(initialFilters);
  const sortConfigRef = useRef(sortConfig);
  const filtersRef = useRef(filters);
  const dispatch = useDispatch();

  useEffect(() => {
    sortConfigRef.current = sortConfig;
    filtersRef.current = filters;
    applyChanges();
  }, [sortConfig, filters]);

  const applyChanges = (filters?: SpotFilters[]) => {
    if (sortConfigRef.current?.column && sortConfigRef.current?.order) {
      dispatch(fetchSpot(filters || filtersRef.current || [], undefined, sortConfigRef.current));
    } else {
      dispatch(fetchSpot(filters || filtersRef.current || []));
    }
  }

  return { sortConfig, setSortConfig, filters, setFilters, applyChanges };
};