import { db, auth } from "firebase-config";
import { UserNotification } from "types/Models";
import { UserNotification as UserNotificationCollection } from "constants/Collections";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";

interface NotificationServiceType {
  getUserNotification(): Promise<UserNotification[] | null>;
  updateUserNotification(data: UserNotification): void;
  fetchUserNormalNotification(): Promise<UserNotification[]>;
  markReadUserNormalNotifications(): Promise<boolean>;
}

const NotificationService: NotificationServiceType = {
  getUserNotification: async () => {
    const uid: string | undefined = auth.currentUser?.uid;
    if (uid) {
      try {
        const q = query(
          collection(db, UserNotificationCollection),
          where("seen", "==", false),
          where("uid", "==", uid),
          orderBy("timestamp", "desc"),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        return data as unknown as UserNotification[];
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  },
  updateUserNotification: async (data) => {
    try {
      const notificationRef = doc(db, UserNotificationCollection, data.id);
      await setDoc(notificationRef, data, { merge: true });
    } catch (e) {
      return null;
    }
  },
  fetchUserNormalNotification: async () => {
    const uid: string | undefined = auth.currentUser?.uid;
    if (uid) {
      try {
        const q = query(
          collection(db, UserNotificationCollection),
          where("uid", "==", uid),
          orderBy("timestamp", "desc"),
          limit(100)
        );
        const querySnapshot = await getDocs(q);
        const data: any[] = querySnapshot.docs.map((doc) => {
          const notificationData = doc.data();

          if (notificationData?.timestamp?.seconds){
            notificationData.timestamp = notificationData?.timestamp?.seconds * 1000;
          }

          return {
            id: doc.id,
            ...notificationData,
          };
        });

        data.sort((a, b) => {
          return b.timestamp - a.timestamp;
        } );

        return data as unknown as UserNotification[];
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  },
  markReadUserNormalNotifications: async () => {
    const uid: string | undefined = auth.currentUser?.uid;
    if (uid) {
      try {
        const q = query(
          collection(db, UserNotificationCollection),
          where("uid", "==", uid),
          where("seen", "==", false)
        );
        const querySnapshot = await getDocs(q);
        const batch = writeBatch(db);

        querySnapshot.forEach((docData) => {
          const notificationRef = doc(
            db,
            UserNotificationCollection,
            docData.id
          );
          batch.set(notificationRef, { seen: true }, { merge: true });
        });
        await batch.commit();
        return true;
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  },
};

export default NotificationService;
