import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import { PricingListProps } from "types/Component";

const PricingList = ({
  icon,
  subscriptionType,
  benefits,
  YearlyPrice,
  btnTxt,
  priceId,
  onPress,
  month,
}: PricingListProps) => {
  return (
    <div className="text-white h-fit relative w-[90%] mb-24 md:mb-0 md:w-[300px] flex flex-col items-center px-[20px] sm:px-[60px] md:px-[30px] lg:px-[25px] xl:px-[40px] glassmorphism mx-3 pb-24">
      <img
        className="absolute w-full top-[-5px] left-0 right-0 min-h-[200px]"
        src={Icons.SubCompBg}
        alt="subscription"
      />
      <img
        className="w-[85%] relative mt-[40px] min-h-[80px]"
        src={icon}
        alt=""
      />
      <p className="text-white font-[Play] text-[30px] xl:text-[36px] text-center font-bold mt-7">
        {subscriptionType}
      </p>
      <div className="w-full">
        {benefits.map((item, index) => {
          return (
            <div className="flex my-1" key={index}>
              <div>
                <BiCheckCircle size={25} className="mt-0.5" />
              </div>
              <p className="font-semibold font-[Barlow] text-white text-base xl:text-lg ml-2">
                {item}
              </p>
            </div>
          );
        })}
        <div className="font-[Barlow] text-xl font-semibold text-center flex items-center justify-center mt-10">
          $ <span className="text-2xl mx-1">{month}</span>{" "}
          <span className="font-normal">/ month</span>
        </div>

        <p className="text-xs text-center w-full mt-3">
          ${YearlyPrice} billed annually
        </p>
      </div>
      <div className="w-[70%] absolute top-[calc(100%-30px)]">
        <BorderButtonSecondary
          text={btnTxt}
          onClick={() => onPress(priceId)}
          height="h-[50px]"
          childHeight="h-[47px]"
        />
      </div>
    </div>
  );
};

export default PricingList;
