import { deleteAccount } from "firebase-config";
import useAuth from "./auth.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import { useState } from "react";

export const useAccount = () => {
  const { logOut } = useAuth();

  const deleteUserAccount = (userId: string) => {
    try {
      deleteAccount({
        userId,
        env: process.env.NODE_ENV,
      });
      logOut();
      setTimeout(() => {
        globalNavigate("/");
      }, 200);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    deleteUserAccount,
  };
};
