import { TextInputProps } from "types/Component";

function TextInput({
  placeHolder,
  name,
  type,
  text,
  setText,
  error,
  className,
  multiple,
}: TextInputProps) {
  if (!multiple) {
    return (
      <input
        className={`w-full h-10 bg-transparent border border-white border-solid px-3 text-white ${
          error ? "border-red" : ""
        }${className ? className : "mb-5"}`}
        placeholder={placeHolder}
        value={text}
        onChange={(e: any) => setText(e?.target?.value)}
        type={type ? type : "text"}
        name={name}
        id=""
      />
    );
  } else {
    return (
      <textarea
        className={`w-full h-min-10 bg-transparent border border-white border-solid py-2 px-3 text-white ${
          className ? className : "mb-5"
        }`}
        value={text}
        placeholder={placeHolder}
        onChange={(e: any) => setText(e?.target?.value)}
        rows={5}
        cols={50}
        name={name}
        id="multiLineInput"
      ></textarea>
    );
  }
}

export default TextInput;
