import React from 'react';

const VerticalDivider = () => {
  return <div className="h-full border-l border-white border-opacity-20"></div>;
};

const HorizontalDivider = () => {
  return <div className="w-full border-t border-white border-opacity-20"></div>;
};

export { VerticalDivider, HorizontalDivider };
