import { getRandomAvatar } from "helpers/Utils";
import useProfile from "hooks/profile.hook";
import React, { useEffect, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";

interface UserAvatarProps {
  className?: string;
  isCurrentUser?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  className,
  isCurrentUser = false
}) => {
  const { userAvatar, setUserAvatar } = useProfile();
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    if (isCurrentUser){
      if (!userAvatar) {
        setUserAvatar(getRandomAvatar());
      }else if(userAvatar && isCurrentUser) {
        setImageSrc(userAvatar);
      }
    } else {
      setImageSrc(getRandomAvatar());
    }
  }, [userAvatar]);


  if (!imageSrc) return (<BsPersonCircle className={className} color="#fff" />)

  return (
    <img
      src={ imageSrc }
      alt="User Avatar"
      className={`rounded-full object-cover ${className}`}
    />
  );
};
