import Icons from "assets/Icons"
import BorderButtonSecondary from "components/Button/BorderButtonSecondary"
import { globalNavigate } from "routes/GlobalRoutes"

export const NewGeneralLogBook = ()=>{
    return(
        <BorderButtonSecondary
        text={`New General Logbook`}
        onClick={() => globalNavigate("/log-contacts/add-logbook")}
        className="sm:mb-0"
        icon={<img className="h-4 w-5 mr-2" alt="general logbook" src={Icons.Log} />}
      />
    )
}