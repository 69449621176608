import { CountryDropdown } from "react-country-region-selector";
import { CountyPickerWithLabelProps } from "types/Component";
import Select from "react-select";
import { useMemo } from "react";
import { dxccCountryList } from "constants/DXCCCountryList";

function CountryPicker({
  multiline,
  label,
  placeholder,
  name,
  setText,
  error,
  value,
  className,
}: CountyPickerWithLabelProps) {
  const transformData = (data: any) => {
    return data.map((item: any) => ({
      value: item.flagCode,
      label: item.country,
    }));
  };

  const options = useMemo(
    () => transformData(dxccCountryList),
    [dxccCountryList]
  );
  return (
    <div className="relative">
      {value ? null : (
        <span className="absolute top-[0.7rem] left-3 text-[#999]">
          {label}
        </span>
      )}

      <Select
        onChange={(option) => {
          setText(option?.label);
        }}
        placeholder={"Select Country"}
        aria-placeholder="Select Country"
        options={options}
        value={{
          label: value?.country,
          value: value?.countryCode,
        }}
        isSearchable={true}
        styles={{
          control: (provided) => ({
            ...provided,
            minHeight: "2.5rem",
            marginBottom: "1rem",
            backgroundColor: "transparent",
            marginTop: "0.375rem",
            height: "2.5rem",
            borderRadius: 0,
            borderColor: error ? "rgb(194 65 12)" : "#fff",
            "&:hover": {
              borderColor: "#888",
            },
            borderWidth: 1,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#6c757d" : "#00022F",
            color: state.isSelected ? "#fff" : "#fff",
            borderWidth: 1,
            borderColor: "#23234C",
            "&:hover": {
              backgroundColor: "#6c757d",
              color: "#fff",
            },
            margin: 0,
            padding: "8px 12px",
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: "#f5f5f5",
            marginTop: 0,
            paddingTop: 0,
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#fff",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#fff",
          }),
          input: (provided) => ({
            ...provided,
            color: "#fff", // Change the text color of the search input
          }),
        }}
      />
    </div>
  );
}

export default CountryPicker;
