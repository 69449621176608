import Icons from "assets/Icons";
import { RiFacebookFill } from "react-icons/ri";
import { SiLinkedin } from "react-icons/si";
import { PiInstagramLogoFill } from "react-icons/pi";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import TextDesc from "components/Text/TextDesc";
import { globalNavigate } from "routes/GlobalRoutes";
import { EDIT_PROFILE, MY_PROFILE_ROUTE, SETTING_ROUTE } from "constants/Links";
import useProfile from "hooks/profile.hook";
import ReactCountryFlag from "react-country-flag";
import { openInNewTab, findCountryCode } from "helpers/Utils";
import { useEffect, useState } from "react";
import RouteLoading from "components/Loading/RouteLoading";
import TabNavGlow from "components/TabNavGlow";
import AwardList from "components/Award/AwardList";
import CustomBorderButton from "components/Button/CustomBorderButton";
import useConfig from "hooks/config.hook";
import { storeUserProfileDataByCallSign } from "store/Actions/guest.action";
import { useDispatch } from "react-redux";
import { UserAwards } from "types/Models";
import UserProfileLogBook from "components/UserProfileLogBook";
import { useSearchParams } from "react-router-dom";
import {
  setShowAccountDeleteModal,
  setUserAdminControl,
} from "store/Actions/profile.actions";
import AccessControl from "components/AccessControl";
import { PERMISSIONS } from "constants/Permissions";
import { getAllUserContactsWithPagination } from "store/Actions/logBook.actions";
import { profileTabs } from "constants/NavMenus";
import { UserAvatar } from "components/UserAvatar";
import { CallsignProfile } from "./CallsignProfile";
import CustomValidation from "helpers/Validation";
import CountryDxccFlag from "components/CountryDxccFlag";

export const WrlProfile = () => {
  const {
    userProfile,
    userData,
    setUserData,
    callSignFromParam,
    handleFetchDataByCallSign,
    isFetching,
    guestUserProfile,
    memberStatus,
    setSelectedIndex,
    selectedIndex,
    userAwards,
    userAwardsLoading,
    selectedAward,
    setSelectedAward,
    subButtonClick,
    setGuestMemberStatus,
    guestMemberStatus,
    location,
    setFeaturedStatusForAward,
    featuredAwards,
    setFeaturedAwards,
    setGuestUserProfile,
  } = useProfile();

  let [searchParams, setSearchParams] = useSearchParams();

  const [optionsDropdown, setOptionsDropdown] = useState(false);

  const [headerDropDownNavMenu, setHeaderDropDownNavMenu] = useState([
    {
      title: "Update Operating Locations",
      link: "/log-contacts/saved-locations",
    },
    {
      title: "Edit Profile",
      link: `${MY_PROFILE_ROUTE}/${EDIT_PROFILE}`,
    },
    {
      title: "Manage Subscription",
      link: SETTING_ROUTE,
    },
    {
      title: "Delete My Account",
    },
  ]);

  const dispatch = useDispatch();

  const { getSubscriptionLevelButtonColor, getSubscriptionLevelButtonBgColor } =
    useConfig();

  useEffect(() => {
    const filteredFeaturedAwards = userAwards.filter(
      (prev: UserAwards) => prev?.featured === true
    );
    setFeaturedAwards(filteredFeaturedAwards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAwards]);

  useEffect(() => {
    setSelectedIndex(
      searchParams.get("tab") ? searchParams.get("tab") : "Logbook"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams((prev: any) => {
        return {
          ...prev,
          tab: "Logbook",
        };
      });
    }
    dispatch(storeUserProfileDataByCallSign(null));
    handleFetchDataByCallSign();

    //reset guest user profile when unmouting
    return () => {
      setGuestUserProfile(null);
    };
  }, []);

  useEffect(() => {
    if (
      guestUserProfile &&
      !isFetching &&
      userProfile?.callSign !== callSignFromParam &&
      !location.state?.uid
    ) {
      setUserData(guestUserProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestUserProfile]);

  useEffect(() => {
    if (userData?.callSign?.trim() === callSignFromParam) {
      if (userData?.membershipStatus) {
        if (userData?.membershipStatus === "basic") {
          setGuestMemberStatus("MEMBER");
        } else {
          setGuestMemberStatus(userData?.membershipStatus.toLocaleUpperCase());
        }
      } else {
        setGuestMemberStatus("FREE");
      }
    }

    if (userData?.uid) {
      // getAllUserContactsData(userData.uid);
      dispatch(getAllUserContactsWithPagination(userData.uid));
    }

    // console.log("userData", userData);
    if (location?.state?.uid) {
      // getAllUserContactsData(location?.state?.uid);
      dispatch(getAllUserContactsWithPagination(location?.state?.uid));
    } else if (userData?.uid) {
      // getAllUserContactsData(userData.uid);
      dispatch(getAllUserContactsWithPagination(userData.uid));
    } else if (location?.state) {
      // getAllUserContactsData(location?.state);
      dispatch(getAllUserContactsWithPagination(location?.state));
    }
  }, [userData]);

  if (isFetching) return <RouteLoading noBg />;

  if (!userData && !isFetching) {
    // setProfileMode(ProfileModes.CALLSIGN);
    return <CallsignProfile />;
  }

  const handleDropdownButton = (item: any) => {
    if (item.link) {
      globalNavigate(item.link);
    } else if (item.title === "Delete My Account") {
      dispatch(setShowAccountDeleteModal(true));
    }
  };

  return (
    <div className="px-4 py-8 sm:p-8 lg:p-14 dashboardWrapper overflow-x-hidden">
      <div className="relative rounded-[20px] sm:rounded-[25px] md:rounded-[50px] bg-[#0B0B39] flex flex-col md:flex-row justify-between pl-5 sm:pl-6 pr-5 sm:pr-10">
        {userProfile?.callSign === callSignFromParam ? (
          <div
            className="flex text-white absolute top-6 right-8 cursor-pointer z-10"
            onMouseEnter={() => setOptionsDropdown(true)}
            onMouseLeave={() => setOptionsDropdown(false)}
          >
            <img src={Icons.ListIcon} alt="log" className="w-7 h-7" />

            {optionsDropdown ? (
              <div
                className="w-[15vw] absolute top-[100%] right-0 bg-white"
                // onMouseEnter={() => setUserDropdown(true)}
                // onMouseLeave={() => setUserDropdown(false)}
              >
                {headerDropDownNavMenu.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`border-solid border-white border-[0.5px] pl-3 w-full bg-[#1A1C5F] text-sm font-[Play] py-2 cursor-pointer hover:glassmorphism 
                      ${
                        item.title === "Delete My Account"
                          ? "font-bold text-[#FF3B30]"
                          : "text-white"
                      }`}
                      onClick={() => handleDropdownButton(item)}
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="relative mt-3 flex flex-col items-center">
          <div className="absolute w-full flex justify-center">
            <img
              // className="w-full object-fill md:w-[196.8px] h-[295.2px] lg:w-[246px] lg:h-[369px]"
              className={`w-[170px] object-fill md:w-[196.8px] lg:w-[246px] ${
                userData?.foundingMemberCount
                  ? "h-[330px] lg:h-[311px]"
                  : "h-[251px] lg:h-[298px]"
              }`}
              src={Icons.GradientRectanglePic}
              alt="bg"
            />
          </div>
          {/* <div className="bg-[#161638] h-[272px] w-[217px] lg:h-[340px] lg:w-[272px] rounded-[24px] flex flex-col items-center pt-8 lg:pt-11 relative mt-3.5"> */}
          <div
            className={`bg-[#161638] ${
              userData?.foundingMemberCount
                ? "h-[308px] lg:h-[285px]"
                : "h-[225px] lg:h-[272px]"
            } w-[240px] lg:w-[272px] rounded-[24px] flex flex-col items-center pt-8 lg:pt-8 relative mt-3.5`}
          >
            {userData && userData?.profilePic ? (
              <img
                className="w-32 h-32 lg:w-32 lg:h-32 rounded-full object-cover"
                src={userData?.profilePic}
                alt="profile pic"
              />
            ) : (
              <UserAvatar className="w-36 h-36 lg:w-44 lg:h-44" isCurrentUser />
            )}
            {userData?.foundingMemberCount ? (
              <p className="font-[Play] font-bold text-white bg-[#FFFFFF14] w-[194.4px] h-[41px] lg:w-[243px] flex justify-center items-center text-base mt-5">
                Founding Member{" "}
                <span className="text-[#17F9DA] ml-1">
                  {" "}
                  #{userData.foundingMemberCount}
                </span>
              </p>
            ) : null}
            <div
              className={`flex items-center mb-2 ${
                userData?.foundingMemberCount ? "mt-[10px]" : "mt-[10px]"
              }`}
            >
              <p className="text-[13px] text-white font-[Play] font-bold">
                Subscription Level :{" "}
              </p>
              <div className="w-auto ml-2">
                {/* <BorderButtonSecondary
                text={getSubscriptionLevel()}
                onClick={() => {}}
                height="h-[24px]"
                className="ml-2"
                childHeight="h-[57px]"
                childClassName="text-[14px] font-bold px-2"
              /> */}
                <CustomBorderButton
                  text={
                    userProfile?.callSign === callSignFromParam
                      ? memberStatus.toUpperCase()
                      : guestMemberStatus.toUpperCase()
                  }
                  onClick={subButtonClick}
                  height="h-[24px]"
                  className=""
                  childHeight="h-[57px]"
                  childClassName="text-[13px] font-bold px-2"
                  fromBg={getSubscriptionLevelButtonColor(
                    "from",
                    userProfile?.callSign === callSignFromParam
                      ? memberStatus
                      : guestMemberStatus
                  )}
                  viaBg={getSubscriptionLevelButtonColor(
                    "via",
                    userProfile?.callSign === callSignFromParam
                      ? memberStatus
                      : guestMemberStatus
                  )}
                  toBg={getSubscriptionLevelButtonColor(
                    "to",
                    userProfile?.callSign === callSignFromParam
                      ? memberStatus
                      : guestMemberStatus
                  )}
                  childBgColor={getSubscriptionLevelButtonBgColor(
                    userProfile?.callSign === callSignFromParam
                      ? memberStatus
                      : guestMemberStatus
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-7 mb-6">
            {userData?.facebook ? (
              <RiFacebookFill
                color="#fff"
                className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                onClick={() => {
                  openInNewTab(
                    `https://www.facebook.com/${userData?.facebook}`
                  );
                }}
              />
            ) : null}
            {userData?.linkedin ? (
              <SiLinkedin
                color="#fff"
                className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                onClick={() => {
                  openInNewTab(
                    `https://www.linkedin.com/in/${userData?.linkedin}`
                  );
                }}
              />
            ) : null}
            {userData?.twitter ? (
              <img
                src={Icons.TwitterXWhite}
                className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                onClick={() => {
                  openInNewTab(`https://x.com/${userData?.twitter}`);
                }}
                alt="twitter icon"
              />
            ) : null}
            {userData?.instagram ? (
              <PiInstagramLogoFill
                color="#fff"
                className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                onClick={() => {
                  openInNewTab(`https://instagram.com/${userData?.instagram}`);
                }}
              />
            ) : null}
          </div>
        </div>
        <div className="profileDesc pt-7 sm:pt-12 relative">
          <p className="font-[Barlow] text-white text-base lg:text-2xl">
            {userData?.callSign}
          </p>
          <h1 className="font-[Barlow] font-bold text-white text-xl lg:text-3xl">
            {userData?.firstName} {userData?.lastName}
          </h1>
          <div className="flex flex-col sm:flex-row mt-8 mb-9">
            {userData?.country ? (
              <div className="flex items-center mb-3 sm:mb-0 mr-0 sm:mr-5 xl:mr-16">
                <CountryDxccFlag
                  countryCode={userData?.country || ""}
                  flagCode={userData?.flagCode || ""}
                />

                <p className="text-base xl:text-lg font-[Play] ml-2 font-bold text-white">
                  {userData?.country}
                </p>
              </div>
            ) : null}
            <div
              className="flex items-center cursor-pointer"
              onClick={() =>
                openInNewTab(
                  `https://community.worldradioleague.com/u/${userData?.callSign}/summary`
                )
              }
            >
              <img
                className="w-5 h-5 mr-2 xl:mr-3.5"
                src={Icons.Chatbox}
                alt=""
              />
              <p className="text-base xl:text-lg font-[Play] font-bold text-white">
                Send Message
              </p>
            </div>
          </div>
          <TextDesc text={userData?.bio} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full text-white mt-6 gap-4 items-center sm:justify-center">
        {userProfile?.callSign === callSignFromParam ? (
          <div className="flex w-[70%] md:w-[30%] lg:w-[25%]">
            <BorderButtonPrimary
              className="w-full"
              text="Update Operating Locations"
              onClick={() => globalNavigate("/log-contacts/saved-locations")}
            />
          </div>
        ) : null}

        {userProfile?.callSign === callSignFromParam ? (
          <div className="flex w-[70%] md:w-[30%] lg:w-[25%]">
            <BorderButtonPrimary
              className="w-full"
              text="Edit Profile"
              onClick={() =>
                globalNavigate(`${MY_PROFILE_ROUTE}/${EDIT_PROFILE}`)
              }
            />
          </div>
        ) : null}

        <AccessControl
          permission={PERMISSIONS.superAdmin}
          children={
            <div className="flex w-[70%] md:w-[30%] lg:w-[25%]">
              <BorderButtonPrimary
                className="w-full"
                text="Admin Controls"
                onClick={() => {
                  dispatch(setUserAdminControl(userData));
                }}
              />
            </div>
          }
        />
      </div>

      {featuredAwards.length ? (
        <>
          <h6 className="text-xl text-white font-[Play] font-bold mt-7 text-center mb-2">
            Featured Awards
          </h6>
          <div className="bg-[#0B0B39] py-6 px-6 rounded-[20px] sm:rounded-[25px] md:rounded-[50px] flex justify-start flex-wrap">
            {featuredAwards.map((item: UserAwards, index: number) => {
              return (
                <img
                  key={index}
                  className="awardImg my-3 md:my-0"
                  src={item.image}
                  alt={item.name}
                />
              );
            })}
          </div>
        </>
      ) : null}
      <TabNavGlow
        selectedIndex={selectedIndex ? selectedIndex : ""}
        setSelectedIndex={(index) => {
          setSearchParams((prev: any) => {
            return {
              ...prev,
              tab: index,
            };
          });
        }}
        tabs={profileTabs}
      />

      {selectedIndex === "Logbook" ? (
        <div className="mt-6">
          <UserProfileLogBook userData={userData} />
        </div>
      ) : selectedIndex === "Biography" ? (
        <div className="mt-6 rounded-[20px] sm:rounded-[25px] md:rounded-[50px] bg-[#0B0B39] flex flex-col md:flex-row justify-between pl-5 sm:pl-6 pr-5 sm:pr-10">
          <div className="profileDesc pt-7 sm:pt-12 relative">
            <TextDesc text={userData?.bio || ""} />
          </div>
        </div>
      ) : selectedIndex === "Awards" ? (
        userAwardsLoading ? (
          <RouteLoading noBg />
        ) : (
          <AwardList
            userAwards={userAwards}
            selectedAward={selectedAward}
            setSelectedAward={setSelectedAward}
            setFeaturedStatusForAward={setFeaturedStatusForAward}
            guestUser={userProfile?.callSign !== callSignFromParam}
            userCallSign={callSignFromParam}
            user={userData as any}
          />
        )
      ) : selectedIndex === "Certificates" ? (
        <div className="flex flex-wrap justify-between bg-[#0B0B39] p-6 md:p-12 mt-6 rounded-[20px] sm:rounded-[25px] md:rounded-[50px]">
          {[1, 2, 3, 4].map((item, index) => {
            return (
              <div
                className="bg-[#FFFFFFBF] w-[100%] sm:w-[48%] mb-10 rounded-[20px] sm:rounded-[15px] md:rounded-[30px] overflow-hidden"
                key={index}
              >
                <div className="bg-[#36365D] w-full p-[30px] sm:p-[15px] md:p-[30px] flex justify-center">
                  <img
                    className="rounded-[5px] md:rounded-[0px] w-full"
                    src={Icons.Certificate01}
                    alt=""
                  />
                </div>
                <div className="bg-[#CBCBD5] w-full h-[80px] md:h-[100px] flex flex-row justify-between items-center px-[5%] md:px-[7%]">
                  <button className="bg-gradient-to-r from-[#130EFF] via-[#FB047B] to-[#FF2CF7] w-[110px] sm:w-[90px] md:w-[130px] h-[34px] rounded-full flex justify-center items-center">
                    <div className="text-white bg-gradient-to-r from-[#9C27B0] to-[#651FFF] w-[107px] sm:w-[87px] md:w-[127px] h-[31px] rounded-full flex justify-center items-center bg-[#]">
                      Print
                    </div>
                  </button>
                  <button className="text-[#BF1D27] text-sm sm:text-lg md:text-xl font-[Barlow]">
                    see preview
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      ) : selectedIndex === "" ? (
        <AwardList
          userAwards={userAwards}
          selectedAward={selectedAward}
          setSelectedAward={setSelectedAward}
          setFeaturedStatusForAward={setFeaturedStatusForAward}
          guestUser={userProfile?.callSign !== callSignFromParam}
          userCallSign={callSignFromParam}
          user={userData as any}
        />
      ) : null}
    </div>
  );
};
