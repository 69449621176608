import React, { useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";

import { ContactMarkerProps } from "types/Component";
import contactMarkerIcon from "../../assets/Icons/contactMarker.svg";
import { findCountryCode } from "helpers/Utils";
import ReactCountryFlag from "react-country-flag";
import Icons from "assets/Icons";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";

const ContactMarker = ({ position, index }: ContactMarkerProps) => {
  const [infoVisibile, setInfoVisibile] = useState(false);
  // const contactMarkerIcon = {
  //   url: require("../../assets/Icons/contactMarker.svg"), // Assuming it's a required module
  //   scaledSize: new window.google.maps.Size(10, 10),
  //   origin: new window.google.maps.Point(0, 0), // Optional: Set origin point for anchor
  //   anchor: new window.google.maps.Point(12.5, 25), // Optional: Set anchor point
  // };
  return (
    <MarkerF
      key={`${position.lat}-${position.lng}-${index}`}
      position={position}
      icon={{
        url: contactMarkerIcon,
        scaledSize: new window.google.maps.Size(20, 20),
        origin: new window.google.maps.Point(0, 0), // Optional: Set origin point for anchor
        anchor: new window.google.maps.Point(10,20), // Optional: Set anchor point
      }}
      onMouseOver={(e) => {
        setInfoVisibile(true);
      }}
      onMouseOut={(e) => {
        setInfoVisibile(false);
      }}
    >
      {infoVisibile ? (
        <InfoWindowF
          onCloseClick={() => {
            setInfoVisibile(false);
          }}
          options={{ disableAutoPan: true }}
          position={position}
        >
          {position.contactInfo ? (
            <div className="h-[55px] rounded-sm bg-[#fff] py-2 pr-6 pl-2 flex">
              <div className="relative flex items-center mr-3">
                {position.contactInfo?.image ? (
                  <img
                    className="w-[40px] h-[40px] rounded-full bg-[#f2f2f2]"
                    src={position.contactInfo?.image}
                    alt=""
                  />
                ) : (
                  <UserAvatar className="h-8 w-8" />
                )}
                <div className="absolute bottom-[0px] right-[-2px]">
                  <CountryDxccFlag
                    countryCode={position.contactInfo.country || ""}
                    flagCode={position.contactInfo.flagCode || ""}
                  />
                </div>
              </div>
              <div className="">
                <p className="font-[Play] font-normal text-[#010A41]">
                  {position.contactInfo.name} - {position.contactInfo.callSign}
                </p>
                <div className="flex gap-1">
                  <img
                    src={Icons.Frequency}
                    alt=""
                    className="w-[13px] h-[13px]"
                  />
                  {position.contactInfo.frequency ? (
                    <p className="font-[Play] text-[11px] text-[#010A41]">
                      {position.contactInfo.frequency} MHz{" "}
                      {`(${position.contactInfo.mode})`}
                    </p>
                  ) : (
                    <p className="font-[Play] text-[11px] text-[#010A41]">--</p>
                  )}
                </div>
                <div className="flex gap-1">
                  <img
                    src={Icons.Distance}
                    alt=""
                    className="w-[13px] h-[13px]"
                  />
                  <p className="font-[Play] text-[11px] text-[#010A41]">
                    {position.contactInfo?.distance
                      ? `${position.contactInfo?.distance.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }
                        )} KM`
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </InfoWindowF>
      ) : null}
    </MarkerF>
  );
};

export default ContactMarker;
