import Header from "components/Header";
import AddLogBookWrapper from "components/LogBook/AddLogBookWrapper";
import { PotaActivation } from "components/LogBook/PotaActivation";
import { TAGS } from "constants/Config";
import { useSearchParams } from "react-router-dom";

function AddLogBook() {
  let [searchParams] = useSearchParams();
  const tag = searchParams.get("tag") || "";

  const renderComponent = (tag: string) => {
    switch (tag) {
      case TAGS.POTA:
        return <PotaActivation />;
      default:
        return <AddLogBookWrapper />;
    }
  };

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Logbook" />
      <div className="w-full dashboardWrapper flex items-center justify-center">
        {renderComponent(tag)}
      </div>
    </main>
  );
}

export default AddLogBook;
