import Icons from "assets/Icons";
import useContest from "hooks/contest.hook";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import moment from "moment";
import { CONTEST_STATUS } from "types/Functions";
import { useSearchParams } from "react-router-dom";
const EnrollSuccessModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    toggleEnrollModal,
    showEnrollSuccessData,
    setSelectedLogbook,
    userProfile,
  } = useContest();
  const navigateToLogBook = () => {
    toggleEnrollModal(null);
    setSelectedLogbook({
      id: showEnrollSuccessData?.logbookId,
      name: showEnrollSuccessData?.logbookName,
      uid: userProfile?.uid,
      contestId: showEnrollSuccessData?.contestId,
      contest: {
        name: showEnrollSuccessData?.contestName || "",
        startDate: showEnrollSuccessData?.startDate,
        endDate: showEnrollSuccessData?.endDate,
        image: "",
      },
    });
    setSearchParams((prev: any) => {
      return {
        ...prev,
        tab: "log_contest_contacts",
        contest_id: searchParams.get("contest_id"),
        "logbook-id": showEnrollSuccessData?.logbookId,
        "logbook-name": showEnrollSuccessData?.logbookName,
      };
    });
  };

  return (
    showEnrollSuccessData && (
      <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[5%] lg:px-[10%]">
        <div className="overflow-x-hidden w-full">
          <div className="w-[100%] h-[100%] items-center justify-center">
            <div className="flex flex-col bg-[#151541] px-3 py-5 items-center w-full h-full custom-bg">
              <h3 className="text-white font-[Play] text-[65px] text-center leading-[65px] mt-16">
                Enroll Success!
              </h3>
              {showEnrollSuccessData.contestStatus ===
              CONTEST_STATUS.UPCOMING ? (
                <p className="text-white font-[Play text-center mt-16 w-[60%]">
                  {`Successfully Enrolled to`}{" "}
                  <span className="font-bold text-[#17F9DA]">{`${showEnrollSuccessData.contestName}`}</span>
                  {`! Come back when the contest starts at `}
                  <span className="font-bold text-[#17F9DA]">
                    {`${moment(
                      new Date(showEnrollSuccessData?.startDate.seconds * 1000)
                    ).format("dddd, d MMM YYYY HH:MM A")}`}
                  </span>{" "}
                  {`to start logging your contacts. You'll see a live
              leaderboard with the other participants.`}
                </p>
              ) : (
                <p className="text-white font-[Play text-center mt-16 w-[60%]">
                  {`Successfully Enrolled to `}{" "}
                  <span className="font-bold text-[#17F9DA]">{`${showEnrollSuccessData.contestName}`}</span>
                  {`! The Contest has started, so jump in and
                  start logging your contacts!`}
                </p>
              )}
              {showEnrollSuccessData.contestStatus ===
              CONTEST_STATUS.UPCOMING ? (
                <div className="w-full md:w-[250px] mt-40 cursor-pointer">
                  <BorderButtonSecondary
                    className="py-[1.5px]"
                    height="h-fit"
                    text={"Close"}
                    onClick={() => toggleEnrollModal(null)}
                    childClassName="text-sm md:text-base py-2 px-[10px]"
                  />
                </div>
              ) : (
                <div className="w-full md:w-[250px] mt-40 cursor-pointer">
                  <BorderButtonSecondary
                    className="py-[1.5px]"
                    height="h-fit"
                    text={"Start Logging Contacts"}
                    onClick={navigateToLogBook}
                    childClassName="text-sm md:text-base py-2 px-[10px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="absolute top-22 right-16 cursor-pointer"
          onClick={() => toggleEnrollModal(null)}
        >
          <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
        </div>
      </section>
    )
  );
};

export default EnrollSuccessModal;
