import { calculateHardwareAnalytics } from "firebase-config";
import { useState } from "react";

export const useHardwareAnalytics = () => {
  const [hardwareAnalytics, setHardwareAnalytics] = useState({
    radios: {},
    antennas: {},
  });
  const [loading, setLoading] = useState(false);

  const getHardwareAnalytics = async (userId: string) => {
    try {
      setLoading(true);
      const requestPayload = {
        userId,
      };

      const analytics: any = await calculateHardwareAnalytics(requestPayload);

      setHardwareAnalytics(analytics?.data);
      setLoading(false);
    } catch (error) {
      console.log("Error getting analytics", error);
      setLoading(false);
      return null;
    }
  };

  return {
    hardwareAnalytics,
    loading,
    getHardwareAnalytics,
  };
};
