import Icons from "assets/Icons";
import TitledInput from "components/Inputs/TitledInput";
import { Formik, Form, useField } from "formik";
import { useDebouncedCallback } from "use-debounce";
import usePota from "hooks/pota.hook";
import { ParkReferenceModel } from "types/Models";
import InputWithButton from "components/Inputs/InputWithButton";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { Modes } from "constants/ArrayVariables";
import { globalNavigate } from "routes/GlobalRoutes";
import { findBandAndModeForFrequency, getStateLongName, getStateShortCode, suggestionsFormatter } from "helpers/Utils";
import { useState } from "react";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";
import { Tooltip } from "components/Tooltip";
import CustomValidation from "helpers/Validation";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { HorizontalDivider } from "components/Divider";
import useLogBook from "hooks/logBook.hook";
import { Templates } from "constants/Config";
import { DefaultLocationSettings } from "components/DefaultLocationSettings";
import { Timestamp } from "firebase/firestore";
interface CheckboxProps {
  name: string;
  label: string;
}

export type PotaActivationForm = {
  myPark: "";
  frequency: "";
  userMode: "";
  myParks: Record<string, ParkReferenceModel>;
  country: string;
  state: string;
  grid: string;
  latitude: string | number;
  longitude: string | number;
};

const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        {...field}
        {...props}
        className="form-checkbox h-5 w-5 bg-[#0243DD]"
      />
      <label
        htmlFor={props.name}
        className="ml-2 text-white text-sm font-Play "
      >
        {label}
      </label>
    </div>
  );
};

export function PotaActivation() {
  const userProfile = useSelector(getUserProfileSelector);
  const [formData, setFormData] = useState<any>({});
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const {
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    getParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    setParkByReference,
    getNearbyParkReferences,
    nearbyParkReferencesData,
    setAutoSpotData,
    addPotaAutoSpot,
  } = usePota();

  const { createNewLogBook } = useLogBook();

  const debouncedParkReferenceChangeHandler = useDebouncedCallback(
    (searchString) => getParReferenceSuggestions(searchString),
    1000
  );

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) => getParkByReference(searchString),
    200
  );

  const resetParkReferenceSuggestions = (key: string) => {
    setTimeout(() => setParReferenceSuggestions({ key, value: null }), 200);
  };

  const hideParkSuggestions = () => {
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const showParkSuggestions = (key: string, setValues: any) => {
    if (!showSuggestions) return {};

    return {
      suggestions: formData?.[key]
        ? parkReferenceSuggestionsData?.[key]
        : nearbyParkReferencesData,
      suggestionsFormatter,
      onSuggestionClick: (suggestion: ParkReferenceModel) => {
        // Check if myParks has more than 4 keys
        if (Object.keys(formData?.myParks || {}).length >= 5) {
          showToast({
            message: "A maximum of 5 parks are allowed",
            type: ToastTypes.ERROR,
          });
          return; // Stop further execution
        }

        // after setting value remove suggestions
        resetParkReferenceSuggestions(key);

        setValues((prev: any) => {
          return {
            ...prev,
            myParks: { ...prev.myParks, [suggestion?.reference]: suggestion },
            myPark: "",
          };
        });

        // set formdata
        setFormData((prev: any) => {
          return {
            ...prev,
            myParks: { ...prev.myParks, [suggestion?.reference]: suggestion },
            myPark: "",
          };
        });

        // hide suggestions
        hideParkSuggestions();
      },
    };
  };

  const findMyParkHandler = async () => {
    const coor = await fetchCoordinates();

    if (coor?.latitude && coor?.longitude) {
      getNearbyParkReferences([coor?.latitude, coor?.longitude]);
      setShowSuggestions(true);
    }
  };

  const renderSelectedParks = (setValues: any) => {
    if (!Object.keys(formData?.myParks || {}).length) return null;

    const removePark = (key: string) => {
      //remove park from form data as well as key
      setFormData((prev: any) => {
        const { [key]: omit, ...rest } = prev.myParks;
        return { ...prev, myParks: rest };
      });

      // also update formit
      setValues((prev: any) => {
        const { [key]: omit, ...rest } = prev.myParks;
        return { ...prev, myParks: rest };
      });
    };

    return (
      <div className="flex flex-row text-white text-[11px] gap-2 -mt-[10px] flex-wrap">
        {Object.keys(formData.myParks).map((key, index) => (
          <div
            key={index}
            className="flex flex-row rounded-[10px] border border-neutral-400 gap-2 px-2 py-1 items-center bg-neutral-400 bg-opacity-25 "
          >
            <Tooltip
              message={`${formData.myParks[key]?.reference} - ${formData.myParks[key]?.parktypeDesc}`}
              className="w-[180px]"
            >
              <div>{`${formData.myParks[key]?.reference}`}</div>
            </Tooltip>

            <img
              src={Icons.CrossIcon}
              className="h-3 w-3"
              alt="close"
              onClick={() => removePark(key)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="mt-[300px] md:mt-0 glassmorphism w-[90%] sm:w-2/3 md:w-2/3 md:w-4/6 lg:w-4/6 xl:w-4/6 2xl:w-3/6 py-11 px-6 sm:px-6 md:px-12 lg:px-14 xl:px-20 flex flex-col items-center border-2 border-emerald-500">
      <Formik
        initialValues={{
          myPark: "",
          frequency: "",
          userMode: "",
          myParks: {},
          country: "",
          state: "",
          grid: "",
          latitude: "",
          band: "",
          longitude: "",
          autospot: false,
          logbookStyle: Templates.POTA,
          defaultCallSign: userProfile?.callSign || "",
          locationSource: "",
        }}
        validationSchema={CustomValidation.PotaActivationValidationSchema}
        onSubmit={async (values) => {
          if (values?.frequency || values?.userMode) {
            setAutoSpotData({
              frequency: values?.frequency,
              mode: values?.userMode,
            });

            if (values?.autospot && values?.myParks) {
              const parkReference = Object.keys(values?.myParks)?.[0];

              if (parkReference) {
                // add spot
                addPotaAutoSpot({
                  activator: values?.defaultCallSign,
                  spotter: values?.defaultCallSign,
                  reference: parkReference,
                  comments: "Starting POTA Activation",
                  frequency: `${parseFloat(values?.frequency) * 1000}`,
                  mode: values?.userMode,
                  band: values?.band,
                });
              }
            }
          }

          let name = "";
          name = `${values?.defaultCallSign} @ `;
          name += Object.keys(values?.myParks || {}).join(", ");

          const defaultLocation = {
            isCustom: true,
            country: values.country,
            state: getStateShortCode(values.state),
            stateLongName:getStateLongName(values.state),
            qth: "",
            lat: parseFloat(values?.latitude),
            lng: parseFloat(values?.longitude),
            gridsquare: values?.grid,
            id: "",
            locationType: "",
            default: false,
            name: "",
            uid: "",
            locationSource: values?.locationSource || "",
          };
          let newLogbookData: any = {
            name,
            defaultLocation,
            logbookStyle: values?.logbookStyle || Templates.POTA,
            defaultCallSign: values?.defaultCallSign || "",
            myParks: values?.myParks || {},
          };
          if (values?.autospot && values?.myParks) {
            const parkReference = Object.keys(values?.myParks)?.[0];

            if (parkReference) {
              // add spot
              newLogbookData.lastSpottedAt = Timestamp.now();
              newLogbookData.lastSpottedFrequency = values?.frequency;
              newLogbookData.lastSpottedMode = values?.userMode;
              newLogbookData.lastSpottedBand = values?.band;
              newLogbookData.lastSpottedComment = "Starting POTA Activation";
            }
          }
          createNewLogBook(newLogbookData);
        }}
        // innerRef={formikRef}
      >
        {({ errors, touched, handleChange, submitForm, values, setValues }) => {
          const onFrequencyBlur = () => {
            const freqNumber = parseFloat(values.frequency);
            const bandMode = findBandAndModeForFrequency(freqNumber);
            setValues((prev: any) => {
              return {
                ...prev,
                userMode: bandMode?.mode,
                band: bandMode?.band,
              };
            });
            setFormData((prev: any) => ({
              ...prev,
              userMode: bandMode?.mode,
              band: bandMode?.band,
            }));
          };

          const handleKeyDown = (event: any) => {
            if (event.key === "Enter") {
              event.preventDefault();
              submitForm();
            }
          };

          return (
            <Form
              className="flex flex-col w-full gap-4"
              onKeyDown={handleKeyDown}
            >
              <div className="flex flex-col">
                <div className="flex flex-row gap-3 justify-center items-center md:justify-start lg:justify-start xl:justify-start">
                  <img src={Icons.parkWhite} className="h-8" alt="close" />
                  <div className="text-white text-3xl font-bold font-['Play']">
                    New POTA Activation
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-4 w-full items-center md:items-start">
                <div className="w-[68%]">
                  <TitledInput
                    setText={(e) => {
                      setValues((prev: any) => {
                        return {
                          ...prev,
                          myPark: e.target.value.toUpperCase(),
                        };
                      });

                      setFormData((prev: any) => ({
                        ...prev,
                        myPark: e.target.value.toUpperCase(),
                      }));

                      if (e.target.value.length > 1) {
                        debouncedParkReferenceChangeHandler({
                          key: "myPark",
                          value: e.target.value.toUpperCase().trim(),
                        });
                      }

                      // reset park data on change
                      setParkByReference({ key: "myPark", value: null });
                    }}
                    onBlur={() => {
                      if (values.myPark) {
                        debouncedGetParkByReference({
                          value: values.myPark,
                          key: "myPark",
                        });
                      }

                      // remove suggestions on blur
                      resetParkReferenceSuggestions("myPark");

                      // hide suggestions
                      hideParkSuggestions();
                    }}
                    onFocus={() => setShowSuggestions(true)}
                    // status={
                    //   parkReferenceData && parkReferenceData?.myPark
                    //     ? "Park Match"
                    //     : null
                    // }
                    // statusBg={
                    //   parkReferenceData && parkReferenceData?.myPark
                    //     ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
                    //     : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
                    // }
                    isLoading={!!isParkReferenceDataLoading?.myPark}
                    name="myPark"
                    title="Select Your Park(s)"
                    placeHolder="Type the name of the park here"
                    width="w-full"
                    tabindex={15}
                    value={values.myPark}
                    error={
                      errors?.myParks && touched?.myParks
                        ? errors?.myParks
                        : undefined
                    }
                    containerClass={"m-0"}
                    {...showParkSuggestions("myPark", setValues)}
                    className={"h-10"}
                  />

                  {renderSelectedParks(setValues)}
                </div>

                <button
                  onClick={findMyParkHandler}
                  className="text-play text-white rounded-full px-4 py-1 border-2 border-emerald-500 mt-6"
                >
                  Find My Park
                </button>
              </div>

              <HorizontalDivider />

              <div className="flex flex-col gap-3 items-center md:items-start">
                <div className="flex font-Play text-white w-[68%] md:w-full">
                  You can preset frequency & mode now or wait until you’re
                  logging:
                </div>
                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-4 w-full items-center">
                  <InputWithButton
                    setText={(e) => {
                      handleChange(e);
                      setFormData((prev: any) => ({
                        ...prev,
                        frequency: e.target.value,
                      }));
                    }}
                    name="frequency"
                    title="Frequency"
                    placeHolder="Frequency"
                    onFieldBlur={onFrequencyBlur}
                    width="w-[68%] md:w-[48%] lg:w-[48%] xl:w-[48%] 2xl:w-[48%]"
                    value={values.frequency}
                    btnTxt="MHz"
                    fitBtn
                    onBtnClick={() => {}}
                    error={
                      errors?.frequency && touched?.frequency
                        ? errors?.frequency
                        : undefined
                    }
                    className="h-8"
                    btnHeight="h-8"
                    //   containerClass={display ? "pointer-events-none" : ""}
                  />

                  <DropDownTitled
                    onChange={(e) => {
                      handleChange(e);
                      // setUserMode(e.target.value);
                      setFormData((prev: any) => ({
                        ...prev,
                        userMode: e.target.value,
                      }));
                    }}
                    name="userMode"
                    value={values.userMode}
                    title="Mode"
                    placeHolder="Mode"
                    tabindex={6}
                    width="w-[68%] md:w-[48%] lg:w-[48%] xl:w-[48%] 2xl:w-[48%]"
                    lists={Modes}
                    error={
                      errors?.userMode && touched?.userMode
                        ? errors?.userMode
                        : undefined
                    }
                    containerClass=""
                    className={"h-11"}
                  />
                </div>
              </div>

              {formData && formData.frequency && formData.userMode ? (
                <div className="w-full flex flex-row justify-center md:justify-start lg:justify-start xl:justify-start -mt-2">
                  <Checkbox
                    name="autospot"
                    label={`Autospot to POTA ${formData?.frequency} MHz ${formData?.userMode}`}
                  />
                </div>
              ) : null}

              <HorizontalDivider />

              <DefaultLocationSettings />

              <div className="w-full flex flex-row justify-center">
                <button
                  type="submit"
                  className="mt-10 w-[68%] md:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[40%] text-white text-xl font-normal font-['Play'] border rounded-full px-3 py-1.5 border-2 border-emerald-500"
                >
                  Start Logging
                </button>
              </div>

              <div className="absolute top-2 right-2 cursor-pointer">
                <img
                  onClick={() => globalNavigate("/log-contacts")}
                  src={Icons.PotaClose}
                  className="h-8 w-8"
                  alt="close"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
