import { CoordinatesParams } from "store/Actions/profile.actions";

export const fetchCoordinates = async (): Promise<any> => {
  return new Promise<CoordinatesParams | null>((resolve) => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            resolve(null);
          } else {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                let coordinates: CoordinatesParams = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                };
                resolve(coordinates);
              },
              (e) => {
                resolve(null);
              }
            );
          }
        });
    } else {
      resolve(null);
    }
  });
};
