import React, { useState, useEffect } from "react";
import Icons from "assets/Icons";
import Images from "assets/Images";
import { Award } from "types/Models";

export type AwardItemProps = {
  add?: boolean;
  buttonText?: string;
  onClick?: () => void;
  data?: Award;
  type?: "Award";
};

function AwardItem({
  add,
  buttonText,
  onClick,
  data,
  type,
}: AwardItemProps) {
  return (
    <>
      <div
        className="relative mt-6 flex flex-col items-center todayLeaderImgWrapper cursor-pointer mb-10"
        onClick={() => {}}
      >
        <div className="absolute w-full flex justify-center h-[104%] top-[-1%]">
          <img
            // className="w-full object-fill md:w-[196.8px] h-[295.2px] lg:w-[246px] lg:h-[369px]"
            className={`object-fill w-[95%] h-full`}
            src={Icons.LeaderBg}
            alt="bg"
          />
        </div>
        <div
          className={`bg-[#161638] w-[91%] h-full  rounded-[10px] flex flex-col items-center justify-between pt-2 relative mt-1`}
        >
          {!add && (
            <div className="bg-[#292948] w-[95%] mb-3">
              <p className="font-[Play] text-[18px] text-[#17F9DA] text-center">
                {
                  data?.name
                }
              </p>
            </div>
          )}

          <div className="w-full flex flex-col items-center mt-2">
            <div
              className="w-26 h-26"
              onClick={() => {
                onClick && onClick();
              }}
            >
              {add ? (
                <div className="w-[150px] rounded-md h-24 bg-[#282851] flex items-center justify-center flex-col mt-2 md:mt-5">
                  <img src={Images.Add} alt="bg" className={"w-6 h-6"} />
                  <p className="font-[Play] text-white">{buttonText}</p>
                </div>
              ) : (
                <>
                  {data?.image ? (
                    <img
                      src={data?.image || ""}
                      alt="bg"
                      className={"w-24 h-24 -mt-3"}
                    />
                  ) : (
                    <img
                      src={
                        type === "Award"
                          ? Icons.RadioIcon
                          : Icons.AntennaIcon || ""
                      }
                      alt="bg"
                      className={"w-20 h-20 -mt-3"}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AwardItem;
