import { logEvent } from "firebase/analytics";
import { analytics } from "firebase-config";

interface SupportServiceType {
  reportBug(arg: any): Promise<any>;
  sendInvite(arg: any): Promise<any>;
}
const SupportService: SupportServiceType = {
  reportBug: async (requestPayload: any) => {
    return fetch("https://hooks.zapier.com/hooks/catch/18367683/3vb70qe/", {
      method: "POST",
      body: JSON.stringify(requestPayload),
    });
  },
  sendInvite: async (requestPayload: any) => {
    return fetch("https://hooks.zapier.com/hooks/catch/18367683/2y42xwn/", {
      method: "POST",
      body: JSON.stringify(requestPayload),
    });
  }
};

export default SupportService;
