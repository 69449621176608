import React, { useEffect } from "react";
import Header from "components/Header";
import useLogBook from "hooks/logBook.hook";
import moment from "moment";
import { getStatusColor } from "helpers/Utils";
import ActivityIndicator from "components/Loading/ActivityIndicator";
function ImportSummary() {
  const { userLogbookListener, selectedLogBook } = useLogBook();

  useEffect(() => {
    const unsubscribe = userLogbookListener();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="w-full dashboardWrapper flex flex-col items-center relative">
      <Header title="Import Summary" />
      <div className="w-full md:px-20 px-2 mt-10">
        <div className="w-full md:h-[300px] h-[500px]  flex flex-col bg-[#151541] py-[25px] px-[25px]">
          <p className="font-[Play] text-[24px] font-bold text-white">
            Import Details
          </p>
          <div className="w-full md:px-10 px-1 mt-3">
            <div className="flex justify-between">
              <p className="text-white font-[Play]">
                <span className="text-[#17F9DA] font-bold font-[Play]">
                  Logbook Name:
                </span>{" "}
                {selectedLogBook?.name}
              </p>
              <p className="text-white font-[Play]">
                <span className="text-[#17F9DA] font-bold font-[Play]">
                  File Name:
                </span>{" "}
                {selectedLogBook?.fileName}
              </p>

              <p className="text-white font-[Play]">
                <span className="text-[#17F9DA] font-bold font-[Play]">
                  Import Date:
                </span>{" "}
                {selectedLogBook?.importDate
                  ? moment(
                      new Date(selectedLogBook?.importDate?.seconds * 1000)
                    ).format("MM/DD/YYYY")
                  : ""}
              </p>
            </div>
            <div className="flex justify-between mt-10">
              <div className="">
                <p className="text-white font-[Play]">
                  Total QSOs:{" "}
                  {selectedLogBook?.totalImportContacts
                    ? selectedLogBook?.totalImportContacts.toLocaleString()
                    : "0"}
                </p>
                <p className="text-white font-[Play]">
                  Total Successful:{" "}
                  <span className="text-[#3AFE09]">
                    {selectedLogBook?.successContacts
                      ? selectedLogBook?.successContacts.toLocaleString()
                      : "0"}
                  </span>
                </p>
                <p className="text-white font-[Play]">
                  Total Failed:{" "}
                  <span className="text-[#FF0202]">
                    {selectedLogBook?.failedContacts
                      ? selectedLogBook?.failedContacts.toLocaleString()
                      : "0"}
                  </span>
                </p>
                <p className="text-white font-[Play]">
                  Total Duplicates:{" "}
                  <span className="text-[#FFF500]">
                    {selectedLogBook?.duplicateContacts
                      ? selectedLogBook?.duplicateContacts.toLocaleString()
                      : "0"}
                  </span>
                </p>
              </div>
              <div>
                <p
                  className={`font-[Play]  ${getStatusColor(
                    selectedLogBook?.importStatus || ""
                  )}`}
                >
                  {selectedLogBook?.importStatus}
                </p>
                {selectedLogBook?.importStatus === "In Progress" ? (
                  <ActivityIndicator size={20} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full items-center justify-center flex absolute bottom-20 right-2 md:right-10">
            <p className="font-[Play] text-white text-center">
              Info: Import status will be updated here every minute
            </p>
          </div>
        </div>
        {selectedLogBook?.errorLogs &&
        selectedLogBook?.errorLogs?.length > 0 ? (
          <div className="w-full bg-[#151541] py-[25px] px-[25px] mt-6 h-[300px] relative overflow-scroll">
            <p className="font-[Play] text-[24px] font-bold text-white">
              Error Logs
            </p>
            <div className="w-full md:px-5 px-1 mt-3">
              {selectedLogBook?.errorLogs?.map((item, index) => {
                //split item with  : and make the first part bold and second part normal
                const splitItem = item.split(":");
                return (
                  <p className="text-white font-[Play] text-sm">
                    <span className="font-bold text-[#a25656]">
                      {splitItem[0]}:
                    </span>{" "}
                    {splitItem[1]}
                  </p>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
}

export default ImportSummary;
