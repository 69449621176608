import { ContestState } from "types/State";
import { RootState } from "store/Reducers";
import {
  ContestActionPayload,
  SHOW_ADD_CONTEST_MODAL,
  SET_ACTIVE_CONTEST,
  SET_PASSED_CONTEST,
  SET_UPCOMING_CONTEST,
  SET_CONTEST_DETAILS,
  SET_ENROLL_SUCCESS,
  SET_CONTESTANT_DETAIL,
  SAVE_USER_CONTEST_RANKING,
  SET_CONTEST_LEADER_DATA,
  SET_CONTEST_LEADERBOARD,
  SET_CONTEST_CONTACTS,
  SET_USER_CONTESTANT_DETAILS,
  SET_AWARD_CONTEST,
  SET_CONTEST_ID_FOR_EDIT_MODAL,
} from "store/Actions/contest.actions";
const defaultState: ContestState = {
  showAddContestModal: false,
  showEditContestModal: null,
  activeContest: [],
  upcomingContest: [],
  passedContest: [],
  contestDetails: null,
  enrollSuccessData: null,
  contestantDetails: null,
  userContestRanking: 0,
  contestLeader: {},
  contestLeaderBoard: [],
  contestContacts: [],
  userContestantDetails: null,
  awardContest: [],
};

export default function contestReducer(
  state = defaultState,
  action: ContestActionPayload
) {
  switch (action.type) {
    case SHOW_ADD_CONTEST_MODAL:
      return {
        ...state,
        showAddContestModal: action.payload,
      };
    case SET_CONTEST_ID_FOR_EDIT_MODAL:
      return {
        ...state,
        showEditContestModal: action.payload,
      };
    case SET_ACTIVE_CONTEST:
      return {
        ...state,
        activeContest: action.payload,
      };
    case SET_PASSED_CONTEST:
      return {
        ...state,
        passedContest: action.payload,
      };
    case SET_UPCOMING_CONTEST:
      return {
        ...state,
        upcomingContest: action.payload,
      };
    case SET_CONTEST_DETAILS:
      return {
        ...state,
        contestDetails: action.payload,
      };
    case SET_ENROLL_SUCCESS:
      return {
        ...state,
        enrollSuccessData: action.payload,
      };
    case SET_CONTESTANT_DETAIL:
      return {
        ...state,
        contestantDetails: action.payload,
      };
    case SAVE_USER_CONTEST_RANKING:
      return {
        ...state,
        userContestRanking: action.payload,
      };
    case SET_CONTEST_LEADER_DATA:
      return {
        ...state,
        contestLeader: action.payload,
      };
    case SET_CONTEST_LEADERBOARD:
      return {
        ...state,
        contestLeaderBoard: action.payload,
      };
    case SET_CONTEST_CONTACTS:
      return {
        ...state,
        contestContacts: action.payload,
      };
    case SET_USER_CONTESTANT_DETAILS:
      return {
        ...state,
        userContestantDetails: action.payload,
      };
    case SET_AWARD_CONTEST:
      return {
        ...state,
        awardContest: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getShowAddContestModalSelector = (state: RootState) => {
  return state.contest.showAddContestModal;
};

export const getShowEditContestModalSelector = (state: RootState) => {
  return state.contest.showEditContestModal;
};

export const getActiveContestSelector = (state: RootState) => {
  return state.contest.activeContest;
};

export const getUpcomingContestSelector = (state: RootState) => {
  return state.contest.upcomingContest;
};

export const getPassedContestSelector = (state: RootState) => {
  return state.contest.passedContest;
};
export const getContestDetailsSelector = (state: RootState) => {
  return state.contest.contestDetails;
};
export const getEnrollDetailsSelector = (state: RootState) => {
  return state.contest.enrollSuccessData;
};

export const getContestantDetailSelector = (state: RootState) => {
  return state.contest.contestantDetails;
};

export const getUserContestRankingSelector = (state: RootState) => {
  return state.contest.userContestRanking;
};

export const getContestLeaderSelector = (state: RootState) => {
  return state.contest.contestLeader;
};

export const getContestLeaderBoardSelector = (state: RootState) => {
  return state.contest.contestLeaderBoard;
};

export const getContestContactsSelector = (state: RootState) => {
  return state.contest.contestContacts;
};

export const getUserContestantDetailsSelector = (state: RootState) => {
  return state.contest.userContestantDetails;
};

export const getAwardContestSelector = (state: RootState) => {
  return state.contest.awardContest || [];
};
