import { RootState } from "store/Reducers";
import { CallsignState } from "types/State";
import {
  CallsignActionPayload,
  SET_CALLSIGN_DATA,
  SET_CALLSIGN_LOADING,
  SET_CALLSIGN_SEARCH_KEY,
  SET_CALLSIGN_SUGGESTIONS_DATA,
} from "store/Actions/callsign.actions";

const defaultState: CallsignState = {
  callsignData: null,
  callsignSuggestionsData: null,
  isCallsignDataLoading: false,
  callsignSearchKey: "",
};

export default function callsignReducer(
  state = defaultState,
  action: CallsignActionPayload
) {
  switch (action.type) {
    case SET_CALLSIGN_DATA:
      return {
        ...state,
        callsignData: action.payload,
      };
    case SET_CALLSIGN_SUGGESTIONS_DATA:
      return {
        ...state,
        callsignSuggestionsData: action.payload,
      };
    case SET_CALLSIGN_LOADING:
      return {
        ...state,
        isCallsignDataLoading: action.payload,
      };
    case SET_CALLSIGN_SEARCH_KEY:
      return {
        ...state,
        callsignSearchKey: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getCallsignDataSelector = (state: RootState) => {
  return state.callsign.callsignData;
};

export const getCallsignDataLoadingSelector = (state: RootState) => {
  return state.callsign.isCallsignDataLoading;
};

export const getCallsignSearchKeySelector = (state: RootState) => {
  return state.callsign.callsignSearchKey;
}

export const getCallsignSuggestionsDataSelector = (state: RootState) => {
  return state.callsign.callsignSuggestionsData;
};