import awards from "constants/Awards";
import { AwardActionPayload, SET_AWARD_LOADING, SET_AWARDS_DATA, SET_SHOW_AWARD_MODAL } from "store/Actions/award.actions";
import { RootState } from "store/Reducers";
import { AwardState } from "types/State";

const defaultState: AwardState = {
  awards: [],
  isLoading: false,
  showAwardModal: false,
};

export default function callsignReducer(
  state = defaultState,
  action: AwardActionPayload
) {
  switch (action.type) {
    case SET_AWARDS_DATA:
      return {
        ...state,
        awards: action.payload,
      };
    case SET_AWARD_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_SHOW_AWARD_MODAL:
      return {
        ...state,
        showAwardModal: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getAwardsSelector = (state: RootState) => {
  return state.award.awards;
};

export const getAwardsLoadingSelector = (state: RootState) => {
  return state.award.isLoading;
};

export const getShowAwardModalSelector = (state: RootState) => {
  return state.award.showAwardModal;
};