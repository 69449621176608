import Icons from "assets/Icons";
import { AwardsTypeArray, SubscriptionTypeArray } from "types/Component";

const awards: AwardsTypeArray[] = [
  { name: "Oceania", icon: Icons.OceaniaAward },
  { name: "Africa", icon: Icons.AfricaAward },
  { name: "Europe", icon: Icons.EuropeAward },
  { name: "northAmerica", icon: Icons.NorthAmericaAward },
  { name: "SouthAmerica", icon: Icons.SouthAmericaAward },
  { name: "Asia", icon: Icons.AsiaAward },
];

export const subscriptionPlans: SubscriptionTypeArray[] = [
  {
    icon: Icons.SubscriptionBasic,
    subscriptionType: "Basic",
    benefits: ["Unlimited Contacts", "Full Contest Access", "Community Access"],
    YearlyPrice: 35,
    btnTxt: "Subscribe",
  },
  {
    icon: Icons.SubscriptionPremium,
    subscriptionType: "Premium",
    benefits: [
      "Unlimited Contacts",
      "Full Contest Access",
      "Community Access",
      "PDF Printable Awards",
    ],
    YearlyPrice: 55,
    btnTxt: "Subscribe",
  },
  {
    icon: Icons.SubscriptionVip,
    subscriptionType: "VIP",
    benefits: [
      "Unlimited Contacts",
      "Full Contest Access",
      "PDF Printable Awards",
      "VIP Community Access with Pre-Release Feature Information",
    ],
    YearlyPrice: 149,
    btnTxt: "Subscribe",
  },
];

export const subscriptionPlanTypes = ["Free", "Basic", "Premium", "VIP"];

export default awards;
