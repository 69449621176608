import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import FeaturedMembersProfile from "components/FeaturedMembers/FeaturedMembersProfile";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { MEMBERS_DIRECTORY } from "constants/Links";
import React from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import { FeaturedMembersProps } from "types/Component";

function FeaturedMembers({ featuredMembers, isLoading }: FeaturedMembersProps) {
  return (
    <div className="w-full  px-3 lg:px-8 -mt-6">
      <div className="mb-20 pb-12 bg-[#0B0B38]">
        <div className="w-full border-b border-solid border-b-white flex items-center py-[20px] pl-[24px]">
          <img src={Icons.TopChart} alt="icon" />
          <p className="text-white font-[play] font-bold text-base ml-[15px]">
            FEATURED <span className="text-[#17F9DA]">MEMBERS</span>
          </p>
        </div>
        <div className="px-3 lg:px-3 flex justify-between w-full flex-wrap">
          {isLoading ? (
            <ActivityIndicator size={36} />
          ) : (
            featuredMembers.map((data, i) => (
              <FeaturedMembersProfile key={i} user={data} />
            ))
          )}
        </div>
        <div className="flex justify-center items-center mt-12">
          <div className="w-72">
            <BorderButtonSecondary
              text="View Members Directory"
              onClick={() => globalNavigate(MEMBERS_DIRECTORY)}
              className=""
              fontStyle="font-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedMembers;
