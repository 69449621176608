import TableRecentContactCol from "components/Table/TableRecentContact/TableRecentContactCol";
import React from "react";
import { TableRecentContactProps } from "types/Component";
import { LogBookContactModel } from "types/Models";

function TableRecentContact({ list }: TableRecentContactProps) {
  return (
    <div className="w-full  overflow-x-auto">
      <table className="w-full min-w-max table-auto">
        <tbody>
          <tr className="bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white">
              Date/Time
            </th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white">
              From
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white"></th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white"></th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white"></th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white">
              To
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white"></th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white ">
              <div className=""></div>
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white ">
              <div className=""></div>
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white ">
              <p className=" pl-3 text-left">Frequency</p>
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white ">
              <p className=" pl-3 text-left">Mode</p>
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white ">
              <p className=" pl-3 text-left">Distance</p>
            </th>
            {/* <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white w-[180px]">
              <p className="w-[180px] pl-3 text-left">Distance</p>
            </th> */}
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white ">
              <p className=" pl-3 text-left">UTC Time</p>
            </th>
          </tr>
          {list.map((item: LogBookContactModel, index) => {
            return (
              <TableRecentContactCol key={index} index={index} contact={item} />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableRecentContact;
