import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db, getCountryFromCallSign } from "firebase-config";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { CoordinatesParams } from "store/Actions/profile.actions";
import useConfig from "./config.hook";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { SpotModel } from "types/Models";
import { Timestamp, collection, onSnapshot, query } from "firebase/firestore";
import { Coordinates } from "types/State";
import { SPOT_SOURCE } from "types/Functions";
import { createSpot, editSpot, fetchSpot } from "store/Actions/spotting.action";
import { findBandAndModeForFrequency } from "helpers/Utils";
import { Spotting } from "constants/Collections";
import { getAddSpotModalSelector } from "store/Reducers/logBook.reducer";
import { getIsSpottingLoadingSelector } from "store/Reducers/loading.reducer";
const useSpotting = () => {
  const dispatch = useDispatch();
  const { handlePrimaryModal } = useConfig();
  const spotLoading = useSelector(getIsSpottingLoadingSelector);

  const addSpotModal = useSelector(getAddSpotModalSelector);

  const userProfile = useSelector(getUserProfileSelector);

  const [myCallSign, setMyCallSign] = useState(userProfile?.callSign);
  const [theirCallSign, setTheirCallSign] = useState(
    addSpotModal?.theirCallSign || ""
  );
  const [myLocation, setMyLocation] = useState(
    `${userProfile?.state || userProfile?.city}, ${userProfile?.country}`
  );
  const [theirCountry, setTheirCountry] = useState(
    addSpotModal?.theirCountry || ""
  );
  const [theirState, setTheirState] = useState(addSpotModal?.theirState || "");
  const [theirGrid, setTheirGrid] = useState(addSpotModal?.theirGrid || "");
  const [frequency, setFrequency] = useState(addSpotModal?.frequency || "");
  const [mode, setMode] = useState(addSpotModal?.mode || "");
  const [band, setBand] = useState(addSpotModal?.band || "");
  const [comments, setComments] = useState(addSpotModal?.comments || "");
  const [userCoordinates, setUserCoordinates] = useState<Coordinates>(
    userProfile?.coordinates || { latitude: 0, longitude: 0 }
  );

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (addSpotModal && addSpotModal.theirCallSign) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserCountryFromCallSign = async (callSign: string) => {
    try {
      setLoading(true);
      const countryResponse: any = await getCountryFromCallSign(callSign);
      if (countryResponse && countryResponse.data) {
        setTheirCountry(countryResponse?.data?.Country || "");
      } else {
        setTheirCountry("");
      }
    } catch (e) {
      setTheirCountry("");
    }

    setLoading(false);
  };
  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const onFindMyLocationClicked = async () => {
    const coor: CoordinatesParams = await fetchCoordinates();
    if (coor) {
      setUserCoordinates(coor);
    } else {
      handlePrimaryModal({
        title:
          "We dont have access to your location data, you can give us permission by going to the top URL section of your app and granting us permission.",
        type: "Location Permission",
        onCancelPressed: () => handlePrimaryModal(null),
        onSubmitPressed: () => {
          handlePrimaryModal(null);
        },
        submitText: "Ok",
        cancelText: "Cancel",
      });
    }
  };

  const onFrequencyBlur = () => {
    try {
      if (frequency) {
        const freqNumber = parseFloat(frequency);
        const bandMode = findBandAndModeForFrequency(freqNumber);
        if (bandMode) {
          setBand(bandMode.band);
          setMode(bandMode.mode);
        } else {
          setBand("");
          setMode("");
        }
      }
    } catch (e) {}
  };

  const saveSpot = () => {
    if (myCallSign) {
      if (theirCallSign) {
        if (frequency) {
          if (mode) {
            const spotData: SpotModel = {
              myCallSign,
              theirCallSign,
              myLocation,
              theirCountry,
              theirState,
              theirGrid,
              frequency,
              mode,
              comments,
              myCoordinates: userCoordinates,
              timestamp: Timestamp.fromDate(new Date()),
              spotSource: SPOT_SOURCE.WRL_SPOTTING,
              uid: userProfile?.uid,
              lastSeen: new Date().getTime(),
              myCountry: userProfile?.country,
              myState: userProfile?.state || userProfile?.city,
              myProfilePic: userProfile?.profilePic || "",
              band,
            };
            dispatch(createSpot(spotData));
          } else {
            toast("Please enter the mode");
          }
        } else {
          toast("Please enter the frequency");
        }
      } else {
        toast("Please enter the other persons callsign");
      }
    } else {
      toast("Please enter your call sign");
    }
  };

  const respotSpot = () => {
    if (myCallSign) {
      if (theirCallSign) {
        if (frequency) {
          if (mode) {
            const spotData: any = {
              ...addSpotModal,
              id: addSpotModal?.id,
              myCallSign,
              theirCallSign,
              frequency,
              mode,
              myCoordinates: userCoordinates,
              lastSeen: new Date().getTime(),
              spotCount: addSpotModal?.spotCount
                ? addSpotModal?.spotCount + 1
                : 1,
              myCountry: userProfile?.country,
              myState: userProfile?.state || userProfile?.city,
              myProfilePic: userProfile?.profilePic || "",
            };
            dispatch(editSpot(spotData));
          } else {
            toast("Please enter the mode");
          }
        } else {
          toast("Please enter the frequency");
        }
      } else {
        toast("Please enter the other persons callsign");
      }
    } else {
      toast("Please enter your call sign");
    }
  };

  const spotCollectionListener = () => {
    const unsubscribe = onSnapshot(
      query(collection(db, Spotting)),
      (snapshot) => {
        // console.log("SPOTTING REFRESHED");
        // dispatch(fetchSpot([], false));
      }
    );
    return unsubscribe;
  };

  return {
    myCallSign,
    setMyCallSign,
    theirCallSign,
    setTheirCallSign,
    myLocation,
    setMyLocation,
    theirCountry,
    setTheirCountry,
    theirState,
    setTheirState,
    theirGrid,
    setTheirGrid,
    frequency,
    setFrequency,
    mode,
    setMode,
    comments,
    setComments,
    getUserCountryFromCallSign,
    loading,
    onFindMyLocationClicked,
    saveSpot,
    onFrequencyBlur,
    spotCollectionListener,
    editMode,
    respotSpot,
    spotLoading,
    userProfile,
  };
};

export default useSpotting;
