import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logbookTabs } from "constants/NavMenus";
import LogContacts from "./LogContacts";
import LogbookSettings from "./LogBookSettings/LogbookSettings";
import Icons from "assets/Icons";
import useLogBook from "hooks/logBook.hook";
import { Templates } from "constants/Config";
import { globalNavigate } from "routes/GlobalRoutes";
import { fetchContestDetails } from "store/Actions/contest.actions";
// import useMap from "hooks/map.hook";

function AddContactLogBook() {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState<string | null>("");

  const location = useLocation();
  const { selectedLogBook } = useLogBook();
  const isPota = selectedLogBook?.logbookStyle === Templates.POTA;

  useEffect(() => {
    if (!searchParams.get("tab")) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("tab", "Log Contacts");
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedLogBook?.contestId) {
      dispatch(
        fetchContestDetails({
          contestId: selectedLogBook?.contestId,
          userId: "",
        })
      );
    }
  }, []);

  useEffect(() => {
    let selectedTab = searchParams.get("tab")
      ? searchParams.get("tab")
      : "Logbook";

    if (searchParams.get("contest_id")) {
      selectedTab = searchParams.get("contest_tab");
    }

    setSelectedIndex(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getLogbookName = () => {
    let name = searchParams.get("logbook-name");

    if (selectedLogBook?.name) {
      name = selectedLogBook?.name;
    } else if (isPota) {
      name = `${selectedLogBook?.defaultCallSign} @ `;
      name += Object.keys(selectedLogBook?.myParks || {}).join(", ");
    }

    return name;
  };

  const getTabs = () => {
    if (selectedIndex === "Log Contacts") {
      return <LogContacts />;
    } else if (selectedIndex === "Logbook Settings") {
      return <LogbookSettings />;
    } else {
      return <LogContacts />;
    }
  };

  const onSettingsClickHandler = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (searchParams.get("contest_id")) {
      newSearchParams.set("contest_tab", logbookTabs[1]);
    } else {
      newSearchParams.set("tab", logbookTabs[1]);
    }
    setSearchParams(newSearchParams);
  };

  const onBackClickHandler = () => {
    if (searchParams.get("contest_id")) {
      if (searchParams.get("contest_tab") === logbookTabs[1]) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("contest_tab");
        setSearchParams(newSearchParams);
      } else {
        globalNavigate(-1);
      }
    } else {
      if (searchParams.get("tab") === logbookTabs[0]) {
        globalNavigate("/log-contacts");
      } else {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("tab", logbookTabs[0]);
        setSearchParams(newSearchParams);
      }
    }
  };

  return (
    <main
      className={`relative w-full h-full ${
        searchParams.get("contest_id") ? "" : "lg:w-4/5"
      }`}
    >
      {searchParams.get("contest_id") ? null : <Header title="Add a Contact" />}
      <div
        className={`w-full flex flex-col items-center pt-6 pb-7 sm:pb-10 overflow-x-hidden dashboardWrapper ${
          searchParams.get("contest_id") ? "" : "px-3 sm:px-7"
        }`}
      >
        <div className="flex flex-row w-full text-white py-5 justify-between">
          <div
            className="flex flex-row gap-1 items-center cursor-pointer"
            onClick={onBackClickHandler}
          >
            <img src={Icons.BackIcon} alt="back button" /> Back
          </div>
          <div className="flex items-center text-2xl font-Play gap-2">
            {isPota ? <img src={Icons.PotaTree} alt="logbook name" /> : null}
            {getLogbookName()}
          </div>
          <div
            className="cursor-pointer items-center"
            onClick={onSettingsClickHandler}
          >
            <img src={Icons.SettingsIcon} alt="settings button" />
          </div>
        </div>

        {getTabs()}
      </div>
    </main>
  );
}

export default AddContactLogBook;
