import React, { useState } from "react";

import TabNavGlow from "components/TabNavGlow";
import { stationTabs } from "constants/NavMenus";
import Awards from "components/Awards";

const AdminOptions = () => {
  const [selectedIndex, setSelectedIndex] = useState<string | null>("Awards");

  const getLayout = () => {
    switch (selectedIndex) {
      case "Awards":
        return <Awards />;
      default:
        return <Awards />;
    }
  };

  return (
    <div className="w-full flex justify-center p-10 dashboardWrapper overflow-x-hidden">
      <div className="h-full w-full overflow-x-hidden">
        <div className="glassmorphism w-full h-fit pb-6">
          <div className="px-3 md:px-7 py-5 flex items-center justify-between border-b border-slate-400 border-solid">
            <p className="font-[play] font-bold text-lg text-white">
              More Options
            </p>
          </div>
          <div className="mt-6 mb-5 h-fit overflow-x-scroll">
            <div className="w-full flex justify-start -mt-6">
              <TabNavGlow
                selectedIndex={selectedIndex ? selectedIndex : ""}
                setSelectedIndex={(index) => {
                  setSelectedIndex(index);
                }}
                tabs={["Awards"]}
              />
            </div>
          </div>
          {getLayout()}
        </div>
      </div>
    </div>
  );
};

export default AdminOptions;
