import { LeaderBoardState } from "types/State";
import { RootState } from "store/Reducers";
import {
  LeaderBoardActionPayload,
  SAVE_USERS_DATA_FOR_LEADERBOARD,
  SAVE_USERS_DATA_FOR_LEADERBOARD_WITH_MODE,
  SET_WEEK_LEADERS,
  SET_WEEK_LEADER_MODE,
  SET_DISTANCE_LEADERBOARD,
  SET_DX_LEADERBOARD,
  SET_STATE_LEADER
} from "store/Actions/leaderBoard.action";
const defaultState: LeaderBoardState = {
  leaderBoardUsersData: [],
  leaderBoardUsersDataWithMode: [],
  weekLeaders: [],
  weekModeLeaders: [],
  distanceLeaders: [],
  dxLeaders: [],
  stateLeader: null
};

export default function leaderBoardReducer(
  state = defaultState,
  action: LeaderBoardActionPayload
) {
  switch (action.type) {
    case SAVE_USERS_DATA_FOR_LEADERBOARD:
      return {
        ...state,
        leaderBoardUsersData: action.payload,
      };
    case SAVE_USERS_DATA_FOR_LEADERBOARD_WITH_MODE:
      return {
        ...state,
        leaderBoardUsersDataWithMode: action.payload,
      };
    case SET_WEEK_LEADERS:
      return {
        ...state,
        weekLeaders: action.payload,
      };
    case SET_WEEK_LEADER_MODE:
      return {
        ...state,
        weekModeLeaders: action.payload,
      };
    case SET_DISTANCE_LEADERBOARD:
      return {
        ...state,
        distanceLeaders: action.payload,
      };
    case SET_DX_LEADERBOARD:
      return {
        ...state,
        dxLeaders: action.payload,
      };
    case SET_STATE_LEADER:
      return {
        ...state,
        stateLeader: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getLeaderBoardUsersData = (state: RootState) => {
  return state.leaderBoard.leaderBoardUsersData;
};

export const getLeaderBoardUsersDataWithMode = (state: RootState) => {
  return state.leaderBoard.leaderBoardUsersDataWithMode;
};
export const getWeekLeadersSelector = (state: RootState) => {
  return state.leaderBoard.weekLeaders;
};

export const getWeekModeLeadersSelector = (state: RootState) => {
  return state.leaderBoard.weekModeLeaders;
};

export const getDistanceLeadersSelector = (state: RootState) => {
  return state.leaderBoard.distanceLeaders;
};

export const getDXLeadersSelector = (state: RootState) => {
  return state.leaderBoard.dxLeaders || [];
};

export const getStateLeaderSelector = (state: RootState) => {
  return state.leaderBoard.stateLeader;
};
