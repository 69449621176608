import { db, auth, getAllSpots } from "firebase-config";
import { SpotModel } from "types/Models";
import { Spotting } from "constants/Collections";
import { collection, doc, setDoc } from "firebase/firestore";

interface SpottingServiceType {
  createSpot(data: SpotModel): Promise<boolean | null>;
  fetchSpot(data: any): Promise<SpotModel[] | null>;
  editSpot(data: any): Promise<boolean | null>;
}

const SpottingService: SpottingServiceType = {
  createSpot: async (data) => {
    const uid: string | undefined = auth.currentUser?.uid;
    if (uid) {
      const spotRef = doc(collection(db, Spotting));
      try {
        await setDoc(spotRef, data);
        return true;
      } catch (e) {
        console.error("createSpot ERROR : ", e);
        return null;
      }
    } else {
      return null;
    }
  },
  fetchSpot: async (data) => {
    try {
      let spots: SpotModel[] = [];
      const getSpots: { data: any } = await getAllSpots(data);

      if (getSpots && getSpots.data && Array.isArray(getSpots.data)) {
        spots = getSpots.data as SpotModel[];
      }
      return spots;
    } catch (e) {
      return null;
    }
  },
  editSpot: async (data) => {
    try {
      const spotRef = doc(db, Spotting, `${data.id}`);
      await setDoc(spotRef, data, { merge: true });
      return true;
    } catch (e) {
      return null;
    }
  },
};

export default SpottingService;
