import React, { useEffect } from "react";
import Icons from "assets/Icons";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { subscriptionPlanTypes } from "constants/Awards";

import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useContest from "hooks/contest.hook";
import { AllBandsOption, Modes } from "constants/ArrayVariables";

import { UserAwards } from "types/Models";

import TimePicker from "react-time-picker";
import { convertBandValue } from "helpers/Utils";
const AddContestModal = () => {
  const {
    toggleAddContestModal,
    name,
    setName,

    setImage,
    description,

    startDate,
    setStartDate,
    endDate,
    setEndDate,
    membershipLevel,
    setMembershipLevel,
    bands,
    setBands,
    modes,
    setModes,
    geo,
    setGeo,
    multiplier,
    setMultiplier,
    exchangeOne,
    setExchangeOne,
    exchangeTwo,
    setExchangeTwo,
    onPillClick,
    onSaveContest,
    isContestLoading,
    shortDescription,
    setShortDescription,
    onDescriptionTitleChange,
    onDescriptionBodyChange,
    addSectionClicked,
    removeSectionClicked,
    fetchAwardContest,
    awardContest,
    setSelectedAwards,
    selectedAwards,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
  } = useContest();

  const getPlans = (selectedPlan: string) => {
    const startIndex = subscriptionPlanTypes.indexOf(selectedPlan);
    return subscriptionPlanTypes.slice(startIndex + 1);
  };

  useEffect(() => {
    fetchAwardContest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAwardSelect = (item: UserAwards) => {
    const index = selectedAwards.findIndex(
      (award: any) => award.id === item.id
    );
    if (index > -1) {
      const newArray = [...selectedAwards];
      newArray.splice(index, 1);
      setSelectedAwards(newArray);
    } else {
      setSelectedAwards([...selectedAwards, item]);
    }
  };

  const isAwardSelected = (item: UserAwards) => {
    return selectedAwards.findIndex((award: any) => award.id === item.id) > -1;
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[5%] lg:px-[10%]">
      <div className="overflow-x-hidden w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] px-3 py-5 items-center">
            <h5 className="font-[Play] text-xl font-bold text-white py-2 ml-3 w-full">
              Create Contest (Admin)
            </h5>
            <TitledInputDefault
              setText={(e) => setName(e.target.value)}
              name="contestName"
              title="Contest Name"
              placeHolder="Contest Name"
              width={"w-[78.5%]"}
              className={""}
              tabindex={1}
              value={name}
              error={""}
            />
            <TitledInputDefault
              setText={(e) => {
                setImage(e.target?.files);
              }}
              name="contestName"
              title="Featured Image"
              placeHolder="Contest Name"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              type="file"
              // value={image}
              error={""}
            />
            <TitledInputDefault
              setText={(e) => setShortDescription(e.target.value)}
              name="contestName"
              title="Short Description"
              placeHolder="short description"
              width={"w-[78.5%]"}
              className={""}
              tabindex={1}
              value={shortDescription}
              error={""}
            />
            <div className="w-[78.5%] mb-3 flex justify-between">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Description Section
              </p>
              <button
                className="text-[#17F9DA] cursor-pointer"
                onClick={addSectionClicked}
              >
                Add Description Section
              </button>
            </div>
            <div className="w-[78.5%] mb-3 ml-20">
              {description && description?.length
                ? description.map((desc, index) => (
                    <div>
                      <div className="w-[78.5%] flex justify-between">
                        <span></span>
                        <button
                          className="text-[red] cursor-pointer justify-end"
                          onClick={() => removeSectionClicked(index)}
                        >
                          Remove Section
                        </button>
                      </div>
                      <TitledInputDefault
                        setText={(e) =>
                          onDescriptionTitleChange(index, e.target.value)
                        }
                        name={"Description " + (index + 1)}
                        title={"Description " + (index + 1)}
                        placeHolder={`Description ${index + 1} Title`}
                        width={"w-[78.5%]"}
                        className={""}
                        tabindex={1}
                        value={desc.title}
                        error={""}
                      />
                      <TitledInputDefault
                        setText={(e) =>
                          onDescriptionBodyChange(index, e.target.value)
                        }
                        name={"Description Body" + (index + 1)}
                        title={"Description Body " + (index + 1)}
                        placeHolder={`Description ${index + 1} Body`}
                        width={"w-[78.5%]"}
                        className={"h-20"}
                        tabindex={1}
                        value={desc.description}
                        error={""}
                        multiple={true}
                      />
                      <hr className="w-[78.5%] mb-4" />
                    </div>
                  ))
                : null}
            </div>
            <div className="flex w-[78.5%] items-center">
              <TitledInputDefault
                setText={(e) => setStartDate(e.target.value)}
                name="contestName"
                title="Start Date / Time (UTC)"
                placeHolder="start date"
                width={"w-[50.5%]"}
                className={""}
                tabindex={2}
                type="date"
                value={startDate}
                error={""}
              />
              <TimePicker
                maxTime="23:59:59"
                locale="sv-sv"
                className={`h-8 bg-[#23234C] text-white font-[Barlow] text-sm w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%] ml-2 mt-[5px]`}
                onChange={(e: any) => {
                  setStartTime(e);
                }}
                value={startTime}
                disableClock={true}
                id=""
                clockClassName="hidden"
              />
            </div>
            <div className="flex w-[78.5%] items-center">
              <TitledInputDefault
                setText={(e) => setEndDate(e.target.value)}
                name="contestName"
                title="End Date / Time (UTC)"
                placeHolder="end date"
                width={"w-[50.5%]"}
                className={""}
                tabindex={2}
                type="date"
                value={endDate}
                error={""}
              />
              <TimePicker
                maxTime="23:59:59"
                locale="sv-sv"
                className={`h-8 bg-[#23234C] text-white font-[Barlow] text-sm w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[13%] ml-2 mt-[5px]`}
                onChange={(e: any) => {
                  setEndTime(e);
                }}
                value={endTime}
                disableClock={true}
                id=""
                clockClassName="hidden"
              />
            </div>

            <div className="w-[78.5%] mb-1">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Membership Status
              </p>
            </div>
            <div className="flex items-start justify-start w-[78.5%] mt-2 mb-4">
              {subscriptionPlanTypes.map((item, index) => (
                <button
                  onClick={() =>
                    onPillClick(item, getPlans(item), setMembershipLevel)
                  }
                  className={`rounded-full mx-4 ${
                    membershipLevel.includes(item)
                      ? "bg-[#17F9DA] text-black"
                      : "bg-[#23234C] text-white"
                  }  px-6 py-1 font-[Play] text-sm `}
                >
                  {item}
                </button>
              ))}
            </div>
            <div className="w-[78.5%] mt-4">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Bands
              </p>
            </div>
            <div className="flex items-start justify-start w-[78.5%] mt-2 mb-4 flex-wrap">
              {AllBandsOption.map((item, index) => (
                <button
                  onClick={() => onPillClick(item.name, bands, setBands)}
                  className={`rounded-full mx-4 mb-3 ${
                    bands.includes(item.name)
                      ? "bg-[#17F9DA] text-black"
                      : "bg-[#23234C] text-white"
                  }  px-6 py-1 font-[Play] text-sm `}
                >
                  {convertBandValue(item.name, item.unit)+item.unit}
                </button>
              ))}
            </div>
            <div className="w-[78.5%] mt-4">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Modes
              </p>
            </div>
            <div className="flex items-start justify-start w-[78.5%] mt-2 mb-4 flex-wrap">
              {Modes.map((item, index) => (
                <button
                  onClick={() => onPillClick(item, modes, setModes)}
                  className={`rounded-full mx-4 mb-3 ${
                    modes.includes(item)
                      ? "bg-[#17F9DA] text-black"
                      : "bg-[#23234C] text-white"
                  }  px-6 py-1 font-[Play] text-sm `}
                >
                  {item}
                </button>
              ))}
            </div>
            <TitledInputDefault
              setText={(e) => setGeo(e.target.value)}
              name="contestName"
              title="User Geo"
              placeHolder="user geo"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              value={geo}
              error={""}
            />
            <TitledInputDefault
              setText={(e) => setMultiplier(e.target.value)}
              name="contestName"
              title="Points Multiplier"
              placeHolder="points multiplier"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              type="number"
              value={multiplier}
              error={""}
            />
            <TitledInputDefault
              setText={(e) => setExchangeOne(e.target.value)}
              name="contestName"
              title="Contest Exchange 1"
              placeHolder="exchange 1"
              width={"w-[78.5%]"}
              className={""}
              tabindex={2}
              value={exchangeOne}
              error={""}
            />
            <TitledInputDefault
              setText={(e) => setExchangeTwo(e.target.value)}
              name="contestName"
              title="Contest Exchange 2"
              placeHolder="exchange 2"
              width={"w-[78.5%] mb-6"}
              className={""}
              tabindex={2}
              value={exchangeTwo}
              error={""}
            />
            <div className="w-[78.5%]">
              <p className="font-[Barlow] font-bold text-sm text-white truncate text-left self-start">
                Contest Awards (Select Awards to be given to the winners)
              </p>
            </div>
            <div className="flex w-[78.5%] mt-4 mb-10">
              {awardContest &&
                awardContest?.map((item, index) => (
                  <div
                    className={`w-50 h-50 mr-4 border rounded-lg p-4 cursor-pointer ${
                      isAwardSelected(item) ? "bg-[#17F9DA]" : ""
                    } `}
                    onClick={() => {
                      handleAwardSelect(item);
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-[200px] h-[200px]"
                    />
                    <h3
                      className={`font-[Barlow] text-sm mt-3 ${
                        isAwardSelected(item) ? "text-black" : "text-white"
                      } `}
                    >
                      {item.name}
                    </h3>
                    <p
                      className={`${
                        isAwardSelected(item) ? "text-black" : "text-white"
                      }`}
                    >
                      {item.description}
                    </p>
                  </div>
                ))}
            </div>
            <div className="w-full sm:w-[49%] md:w-[39%] xl:w-[27.5%] flex justify-between mb-8 mt-10 sm:mt-0">
              <div className="flex items-end w-[47.5%]">
                <BorderButtonSecondary
                  isLoading={false}
                  text={"Cancel"}
                  onClick={() => {
                    toggleAddContestModal(false);
                  }}
                  childClassName="text-sm"
                />
              </div>
              <div className="flex items-end w-[47.5%]">
                <BorderButtonSecondary
                  isLoading={isContestLoading}
                  text={"Save"}
                  onClick={() => onSaveContest()}
                  childClassName="text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute top-22 right-16 cursor-pointer"
        onClick={() => toggleAddContestModal(false)}
      >
        <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
      </div>
    </section>
  );
};

export default AddContestModal;
