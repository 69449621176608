import { Award } from "types/Models";

export const FETCH_AWARDS_DATA = "FETCH_AWARDS_DATA";
export const ADD_AWARD = "ADD_AWARD";
export const SET_AWARDS_DATA = "SET_AWARD_DATA";
export const SET_AWARD_LOADING = "SET_AWARD_LOADING";
export const SET_SHOW_AWARD_MODAL = "SET_SHOW_AWARD_MODAL";

export interface AwardActionPayload {
  type: string;
  payload?: any;
}

export function fetchAwards(): AwardActionPayload {
  return {
    type: FETCH_AWARDS_DATA,
  };
}

export function addAward(data: Award): AwardActionPayload {
  return {
    type: ADD_AWARD,
    payload: data,
  };
}

export function setAwardsData(data: any): AwardActionPayload {
  return {
    type: SET_AWARDS_DATA,
    payload: data,
  };
}

export function setAwardLoading(isLoading: boolean): AwardActionPayload {
  return {
    type: SET_AWARD_LOADING,
    payload: isLoading,
  };
}

export function setShowAwardModal(show: boolean): AwardActionPayload {
  return {
    type: SET_SHOW_AWARD_MODAL,
    payload: show,
  };
}