import useLogBook from "hooks/logBook.hook";
import { useEffect, useState } from "react";
import { ArcType, LogBookContactModel } from "types/Models";

const useGlobe = () => {
  const OPACITY = 0.8;
  const [logBookArc, setLogBookArc] = useState<ArcType[]>([]);
  const [hoverArc, setHoverArc] = useState<ArcType | null>(null);
  const { allContacts } = useLogBook();
  const [selectedGlobe, setSelectedGlobe] = useState<boolean>(true);

  const handleLogBookArcs = () => {
    let AllArcs: ArcType[] = [];
    allContacts.forEach((item: LogBookContactModel) => {
      if (
        item?.myCoordinates?.latitude &&
        item?.myCoordinates?.longitude &&
        item?.theirCoordinates?.latitude &&
        item?.theirCoordinates?.longitude
      ) {
        AllArcs.push({
          startLat: item?.myCoordinates?.latitude,
          startLng: item?.myCoordinates?.longitude,
          endLat: parseFloat(item?.theirCoordinates?.latitude),
          endLng: parseFloat(item?.theirCoordinates?.longitude),
          theirCallsign: item?.theirCallsign,
          theirCountry: item?.theirCountry,
          myCountry: item?.myCountry,
          theirState: item?.theirState,
          myCallSign: item?.myCallSign,
          state: item?.state,
          country: item?.country,
          color: ["#17F9DA"],
          flagCode: item.flagCode,
          myFlagCode: item.myFlagCode
        });
      }
    });
    setLogBookArc(AllArcs);
  };

  useEffect(() => {
    handleLogBookArcs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContacts]);

  return {
    logBookArc,
    selectedGlobe,
    setSelectedGlobe,
    hoverArc,
    setHoverArc,
    OPACITY,
  };
};

export default useGlobe;
