import moment from "moment";
import { FaCopy, FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";
import { ContestRulesProps, ToastTypes } from "types/Component";
import MDEditor from "@uiw/react-md-editor";
import ContestCountDown from "components/ContestCountDown/index";
import { CONTEST_STATUS } from "types/Functions";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useContest from "hooks/contest.hook";
import { showToast } from "helpers/Toast";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Linkify from "react-linkify";
import { UserAvatar } from "components/UserAvatar";
import { UserProfile } from "types/Models";
import { Tooltip } from "components/Tooltip";
const componentDecorator = (href: any, text: any, key: any) => (
  <a
    href={href}
    key={key}
    style={{ color: "#17F9DA" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);
function ContestRules({ contestDetail, contestStatus }: ContestRulesProps) {
  const {
    enrollToContest,
    isEnrollContestLoading,
    contestantDetails,
    isContestantDetailLoading,
    setSelectedLogbook,
  } = useContest();

  let [searchParams, setSearchParams] = useSearchParams();

  const getContestStatusText = () => {
    if (contestStatus === CONTEST_STATUS.UPCOMING) {
      return "Contest starts in";
    } else if (contestStatus === CONTEST_STATUS.ACTIVE) {
      return "Contest ends in";
    } else if (contestStatus === CONTEST_STATUS.PASSED) {
      return "Contest has ended";
    } else {
      return "Contest has ended";
    }
  };

  const goToLogContact = () => {
    // if (contestStatus === CONTEST_STATUS.ACTIVE) {
    setSelectedLogbook({
      id: contestantDetails?.enrolledUser.logBookId,
      name: contestantDetails?.enrolledUser.logbookName,
      uid: contestantDetails?.enrolledUser.uid,
      contestId: contestDetail?.id,
      contest: {
        name: contestDetail?.name || "",
        startDate: contestDetail?.startDate,
        endDate: contestDetail?.endDate,
        image: contestDetail?.image || "",
        shortDescription: contestDetail?.shortDescription || "",
      },
    });
    setSearchParams((prev: any) => {
      return {
        ...prev,
        tab: "log_contest_contacts",
        contest_id: searchParams.get("contest_id"),
        "logbook-id": contestantDetails?.enrolledUser.logBookId,
        "logbook-name": contestantDetails?.enrolledUser.logbookName,
      };
    });
    // } else {
    //   showToast({
    //     message: "Contest hasn't started yet.",
    //     type: ToastTypes.WARN,
    //   });
    // }
  };

  const renderParticipant = (user: UserProfile) => {
    if (user.profilePic) {
      return (
        <Tooltip
          message={user.callSign || ""}
          children={
            <img
              className="w-[30px] h-[30px] rounded-full mx-1 mb-2"
              src={user.profilePic}
              alt=""
            />
          }
        />
      );
    } else {
      return (
        <Tooltip
          message={user.callSign || ""}
          children={<UserAvatar className="w-[30px] h-[30px] mx-1 mb-2" />}
        />
      );
    }
  };
  

  return (
    <div className="rounded-[20px] sm:rounded-[25px] md:rounded-[50px] bg-[#0B0B39] flex flex-col items-center pl-5 sm:pl-6 pr-5 sm:pr-10 mt-7 pt-7">
      <div className="flex justify-between items-center w-full flex-wrap">
        <div className="mb-5 flex items-center bg-[#30326E] border-[2px] border-solid border-[#4AA0D3] py-2 px-4 rounded-[10px] w-full md:w-[340px]">
          <p className="text-white mr-1">Share to Social Media:</p>
          <FacebookShareButton
            url={`${process.env.REACT_APP_SHARE_URL}contest/${contestDetail.id}`}
            quote={"Check out this Contest on World Radio League"}
            hashtag="#WorldRadioLeague"
          >
            <div className="w-[25px] h-[25px] rounded-[4px] bg-[#1877F2] flex items-center justify-center mr-2">
              <FaFacebook size={16} color="#fff" />
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            url={`${process.env.REACT_APP_SHARE_URL}contest/${contestDetail.id}`}
            title={"Check out this Contest on World Radio League"}
            hashtags={["WorldRadioLeague"]}
          >
            <div className="w-[25px] h-[25px] rounded-[4px] bg-[#1DA1F2] flex items-center justify-center mr-2">
              <FaTwitter size={16} color="#fff" />
            </div>
          </TwitterShareButton>

          <LinkedinShareButton
            url={`${process.env.REACT_APP_SHARE_URL}contest/${contestDetail.id}`}
            title="Check out this Contest on World Radio League"
            summary="Check out this Contest on World Radio League"
            source="https://www.worldradioleague.com"
          >
            <div className="w-[25px] h-[25px] rounded-[4px] bg-[#2867B2] flex items-center justify-center mr-2">
              <FaLinkedin size={16} color="#fff" />
            </div>
          </LinkedinShareButton>

          <div
            className="w-[25px] h-[25px] rounded-[4px] bg-transparent border-white border-[0.5px] flex items-center justify-center mr-2 cursor-pointer"
            onClick={async () => {
              await navigator.clipboard.writeText(
                `${process.env.REACT_APP_SHARE_URL}contest/${contestDetail.id}`
              );
              showToast({
                message: "Share link copied to clipboard!",
                type: ToastTypes.SUCCESS,
              });
            }}
          >
            <FaCopy size={16} color="#fff" />
          </div>

          {/* <div className="w-[25px] h-[25px] rounded-[4px] bg-[#F00073] flex items-center justify-center mr-2">
            <FaInstagram size={16} color="#fff" />
          </div> */}
        </div>
        {contestStatus === CONTEST_STATUS.PASSED ? null : (
          <div className="w-full md:w-[250px]">
            <BorderButtonSecondary
              className="py-[1.5px]"
              height="h-11"
              text={
                contestantDetails?.isUserEnrolled
                  ? "Go To My Logbook"
                  : "Enroll Now for FREE!"
              }
              onClick={() => {
                if (contestantDetails?.isUserEnrolled) {
                  goToLogContact();
                } else {
                  enrollToContest(contestDetail);
                }
              }}
              childClassName="text-sm md:text-base py-2 px-[10px]"
              isLoading={isEnrollContestLoading || isContestantDetailLoading}
            />
          </div>
        )}
      </div>
      <h3 className="text-white font-[Play] font-bold text-[65px] text-center leading-[65px] mt-10">
        {contestDetail.name}
      </h3>

      <p className="mt-10 mb-2 text-white font-[Play]">
        {getContestStatusText()}
      </p>
      {contestDetail.startDate && contestDetail.endDate ? (
        <ContestCountDown
          startDate={
            new Date(
              new Date(contestDetail.startDate.seconds * 1000).toISOString()
            )
          }
          endDate={
            new Date(
              new Date(contestDetail.endDate.seconds * 1000).toISOString()
            )
          }
        />
      ) : null}
      {/* @ts-ignore */}
      {/* @ts-ignore */}
      <Linkify componentDecorator={componentDecorator}>
        <p className="text-[14px] text-center text-white py-7">
          {contestDetail.shortDescription}
        </p>
      </Linkify>

      <p className="text-white font-[Barlow] font-bold text-[16px] mt-5">
        <span className="text-[#17F9DA]">Starts:</span>{" "}
        {moment(new Date(contestDetail.startDate.seconds * 1000))
          .utc()
          .format("dddd, DD MMMM, YYYY  HH:mm")}
        <span className="text-[#17F9DA] ml-3">Ends:</span>{" "}
        {moment(new Date(contestDetail.endDate.seconds * 1000))
          .utc()
          .format("dddd, DD MMMM, YYYY  HH:mm")}
      </p>

      {contestDetail.image ? (
        <img
          className="my-10 w-full"
          src={contestDetail.image}
          alt={contestDetail.name}
        />
      ) : (
        <div className="my-10"></div>
      )}

      {contestStatus === CONTEST_STATUS.PASSED ? null : (
        <div className="w-[250px] mb-5 sm:mb-0">
          <BorderButtonSecondary
            className="py-[1.5px] mb-5"
            height="h-11"
            text={
              contestantDetails?.isUserEnrolled
                ? "Go To My Logbook"
                : "Enroll Now for FREE!"
            }
            onClick={() => {
              if (contestantDetails?.isUserEnrolled) {
                goToLogContact();
              } else {
                enrollToContest(contestDetail);
              }
            }}
            childClassName="text-sm md:text-base py-2 px-[10px]"
            isLoading={isEnrollContestLoading || isContestantDetailLoading}
          />
        </div>
      )}

      <div className="flex items-center mb-5 flex-wrap">
        <p className="text-[#4AA0D3] mr-4 font-[Barlow] font-bold text-[14px]">
          {contestantDetails?.contestantCount} people contesting
        </p>
        {contestantDetails?.contestants.map(renderParticipant)}
        {/* <p className="text-[#777] font-[Barlow] font-light ml-4 text-[12px]">
          see details
        </p> */}
      </div>
      <div className="h-[1px] bg-white w-full" />
      <div className="my-6 items-start justify-start w-full">
        {contestDetail.description &&
        Array.isArray(contestDetail.description) ? (
          contestDetail.description.map((desc) => (
            <div>
              <p className="font-[Barlow] font-bold text-[#17F9DA] text-[18px]">
                {desc.title}
              </p>
              <Linkify componentDecorator={componentDecorator}>
                <div className="whitespace-pre-wrap text-white font-regular text-[14px]">
                  {desc.description}
                </div>
              </Linkify>
              <div className="h-[1px] bg-white w-full my-6" />
            </div>
          ))
        ) : (
          <MDEditor.Markdown
            source={contestDetail.description}
            style={{ whiteSpace: "pre-wrap" }}
          />
        )}
      </div>
      {contestDetail.awards ? (
        <div className="w-full justify-between mb-4 flex-wrap">
          <p className="font-[Barlow] font-bold text-[#17F9DA] text-[18px]">
            Awards
          </p>
          <div className="flex mt-4">
            {contestDetail.awards.map((item) => {
              return (
                <img
                  className="w-full sm:w-[48%] md:w-[23%] mb-5 mr-4"
                  src={item.image}
                  alt=""
                />
              );
            })}
          </div>
          <div className="h-[1px] bg-white w-full" />
        </div>
      ) : null}

      {contestStatus === CONTEST_STATUS.PASSED ? null : (
        <div className="w-[250px] mt-5">
          <BorderButtonSecondary
            className="py-[1.5px] mb-5"
            height="h-11"
            text={
              contestantDetails?.isUserEnrolled
                ? "Go To My Logbook"
                : "Enroll Now for FREE!"
            }
            onClick={() => {
              if (contestantDetails?.isUserEnrolled) {
                goToLogContact();
              } else {
                enrollToContest(contestDetail);
              }
            }}
            childClassName="text-sm md:text-base py-2 px-[10px]"
            isLoading={isEnrollContestLoading || isContestantDetailLoading}
          />
        </div>
      )}
    </div>
  );
}

export default ContestRules;
