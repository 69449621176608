import { BorderButtonPrimaryProps } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";

function BorderButtonPrimary({
  text,
  onClick,
  disabled,
  className,
  isLoading,
  height,
  type,
  childClassName,
}: BorderButtonPrimaryProps) {
  return (
    <button
      className={`bg-gradient-to-r from-[#FFD80E] via-[#272750] to-[#2CD9FF] w-full mb-0 sm:mb-5 ${
        className ? className : ""
      } ${height ? height : "h-9"} ${
        isLoading ? "pointer-events-none" : ""
      } rounded-full flex justify-center items-center`}
      onClick={onClick}
      type={type ? type : "button"}
    >
      <div
        className={`text-white bg-[#1D204A] w-[calc(100%-3px)] h-[calc(100%-3px)] rounded-full flex justify-center items-center font-Play ${
          childClassName ? childClassName : "text-sm sm:text-base"
        }`}
      >
        {isLoading ? <ActivityIndicator size={16} /> : text}
      </div>
    </button>
  );
}

export default BorderButtonPrimary;
