import Icons from "assets/Icons";
import { globalNavigate } from "routes/GlobalRoutes";

export const NavigateBackButton = () => {
  const onBackClickHandler = () => {
    globalNavigate(-1);
  };

  return (
    <div className="flex flex-row w-full text-white pb-5 justify-between">
      <div
        className="flex flex-row gap-1 items-center cursor-pointer"
        onClick={onBackClickHandler}
      >
        <img src={Icons.BackIcon} alt="back button" /> Back
      </div>
    </div>
  );
};
