import { useField } from "formik";
import { useEffect, useState } from "react";
import {
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { RegionPickerWithLabelProps } from "types/Component";

function RegionPickerWithLabel({
  multiline,
  label,
  placeholder,
  name,
  setText,
  error,
  value,
  country,
}: RegionPickerWithLabelProps) {
  const [field] = useField({
    name: name ? name : "",
  });

  const [regionsAvailable, setRegionsAvailable] = useState(false);
  useEffect(() => {
    if (country) {
      const countryData = CountryRegionData.find(
        ([name, code]) => name === country || code === country
      );
      if (countryData && countryData[2]) {
        const regions = countryData[2]
          .split("|")
          .map((region) => region.split("~")[0]);
        setRegionsAvailable(regions.length > 0);
      } else {
        setRegionsAvailable(false);
      }
    } else {
      setRegionsAvailable(true); // Default to true if no country is selected
    }
  }, [country]);
  return (
    <div
      className={`w-full bg-[#FFFFFF0F] relative px-[15px] py-1 h-[55px] ${
        error ? "border border-orange-700 border-solid" : ""
      }`}
    >
      <p className="text-[#17F9DA] text-xs font-[Play] mb-1.5">{label}</p>
      {regionsAvailable ? (
        <RegionDropdown
          {...field}
          name={name}
          country={country ? country : ""}
          value={value ? value : ""}
          classes="border-none w-full bg-transparent font-medium font-[Barlow] text-sm text-[#999]"
          onChange={(txt) => setText(txt)}
        />
      ) : (
        <input
          {...field}
          onChange={(event) => {
            setText(event.target.value);
          }}
          name={name}
          value={value ? value : ""}
          title={"State"}
          placeholder={"State/City"}
          className={
            "border-none w-full bg-transparent font-medium font-[Barlow] text-sm text-[#999]"
          }
        />
      )}
    </div>
  );
}

export default RegionPickerWithLabel;
