import { UserNotification as UserNotificationCollection } from "constants/Collections";
import { db } from "firebase-config";
import {
  Unsubscribe,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserNotifications,
  setContactMapModalStatus,
  setUserNormalNotifications,
  updateUserNotification,
} from "store/Actions/notification.actions";
import { getContactMapModalStatus } from "store/Reducers/notification.reducer";
import { UserNotification } from "types/Models";
import useProfile from "./profile.hook";

const useNotification = () => {
  const dispatch = useDispatch();
  const { userProfile } = useProfile();
  const contactMapModalStatus = useSelector(getContactMapModalStatus);
  const [notificationData, setNotificationData] =
    useState<UserNotification | null>(null);
  const fetchNotifications = () => {
    dispatch(fetchUserNotifications());
  };

  const updateNotifications = (notifData: UserNotification) => {
    dispatch(
      updateUserNotification({
        ...notifData,
        seen: true,
        seenOn: new Date().getTime(),
      })
    );
  };

  const handleContactMapModalStatus = (status: boolean) => {
    dispatch(setContactMapModalStatus(status));
  };

  const listenToNotifications = (userId: string) => {    
    return onSnapshot(
      query(
        collection(db, UserNotificationCollection),
        where("seen", "==", false),
        where("banner", "==", true),
        where("uid", "==", userId),
        orderBy("timestamp", "desc"),
        limit(1)
      ),
      (snapshot) => {
        const data: UserNotification[] = snapshot.docs.map((doc) => {
          return {
            ...(doc.data() as UserNotification),
            id: doc.id,
          };
        });
        if (data && data[0]) {
          setNotificationData(data[0]);
        }
      }
    );
  };

  const listenToNormlNotifications = (userId: string) => {
    return onSnapshot(
      query(
        collection(db, UserNotificationCollection),
        where("uid", "==", userId),
        where("banner", "==", false),
        orderBy("timestamp", "desc"),
        limit(100)
      ),
      (snapshot) => {
        const data: UserNotification[] = snapshot.docs.map((doc) => {
          return {
            ...(doc.data() as UserNotification),
            id: doc.id,
          };
        });
        if (data) {
          dispatch(setUserNormalNotifications(data));
        }
      }
    );
  };

  return {
    fetchNotifications,
    setNotificationData,
    notificationData,
    updateNotifications,
    handleContactMapModalStatus,
    contactMapModalStatus,
    listenToNotifications,
    listenToNormlNotifications,
  };
};

export default useNotification;
