export const FETCH_CALLSIGN_HAMDB = "FETCH_CALLSIGN_HAMDB";
export const FETCH_CALLSIGN_FIRESTORE = "FETCH_CALLSIGN_FIRESTORE";
export const SET_CALLSIGN_DATA = "SET_CALLSIGN";
export const SET_CALLSIGN_SUGGESTIONS_DATA = "SET_CALLSIGN_SUGGESTIONS_DATA";
export const SET_CALLSIGN_LOADING = "SET_CALLSIGN_LOADING";
export const SET_CALLSIGN_SEARCH_KEY = "SET_CALLSIGN_SEARCH_KEY";

export interface CallsignActionPayload {
  type: string;
  payload?: any;
}

export function fetchCallsignFromHamDB(callsign: string): CallsignActionPayload {
  return {
    type: FETCH_CALLSIGN_HAMDB,
    payload: callsign,
  };
}

export function setCallSignData(data: any): CallsignActionPayload {
  return {
    type: SET_CALLSIGN_DATA,
    payload: data,
  };
}

export function fetchCallsignFromFirestoreDB(callsign: string): CallsignActionPayload {
  return {
    type: FETCH_CALLSIGN_FIRESTORE,
    payload: callsign,
  };
}

export function setCallSignSuggestionsData(data: any): CallsignActionPayload {
  return {
    type: SET_CALLSIGN_SUGGESTIONS_DATA,
    payload: data,
  };
}

export function setCallSignLoading(isLoading: boolean): CallsignActionPayload {
  return {
    type: SET_CALLSIGN_LOADING,
    payload: isLoading,
  };
}

export function setCallSignSearchKey(searchKey: string): CallsignActionPayload {
  return {
    type: SET_CALLSIGN_SEARCH_KEY,
    payload: searchKey,
  };
}