import { getUsersForMap, getUsersForGlobe } from "firebase-config";
import { UserProfile } from "types/Models";

import { getCountFromServer, getDocs } from "firebase/firestore";
import { MembersData } from "store/Actions/members.actions";
import {
  buildDynamicQuery,
  buildDynamicQueryForPaginationCount,
  buildDynamicQueryForPaginationNext,
  buildDynamicQueryForPaginationPrev,
} from "helpers/Utils";

interface MemberServiceType {
  getMembers(conditions: any): Promise<MembersData | null>;
  getMembersWithPagination(payload: any): Promise<MembersData | null>;
  getMapMembers(payload: any): Promise<UserProfile | null>;
  getGlobeMembers(payload: any): Promise<UserProfile | null>;
}

const MemberService: MemberServiceType = {
  getMembers: async (conditions) => {
    try {
      const q: any = buildDynamicQuery(conditions);

      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        const users: UserProfile[] = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...(doc.data() as UserProfile),
          };
        });
        const countQuery = buildDynamicQueryForPaginationCount(conditions);
        const count = await getCountFromServer(countQuery);
        return {
          users: users,
          count: count?.data()?.count,
          lastDoc: users[users?.length - 1],
          firstDoc: users[0],
          page: 1,
        };
      } else {
        return null;
      }
    } catch (e) {
      // console.log("ERROR : ", e);
      return null;
    }
  },
  getMembersWithPagination: async (payload: any) => {
    // console.log("conditions", payload);
    try {
      let q;
      if (payload?.move !== -1) {
        q = buildDynamicQueryForPaginationNext(
          payload?.conditions,
          payload?.doc?.lastDoc["timestamp"]
        );
        // q = query(
        //   collection(db, UserDataTable),
        //   orderBy("timestamp", "desc"),
        //   startAfter(payload?.doc?.lastDoc["timestamp"]),
        // );
      } else {
        q = buildDynamicQueryForPaginationPrev(
          payload?.conditions,
          payload?.doc?.firstDoc["timestamp"]
        );
        // q = query(
        //   collection(db, UserDataTable),
        //   orderBy("timestamp", "desc"),
        //   endBefore(payload?.doc?.firstDoc["timestamp"]),
        // );
      }
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        const users: UserProfile[] = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...(doc.data() as UserProfile),
          };
        });
        const countQuery = buildDynamicQueryForPaginationCount(
          payload?.conditions
        );
        const count = await getCountFromServer(countQuery);
        return {
          users: users,
          count: count?.data()?.count,
          firstDoc: users[0],
          lastDoc: users[users?.length - 1],
          page: payload?.page,
        };
      } else {
        return null;
      }
    } catch (e) {
      // console.log("ERROR : ", e);
      return null;
    }
  },
  getMapMembers: async (payload) => {
    try {
      const {
        northEastLat,
        northEastLong,
        southWestLat,
        southWestLong,
        mapZoom,
      } = payload;
      const data = await getUsersForMap({
        northEastLat,
        northEastLong,
        southWestLat,
        southWestLong,
        mapZoom,
      });
      return data as UserProfile;
    } catch (e) {
      return null;
    }
  },
  getGlobeMembers: async (payload) => {
    try {
      const { lat, lng } = payload;
      const data = await getUsersForGlobe({
        lat,
        lng,
      });
      return data as UserProfile;
    } catch (e) {
      return null;
    }
  },
};

export default MemberService;
