import { Modes } from "constants/ArrayVariables";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLeaderBoardDataWithMode,
  getLeaderBoardDataForOverAll,
} from "store/Actions/leaderBoard.action";
import {
  getLeaderBoardUsersData,
  getLeaderBoardUsersDataWithMode,
} from "store/Reducers/leaderBoard.reducer";
import {
  getIsLeaderBoardByModeLoadingSelector,
  getIsLeaderBoardOverAllLoadingSelector,
} from "store/Reducers/loading.reducer";

const useLeaderBoard = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(Modes?.length ? Modes[0] : "");
  const UsersDataForOverAll = useSelector(getLeaderBoardUsersData);
  const UsersDataWithMode = useSelector(getLeaderBoardUsersDataWithMode);
  const isOverAllLoading = useSelector(getIsLeaderBoardOverAllLoadingSelector);
  const isGetDataModeLoading = useSelector(
    getIsLeaderBoardByModeLoadingSelector
  );

  const fetchUsersData = () => {
    dispatch(getLeaderBoardDataForOverAll());
  };

  const fetchUsersDataByMode = (mode: string) => {
    dispatch(fetchLeaderBoardDataWithMode(mode));
  };

  return {
    UsersDataForOverAll,
    fetchUsersData,
    fetchUsersDataByMode,
    UsersDataWithMode,
    selectedTab,
    setSelectedTab,
    isOverAllLoading,
    isGetDataModeLoading,
  };
};

export default useLeaderBoard;
