import React, { useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import MemberMapInfo from "components/MemberMapInfo";
import { MembershipMarkerProps } from "types/Component";
import customMarkerIcon from "../../assets/Icons/memberMarker.svg";
import { globalNavigate } from "routes/GlobalRoutes";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
const MembershipMarker = ({
  user,
  index,
  clusterer,
}: MembershipMarkerProps) => {
  const [infoVisibile, setInfoVisibile] = useState(false);

  return (
    <MarkerF
      key={`${user.coordinates?.latitude}-${user.coordinates?.longitude}-${index}`}
      position={{
        lat: user.coordinates?.latitude || 0,
        lng: user.coordinates?.longitude || 0,
      }}
      clusterer={clusterer}
      onClick={() => {
        globalNavigate(
          `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
            user?.callSign ? user?.callSign : ""
          )}?tab=Logbook&contact-tab=entries`,
          {
            state: user.uid,
          }
        );
      }}
      onMouseOver={(e) => {
        setInfoVisibile(true);
      }}
      icon={{
        url: customMarkerIcon,
        scaledSize: new window.google.maps.Size(40, 40),
      }}
      onMouseOut={(e) => {
        setInfoVisibile(false);
      }}
    >
      {infoVisibile ? (
        <InfoWindowF
          onCloseClick={() => {
            setInfoVisibile(false);
          }}
          options={{ disableAutoPan: true }}
          position={{
            lat: user.coordinates?.latitude || 0,
            lng: user.coordinates?.longitude || 0,
          }}
        >
          <MemberMapInfo user={user} />
        </InfoWindowF>
      ) : null}
    </MarkerF>
  );
};

export default MembershipMarker;
