import { ContestTableHeaderProps } from "types/Component";

function ContestTableHeader({
  title,
  filterOnePlaceholder,
  filterTwoPlaceholder,
  filterOneTitle,
  filterTwoTitle,
}: ContestTableHeaderProps) {
  return (
    <div className="flex items-center justify-between">
      <p className="ml-3 font-[Play] font-bold text-white text-xl">{title}</p>

      <div className="flex">
        {/* <div className="flex">
          <p className="text-white font-[Play] font-sm">{filterOneTitle}</p>
          <DropDown
            placeHolder={filterOnePlaceholder}
            list={[]}
            onChange={(e: any) => {}}
            width={"w-[37.5%] sm:w-[23.5%] md:w-[70px]"}
            className="ml-3"
          />
        </div>
        <div className="flex ml-5">
          <p className="text-white font-[Play] font-sm">{filterTwoTitle}</p>
          <DropDown
            placeHolder={filterTwoPlaceholder}
            list={[]}
            onChange={(e: any) => {}}
            width={"w-[37.5%] sm:w-[23.5%] md:w-[100px]"}
            className="ml-3"
          />
        </div> */}
      </div>
    </div>
  );
}

export default ContestTableHeader;
