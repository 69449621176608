import {
  auth,
  payments,
  db,
  createStripePortalSession,
  updateAutoRenew,
} from "firebase-config";
import {
  Product,
  getProducts,
  Session,
} from "@stripe/firestore-stripe-payments";
import * as functions from "firebase/functions";

import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  orderBy,
  getDocs,
  getCountFromServer,
  where,
} from "firebase/firestore";
import {
  CheckoutSessionsTable,
  Payments,
  Subscriptions,
  UserDataTable,
} from "constants/Collections";
import { SubscriptionStatus } from "types/Models";
interface SubscriptionServiceType {
  createCheckoutSession(priceId: string): Promise<boolean | null>;
  fetchProducts(): Promise<Product[] | null>;
  fetchSubscriptionStatus(): Promise<SubscriptionStatus | null>;
  createPortalSession(): Promise<functions.HttpsCallableResult<unknown> | null>;
  fetchSubscribedMembersCount(): Promise<number | null>;
  updateSubAutoRenew(autoRenew: boolean): void;
}

const SubscriptionService: SubscriptionServiceType = {
  createCheckoutSession: async (priceId) => {
    try {
      const uid: string = auth.currentUser?.uid || "";

      const sessionDocRef = collection(
        db,
        UserDataTable,
        uid,
        CheckoutSessionsTable
      );
      const newDoc = await addDoc(sessionDocRef, {
        price: priceId,
        allow_promotion_codes: true,
        success_url: `${window.location.origin}/membership?success=true`,
        cancel_url: `${window.location.origin}/membership?success=false`,
      });

      const unsubscribe = onSnapshot(
        doc(db, UserDataTable, uid, CheckoutSessionsTable, newDoc.id),
        (snapshot) => {
          const docData = snapshot.data() as Session;
          const { url } = docData;

          if (url) {
            unsubscribe();
            window.location.assign(url);
          } else {
            return false;
          }
        }
      );
      return true;
    } catch (e) {
      return null;
    }
  },
  fetchProducts: async () => {
    try {
      const products = await getProducts(payments, {
        includePrices: true,
        activeOnly: true,
      });
      return products;
    } catch (e) {
      return null;
    }
  },
  fetchSubscriptionStatus: async () => {
    try {
      await auth.currentUser?.getIdToken(true);

      const uid: string = auth.currentUser?.uid || "";
      let finalSubscriptionStatus: SubscriptionStatus = {};

      const q = query(
        collection(db, UserDataTable, uid, Payments),
        orderBy("created", "desc")
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        if (querySnapshot.docs.length) {
          const paymentData = querySnapshot.docs[0].data();
          if (paymentData && paymentData.charges) {
            if (paymentData.charges.data && paymentData.charges.data[0]) {
              if (
                paymentData.charges.data[0].payment_method_details &&
                paymentData.charges.data[0].payment_method_details.card
              ) {
                finalSubscriptionStatus.cardLast4Digits =
                  paymentData.charges.data[0].payment_method_details.card.last4;
                finalSubscriptionStatus.cardProvider =
                  paymentData.charges.data[0].payment_method_details.card.brand;
              }
            }
          }
        }
      }

      const qu = query(
        collection(db, UserDataTable, uid, Subscriptions),
        orderBy("created", "desc")
      );
      const quSnapshot = await getDocs(qu);
      if (quSnapshot !== null || quSnapshot !== undefined) {
        if (quSnapshot.docs.length) {
          const subscriptionData = quSnapshot.docs[0].data();
          finalSubscriptionStatus.startDate =
            subscriptionData.current_period_start;
          finalSubscriptionStatus.status = subscriptionData.status;
          finalSubscriptionStatus.renewalDate =
            subscriptionData.current_period_end;
          if (subscriptionData.items && subscriptionData.items[0]) {
            if (
              subscriptionData.items[0].price &&
              subscriptionData.items[0].price.product
            ) {
              finalSubscriptionStatus.name =
                subscriptionData.items[0].price.product.name;
              finalSubscriptionStatus.image =
                subscriptionData.items[0].price.product.images[0];
              finalSubscriptionStatus.description =
                subscriptionData.items[0].price.product.description;
              finalSubscriptionStatus.amount =
                subscriptionData.items[0].price.unit_amount / 100;
            }
          }
        }
      }

      return finalSubscriptionStatus;
    } catch (e) {
      return null;
    }
  },
  createPortalSession: async () => {
    try {
      return await createStripePortalSession({
        returnUrl: `${window.location.origin}/membership`,
      });
    } catch (e) {
      return null;
    }
  },
  fetchSubscribedMembersCount: async () => {
    try {
      const coll = collection(db, UserDataTable);
      const q = query(coll, where("subscriptionStatus", "==", "active"));
      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;
    } catch (e) {
      return null;
    }
  },
  updateSubAutoRenew: async (autoRenew) => {
    try {
      const uid: string = auth.currentUser?.uid || "";
      const response = await updateAutoRenew({
        userId: uid,
        autoRenew,
      });
      console.log("updateSubAutoRenew RESPONSE : ", response);
      return response;
    } catch (e) {
      console.log("updateSubAutoRenew ERROR : ", e);
      return null;
    }
  },
};
export default SubscriptionService;
