import Icons from "assets/Icons";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";

import React from "react";
import { RiFacebookFill } from "react-icons/ri";
import { AwardListProps } from "types/Component";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { savePDFAward } from "helpers/Utils";
import moment from "moment";
import useSubscription from "hooks/subscription.hook";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { useDispatch } from "react-redux";
import { setShowAddAwardModal } from "store/Actions/profile.actions";
import AccessControl from "components/AccessControl";
import { PERMISSIONS } from "constants/Permissions";

function AwardList({
  userAwards,
  selectedAward,
  setSelectedAward,
  setFeaturedStatusForAward,
  guestUser,
  userCallSign,
  user,
}: AwardListProps) {
  const { isUserPremiumAndAbove, navigateToPricing } = useSubscription();
  const dispatch = useDispatch();
  return (
    <div className="bg-[#0B0B39] mt-6 w-full rounded-[20px] sm:rounded-[25px] md:rounded-[50px] flex flex-col sm:flex-row justify-between">
      {selectedAward ? (
        <div className="w-full sm:w-[45%] h-full">
          <div className="relative flex flex-col items-center h-full">
            <div className="absolute w-full h-[110%] absoluteGradientImg top-[-5%] flex justify-center">
              <img
                className="w-full object-fill"
                src={Icons.GradientRectanglePic}
                alt="bg"
              />
            </div>
            <div className="bg-[#161638] h-full w-full rounded-[24px] flex flex-col items-center pt-8 lg:pt-12 pb-6 relative">
              <img
                className="w-[80%]"
                src={selectedAward.image}
                alt="profile pic"
              />
              {guestUser ? null : (
                <div className="w-full xl:w-[300px] px-2 md:px-4 mt-3 flex justify-between">
                  <div className="w-[47%]">
                    <BorderButtonPrimary
                      className="py-[1.5px]"
                      height="h-fit"
                      text="Save PDF"
                      onClick={() => {
                        isUserPremiumAndAbove()
                          ? savePDFAward(
                              moment.utc(
                                new Date(
                                  selectedAward?.timestamp?.seconds
                                    ? selectedAward?.timestamp?.seconds * 1000
                                    : new Date().getTime() 
                                )
                              ).format("MMM, DD YYYY"),
                              `${user?.firstName} ${user?.lastName}`,
                              user?.callSign || "",
                              `${user?.foundingMemberCount}`,
                              true,
                              selectedAward
                            )
                          : navigateToPricing();
                      }}
                      childClassName="text-sm md:text-base py-2 px-[10px]"
                    />
                  </div>
                  <div className="w-[47%]">
                    <BorderButtonPrimary
                      className="py-[1.5px]"
                      height="h-fit"
                      text="Print"
                      onClick={() => {
                        isUserPremiumAndAbove()
                          ? savePDFAward(
                              moment.utc(
                                new Date(
                                  selectedAward?.timestamp?.seconds
                                    ? selectedAward?.timestamp?.seconds * 1000
                                    : new Date().getTime()
                                )
                              ).format("MMM, DD YYYY"),
                              `${user?.firstName} ${user?.lastName}`,
                              user?.callSign || "",
                              `${user?.foundingMemberCount}`,
                              false,
                              selectedAward
                            )
                          : navigateToPricing();
                      }}
                      childClassName="text-sm md:text-base py-2 px-[10px]"
                    />
                  </div>
                </div>
              )}

              {guestUser ? null : (
                <div className="w-full xl:w-[300px] px-2 md:px-4 mt-3 sm:mt-1">
                  <BorderButtonPrimary
                    className="py-[1.5px]"
                    height="h-fit"
                    text={
                      selectedAward?.featured
                        ? "Remove Featured Award from Profile"
                        : "Set as Featured Award on Profile"
                    }
                    onClick={() => setFeaturedStatusForAward(selectedAward)}
                    childClassName="text-sm md:text-base py-2 px-[10px]"
                  />
                </div>
              )}
              {guestUser ? null : (
                <div>
                  <p className="text-white font-[Barlow] text-3xl font-bold mt-2">
                    Share to socials
                  </p>
                  <div className="flex items-center justify-center mt-2">
                    <FacebookShareButton
                      url={`${window.location.origin}/profile/${userCallSign}`}
                      title={`I just won ${selectedAward?.name} from the World Radio League!`}
                      className="Demo__some-network__share-button"
                      quote={`I just won ${selectedAward?.name} from the World Radio League!`}
                      hashtag="#WorldRadioLeague"
                    >
                      <RiFacebookFill
                        color="#fff"
                        className="mx-3 w-4 h-4 lg:w-5 lg:h-5"
                      />
                    </FacebookShareButton>
                    {/* <div
                    className="cursor-pointer"
                    onClick={() =>
                      openInNewTab(
                        `https://www.linkedin.com/sharing/share-offsite/?url=https://plus.unsplash.com/premium_photo-1666232903265-ba0419aa3ef7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHx8&auto=format&fit=crop&w=800&q=60`
                      )
                    }
                  >
                    <SiLinkedin
                      color="#fff"
                      className="mx-3 w-4 h-4 lg:w-5 lg:h-5"
                    />
                  </div> */}
                    <TwitterShareButton
                      url={`${window.location.origin}/profile/${userCallSign}`}
                      title={`I just won ${selectedAward?.name} from the World Radio League!`}
                      className="Demo__some-network__share-button"
                    >
                      <img
                        className="mx-3 w-4 h-4 lg:w-5 lg:h-5"
                        src={Icons.TwitterXWhite}
                        alt="twitter icon"
                      />
                    </TwitterShareButton>
                    {/* <PiInstagramLogoFill
                    color="#fff"
                    className="mx-3 w-4 h-4 lg:w-5 lg:h-5"
                  /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className="w-full mt-12 sm:mt-0 sm:w-[50%] flex flex-wrap items-center py-10 pl-5 pr-5">
        <AccessControl
          permission={PERMISSIONS.addAward}
          children={
            <div className="flex justify-end w-full pr-3 -mt-16">
              <div className="w-[220px] mr-4">
                <BorderButtonSecondary
                  text={`Add an Award`}
                  onClick={() => {
                    dispatch(setShowAddAwardModal(user!));
                  }}
                  className="sm:mb-0 cursor-pointer"
                />
              </div>
            </div>
          }
        />

        {userAwards.map((item, index) => {
          return (
            <img
              key={index}
              className="h-fit w-[30%] mx-[3%] cursor-pointer"
              src={item.image}
              alt={item.name}
              onClick={() => {
                setSelectedAward(item);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AwardList;
