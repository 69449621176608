import React from "react";
import { TopTabNavProps } from "types/Component";

function TopTabNav({
  selectedTabIndex,
  setSelectedTabIndex,
  firstIndexText,
  secondIndexText,
  thirdIndexText,
}: TopTabNavProps) {
  return (
    <div className="flex justify-between items-center w-full mt-6">
      <button
        className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 ${
          selectedTabIndex === "entries" ? "bg-[#151762]" : "bg-[#151541]"
        }`}
        onClick={() => setSelectedTabIndex("entries")}
      >
        <div className="w-full flex items-center justify-start">
          {firstIndexText}
        </div>
      </button>
      <button
        className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 ${
          selectedTabIndex === "qso" ? "bg-[#151762]" : "bg-[#151541]"
        }`}
        onClick={() => setSelectedTabIndex("qso")}
      >
        <div className="w-full flex items-center justify-start">
          {secondIndexText}
        </div>
      </button>
      {thirdIndexText ? (
        <button
          className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 ${
            selectedTabIndex === "spotting" ? "bg-[#151762]" : "bg-[#151541]"
          }`}
          onClick={() => setSelectedTabIndex("spotting")}
        >
          <div className="w-full justify-between flex items-center pr-4">
            <div className="w-full flex items-center justify-start">
              {thirdIndexText}
            </div>
            {/* <div className="bg-[#9f0e5c] rounded-full items-center justify-center flex h-[17px] w-[45px]">
              <h2 className="font-[Play] text-[11px] text-white">New</h2>
            </div> */}
          </div>
        </button>
      ) : null}
    </div>
  );
}

export default TopTabNav;
