import React from "react";

import { ConfirmationInfoProps } from "types/Component";

function ConfirmationInfo({ title, value }: ConfirmationInfoProps) {
  return (
    <div className="flex mb-2">
      <p className="font-bold text-white text-[15px] mr-3 md:mr-10 w-[100px]">
        {title}
      </p>
      <p className=" text-white text-[13px] bg-[#292B6A] w-[200px] pl-2 py-1">
        {value}
      </p>
    </div>
  );
}

export default ConfirmationInfo;
