import React from "react";

import { ContactInfoProps } from "types/Component";

function ContactInfo({ icon, value }: ContactInfoProps) {
  return (
    <div className="flex items-center mb-2">
      <img src={icon} className="w-[25px] h-[25px] mr-2" alt="freq" />
      <p className="text-white text-[20px] text-shadow">{value}</p>
    </div>
  );
}

export default ContactInfo;
