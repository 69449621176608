import Icons from "assets/Icons";
import Header from "components/Header";
// import ActivityIndicator from "components/Loading/ActivityIndicator";
// import TableLeaderBoard from "components/Table/TableLeaderBoard";
import TableWrapperLeaderBoard from "components/Table/TableWrapperLeaderBoard";
import LeaderBoardTab from "components/TopTab/LeaderBoardTab";
import { Modes } from "constants/ArrayVariables";
// import Awards, { AwardsType } from "constants/Awards";
import useLeaderBoard from "hooks/leaderBoard.hook";
// import useProfile from "hooks/profile.hook";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import LeaderBoardFilterControls from "components/LeaderBoardFilterControls";

import { findCountryCode, getISOWeek, getStateLongName } from "helpers/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getDXLeaderBoard,
  getDistanceLeaderBoard,
  getLeaderBoardByWeek,
  getLeaderBoardByWeekByMode,
} from "store/Actions/leaderBoard.action";
import {
  getIsDXLeaderBoardLoadingSelector,
  getIsDistanceLeaderBoardLoadingSelector,
  getIsLeaderBoardByModeWeekLoadingSelector,
  getIsLeaderBoardByWeekLoadingSelector,
} from "store/Reducers/loading.reducer";
import {
  getDXLeadersSelector,
  getDistanceLeadersSelector,
  getWeekLeadersSelector,
  getWeekModeLeadersSelector,
} from "store/Reducers/leaderBoard.reducer";
import ReactCountryFlag from "react-country-flag";
import { stateFlags } from "constants/Config";
// import { BsPersonCircle } from "react-icons/bs";
function LeaderBoardDetail() {
  // const { userProfile } = useProfile();
  const {
    fetchUsersData,

    fetchUsersDataByMode,
    setSelectedTab,
    selectedTab,
  } = useLeaderBoard();

  let [searchParams, setSearchParams] = useSearchParams();
  const state = searchParams.get("state") || "";
  const dispatch = useDispatch();

  const isWeekLeaderboardLoading = useSelector(
    getIsLeaderBoardByWeekLoadingSelector
  );
  const isWeekModeLeaderboardLoading = useSelector(
    getIsLeaderBoardByModeWeekLoadingSelector
  );
  const isDistanceLeaderLoading = useSelector(
    getIsDistanceLeaderBoardLoadingSelector
  );
  const isDXLeaderboardLoading = useSelector(getIsDXLeaderBoardLoadingSelector);

  const weekLeaders = useSelector(getWeekLeadersSelector);
  const weekModeLeaders = useSelector(getWeekModeLeadersSelector);
  const distanceLeaders = useSelector(getDistanceLeadersSelector);
  const dxLeaders = useSelector(getDXLeadersSelector);

  useEffect(() => {
    const state = searchParams.get("state");
    const country = searchParams.get("country");
    const week = searchParams.get("week")
      ? parseInt(searchParams.get("week")!)
      : getISOWeek();
    if (searchParams.get("show") === "weekly") {
      dispatch(
        getLeaderBoardByWeek({
          week,
          state,
          country,
        })
      );
      dispatch(getLeaderBoardByWeekByMode(week, selectedTab, state, country));
      dispatch(
        getDistanceLeaderBoard({
          week,
          state,
          country,
        })
      );

      dispatch(
        getDXLeaderBoard({
          week,
          state,
          country,
        })
      );
    } else if (searchParams.get("show") === "alltime") {
      dispatch(
        getLeaderBoardByWeek({
          week: null,
          state,
          country,
        })
      );
      dispatch(
        getLeaderBoardByWeekByMode(undefined, selectedTab, state, country)
      );
      dispatch(
        getDistanceLeaderBoard({
          week: null,
          state,
          country,
        })
      );

      dispatch(
        getDXLeaderBoard({
          week: null,
          state,
          country,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("week"), searchParams.get("show")]);

  useEffect(() => {
    setSelectedTab(
      searchParams.get("mode-tab") ? searchParams.get("mode-tab")! : Modes[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get("country") || !searchParams.get("state")) {
      // globalNavigate("/leaderboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchUsersData();
    fetchUsersDataByMode(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedMode = (value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("show", value);
    setSearchParams(newSearchParams);
  };
  const increaseWeek = () => {
    const currentISOWeek = parseInt(searchParams.get("week")!);
    if (currentISOWeek < 52) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("week", `${currentISOWeek + 1}`);
      setSearchParams(newSearchParams);
    }
  };
  const decreaseWeek = () => {
    const currentISOWeek = parseInt(searchParams.get("week")!);
    if (currentISOWeek > 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("week", `${currentISOWeek - 1}`);
      setSearchParams(newSearchParams);
    }
  };

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="LeaderBoard" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        <LeaderBoardFilterControls
          selectedMode={searchParams.get("show")!}
          setSelectedMode={setSelectedMode}
          increaseWeek={increaseWeek}
          decreaseWeek={decreaseWeek}
          currentWeek={searchParams.get("week")!}
        />
        <div className="w-full flex items-center justify-center mb-8 mt-8">
          <div className="bg-[#151541] rounded-full w-[100%] md:w-[50%] py-2">
            <div className="flex justify-center">
              {searchParams.get("country") ? (
                <div className="flex items-center mr-4">
                  {findCountryCode({
                    name: searchParams.get("country") || "",
                  }) ? (
                    <ReactCountryFlag
                      countryCode={
                        findCountryCode({
                          name: searchParams.get("country") || "",
                        }).alpha2
                      }
                      svg
                      style={{
                        width: "1.2em",
                        height: "1.2em",
                      }}
                      title={searchParams.get("country") || ""}
                    />
                  ) : null}

                  {/* <p className="text-base xl:text-lg font-[Play] ml-2 font-bold text-white">
                      {userData?.country}
                    </p> */}
                </div>
              ) : searchParams.get("state") ? (
                <img
                  src={searchParams.get("state") ? stateFlags[getStateLongName(state)] : ""}
                  alt=""
                  className="h-6 w-8 mr-2"
                />
              ) : null}
              <p className="text-white text-[18px] font-[Play] font-bold text-center">
                {`${
                  searchParams.get("country")
                    ? searchParams.get("country")!.toUpperCase()
                    : "" + getStateLongName(searchParams.get("state") || "")?.toUpperCase() + " STATE "
                } LEADERBOARD`}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-start sm:justify-between">
          <div className="w-full sm:w-[49%] bg-[#0B0B38] h-fit mb-10 sm:mb-0">
            <div className="w-full border-b border-solid border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5" src={Icons.TopChart} alt="icon" />
              <p className="text-white font-[play] font-bold text-base ml-[15px]">
                MOST CONTACTS <span className="text-[#17F9DA]">OVERALL</span>
              </p>
            </div>
            <TableWrapperLeaderBoard
              isLoading={isWeekLeaderboardLoading}
              list={weekLeaders}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
          <div className="w-full sm:w-[49%] bg-[#0B0B38] h-fit">
            <div className="border-b border-solid border-b-white w-full pb-[10px]">
              <div className="w-full flex items-center py-[15px] pl-[24px] mb-0">
                <img className="h-5 w-5" src={Icons.TopChart} alt="icon" />
                <p className="text-white font-[play] font-bold text-base ml-[15px]">
                  TOP OPERATORS <span className="text-[#17F9DA]">BY MODE</span>
                </p>
              </div>
              <LeaderBoardTab
                tabs={Modes}
                setSelectedTab={(tab) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.set("mode-tab", tab);
                  setSearchParams(newSearchParams);
                }}
                selectedTab={selectedTab}
                fetchUsersDataByMode={(item) => {
                  const week = searchParams.get("week")
                    ? parseInt(searchParams.get("week")!)
                    : getISOWeek();
                  dispatch(
                    getLeaderBoardByWeekByMode(
                      searchParams.get("show")! === "alltime"
                        ? undefined
                        : week,
                      item,
                      searchParams.get("state"),
                      searchParams.get("country")
                    )
                  );
                }}
              />
            </div>
            <TableWrapperLeaderBoard
              isLoading={isWeekModeLeaderboardLoading}
              list={weekModeLeaders}
              selectedTab={selectedTab}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
          <div className="w-full sm:w-[49%] bg-[#0B0B38] h-fit mb-10 sm:mb-0">
            <div className="w-full border-b border-solid border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5" src={Icons.TopChart} alt="icon" />
              <p className="text-white font-[play] font-bold text-base ml-[15px]">
                LONGEST <span className="text-[#17F9DA]">DISTANCE</span>
              </p>
            </div>
            <TableWrapperLeaderBoard
              isLoading={isDistanceLeaderLoading}
              list={distanceLeaders}
              distance={true}
              leaderboard={true}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
          <div className="w-full sm:w-[49%] bg-[#0B0B38] h-fit mb-10 sm:mb-0 mt-4">
            <div className="w-full border-b border-solid border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5" src={Icons.TopChart} alt="icon" />
              <p className="text-white font-[play] font-bold text-base ml-[15px]">
                MOST <span className="text-[#17F9DA]">DX CONTACTS</span>
              </p>
            </div>
            <TableWrapperLeaderBoard
              isLoading={isDXLeaderboardLoading}
              list={dxLeaders}
              leaderboard={true}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default LeaderBoardDetail;
