import React, { useEffect } from "react";
import AccessControl from "components/AccessControl";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import ContestTableHeader from "components/ContestTableHeader";
import Header from "components/Header";
import ContestTableComp from "components/Table/ContestTable/ContestTable";
import { PERMISSIONS } from "constants/Permissions";
import useContest from "hooks/contest.hook";
import useConfig from "hooks/config.hook";
import { Unsubscribe } from "firebase/firestore";

const Contest = () => {
  const {
    toggleAddContestModal,
    fetchContest,
    isActiveContestLoading,
    isUpcomingContestLoading,
    isPassedContestLoading,
    activeContest,
    upcomingContest,
    passedContest,
    getSumOfContestants,
    userProfile,
    handleDeleteContest,
    contestListener,
    storeContestIdForEditModal,
  } = useContest();

  const { handlePrimaryModal } = useConfig();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    fetchContest();

    unsubscribe = contestListener();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Function moved to cloud functions
  // useEffect(() => {
  //   dispatch(getContestLeader());
  // }, [activeContest, passedContest]);

  const deleteContest = (e: any, item: any) => {
    e.stopPropagation();
    handlePrimaryModal({
      title: "Are you sure you want to delete contest?",
      type: "Delete Contest",
      onCancelPressed: () => handlePrimaryModal(null),
      onSubmitPressed: () => {
        if (item.id) {
          handleDeleteContest(item.id);
          handlePrimaryModal(null);
        }
      },
      submitText: "Yes",
      cancelText: "No",
    });
  };
  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Contest" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        <div className="flex flex-wrap justify-start sm:justify-between">
          <AccessControl
            permission={PERMISSIONS.createContest}
            children={
              <div className="flex justify-end w-full mb-5">
                <div className="w-[220px]">
                  <BorderButtonSecondary
                    text={`Create Contest`}
                    onClick={() => toggleAddContestModal(true)}
                    className="sm:mb-0 cursor-pointer"
                  />
                </div>
              </div>
            }
          />
          <div className="p-[2px] bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full">
            <div className="bg-[#010950] flex flex-col w-full py-5 px-4">
              <div className="flex">
                <div className="border-solid flex items-center justify-center border-[#CC000A] border w-[155px] rounded-md">
                  <div className="w-[9px] h-[9px] bg-[#CC000A] rounded animate-shrink-grow"></div>
                  <p className="ml-3 font-[Play] font-bold text-white">
                    LIVE Contests
                  </p>
                </div>
                <div className="ml-5">
                  <p className="text-white font-[Play]">
                    {getSumOfContestants()} contesting
                  </p>
                </div>
              </div>
              <ContestTableComp
                lists={activeContest}
                isLoading={isActiveContestLoading}
                live={true}
                userEnrolledContests={userProfile?.contests || []}
                onEdit={(e, item) => {
                  storeContestIdForEditModal(item);
                }}
                onDelete={(e, item) => {
                  deleteContest(e, item);
                }}
              />
            </div>
          </div>
          <div className="bg-[#151541] flex flex-col w-full py-5 px-4 mt-5">
            <ContestTableHeader
              title="Upcoming Contests"
              filterOnePlaceholder="1"
              filterTwoPlaceholder="All Contest"
              filterOneTitle="Show"
              filterTwoTitle="List"
            />
            <ContestTableComp
              lists={upcomingContest}
              isLoading={isUpcomingContestLoading}
              onEdit={(e, item) => {
                storeContestIdForEditModal(item);
              }}
              userEnrolledContests={userProfile?.contests || []}
              onDelete={(e, item) => {
                deleteContest(e, item);
              }}
            />
          </div>
          <div className="bg-[#151541] flex flex-col w-full py-5 px-4 mt-5 opacity-80">
            <ContestTableHeader
              title="Recently Completed Contests"
              filterOnePlaceholder="1"
              filterTwoPlaceholder="All Contest"
              filterOneTitle="Show"
              filterTwoTitle="List"
            />
            <ContestTableComp
              lists={passedContest}
              isLoading={isPassedContestLoading}
              onEdit={(e, item) => {
                storeContestIdForEditModal(item);
              }}
              userEnrolledContests={userProfile?.contests || []}
              passed
              onDelete={(e, item) => {
                deleteContest(e, item);
              }}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Contest;
