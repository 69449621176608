import React from "react";
import Header from "components/Header";
import DefaultWrl from "components/LogbookTemplates/DefaultWrl";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import N4MLLog from "components/LogbookTemplates/N4MLLog";
import POTALog from "components/LogbookTemplates/POTALog";
import { getSelectedLogBookSelector } from "store/Reducers/logBook.reducer";
import { useSelector } from "react-redux";
import { Templates } from "constants/Config";
import useLogbookSetting from "hooks/logbookSetting.hook";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { Form, Formik } from "formik";
import { NavigateBackButton } from "./NavigateBackButton";
function LogbookStyles() {
  const { selectedLogbook, editLogbook, logbookLoading } = useLogbookSetting();
  const getButton = (template: string) => {
    if (
      template === Templates.DEFAULT_WRL &&
      selectedLogbook?.logbookStyle === undefined
    ) {
      return (
        <div className="bg-gradient-to-br from-[#64E3FF]  to-[#3C1CFD] w-[141px] h-[31px] rounded-full ml-10 text-center font-[Play] pt-1 border border-[#3C1CFD]">
          Default
        </div>
      );
    }
    if (selectedLogbook?.logbookStyle === template) {
      return (
        <div className="bg-gradient-to-br from-[#64E3FF]  to-[#3C1CFD] w-[141px] h-[31px] rounded-full ml-10 text-center font-[Play] pt-1 border border-[#3C1CFD]">
          Default
        </div>
      );
    } else {
      return (
        <div className="w-[141px] ml-10">
          <BorderButtonSecondary
            text={`Set as Default`}
            onClick={() => {
              editLogbook({
                ...selectedLogbook,
                logbookStyle: template,
              });
            }}
            className="sm:mb-0 cursor-pointer"
            height="h-[31px]"
            isLoading={logbookLoading}
            childClassName="text-xs"
          />
        </div>
      );
    }
  };

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Logbook Styles" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        <NavigateBackButton />
        <div className="w-full">
          <div className="flex mb-5">
            <p className="font-[Play] font-bold text-[#17F9DA] text-lg">
              {Templates.DEFAULT_WRL}
            </p>
            {getButton(Templates.DEFAULT_WRL)}
          </div>
          <Formik
            initialValues={{}}
            validationSchema={{}}
            onSubmit={async (
              values,
              { setSubmitting, setTouched, setValues }
            ) => {}}
          >
            {({
              errors,
              touched,
              resetForm,
              handleChange,
              submitForm,
              values,
              setValues,
              setTouched,
            }) => {
              const handleKeyDown = (event: any) => {};
              return (
                <Form
                  className="flex flex-col md:flex-row justify-between w-full bg-[#151541] px-3 py-5"
                  onKeyDown={() => {}}
                >
                  <DefaultWrl
                    setValues={() => {}}
                    values={{}}
                    errors={{}}
                    handleChange={() => {}}
                    touched={{}}
                    submitForm={() => {}}
                    initialData={{}}
                    setTouched={() => {}}
                    onLogPress={() => {}}
                    display={true}
                  />
                </Form>
              );
            }}
          </Formik>

          <p className="font-[Play] text-white mt-2">
            This is a default log layout with lots of field options.
          </p>
          <div className="bg-[#651FFF] h-[1px] w-full mt-5 mb-10" />
          <div className="flex mb-5 items-center">
            <p className="font-[Play] font-bold text-[#17F9DA] text-lg">
              {Templates.N4ML}
            </p>
            {getButton(Templates.N4ML)}
          </div>
          <Formik
            initialValues={{}}
            validationSchema={{}}
            onSubmit={async (
              values,
              { setSubmitting, setTouched, setValues }
            ) => {}}
          >
            {({
              errors,
              touched,
              resetForm,
              handleChange,
              submitForm,
              values,
              setValues,
              setTouched,
            }) => {
              const handleKeyDown = (event: any) => {};
              return (
                <Form
                  className="flex flex-col md:flex-row justify-between w-full bg-[#151541] px-3 py-5"
                  onKeyDown={() => {}}
                >
                  <N4MLLog
                    setValues={() => {}}
                    values={{}}
                    errors={{}}
                    handleChange={() => {}}
                    touched={{}}
                    submitForm={() => {}}
                    initialData={{}}
                    setTouched={() => {}}
                    onLogPress={() => {}}
                    display={true}
                  />
                </Form>
              );
            }}
          </Formik>
          <p className="font-[Play] text-white mt-2">
            This is a streamlined logger designed with less fields.
          </p>
          <div className="bg-[#651FFF] h-[1px] w-full mt-5 mb-10" />
          <div className="flex mb-5 items-center">
            <p className="font-[Play] font-bold text-[#17F9DA] text-lg">
              {Templates.POTA}
            </p>
            {getButton(Templates.POTA)}
          </div>
          <Formik
            initialValues={{}}
            validationSchema={{}}
            onSubmit={async (
              values,
              { setSubmitting, setTouched, setValues }
            ) => {}}
          >
            {({
              errors,
              touched,
              resetForm,
              handleChange,
              submitForm,
              values,
              setValues,
              setTouched,
            }) => {
              const handleKeyDown = (event: any) => {};
              return (
                <Form
                  className="flex flex-col md:flex-row justify-between w-full bg-[#151541] px-3 py-5"
                  onKeyDown={() => {}}
                >
                  <POTALog
                    setValues={() => {}}
                    values={{}}
                    errors={{}}
                    handleChange={() => {}}
                    touched={{}}
                    submitForm={() => {}}
                    initialData={{}}
                    setTouched={() => {}}
                    onLogPress={() => {}}
                    display={true}
                  />
                </Form>
              );
            }}
          </Formik>
          <p className="font-[Play] text-white mt-2">
            This logger has only the required fields needed for POTA activations
          </p>
        </div>
      </div>
    </main>
  );
}

export default LogbookStyles;
