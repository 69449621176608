import { useFormikContext } from "formik";
import { LogbookSettingsForm } from "types/Models";

export const useLogBookSettingsForm = () => {
  const { values, setValues, submitForm } = useFormikContext<LogbookSettingsForm>();

  const setFormValue = (key: string, value: string | boolean) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  return { values, setFormValue, submitForm, setValues };
};
