import TableLeaderBoardCol from "components/Table/TableLeaderBoard/TableLeaderBoardCol";
import { auth } from "firebase-config";
import React from "react";
import { FaSort } from "react-icons/fa6";
import { TableLeaderBoardProps } from "types/Component";
import { UserProfile } from "types/Models";

function TableLeaderBoard({
  list,
  selectedTab,
  contest,
  contestId,
  contestName,
  contestPassed,
  distance,
  weekly
}: TableLeaderBoardProps) {
  return (
    <div className="w-full overflow-y-hidden">
      <table className="md:w-full">
        <tbody>
          <tr
            className={`${
              contest ? "bg-[#353D7D]" : "bg-[#3C3C58]"
            }  pb-2.5 border-0 border-b border-slate-300 border-solid`}
          >
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white w-[50px]">
              No.
            </th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white w-[40px]">
              User
            </th>
            <th className="text-sm font-[Barlow] font-bold pt-3 pb-2 text-white w-[70px]">
              <div className="flex justify-start pl-2 w-[70px]">Callsign</div>
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left px-2 pt-3 pb-2 text-white w-[150px]">
              <div className="flex items-center justify-between">
                Name
                <FaSort size={14} className="ml-4" />
              </div>
            </th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white w-[157px]">
              <div className="flex justify-start pl-2">
                {contest
                  ? "Number of points"
                  : distance
                  ? "Distance"
                  : "Number of contacts"}
              </div>
            </th>
            {contest ? (
              <th className=" pt-3 pb-2 text-white w-[50px]"></th>
            ) : null}
          </tr>
          {list.map((item: UserProfile, index) => {
            const isSameUser = item
              ? item?.uid === auth.currentUser?.uid
              : false;

            return (
              <TableLeaderBoardCol
                key={index}
                index={index}
                userData={item}
                selectedTab={selectedTab}
                className={`${
                  isSameUser
                    ? "bg-[#074673]"
                    : index % 2
                    ? "bg-[#151D5E]"
                    : "bg-[#0B1357]"
                } border-0 border-b border-slate-300 border-solid`}
                contest={contest}
                contestId={contestId}
                contestName={contestName}
                contestPassed={contestPassed}
                distance={distance}
                weekly={weekly}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableLeaderBoard;
