import ActivityIndicator from "components/Loading/ActivityIndicator";
import React, { useState } from "react";
import { ButtonGlowProps } from "types/Component";

function ButtonGlow({
  text,
  onClick,
  selected,
  icon,
  disabled,
  info,
  showLoading,
}: ButtonGlowProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <div
      className={`flex flex-col items-center mx-3 cursor-pointer `}
      onMouseEnter={disabled ? () => setInfoOpen(true) : () => {}}
      onMouseLeave={disabled ? () => setInfoOpen(false) : () => {}}
    >
      <div className="flex items-center">
        {icon ? <img className="h-4 w-4 mr-2" src={icon} alt="" /> : null}
        <div
          className="text-white font-[Play] text-sm sm:text-base px-3 text-center"
          onClick={() => {
            if (!disabled && !showLoading) {
              onClick();
            }
          }}
        >
          {showLoading ? <ActivityIndicator size={10} /> : text}
        </div>
      </div>
      {selected ? (
        <div className="rounded-full h-1 w-[100%] mt-2.5 bg-[#17F9DA]" />
      ) : null}
      {disabled && info && infoOpen ? (
        <div className="absolute top-[16%] w-[200px] right-22 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default ButtonGlow;
