export const CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION";
export const FETCH_SUBSCRIPTION_PRODUCTS = "FETCH_SUBSCRIPTION_PRODUCTS";
export const SET_SUBSCRIPTION_PRODUCTS = "SET_SUBSCRIPTION_PRODUCTS";
export const FETCH_SUBSCRIPTION_STATUS = "FETCH_SUBSCRIPTION_STATUS";
export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";
export const CREATE_PORTAL_SESSION = "CREATE_PORTAL_SESSION";
export const SET_FOUNDING_MEMBER_SPOTS = "SET_FOUNDING_MEMBER_SPOTS";
export const UPDATE_AUTO_RENEW = "UPDATE_AUTO_RENEW";

export interface SubscriptionActionPayload {
  type: string;
  payload?: any;
}

export function createSubscriptionSession(
  lookUpKey: string
): SubscriptionActionPayload {
  return {
    type: CREATE_CHECKOUT_SESSION,
    payload: lookUpKey,
  };
}

export function fetchSubscriptionProducts(): SubscriptionActionPayload {
  return {
    type: FETCH_SUBSCRIPTION_PRODUCTS,
  };
}

export function createPortalSession(): SubscriptionActionPayload {
  return {
    type: CREATE_PORTAL_SESSION,
  };
}

export function fetchSubscriptionStatus(): SubscriptionActionPayload {
  return {
    type: FETCH_SUBSCRIPTION_STATUS,
  };
}

export function updateAutoRenew(status: boolean, setAutoRenew: (value: boolean)=>void): SubscriptionActionPayload {
  return {
    type: UPDATE_AUTO_RENEW,
    payload: {status: status, updateState: setAutoRenew},
  };
}
