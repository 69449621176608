import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import CountryDropDownPicker from "components/DropDown/CountryDropDownPicker";
import DropDown from "components/DropDown/DropDown";

import InputWithButton from "components/Inputs/InputWithButton";
import MembershipTableComp from "components/Table/Membership/MembershipTable";
import { subscriptionPlans } from "constants/Awards";

import useMember from "hooks/member.hook";
import { useEffect } from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import { MemberShipFilter, MembershipProps } from "types/Component";

function Membership({ lists, count, filter, setFilters }: MembershipProps) {
  const {
    isMembersDataLoading,
    callSign,
    setCallSign,
    country,
    setCountry,
    getMembers,
  } = useMember();

  useEffect(() => {
    // console.log("setFilters: ", filter);
  }, [filter]);

  return (
    <section className="bg-[#151541] mt-2.5 w-full pt-5 px-2.5 py-10">
      <div className="flex justify-between flex-wrap items-center">
        <div className="flex">
          <InputWithButton
            text={callSign}
            value={callSign.toUpperCase()}
            setText={(e) => {
              const value = e.target.value.toUpperCase();
              setCallSign(value);
              if (setFilters && e.target?.value?.length) {
                setFilters((prev: MemberShipFilter) => {
                  return { ...prev, callSign: value };
                });
              } else if (setFilters && !e.target?.value?.length) {
                setFilters((prev: MemberShipFilter) => {
                  return { ...prev, callSign: "" };
                });
              }
            }}
            placeHolder="Search by Callsign"
            width="w-[67.5%] sm:w-[43.5%] md:w-[300px]"
            btnWidth={"w-20"}
            btnTxt="Search"
            onBtnClick={() => {
              if (filter) {
                getMembers(filter);
              }
            }}
          />
          <DropDown
            placeHolder="Membership Status"
            list={subscriptionPlans}
            onChange={(e: any) => {
              getMembers({ ...filter, membershipStatus: e.target.value });
              if (setFilters && e.target?.value?.length) {
                setFilters((prev: MemberShipFilter) => {
                  return { ...prev, membershipStatus: e.target.value };
                });
              } else if (setFilters && !e.target?.value?.length) {
                setFilters((prev: MemberShipFilter) => {
                  return { ...prev, membershipStatus: "" };
                });
              }
            }}
            width={"w-[47.5%] sm:w-[23.5%] md:w-[200px]"}
            className="mx-4"
          />
          <div className="w-[47.5%] sm:w-[23.5%] md:w-[200px]">
            <CountryDropDownPicker
              setText={(txt) => {
                getMembers({ ...filter, country: txt, state: "" });
                if (setFilters && txt?.length) {
                  setFilters((prev: MemberShipFilter) => {
                    return { ...prev, country: txt, state: "" };
                  });
                } else if (setFilters && !txt?.length) {
                  setFilters((prev: MemberShipFilter) => {
                    return { ...prev, country: "", state: "" };
                  });
                }
                setCountry(txt);
              }}
              name="country"
              title="Their Country"
              info="Country of the other operator."
              placeHolder="Country"
              value={country}
              className={"mt-3 sm:mt-0"}
              tabindex={7}
              error={undefined}
            />
          </div>
        </div>
        <div className="w-72">
          <BorderButtonSecondary
            text="Find Members Near Me"
            onClick={() => globalNavigate("/members-directory?tab=map")}
            className=""
            fontStyle="font-bold"
          />
        </div>
      </div>
      <MembershipTableComp
        lists={lists}
        isLoading={isMembersDataLoading}
        count={count}
        filter={filter}
        setFilters={setFilters}
      />
    </section>
  );
}

export default Membership;
