export const UPLOAD_PROFILE_IMG = "UPLOAD_PROFILE_IMG";

export type UploadActionPayload = {
  type: string;
  payload: string | null;
};

export function uploadImg(data: any): UploadActionPayload {
  return {
    type: UPLOAD_PROFILE_IMG,
    payload: data,
  };
}
