export const SUPER_ADMIN = "superAdmin";
export const PERMISSIONS = {
  createContest: "createContest",
  editContest: "editContest",
  deleteContest: "deleteContest",
  declareContestWinner: "declareContestWinner",
  addAward: "addAward",
  superAdmin: "superAdmin",

};
