import React, { useState, useEffect, useRef } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
} from "react-simple-maps";
import features from "./features.json";
import { globalNavigate } from "routes/GlobalRoutes";
import { useSearchParams } from "react-router-dom";
import MemberMapInfo from "components/MemberMapInfo";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { getStateLeaderBoard } from "firebase-config";
var isEmpty = require("lodash.isempty");

const WorldMap = () => {
  let [searchParams] = useSearchParams();
  const [showInfo, setShowInfo] = useState<any>(false);
  const [countryLeader, setCountryLeader] = useState<any>(false);
  const [isStateLeaderLoading, setIsStateLeaderLoading] =
    useState<boolean>(false);
  const activeRequestRef = useRef<any>(null);

  const getStateLeaders = async (week: any, country: string) => {
    const requestId = Date.now();
    activeRequestRef.current = requestId;
    setIsStateLeaderLoading(true);
    try {
      const stateResponse: any = await getStateLeaderBoard({ week, country });
      // Check if the request is the latest before updating state
      if (activeRequestRef.current === requestId) {
        setCountryLeader(
          stateResponse && stateResponse.data ? stateResponse.data.data : {}
        );
      }
    } catch (e) {
      if (activeRequestRef.current === requestId) {
        setCountryLeader({});
      }
    }
    if (activeRequestRef.current === requestId) {
      setIsStateLeaderLoading(false);
    }
  };

  useEffect(() => {
    if (showInfo) {
      getStateLeaders(
        searchParams.get("show") === "weekly" ? searchParams.get("week") : null,
        showInfo.name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInfo]);

  return (
    <div>
      <ComposableMap>
        <Geographies geography={features}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: { fill: "#17F9DA" },
                  hover: { fill: "#00D395" },
                  pressed: { fill: "#17F9DA" },
                }}
                fill="#17F9DA"
                onMouseEnter={() => {
                  const centroid = geoCentroid(geo);
                  const { name } = geo.properties;
                  setShowInfo({
                    centroid,
                    name,
                  });
                }}
                onMouseLeave={() => {
                  setShowInfo(null);
                }}
                stroke="#2650FD"
                strokeWidth={0.5}
                onClick={(e) => {
                  globalNavigate(
                    `/leaderboard/detail?country=${geo.properties.name}&week=${
                      searchParams.get("week") ? searchParams.get("week") : 1
                    }&show=${
                      searchParams.get("show")
                        ? searchParams.get("show")
                        : "weekly"
                    }`
                  );
                }}
              />
            ))
          }
        </Geographies>
        {showInfo && (
          <Annotation
            subject={showInfo.centroid}
            dx={-150}
            dy={80}
            connectorProps={{
              stroke: "#FFF",
              strokeWidth: 2,
              strokeLinecap: "round",
            }}
            onMouseEnter={() => {
              setShowInfo(showInfo);
            }}
            onMouseLeave={() => {
              setShowInfo(null);
            }}
            style={{ overflow: "visible" }}
          >
            <foreignObject
              width={200}
              height={85}
              style={{ overflow: "visible" }}
            >
              {isStateLeaderLoading && countryLeader ? (
                <div className="w-[200px] h-[100px] bg-[#fff] border-2 border-[#46467F] rounded-md z-50">
                  <ActivityIndicator size={20} />
                </div>
              ) : (
                <>
                  {isEmpty(countryLeader) ? (
                    <div className="w-[200px] h-[100px] bg-[#fff] border-2 border-[#46467F] rounded-md z-50 flex items-center justify-center text-center">
                      <text>No leader for this territory yet.</text>
                    </div>
                  ) : (
                    <MemberMapInfo
                      user={countryLeader!}
                      weekly={searchParams.get("show") === "weekly"}
                    />
                  )}
                </>
              )}
            </foreignObject>
          </Annotation>
        )}
      </ComposableMap>
    </div>
  );
};

export default WorldMap;
