import Icons from "assets/Icons";
import CustomBorderButton from "components/Button/CustomBorderButton";
import { UserAvatar } from "components/UserAvatar";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import useConfig from "hooks/config.hook";
import React from "react";
import { BsPersonCircle } from "react-icons/bs";
import { globalNavigate } from "routes/GlobalRoutes";
import { FeaturedMembersProfileProps } from "types/Component";

function FeaturedMembersProfile({ user }: FeaturedMembersProfileProps) {
  const {
    getSubscriptionLevelButtonColor,
    getSubscriptionLevelButtonBgColor,
    getMemberStatusText,
  } = useConfig();
  return (
    <div
      className="relative mt-6 flex flex-col items-center memberImgWrapper cursor-pointer"
      onClick={() => {
        globalNavigate(
          `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
            user?.callSign ? user?.callSign : ""
          )}?tab=Logbook&contact-tab=entries`,
          {
            state: user?.uid,
          }
        );
      }}
    >
      <div className="absolute w-full flex justify-center h-[104%] top-[-1%]">
        <img
          // className="w-full object-fill md:w-[196.8px] h-[295.2px] lg:w-[246px] lg:h-[369px]"
          className={`object-fill w-[90%] h-full`}
          src={Icons.GradientRectanglePic}
          alt="bg"
        />
      </div>
      {/* <div className="bg-[#161638] h-[272px] w-[217px] lg:h-[340px] lg:w-[272px] rounded-[24px] flex flex-col items-center pt-8 lg:pt-11 relative mt-3.5"> */}
      <div
        className={`bg-[#161638] w-full h-full lg:w-full rounded-[24px] flex flex-col items-center justify-between pt-2 relative mt-3.5`}
      >
        <div className="w-full flex flex-col items-center">
          <p className="text-[#17F9DA] font-[Play] text-xs font-bold pb-5">
            {user.message}
          </p>
          {/* {userData && userData?.profilePic ? ( */}
          {/* memberImg */}
          {user.profilePic ? (
            <img
              className="w-28 h-28 lg:w-24 lg:h-24 rounded-full object-cover"
              src={user.profilePic}
              alt="profile pic"
            />
          ) : (
            <UserAvatar className="w-28 h-28 lg:w-24 lg:h-24" />
          )}
        </div>
        {/* {userData?.foundingMemberCount ? ( */}
        <div className="w-full flex flex-col items-center">
          <div className="bg-[#FFFFFF14] h-[43.2px] w-[90%] h-[54px] flex flex-col justify-center items-center  mt-7 lg:mt-10">
            <p className="font-[Play] font-bold text-white text-xs lg:text-sm">
              {user.firstName} {user.lastName}
            </p>
            <p className="text-[#17F9DA] font-[Play] text-xs font-bold">
              {user.callSign}
            </p>
          </div>

          <div
            className={`flex items-center ${
              false ? "mt-[10px]" : "mt-[20px]"
            } mb-4`}
          >
            <p className="text-[12px] text-white font-[Play] font-bold">
              Subscription Level :{" "}
            </p>
            <div className="w-auto ml-2">
              <div className="w-[70px]">
                <CustomBorderButton
                  text={getMemberStatusText(user.membershipStatus || "FREE")}
                  onClick={() => {}}
                  height="h-[24px]"
                  className=""
                  childHeight="h-[57px]"
                  childClassName="text-[12px] font-bold px-2"
                  fromBg={getSubscriptionLevelButtonColor(
                    "from",
                    getMemberStatusText(user.membershipStatus || "FREE")
                  )}
                  viaBg={getSubscriptionLevelButtonColor(
                    "via",
                    getMemberStatusText(user.membershipStatus || "FREE")
                  )}
                  toBg={getSubscriptionLevelButtonColor(
                    "to",
                    getMemberStatusText(user.membershipStatus || "FREE")
                  )}
                  childBgColor={getSubscriptionLevelButtonBgColor(
                    getMemberStatusText(user.membershipStatus || "FREE")
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedMembersProfile;
