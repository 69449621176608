import { Product } from "@invertase/firestore-stripe-payments";
import { SUBSCRIPTION_SPOTS_AVAILABLE } from "constants/Config";
import { authErrorHandler } from "helpers/ErrorHandler/auth";
import { showToast } from "helpers/Toast";
import { all, call, put, take } from "redux-saga/effects";
import SubscriptionService from "services/subscription.service";
import { SUBSCRIPTION_LOADING } from "store/Actions/loading.action";
import {
  CREATE_CHECKOUT_SESSION,
  CREATE_PORTAL_SESSION,
  FETCH_SUBSCRIPTION_PRODUCTS,
  FETCH_SUBSCRIPTION_STATUS,
  SET_FOUNDING_MEMBER_SPOTS,
  SET_SUBSCRIPTION_PRODUCTS,
  SET_SUBSCRIPTION_STATUS,
  UPDATE_AUTO_RENEW,
} from "store/Actions/subscription.actions";
import { ToastTypes } from "types/Component";
import { PortalResponse, SubscriptionStatus } from "types/Models";
var isEmpty = require("lodash.isempty");

//Workers

export function* workerCreateCheckoutSession(payload: any) {
  try {
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: true,
    });
    const checkout: boolean = yield call(() =>
      SubscriptionService.createCheckoutSession(payload)
    );
    if (!checkout) {
      authErrorHandler(null);
      yield put({
        type: SUBSCRIPTION_LOADING,
        payload: false,
      });
    }
  } catch (e: any) {
    authErrorHandler(e);
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: false,
    });
  }
}
export function* workerFetchSubscriptionProduct() {
  try {
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: true,
    });

    const products: Product[] = yield call(SubscriptionService.fetchProducts);
    yield put({
      type: SET_SUBSCRIPTION_PRODUCTS,
      payload: products,
    });
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: SUBSCRIPTION_LOADING,
    payload: false,
  });
}
export function* workerFetchSubscriptionStatus() {
  try {
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: true,
    });
    const subscriptionStatus: SubscriptionStatus | null = yield call(
      SubscriptionService.fetchSubscriptionStatus
    );
    const subscribedMembers: number | null = yield call(
      SubscriptionService.fetchSubscribedMembersCount
    );
    if (subscribedMembers !== null) {
      yield put({
        type: SET_FOUNDING_MEMBER_SPOTS,
        payload: `${SUBSCRIPTION_SPOTS_AVAILABLE - subscribedMembers}`,
      });
    }
    if (isEmpty(subscriptionStatus)) {
      yield all([
        put({
          type: SET_SUBSCRIPTION_STATUS,
          payload: null,
        }),
        put({
          type: SUBSCRIPTION_LOADING,
          payload: false,
        }),
      ]);
    } else {
      yield all([
        put({
          type: SET_SUBSCRIPTION_STATUS,
          payload: subscriptionStatus,
        }),
        put({
          type: SUBSCRIPTION_LOADING,
          payload: false,
        }),
      ]);
    }
  } catch (e: any) {
    authErrorHandler(e);
  }
  yield put({
    type: SUBSCRIPTION_LOADING,
    payload: false,
  });
}

export function* workerCreatePortalSession() {
  try {
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: true,
    });
    const portalData: PortalResponse = yield call(
      SubscriptionService.createPortalSession
    );
    if (portalData && portalData.data && portalData.data.url) {
      window.location.assign(portalData.data.url);
    } else {
      authErrorHandler(null);
      yield put({
        type: SUBSCRIPTION_LOADING,
        payload: false,
      });
    }
  } catch (e: any) {
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: false,
    });
    authErrorHandler(e);
  }
}
export function* workerUpdateAutoRenew(payload: any) {
  try {
    const { status, updateState } = payload;
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: true,
    });
    const response: { data: any } = yield call(() =>
      SubscriptionService.updateSubAutoRenew(status)
    );
    if (response.data) {
      if (response.data.success) {
        showToast({
          message: "Subscription status updated.",
          type: ToastTypes.SUCCESS,
        });
        if (updateState) {
          updateState(status);
        }
      } else {
        showToast({
          message:
            "Something went wrong, please click on 'Manage Subscriptions' to be redirected to subscription manager.",
          type: ToastTypes.WARN,
        });
      }
    }
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: false,
    });
    console.log("RENDER RESPONSE : ", response);
  } catch (e: any) {
    console.log("ERROR workerUpdateAutoRenew: ", e);
    yield put({
      type: SUBSCRIPTION_LOADING,
      payload: false,
    });
    authErrorHandler(e);
  }
}

//Watchers
export function* watchCreateCheckoutSession() {
  while (true) {
    const { payload } = yield take(CREATE_CHECKOUT_SESSION);
    yield call(workerCreateCheckoutSession, payload);
  }
}
export function* watchFetchSubscriptionProduct() {
  while (true) {
    yield take(FETCH_SUBSCRIPTION_PRODUCTS);
    yield call(workerFetchSubscriptionProduct);
  }
}
export function* watchFetchSubscriptionStatus() {
  while (true) {
    yield take(FETCH_SUBSCRIPTION_STATUS);
    yield call(workerFetchSubscriptionStatus);
  }
}

export function* watchCreatePortalSession() {
  while (true) {
    yield take(CREATE_PORTAL_SESSION);
    yield call(workerCreatePortalSession);
  }
}

export function* watchUpdateAutoRenew() {
  while (true) {
    const { payload } = yield take(UPDATE_AUTO_RENEW);
    yield call(workerUpdateAutoRenew, payload);
  }
}
