import ActivityIndicator from "components/Loading/ActivityIndicator";
import { Field } from "formik";
import { useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import { TitledInputProps } from "types/Component";

function TitledInputGradient({
  placeHolder,
  name,
  type,
  value,
  setText,
  className,
  width,
  title,
  error,
  props,
  tabindex,
  info,
  onBlur,
  isLoading,
  status,
  statusBg,
}: TitledInputProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <div className={`${width ? width : "w-full"} relative`}>
      <div className="flex items-center w-full">
        <p className="font-[Barlow] font-bold text-sm text-[#17F9DA] truncate">
          {title}
        </p>
        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      <Field
        className={`h-8 bg-[#36B6EF] px-3 font-[Barlow] text-sm text-[#010A41] mt-1.5 w-full ${
          className ? className : ""
        } ${error ? "border border-orange-700 border-solid" : ""}`}
        onChange={(e: any) => setText(e)}
        type={type ? type : "text"}
        name={name}
        id=""
        onBlur={onBlur ? onBlur : () => {}}
        placeholder={placeHolder}
        value={value}
        tabIndex={tabindex}
        {...props}
      />
      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
      <div className="flex justify-between items-center h-[20px]">
        {status && !isLoading ? (
          <div
            className={`${
              statusBg ? statusBg : ""
            } text-[9px] font-[Barlow] font-semibold text-white px-2 py-[1px] rounded-full`}
          >
            {status}
          </div>
        ) : null}
        <div className="w-[32px] h-[20px] flex justify-end absolute right-0">
          {isLoading ? <ActivityIndicator size={8} /> : null}
        </div>
      </div>
    </div>
  );
}

export default TitledInputGradient;
