import { call, put, select, take } from "redux-saga/effects";
import MemberService from "services/members.service";
import {
  GLOBE_MEMBERS_LOADING,
  MAP_MEMBERS_LOADING,
} from "store/Actions/loading.action";
import {
  FETCH_GLOBE_MEMBERS,
  FETCH_MAP_MEMBERS,
  GET_MEMBERS_DATA,
  GET_MEMBERS_DATA_WITH_PAGINATION,
  LOADING_MEMBERS_DATA_STATUS,
  MembersData,
  SAVE_GLOBE_MEMBERS_DATA,
  SAVE_MAP_MEMBERS_DATA,
  SAVE_MEMBERS_DATA,
} from "store/Actions/members.actions";

import { getMembersSelector } from "store/Reducers/members.reducer";
import { MemberShipFilter } from "types/Component";
import { UserProfile } from "types/Models";

//Workers
export function* workerGetMembersData(payload: MemberShipFilter) {
  yield put({
    type: LOADING_MEMBERS_DATA_STATUS,
    payload: true,
  });
  try {
    const membersData: MembersData = yield call(() =>
      MemberService.getMembers(payload)
    );

    yield put({
      type: SAVE_MEMBERS_DATA,
      payload: membersData,
    });
  } catch (e: any) {}
  yield put({
    type: LOADING_MEMBERS_DATA_STATUS,
    payload: false,
  });
}

export function* workerGetMembersDataWithPagination(payload: any) {
  yield put({
    type: LOADING_MEMBERS_DATA_STATUS,
    payload: true,
  });
  try {
    const prevMembersData: MembersData = yield select(getMembersSelector);
    let data = {
      doc: prevMembersData,
      page: payload.page,
      move: payload.move,
      conditions: payload.conditions,
    };
    const membersData: MembersData = yield call(() =>
      MemberService.getMembersWithPagination(data)
    );

    yield put({
      type: SAVE_MEMBERS_DATA,
      payload: membersData,
    });
  } catch (e: any) {}
  yield put({
    type: LOADING_MEMBERS_DATA_STATUS,
    payload: false,
  });
}

export function* workerGetMapMembersData(payload: MemberShipFilter) {
  yield put({
    type: MAP_MEMBERS_LOADING,
    payload: true,
  });
  try {
    const membersMapData: { data: UserProfile } | null = yield call(() =>
      MemberService.getMapMembers(payload)
    );

    yield put({
      type: SAVE_MAP_MEMBERS_DATA,
      payload: membersMapData?.data || [],
    });
  } catch (e: any) {}
  yield put({
    type: MAP_MEMBERS_LOADING,
    payload: false,
  });
}

export function* workerGetGlobeMembersData(payload: MemberShipFilter) {
  yield put({
    type: GLOBE_MEMBERS_LOADING,
    payload: true,
  });
  try {
    const membersGlobeData: { data: UserProfile } | null = yield call(() =>
      MemberService.getGlobeMembers(payload)
    );

    yield put({
      type: SAVE_GLOBE_MEMBERS_DATA,
      payload: membersGlobeData?.data || [],
    });
  } catch (e: any) {}
  yield put({
    type: GLOBE_MEMBERS_LOADING,
    payload: false,
  });
}

// export function* workerGetMembersData(payload: UserNotification) {
//   try {
//     yield call(() => NotificationService.updateUserNotification(payload));
//   } catch (e: any) {}
// }

//Watchers
export function* watchGetMembersData() {
  while (true) {
    const { payload } = yield take(GET_MEMBERS_DATA);
    yield call(workerGetMembersData, payload);
  }
}

export function* watchGetMembersDataWithPagination() {
  while (true) {
    const { payload } = yield take(GET_MEMBERS_DATA_WITH_PAGINATION);
    yield call(workerGetMembersDataWithPagination, payload);
  }
}

export function* watchGetMapMembersData() {
  while (true) {
    const { payload } = yield take(FETCH_MAP_MEMBERS);
    yield call(workerGetMapMembersData, payload);
  }
}

export function* watchGetGlobeMembersData() {
  while (true) {
    const { payload } = yield take(FETCH_GLOBE_MEMBERS);
    yield call(workerGetGlobeMembersData, payload);
  }
}
