import { ProfileState } from "types/State";
import {
  ProfileActionPayload,
  SET_USER_AWARDS,
  IS_CALL_SIGN_AVAILABLE,
  SET_FEATURED_MEMBERS,
  SHOW_AWARD_MODAL,
  SET_AWARDS,
  SET_USER_AWARDS_ADMIN,
  ADMIN_CONTROLS_USER,
  SET_USER_AVATAR,
  SHOW_ACCOUNT_DELETE_MODAL,
} from "store/Actions/profile.actions";
import { RESET_USER_PROFILE, STORE_USER_PROFILE } from "constants/ActionTypes";
import { RootState } from "store/Reducers";

const defaultState: ProfileState = {
  userProfile: null,
  userAvatar: null,
  userAwards: [],
  isCallSignAvailable: false,
  featuredMembers: [],
  showAwardModal: null,
  awards: [],
  userAwardAdmin: [],
  adminUserControlData: null,
  showAccountDeleteModal: false,
};

export default function profileReducer(
  state = defaultState,
  action: ProfileActionPayload
) {
  switch (action.type) {
    case STORE_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case RESET_USER_PROFILE:
      return {
        ...state,
        userProfile: null,
        userAwards: [],
        isCallSignAvailable: false,
      };
    case SET_USER_AWARDS:
      return {
        ...state,
        userAwards: action.payload,
      };
    case IS_CALL_SIGN_AVAILABLE:
      return {
        ...state,
        isCallSignAvailable: action.payload,
      };
    case SET_FEATURED_MEMBERS:
      return {
        ...state,
        featuredMembers: action.payload,
      };
    case SHOW_AWARD_MODAL:
      return {
        ...state,
        showAwardModal: action.payload,
      };
    case SET_AWARDS:
      return {
        ...state,
        awards: action.payload,
      };
    case SET_USER_AWARDS_ADMIN:
      return {
        ...state,
        userAwardAdmin: action.payload,
      };
    case ADMIN_CONTROLS_USER:
      return {
        ...state,
        adminUserControlData: action.payload,
      };
    case SET_USER_AVATAR:
      return {
        ...state,
        userAvatar: action.payload,
      };
    case SHOW_ACCOUNT_DELETE_MODAL:
      return {
        ...state,
        showAccountDeleteModal: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getUserProfileSelector = (state: RootState) => {
  return state.profile.userProfile;
};

export const getUserAwardsSelector = (state: RootState) => {
  return state.profile.userAwards || [];
};
export const getIsCallSignAvailableSelector = (state: RootState) => {
  return state.profile.isCallSignAvailable;
};

export const getFeaturedMembersSelector = (state: RootState) => {
  return state.profile.featuredMembers;
};
export const getShowAwardModalSelector = (state: RootState) => {
  return state.profile.showAwardModal;
};

export const getAwardsSelector = (state: RootState) => {
  return state.profile.awards || [];
};
export const getUserAwardsAdminSelector = (state: RootState) => {
  return state.profile.userAwardAdmin || [];
};

export const getAdminUserControlSelector = (state: RootState) => {
  return state.profile.adminUserControlData;
};

export const getUserAvatarSelector = (state: RootState) => {
  return state.profile.userAvatar;
};

export const getShowAccountDeleteModalSelector = (state: RootState) => {
  return state.profile.showAccountDeleteModal;
}