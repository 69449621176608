import { useEffect } from "react";
import "./App.css";
import { auth } from "firebase-config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoute from "routes";
import useProfile from "hooks/profile.hook";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserAwards,
  sendWordPressLogin,
  storeLoggedInUser,
} from "store/Actions/profile.actions";
import useAuth from "hooks/auth.hook";
import { useSearchParams } from "react-router-dom";
import {
  LOADING_APPLE_AUTH,
  LOADING_GOOGLE_AUTH_STATUS,
  setDiscourseLoading,
} from "store/Actions/loading.action";
import useNotification from "hooks/notification.hook";
import useLogBook from "hooks/logBook.hook";
import { saveDiscourseParams } from "store/Actions/authorization.actions";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
function App() {
  const { handleUserProfile } = useProfile();
  const { logbookCollectionListner } = useLogBook();
  const { fetchNotifications } = useNotification();
  const [searchParams] = useSearchParams();
  const { discourseAuth } = useAuth();
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfileSelector);
  useEffect(() => {
    const sso: string | null = searchParams.get("sso");
    const sig: string | null = searchParams.get("sig");
    if (sso && sig) {
      if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/register"
      ) {
        dispatch(setDiscourseLoading(true));
        dispatch(saveDiscourseParams({ sso, sig }));
      } else {
        dispatch(setDiscourseLoading(false));
        dispatch(saveDiscourseParams({ sso, sig }));
      }
    } else {
      dispatch(setDiscourseLoading(false));
      dispatch(saveDiscourseParams(null));
    }
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      if (user) {
        fetchNotifications();
        handleUserProfile();
        dispatch(fetchUserAwards(user.uid));
        dispatch(sendWordPressLogin());

        if (
          userProfile &&
          userProfile.firstName &&
          userProfile.lastName &&
          userProfile.bio &&
          userProfile?.callSign &&
          userProfile?.callSign?.length
        ) {
          discourseAuth(user, searchParams);
        } else {
          dispatch(saveDiscourseParams({ sso, sig }));
        }
      } else {
        dispatch(storeLoggedInUser(null));
        dispatch({ type: LOADING_GOOGLE_AUTH_STATUS, payload: false });
        dispatch({ type: LOADING_APPLE_AUTH, payload: false });
      }
    });
    const logBookUnsubscribe = logbookCollectionListner();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (logBookUnsubscribe) {
        logBookUnsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app">
      <AppRoute />
      <ToastContainer />
    </div>
  );
}

export default App;
