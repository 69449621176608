export type FindCode = {
  name: string;
};

export enum STRIPE_RESPONSE {
  SUCCESS = "success",
  CANCELLED = "canceled",
}

export enum LEADER_BOARD_MODE {
  ALL_TIME = "alltime",
  WEEKLY = "weekly",
}

export enum CONTEST_STATUS {
  ACTIVE = "active",
  UPCOMING = "upcoming",
  PASSED = "passed",
}

export enum SPOT_SOURCE {
  POTA = "POTA",
  WRL_LOGBOOK = "WRL Logbook",
  WRL_SPOTTING = "WRL Spotting",
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export type SpotRefreshProps = {
  refreshSpots: () => void;
};
