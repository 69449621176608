import React, { useEffect } from "react";
import Header from "components/Header";
import Membership from "components/Table/Membership";
import useMember from "hooks/member.hook";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { globalNavigate } from "routes/GlobalRoutes";
import Icons from "assets/Icons";
import { SlLocationPin } from "react-icons/sl";
import { FiMenu } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import MembershipMap from "components/MembershipMap";
import { RiInformationLine } from "react-icons/ri";

import MembershipGlobe from "components/MembershipGlobe";

const MembersDirectory = () => {
  const { members, getMembers, filter, setFilters } = useMember();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("tab")) {
      globalNavigate("/members-directory?tab=map");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("tab")]);

  useEffect(() => {
    getMembers(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Member Directory" />
      <div className="w-full flex flex-col items-center px-3 sm:px-7 pt-10 pb-7 sm:pb-10 overflow-x-hidden dashboardWrapper">
        <div className="flex mb-4 w-full justify-between">
          <div className="flex mb-4 w-full">
            <div className="w-[120px] mr-3">
              <BorderButtonSecondary
                text={`List View`}
                onClick={() => globalNavigate("/members-directory?tab=list")}
                className="sm:mb-0"
                icon={<FiMenu color="#fff" className="mr-2" />}
                selected={searchParams.get("tab") === "list"}
              />
            </div>
            <div className="w-[120px] mr-3">
              <BorderButtonSecondary
                text={`Globe View`}
                onClick={() => globalNavigate("/members-directory?tab=globe")}
                className="sm:mb-0"
                icon={<img src={Icons.Globe} alt="" className="mr-2" />}
                selected={searchParams.get("tab") === "globe"}
              />
            </div>
            <div className="w-[120px] mr-3">
              <BorderButtonSecondary
                text={`Map View`}
                onClick={() => globalNavigate("/members-directory?tab=map")}
                className="sm:mb-0"
                icon={<SlLocationPin color="#fff" className="mr-2" />}
                selected={searchParams.get("tab") === "map"}
              />
            </div>
          </div>
          {searchParams.get("tab") !== "list" ? (
            <div className="flex justify-end bg-[#524A6F] items-center pr-2 w-[45%] mt-6">
              <RiInformationLine color="#fff" className="ml-1" />
              <p className="pl-3 text-[12px] font-[Play] text-white">
                Exact location of users has been hidden for privacy reasons.
              </p>
            </div>
          ) : null}
        </div>
        {searchParams.get("tab") === "list" ? (
          <Membership
            lists={members?.users}
            count={members?.count}
            filter={filter}
            setFilters={setFilters}
          />
        ) : searchParams.get("tab") === "map" ? (
          <MembershipMap />
        ) : searchParams.get("tab") === "globe" ? (
          <MembershipGlobe />
        ) : null}
      </div>
    </main>
  );
};

export default MembersDirectory;
