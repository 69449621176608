import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import {
  BandOption,
  IsAssistedOption,
} from "constants/Config";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC } from "react";

export const ContestSettings: FC = () => {
  const { values, setFormValue } = useLogBookSettingsForm();

  return (
    <div className="flex flex-col text-white gap-4">
      <div className="font-bold font-Play text-xl">Contest Settings</div>
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          title="Power:"
          value={values?.contestPower || ""}
          input
          type="number"
          setValue={(value) => setFormValue("contestPower", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          title="# of Transmitters:"
          input
          type="number"
          placeholder="Enter number"
          value={values?.numberOfTransmitters || ""}
          setValue={(value) => setFormValue("numberOfTransmitters", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={BandOption}
          title="Band:"
          placeholder="Enter band"
          value={values?.contestBand || ""}
          setValue={(value) => setFormValue("contestBand", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={IsAssistedOption}
          title="Assisted / Non- Assisted:"
          value={values?.assisted || ""}
          setValue={(value) => setFormValue("assisted", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          title="Operator:"
          input
          placeholder="Enter operator"
          value={values?.operator || ""}
          setValue={(value) => setFormValue("operator", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
      </div>
    </div>
  );
};
