import { UserDataTable } from "constants/Collections";
import { db } from "firebase-config";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";

import { UserProfile } from "types/Models";

interface LogBookServiceType {
  getUserLeaderBoardData(): Promise<UserProfile[] | null>;
  getUserLeaderBoardDataByMode(mode: string): Promise<UserProfile[] | null>;
}

const LeaderBoardService: LogBookServiceType = {
  getUserLeaderBoardData: async () => {
    try {
      const arr: UserProfile[] = [];
      const q = query(
        collection(db, UserDataTable),
        orderBy("numberOfContacts", "desc"),
        limit(30)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        querySnapshot.forEach((doc) => {
          let data: UserProfile = {
            ...doc.data(),
          };
          arr.push(data);
        });
        if (arr.length) {
          return arr;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  },
  getUserLeaderBoardDataByMode: async (mode) => {
    try {
      const arr: UserProfile[] = [];
      const q = query(
        collection(db, UserDataTable),
        orderBy(`modes.${mode}`, "desc"),
        limit(50)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot !== null || querySnapshot !== undefined) {
        querySnapshot.forEach((doc) => {
          let data: UserProfile = {
            ...doc.data(),
          };
          arr.push(data);
        });
        if (arr.length) {
          return arr;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  },
};

export default LeaderBoardService;
