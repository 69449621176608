import React, { FC, useEffect, useMemo, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { BandOption, IsAssistedOption } from "constants/Config";
import useContest from "hooks/contest.hook";
import { useEditContact } from "hooks/useEditContact.hook";

type ModalProps = {
  onClose: () => void;
};

export const ContestModal: FC<ModalProps> = ({ onClose }) => {
  const [values, setValues] = useState<any>({});
  const { editContactModalData } = useEditContact();

  const {
    fetchContest,
    isActiveContestLoading,
    isUpcomingContestLoading,
    activeContest,
    upcomingContest,
  } = useContest();

  const totalContest = [...activeContest, ...upcomingContest];
  const isLoading = isActiveContestLoading || isUpcomingContestLoading;

  const contestOptions = totalContest.map((contest) => ({
    label: contest.name,
    value: contest.id,
  }));

  useEffect(() => {
    if (editContactModalData?.contestId) {
      setFormValue("contestId", editContactModalData?.contestId);
    }
  }, [editContactModalData?.contestId]);

  useEffect(() => {
    fetchContest();
  }, []);

  const setFormValue = (key: string, value: string | boolean) => {
    setValues((prev: any) => ({ ...prev, [key]: value }));
  };

  const onSaveClickHandler = () => {
    onClose();
  };

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism py-[5%] px-[2%] md:px-[8%] lg:px-[12%] xl:px-[20%] overflow-scroll">
      <div className=" w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] text-white font-[Play] relative mt-20 border border-[#451836] px-[6%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <div className="flex flex-col text-white gap-4 w-full">
                <div className="font-bold font-Play text-xl">
                  Contest Settings
                </div>
                <div className="flex flex-wrap w-full">
                  <LogbookSettingDropdown
                    list={contestOptions}
                    title="Contest:"
                    value={values?.contestId || ""}
                    setValue={(value) => {
                      if (value === "add") {
                      } else {
                        setFormValue("contestId", value);
                      }
                    }}
                    className="sm:w-[36%] md:w-[40%]"
                  />
                  <LogbookSettingDropdown
                    title="Power:"
                    value={values?.contestPower || ""}
                    input
                    type="number"
                    setValue={(value) => setFormValue("contestPower", value)}
                    className="sm:w-[36%] md:w-[40%]"
                  />
                  <LogbookSettingDropdown
                    title="# of Transmitters:"
                    input
                    type="number"
                    placeholder="Enter number"
                    value={values?.numberOfTransmitters || ""}
                    setValue={(value) =>
                      setFormValue("numberOfTransmitters", value)
                    }
                    className="sm:w-[36%] md:w-[40%]"
                  />
                  <LogbookSettingDropdown
                    list={BandOption}
                    title="Band:"
                    placeholder="Enter band"
                    value={values?.contestBand || ""}
                    setValue={(value) => setFormValue("contestBand", value)}
                    className="sm:w-[36%] md:w-[40%]"
                  />
                  <LogbookSettingDropdown
                    list={IsAssistedOption}
                    title="Assisted / Non- Assisted:"
                    value={values?.assisted || ""}
                    setValue={(value) => setFormValue("assisted", value)}
                    className="sm:w-[36%] md:w-[40%]"
                  />
                  <LogbookSettingDropdown
                    title="Operator:"
                    input
                    placeholder="Enter operator"
                    value={values?.operator || ""}
                    setValue={(value) => setFormValue("operator", value)}
                    className="sm:w-[36%] md:w-[40%]"
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={isLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
