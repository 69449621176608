import { logEvent } from "firebase/analytics";
import { analytics } from "firebase-config";
interface AnalyticsServiceType {
  logAnalyticsEvent(eventName: string, eventData?: any): Promise<void>;
  logGtmEvent(eventData?: any): Promise<void>;
}
const AnalyticsService: AnalyticsServiceType = {
  logAnalyticsEvent: async (eventName, eventData) => {
    if (process.env.NODE_ENV === "production") {
      logEvent(analytics, eventName, eventData);
    }
  },
  logGtmEvent: async (eventData) => {
    if (process.env.NODE_ENV === "production") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(eventData);
    }
  },
};

export default AnalyticsService;
