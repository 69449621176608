import { CustomBorderButtonProps } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";

function CustomBorderButton({
  text,
  onClick,
  className,
  isLoading,
  fontStyle,
  height,
  childHeight,
  childClassName,
  childBgColor,
  fromBg,
  viaBg,
  toBg,
}: CustomBorderButtonProps) {
  return (
    <div
      className={`bg-gradient-to-r from-[${fromBg ? fromBg : "#37A4BA"}] via-[${
        viaBg ? viaBg : "#496780"
      }] to-[${toBg ? toBg : "#CF0971"}] w-full ${className ? className : ""} ${
        height ? height : "h-10"
      } rounded-full flex justify-center items-center`}
      onClick={onClick}
    >
      <div
        className={`${childHeight ? childHeight : ""} ${
          childClassName ? childClassName : "text-sm sm:text-xl"
        } text-white ${
          childBgColor ? childBgColor : "bg-[#1D204A]"
        } w-[calc(100%-3px)] h-[calc(100%-3px)] rounded-full flex justify-center items-center font-Play`}
      >
        {isLoading ? <ActivityIndicator size={16} /> : text}
      </div>
    </div>
  );
}

export default CustomBorderButton;
