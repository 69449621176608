import { IoCreateOutline } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";
import { MY_PROFILE_ROUTE } from "constants/Links";
import { TableContestProps } from "types/Component";
import { globalNavigate } from "routes/GlobalRoutes";

import CustomValidation from "helpers/Validation";

import moment from "moment";
import { useDispatch } from "react-redux";
import {
  setContestDetails,
  setContestantDetail,
} from "store/Actions/contest.actions";

import AccessControl from "components/AccessControl";
import { PERMISSIONS } from "constants/Permissions";
import { UserAvatar } from "components/UserAvatar";

function TableCol({
  item,
  className,
  editable,
  live,
  userEnrolledContests,
  index,
  onEdit,
  onDelete,
  passed,
}: TableContestProps) {
  const dispatch = useDispatch();

  const getUserEnrollment = () => {
    if (
      userEnrolledContests &&
      Array.isArray(userEnrolledContests) &&
      userEnrolledContests.length
    ) {
      if (userEnrolledContests.includes(item.id || "")) {
        return "Enrolled";
      }
    }
    return "Not Enrolled";
  };

  const onContestClick = () => {
    dispatch(setContestDetails(item));
    dispatch(setContestantDetail(null));
    globalNavigate(
      `/contest/contest-detail?tab=contest_rules&contest_id=${item.id}`
    );
  };

  const contestLeaderDetails = () => {
    if (item.leader) {
      return (
        <div
          className="flex items-center h-8 cursor-pointer"
          onClick={() => {
            globalNavigate(
              `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                item.leader?.callSign || ""
              )}?tab=Logbook&contact-tab=entries`,
              {
                state: item.leader?.uid || "",
              }
            );
          }}
        >
          {item.leader?.profilePic ? (
            <img
              src={item.leader?.profilePic}
              className="w-[20px] h-[20px] full-rounded"
              alt={item.name}
            />
          ) : (
            <UserAvatar className="w-7 h-7" />
          )}

          <p className="text-xs font-bold font-[Barlow] ml-5 text-[#17F9DA]">
            {item.leader?.firstName} - {item.leader?.callSign}
          </p>
        </div>
      );
    } else {
      return <div className="h-8">--</div>;
    }
  };

  return (
    <tr className={`${className ? className : ""}`}>
      {!live ? (
        <td
          className="w-[100px] pl-4 cursor-pointer"
          onClick={() => {
            onContestClick();
          }}
        >
          <td className="pl-4 py-2 text-white text-xs font-[Barlow] font-normal w-[70px]">
            <p className="w-[70px]">
              {moment(new Date(item.startDate.seconds * 1000)).format(
                "MMM DD, YYYY"
              )}
            </p>
          </td>
        </td>
      ) : null}
      <td
        className="w-[100px] pl-4 cursor-pointer"
        onClick={() => {
          onContestClick();
        }}
      >
        <td className="py-2 text-white text-xs font-[Barlow] font-normal w-[70px]">
          {moment(new Date(item.endDate.seconds * 1000)).format("MMM DD, YYYY")}
          <p className="w-[70px]"></p>
        </td>
      </td>
      <td
        className="pl-4 py-2 text-white text-xs font-[Barlow] font-normal w-[130px] cursor-pointer"
        onClick={() => {
          onContestClick();
        }}
      >
        <div className="flex">
          {!live ? (
            <img
              src={item.image ? item.image : ""}
              className="w-[36px] h-[36px] mr-5"
              alt={item.name}
            />
          ) : null}

          <p className="w-[130px]">{item.name}</p>
        </div>
      </td>
      <td
        className="pl-4 py-2 text-white text-xs font-[Barlow] font-normal w-[50px] cursor-pointer"
        onClick={() => {
          onContestClick();
        }}
      >
        <p className="w-[50px]">World</p>
      </td>
      <td
        className="text-xs text-white font-[Barlow] font-normal py-2 w-[110px] cursor-pointer"
        onClick={() => {
          onContestClick();
        }}
      >
        <p className="pl-4 w-[110px]">{getUserEnrollment()}</p>
      </td>
      <td className="pl-4 py-2 text-white text-xs font-[Barlow] font-normal w-[100px]">
        {live || passed ? (
          <>{contestLeaderDetails()}</>
        ) : (
          <p
            className="text-[#17F9DA] cursor-pointer"
            onClick={() => {
              onContestClick();
            }}
          >
            View Details
          </p>
        )}
      </td>
      {onEdit ? (
        <AccessControl
          permission={PERMISSIONS.editContest}
          children={
            <td className="py-3.5 w-12">
              <div
                className="flex justify-center items-center text-white w-12 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(e, item);
                }}
              >
                <IoCreateOutline size={20} />
              </div>
            </td>
          }
        />
      ) : null}
      {onDelete ? (
        <AccessControl
          permission={PERMISSIONS.deleteContest}
          children={
            <td className="py-3.5 w-12">
              <div
                className="flex justify-center items-center text-white w-12 cursor-pointer"
                onClick={(e) => {
                  onDelete(e, item);
                }}
              >
                <AiOutlineDelete size={20} />
              </div>
            </td>
          }
        />
      ) : null}
    </tr>
  );
}

export default TableCol;
