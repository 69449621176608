import ActivityIndicator from "components/Loading/ActivityIndicator";

import TableRecentContact from "components/Table/TableRecentContact";
import React from "react";
import { TableWrapperContactsProps } from "types/Component";

function TableWrapperContacts({ isLoading, list }: TableWrapperContactsProps) {
  if (isLoading) {
    return (
      <div className="px-5">
        <ActivityIndicator size={36} />
      </div>
    );
  } else if (!list.length) {
    return (
      <div className="h-[200px] w-full text-white font-[play] flex justify-center items-center">
        No Contacts Found
      </div>
    );
  } else {
    return (
      <div className="p-[20px] overflow-y-hidden">
        <div className="w-full">
          <TableRecentContact list={list} />
        </div>
      </div>
    );
  }
}

export default TableWrapperContacts;
