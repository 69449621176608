import { get } from "http";
import { useDispatch, useSelector } from "react-redux";
import {
  addPotaAutoSpotAction,
  getNearbyParkReferencesAction,
  getParkByReferenceAction,
  getParkReferenceSuggestionsAction,
  setAutoSpotDataAction,
  setNearbyParkReferencesAction,
  setParkByReferenceAction,
  setParkReferenceSuggestionsAction,
} from "store/Actions/pota.actions";
import {
  getAutoSpotDataSelector,
  getNearbyParkReferencesDataSelector,
  getParkByReferenceDataSelector,
  getParkByReferenceLoadingSelector,
  getParkReferenceSuggestionsDataSelector,
} from "store/Reducers/pota.reducer";
import { ParkReferenceModel, PotaAutoSpotPayload } from "types/Models";

const usePota = () => {
  // Props
  const dispatch = useDispatch();
  const parkReferenceData = useSelector(getParkByReferenceDataSelector);
  const isParkReferenceDataLoading = useSelector(
    getParkByReferenceLoadingSelector
  );
  const parkReferenceSuggestionsData = useSelector(
    getParkReferenceSuggestionsDataSelector
  );

  const nearbyParkReferencesData = useSelector(
    getNearbyParkReferencesDataSelector
  );

  const autoSpotData = useSelector(getAutoSpotDataSelector);

  // Functions
  const getParkByReference = (parkReference: {
    value: string;
    key: string;
  }) => {
    dispatch(getParkByReferenceAction(parkReference));
  };

  const setParkByReference = (
    payload: { [key: string]: string | null } | null
  ) => {
    dispatch(setParkByReferenceAction(payload));
  };

  const getParReferenceSuggestions = (parkReference: string) => {
    dispatch(getParkReferenceSuggestionsAction(parkReference));
  };

  const setParReferenceSuggestions = (payload: any) => {
    dispatch(setParkReferenceSuggestionsAction(payload));
  };

  const getNearbyParkReferences = (center: [number, number]) => {
    dispatch(getNearbyParkReferencesAction(center));
  };

  const setNearbyParkReferences = (payload: any) => {
    dispatch(setNearbyParkReferencesAction(payload));
  };

  const addPotaAutoSpot = (
    payload: PotaAutoSpotPayload,
    logBookId?: string
  ) => {
    dispatch(addPotaAutoSpotAction(payload, logBookId));
  };

  const setAutoSpotData = (payload: Partial<PotaAutoSpotPayload> | null) => {
    dispatch(setAutoSpotDataAction(payload));
  };

  return {
    // state
    parkReferenceData,
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    nearbyParkReferencesData,
    autoSpotData,

    // functions
    getParkByReference,
    setParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    getNearbyParkReferences,
    setNearbyParkReferences,
    addPotaAutoSpot,
    setAutoSpotData,
  };
};

export default usePota;
