export const UserDataTable = "UserProfile";
export const LogBookTable = "LogBook";
export const ContestTable = "Contest";
export const LogBookContactTable = "LogBookContact";
export const UserAwards = "UserAwards";
export const CheckoutSessionsTable = "checkout_sessions";
export const Payments = "payments";
export const Subscriptions = "subscriptions";
export const UserNotification = "UserNotifications";
export const EnrolledUsers = "EnrolledUsers";
export const Awards = "Awards";
export const Spotting = "Spots";
export const QsoMapShares = "QsoMapShares";
export const SavedLocations = "SavedLocations";
export const UserRadios = "UserRadios";
export const UserAntennas = "UserAntennas";
export const UserStations = "UserStations";

export const ParkReference = "ParkReference";
export const CallsignCollection = "Callsigns";
