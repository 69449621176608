import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import React from "react";
import { globalNavigate } from "routes/GlobalRoutes";

function EmptyLogBook() {
  return (
    <main className="w-full dashboardWrapper flex flex-col items-center relative">
      <div className="mt-36 flex justify-center items-center flex-col lg:block">
        <h2 className="font-[Play] text-white text-2xl md:text-3xl xl:text-5xl text-center lg:text-left">
          That's a lot of Space!
        </h2>
        <p className="font-[Barlow] text-[#D0D6F9] mt-3.5 ml-1.5 text-lg md:text-2xl w-3/4 lg:w-[744px] text-center lg:text-left">
          Looks like you don't have any logbooks. To get started logging
          contacts, simply click the 'New Logbook' button. If you already have a
          contacts from another logbook like QRZ, you can import those logs
          here!
        </p>
      </div>
      <div className="mt-[100px] w-11/12 sm:w-80">
        <BorderButtonSecondary
          text={`+ New Logbook`}
          onClick={() => globalNavigate("/log-contacts/add-logbook")}
          className="sm:mb-0"
        />
        <p className="text-center text-white font-[Play] my-10">OR</p>
        <BorderButtonSecondary
          text={`Import .ADI File`}
          onClick={() => globalNavigate("/log-contacts/import-contact")}
          className="sm:mb-0"
        />
      </div>
    </main>
  );
}

export default EmptyLogBook;
