import React from "react";
import { geoCentroid } from "d3-geo";
import { Geography } from "react-simple-maps";

import { globalNavigate } from "routes/GlobalRoutes";
import { useSearchParams } from "react-router-dom";
import { StateItemProps } from "types/Component";
import { getStateShortCode } from "../../helpers/Utils";

const StateItem = ({ geo, setShowInfo }: StateItemProps) => {
  let [searchParams] = useSearchParams();
  const centroid = geoCentroid(geo);
  return (
    <g>
      <Geography
        key={geo.rsmKey}
        stroke="#FFF"
        style={{
          default: { fill: "#597BF5" },
          hover: { fill: "#002C87" },
          pressed: { fill: "#002C87" },
        }}
        geography={geo}
        onMouseEnter={() => {
          const { name } = geo.properties;
          setShowInfo({
            centroid,
            name,
          });
        }}
        onMouseLeave={() => {
          setShowInfo(null);
        }}
        onClick={() => {
          globalNavigate(
            `/leaderboard/detail?state=${getStateShortCode(
              geo.properties.name
            )}&week=${
              searchParams.get("week") ? searchParams.get("week") : 1
            }&show=${
              searchParams.get("show") ? searchParams.get("show") : "weekly"
            }`
          );
        }}
        fill="#597BF5"
      />
    </g>
  );
};

export default StateItem;
