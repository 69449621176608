import {
  Antenna,
  CallSignLookUpData,
  LogBookContactModel,
  LogBookModel,
  Radios,
  SavedLocation,
  SpotModel,
} from "types/Models";
import {
  EditLogBook,
  LogbookContactPaginationProp,
  NewLogBook,
  UserContactPaginationProp,
} from "types/State";

export const GET_FILTERED_LOGBOOK_DATA = "GET_FILTERED_LOGBOOK_DATA";
export const ADD_NEW_LOGBOOK = "ADD_NEW_LOGBOOK";
export const SAVE_LOGBOOKS_DATA = "SAVE_LOGBOOKS_DATA";
export const SELECTED_LOGBOOK_DATA = "SELECTED_LOGBOOK_DATA";
export const SAVE_LOGBOOK_CONTACT_DATA = "SAVE_LOGBOOK_CONTACT_DATA";
export const EDIT_LOGBOOK_CONTACT_DATA = "EDIT_LOGBOOK_CONTACT_DATA";
export const DELETE_LOGBOOK_CONTACT_DATA = "DELETE_LOGBOOK_CONTACT_DATA";
export const GET_LOGBOOK_CONTACT_DATA = "GET_LOGBOOK_CONTACT_DATA";
export const GET_LOGBOOK_CONTACT_FOR_ADIF_EXPORT =
  "GET_LOGBOOK_CONTACT_FOR_ADIF_EXPORT";
export const SAVE_CONTACTS_DATA = "SAVE_CONTACTS_DATA";
export const RESET_LOGBOOK_DATA = "RESET_LOGBOOK_DATA";
export const SAVE_ALL_CONTACTS_DATA = "SAVE_ALL_CONTACTS_DATA";
export const SAVE_ALL_CONTACTS_PAGINATED_DATA =
  "SAVE_ALL_CONTACTS_PAGINATED_DATA";
export const GET_All_CONTACT_DATA = "GET_All_CONTACT_DATA";
export const EDIT_CONTACT_MODAL_DATA = "EDIT_CONTACT_MODAL_DATA";
export const GET_LOGBOOK_DATA = "GET_LOGBOOK_DATA";
export const GET_ALL_USER_CONTACTS = "GET_ALL_USER_CONTACTS";
export const GET_ALL_USER_CONTACTS_FOR_PAGINATION =
  "GET_ALL_USER_CONTACTS_FOR_PAGINATION";
export const GET_PAGINATED_USER_CONTACTS_FOR_PAGINATION =
  "GET_PAGINATED_USER_CONTACTS_FOR_PAGINATION";

export const GET_PAGINATED_LOGBOOK_CONTACTS = "GET_PAGINATED_LOGBOOK_CONTACTS";
export const GET_CONTACT_POPUP_SUMMARY = "GET_CONTACT_POPUP_SUMMARY";

export const FETCH_CALLSIGN_DATA = "FETCH_CALLSIGN_DATA";
export const FETCH_COUNTRY_DATA_GRID = "FETCH_COUNTRY_DATA_GRID";

export const SET_CALLSIGN_DATA = "SET_CALLSIGN_DATA";
export const SAVE_ALL_USER_CONTACTS_DATA = "SAVE_ALL_USER_CONTACTS_DATA";
export const SAVE_ALL_PAGINATED_USER_CONTACTS_DATA =
  "SAVE_ALL_PAGINATED_USER_CONTACTS_DATA";
export const SAVE_ALL_PAGINATED_LOGBOOK_CONTACTS_DATA =
  "SAVE_ALL_PAGINATED_LOGBOOK_CONTACTS_DATA";
export const SAVE_CONTACT_POPUP_SUMMARY = "SAVE_CONTACT_POPUP_SUMMARY";

export const SAVE_WORKED_USER_COUNTRIES = "SAVE_WORKED_USER_COUNTRIES";
export const SAVE_WORKED_USER_GRID_SQUARE = "SAVE_WORKED_USER_GRID_SQUARE";
export const GET_PAGINATED_CONTACTS = "GET_PAGINATED_CONTACTS";
export const GET_PAGINATED_CONTACTS_WITH_PAGINATION =
  "GET_PAGINATED_CONTACTS_WITH_PAGINATION";
export const DELETE_USER_LOGBOOK = "DELETE_USER_LOGBOOK";
export const SAVE_LOGBOOK_COORDINATES = "SAVE_LOGBOOK_COORDINATES";
export const DELETE_CONTEST = "DELETE_CONTEST";

export const HANDLE_ADIF_FILE = "HANDLE_ADIF_FILE";

export const FETCH_CONTACT_DETAIL = "FETCH_CONTACT_DETAIL";
export const SET_CONTACT_DETAIL = "SET_CONTACT_DETAIL";

export const SEARCH_LOGBOOK = "SEARCH_LOGBOOK";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";

export const FETCH_DAILY_LEADERS = "FETCH_DAILY_LEADERS";
export const SET_DAILY_LEADERS = "SET_DAILY_LEADERS";

export const ADD_SPOT_MODAL = "ADD_SPOT_MODAL";

export const ADD_RADIO_MODAL = "ADD_RADIO_MODAL";
export const ADD_ANTENNA_MODAL = "ADD_ANTENNA_MODAL";
export const EDIT_LOGBOOK = "EDIT_LOGBOOK";

export const FETCH_SAVED_LOCATION = "FETCH_SAVED_LOCATION";
export const SET_SAVED_LOCATION = "SET_SAVED_LOCATION";
export const ADD_SAVED_LOCATION = "ADD_SAVED_LOCATION";
export const EDIT_SAVED_LOCATION = "EDIT_SAVED_LOCATION";
export const DELETE_SAVED_LOCATION = "DELETE_SAVED_LOCATION";

export const FETCH_RADIOS = "FETCH_RADIOS";
export const SET_RADIOS = "SET_RADIOS";
export const ADD_RADIOS = "ADD_RADIOS";
export const DELETE_RADIOS = "DELETE_RADIOS";

export const FETCH_ANTENNAS = "FETCH_ANTENNAS";
export const SET_ANTENNAS = "SET_ANTENNAS";
export const ADD_ANTENNAS = "ADD_ANTENNAS";
export const DELETE_ANTENNAS = "DELETE_ANTENNAS";

export const ADD_STATIONS_MODAL = "ADD_STATIONS_MODAL";
export const FETCH_STATIONS = "FETCH_STATIONS";
export const SET_STATIONS = "SET_STATIONS";
export const ADD_STATIONS = "ADD_STATIONS";
export const DELETE_STATIONS = "DELETE_STATIONS";

export interface LogBookActionPayload {
  type: string;
  payload?: any;
}

export function createNewLogBookAction(data: NewLogBook): LogBookActionPayload {
  return {
    type: ADD_NEW_LOGBOOK,
    payload: data,
  };
}

export function setContactsData(
  data: LogBookContactModel[]
): LogBookActionPayload {
  return {
    type: SAVE_CONTACTS_DATA,
    payload: data,
  };
}

export function fetchCallSignData(
  callsign: string,
  setValues: any,
  setUserBand: any,
  setUserMode: any
): LogBookActionPayload {
  return {
    type: FETCH_CALLSIGN_DATA,
    payload: {
      callsign,
      setValues,
      setUserBand,
      setUserMode,
    },
  };
}

export function fetchCountryDataGrid(
  grid: string,
  setValues: any
): LogBookActionPayload {
  return {
    type: FETCH_COUNTRY_DATA_GRID,
    payload: {
      grid,
      setValues,
    },
  };
}

export function setCallSignData(
  data: CallSignLookUpData | null
): LogBookActionPayload {
  return {
    type: SET_CALLSIGN_DATA,
    payload: data,
  };
}

export function setAllContactsData(
  data: LogBookContactModel[]
): LogBookActionPayload {
  return {
    type: SAVE_ALL_CONTACTS_DATA,
    payload: data,
  };
}

export function selectEditContactModalData(
  data: LogBookContactModel | null
): LogBookActionPayload {
  return {
    type: EDIT_CONTACT_MODAL_DATA,
    payload: data,
  };
}

export function getLogBooksAction(): LogBookActionPayload {
  return {
    type: GET_FILTERED_LOGBOOK_DATA,
  };
}

export function setSelectedLogBookAction(
  data: NewLogBook
): LogBookActionPayload {
  return {
    type: SELECTED_LOGBOOK_DATA,
    payload: data,
  };
}

export function saveContactLogBookDataAction(
  data: LogBookContactModel,
  resetHandler: () => void
): LogBookActionPayload {
  return {
    type: SAVE_LOGBOOK_CONTACT_DATA,
    payload: { data: data, resetHandler: resetHandler },
  };
}

export function editContactLogBookDataAction(
  data: LogBookContactModel,
  prevValues: LogBookContactModel
) {
  return {
    type: EDIT_LOGBOOK_CONTACT_DATA,
    payload: { data: data, prevValues: prevValues },
  };
}

export function getContactLogBookDataAction(data: any) {
  return {
    type: GET_LOGBOOK_CONTACT_DATA,
    payload: data,
  };
}

export function getContactLogBookForAdifExportAction(
  logbookId: string,
  options?: { parkReference?: string }
) {
  return {
    type: GET_LOGBOOK_CONTACT_FOR_ADIF_EXPORT,
    payload: { logbookId, options },
  };
}

export function getAllContactDataAction() {
  return {
    type: GET_All_CONTACT_DATA,
  };
}

export const deleteContactDataAction = (
  contactId: string,
  logBookId: string,
  contact: LogBookContactModel
): LogBookActionPayload => {
  return {
    type: DELETE_LOGBOOK_CONTACT_DATA,
    payload: { contactId, logBookId, contact },
  };
};

export const getAllUserContacts = (uid: string, data?: any) => {
  return {
    type: GET_ALL_USER_CONTACTS,
    payload: { uid, data: data },
  };
};

export const getAllUserContactsWithPagination = (uid: string) => {
  return {
    type: GET_ALL_USER_CONTACTS_FOR_PAGINATION,
    payload: uid,
  };
};

export const getPaginatedUserContactsWithPagination = (
  conditions: UserContactPaginationProp
) => {
  return {
    type: GET_PAGINATED_USER_CONTACTS_FOR_PAGINATION,
    payload: conditions,
  };
};

export const getPaginatedContactsData = (page: number) => {
  return {
    type: GET_PAGINATED_CONTACTS,
    payload: page,
  };
};

export const getAllPaginatedContactsWithPagination = (condition: any) => {
  return {
    type: GET_PAGINATED_CONTACTS_WITH_PAGINATION,
    payload: condition,
  };
};

export const deleteLogBookAction = (logbookId: string) => {
  return {
    type: DELETE_USER_LOGBOOK,
    payload: logbookId,
  };
};

export const handleEditLogBook = (data: EditLogBook) => {
  return {
    type: SAVE_LOGBOOK_COORDINATES,
    payload: data,
  };
};

export const handleAdifFIle = (data: any) => {
  return {
    type: HANDLE_ADIF_FILE,
    payload: data,
  };
};

export const getPaginatedLogbookContacts = (
  conditions: LogbookContactPaginationProp
) => {
  return {
    type: GET_PAGINATED_LOGBOOK_CONTACTS,
    payload: conditions,
  };
};

export const setUserLogBooks = (data: LogBookModel[]) => {
  return {
    type: SAVE_LOGBOOKS_DATA,
    payload: data,
  };
};
export const fetchLogbookDetails = (logbookId: string) => {
  return {
    type: GET_LOGBOOK_DATA,
    payload: logbookId,
  };
};

export const handleDeleteContestAction = (contestId: string) => {
  return {
    type: DELETE_CONTEST,
    payload: contestId,
  };
};

export const setAllPaginatedContactsData = (data: any) => {
  return {
    type: SAVE_ALL_PAGINATED_LOGBOOK_CONTACTS_DATA,
    payload: data,
  };
};

export const fetchContactDetail = (contactId: string) => {
  return {
    type: FETCH_CONTACT_DETAIL,
    payload: contactId,
  };
};

export const searchContacts = (contactData: any) => {
  return {
    type: SEARCH_LOGBOOK,
    payload: contactData,
  };
};

export const setSearchResults = (results: any) => {
  return {
    type: SET_SEARCH_RESULTS,
    payload: results,
  };
};

export const fetchDailyLeaders = () => {
  return {
    type: FETCH_DAILY_LEADERS,
  };
};

export const setDailyLeaders = (data: any) => {
  return {
    type: SET_DAILY_LEADERS,
    payload: data,
  };
};

export const setAddSpotModal = (value: boolean | SpotModel) => {
  return {
    type: ADD_SPOT_MODAL,
    payload: value,
  };
};
export const setContactDetails = (data: any) => {
  return {
    type: SET_CONTACT_DETAIL,
    payload: data,
  };
};

export const setAddRadioModal = (value: any) => {
  return {
    type: ADD_RADIO_MODAL,
    payload: value,
  };
};

export const setAddAntennaModal = (value: any) => {
  return {
    type: ADD_ANTENNA_MODAL,
    payload: value,
  };
};

export const setEditLogBook = (data: LogBookModel) => {
  return {
    type: EDIT_LOGBOOK,
    payload: data,
  };
};
export const setAddContactLogPopupSummary = (data: any) => {
  return {
    type: SAVE_CONTACT_POPUP_SUMMARY,
    payload: data,
  };
};

export const fetchSavedLocation = () => {
  return {
    type: FETCH_SAVED_LOCATION,
  };
};

export const addSavedLocation = (data: SavedLocation) => {
  return {
    type: ADD_SAVED_LOCATION,
    payload: data,
  };
};

// DELETE_SAVED_LOCATION;
export const deleteSavedLocation = (id: string) => {
  return {
    type: DELETE_SAVED_LOCATION,
    payload: id,
  };
};

// FETCH_RADIOS
export const fetchRadios = () => {
  return {
    type: FETCH_RADIOS,
  };
};

// DELETE_RADIOS
export const deleteRadios = (id: string) => {
  return {
    type: DELETE_RADIOS,
    payload: id,
  };
};

// ADD_RADIOS
export const addRadios = (data: Radios) => {
  return {
    type: ADD_RADIOS,
    payload: data,
  };
};

// FETCH_ANTENNAS
export const fetchAntennas = () => {
  return {
    type: FETCH_ANTENNAS,
  };
};

// DELETE_ANTENNAS
export const deleteAntennas = (id: string) => {
  return {
    type: DELETE_ANTENNAS,
    payload: id,
  };
};

// ADD_ANTENNAS
export const addAntennas = (data: Antenna) => {
  return {
    type: ADD_ANTENNAS,
    payload: data,
  };
};
export const getAddContactLogPopupSummary = (data: any) => {
  return {
    type: GET_CONTACT_POPUP_SUMMARY,
    payload: data,
  };
};

//station actions
export const setAddStationsModal = (value: any) => {
  return {
    type: ADD_STATIONS_MODAL,
    payload: value,
  };
};

export const fetchStations = () => {
  return {
    type: FETCH_STATIONS,
  };
};

export const setStations = (data: any) => {
  return {
    type: SET_STATIONS,
    payload: data,
  };
};

export const addStations = (data: any) => {
  return {
    type: ADD_STATIONS,
    payload: data,
  };
};

export const deleteStations = (id: string) => {
  return {
    type: DELETE_STATIONS,
    payload: id,
  };
};
