import React, { useEffect, useState, useRef } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Marker,
  Annotation,
} from "react-simple-maps";

import allStates from "./allStates.json";

import { useSearchParams } from "react-router-dom";
import StateItem from "./StateItem";
import { useDispatch, useSelector } from "react-redux";
import { SET_STATE_LEADER } from "store/Actions/leaderBoard.action";

import { getStateLeaderSelector } from "store/Reducers/leaderBoard.reducer";
import MemberMapInfo from "components/MemberMapInfo";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { getStateLeaderBoard } from "firebase-config";
import { getStateShortCode } from "../../helpers/Utils";
var isEmpty = require("lodash.isempty");

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const offsets: any = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const MapChart = () => {
  const [showInfo, setShowInfo] = useState<any>(false);
  const [isStateLeaderLoading, setIsStateLeaderLoading] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const stateLeader = useSelector(getStateLeaderSelector);
  const leaderInfo = useRef<any>(null);
  const activeRequestRef = useRef<any>(null);

  const getStateLeaders = async (week: any, state: string) => {
    const requestId = Date.now(); // Unique identifier for the request
    activeRequestRef.current = requestId;
    setIsStateLeaderLoading(true);
    dispatch({
      type: SET_STATE_LEADER,
      payload: {},
    });

    try {
      const stateShortCode = getStateShortCode(state);

      const stateResponse: any = await getStateLeaderBoard({
        week,
        state: stateShortCode,
      });

      // Check if the current request is still the active one
      if (activeRequestRef.current === requestId) {
        if (stateResponse && stateResponse.data) {
          if (stateResponse.data.state) {
            dispatch({
              type: SET_STATE_LEADER,
              payload: stateResponse.data.data,
            });
          } else {
            dispatch({
              type: SET_STATE_LEADER,
              payload: {},
            });
          }
        } else {
          dispatch({
            type: SET_STATE_LEADER,
            payload: {},
          });
        }
      }
    } catch (e) {
      if (activeRequestRef.current === requestId) {
        // Handle errors only if this is the active request
      }
    } finally {
      if (activeRequestRef.current === requestId) {
        setIsStateLeaderLoading(false);
      }
    }
  };

  useEffect(() => {
    leaderInfo.current = showInfo;
    if (showInfo) {
      getStateLeaders(
        searchParams.get("show") === "weekly" ? searchParams.get("week") : null,
        showInfo.name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInfo]);

  return (
    <ComposableMap projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => (
              <StateItem geo={geo} key={geo.rsmKey} setShowInfo={setShowInfo} />
            ))}

            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const cur = allStates.find((s) => s.val === geo.id);
              return (
                <g
                  key={geo.rsmKey + "-name"}
                  onMouseEnter={() => {
                    setShowInfo(showInfo);
                  }}
                  onMouseLeave={() => {
                    setShowInfo(null);
                  }}
                >
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                      <Marker coordinates={centroid}>
                        <text y="2" fontSize={14} textAnchor="middle">
                          {cur.id}
                        </text>
                      </Marker>
                    ) : (
                      <Annotation
                        subject={centroid}
                        dx={offsets[cur.id][0]}
                        dy={offsets[cur.id][1]}
                        connectorProps={{
                          stroke: "#fff",
                          strokeWidth: 2,
                          strokeLinecap: "round",
                        }}
                      >
                        <text
                          x={4}
                          fontSize={14}
                          alignmentBaseline="middle"
                          fill="white"
                        >
                          {cur.id}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
      {showInfo && (
        <Annotation
          subject={showInfo.centroid}
          dx={-50}
          dy={50}
          connectorProps={{
            stroke: "#FFF",
            strokeWidth: 2,
            strokeLinecap: "round",
          }}
          onMouseEnter={() => {
            setShowInfo(showInfo);
          }}
          onMouseLeave={() => {
            setShowInfo(null);
          }}
          style={{ overflow: "visible" }}
        >
          <foreignObject
            width={200}
            height={85}
            style={{ overflow: "visible" }}
          >
            {isStateLeaderLoading && stateLeader ? (
              <div className="w-[200px] h-[100px] bg-[#fff] border-2 border-[#46467F] rounded-md z-50">
                <ActivityIndicator size={20} />
              </div>
            ) : (
              <>
                {isEmpty(stateLeader) ? (
                  <div className="w-[200px] h-[100px] bg-[#fff] border-2 border-[#46467F] rounded-md z-50 flex items-center justify-center text-center">
                    <text>No leader for this territory yet.</text>
                  </div>
                ) : (
                  <MemberMapInfo
                    user={stateLeader!}
                    weekly={searchParams.get("show") === "weekly"}
                  />
                )}
              </>
            )}
          </foreignObject>
        </Annotation>
      )}
    </ComposableMap>
  );
};

export default MapChart;
