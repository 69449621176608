import { BorderDisplayCompProps } from "types/Component";
import { findCountryCode } from "helpers/Utils";
import ReactCountryFlag from "react-country-flag";
import CountryDxccFlag from "components/CountryDxccFlag";

function BorderDisplayComp({
  contactDetail,
  selectedGlobe,
  positionClassName,
  recentGlobeStyle,
}: BorderDisplayCompProps) {
  return (
    <div
      className={`absolute ${
        selectedGlobe
          ? "bottom-11"
          : positionClassName
          ? positionClassName
          : "top-32 sm:top-auto sm:bottom-20"
      } bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full sm:w-fit h-9 rounded-full flex justify-center items-center ${recentGlobeStyle}`}
    >
      <div
        className={`bg-[#1D204A] w-[calc(100%-3px)] h-[calc(100%-3px)] rounded-full flex justify-center items-center text-lg text-white sm:text-2xl font-Play px-5 sm:px-12`}
      >
        <CountryDxccFlag
          countryCode={contactDetail?.myCountry || ""}
          flagCode={contactDetail?.myFlagCode || ""}
        />
        <p className="text-base font-[Play] font-bold text-white mr-3 flex-1">
          {contactDetail?.myCallSign}
        </p>
        <p className="text-base font-[Play] font-normal text-white mr-3">
          contacted
        </p>

        <CountryDxccFlag
          countryCode={
            contactDetail?.theirCountry || contactDetail?.country || ""
          }
          flagCode={contactDetail?.flagCode || ""}
        />
        <p className="text-right text-base font-[Play] font-bold text-white ml-3 flex-1">
          {contactDetail?.theirCallsign}
        </p>
      </div>
    </div>
  );
}

export default BorderDisplayComp;
