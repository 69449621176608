import { findCountryCode } from "helpers/Utils";
import ReactCountryFlag from "react-country-flag";

import React from "react";
import { dxccCountryList } from "constants/DXCCCountryList";
import { LocationMappings } from "constants/LocationMappings";

type CountryDxccFlagProps = {
  countryCode: string;
  flagCode: string;
};

const CountryDxccFlag = ({ countryCode, flagCode }: CountryDxccFlagProps) => {
  if (flagCode) {
    try {
      const flagUrl = require(`../../assets/DXCCFlags/${flagCode?.toUpperCase()}.png`);

      return (
        <div title={countryCode}>
          <img
            src={flagUrl}
            alt={`Flag of ${flagCode}`}
            className="w-[1.5rem] h-[1.5rem]"
            title={countryCode}
          />
        </div>
      );
    } catch (error) {
      console.error(`Flag image for code ${flagCode} not found`, error);
      return null;
    }
  } else if (countryCode) {
    // first filter dxccCountryList by countryCode agains .country and try to fnid the flagCode
    const dxccCountry = dxccCountryList.find(
      (country) => country.country === countryCode || LocationMappings[countryCode] === country.country
    );
    if (dxccCountry) {
      try {
        const flagUrl = require(`../../assets/DXCCFlags/${dxccCountry.flagCode}.png`);

        return (
          <div title={countryCode}>
            <img
              src={flagUrl}
              alt={`Flag of ${dxccCountry.flagCode}`}
              className="w-[1.5rem] h-[1.5rem]"
              title={countryCode}
            />
          </div>
        );
      } catch (error) {
        return null;
      }
    } else if (
      countryCode &&
      findCountryCode({
        name: countryCode,
      })
    ) {
      return (
        <ReactCountryFlag
          countryCode={
            findCountryCode({
              name: countryCode,
            }).alpha2
          }
          svg
          style={{
            width: "1.5em",
            height: "1.5em",
          }}
          title={countryCode}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default CountryDxccFlag;
