import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import BorderButtonSquare from "components/Button/BorderButtonSquare";
import TextInput from "components/Inputs/TextInput";
import useLogBook from "hooks/logBook.hook";
import { globalNavigate } from "routes/GlobalRoutes";

function LogBookWrapper() {
  const {
    newLogBookName,
    setNewLogBookName,
    newLogBookTemplate,
    createNewLogBook,
    isSavingOnLoading,
  } = useLogBook();
  return (
    <div className="glassmorphism w-5/6 sm:w-2/3 md:w-1/2 py-11 px-5 sm:px-7 md:px-9 lg:p-12 flex flex-col items-center">
      <div className="w-full">
        <h4 className="font-['Barlow'] text-base text-white mb-2.5 font-bold">
          Logbook Name
        </h4>
        <TextInput
          text={newLogBookName}
          setText={(text) => {
            setNewLogBookName(text);
          }}
          placeHolder="Logbook Name *"
          name="logbookName"
          className="mb-2.5"
        />
        <p className="font-[Barlow] font-normal text-[#17F9DA] text-xs">
          Choose any name! Example: HF Log, 10M Log, SSB, POTA
        </p>
        {/* <h4 className="font-['Barlow'] text-lg text-white mb-2.5 font-bold mt-6">
          Templates
        </h4>
        <DropDownInput
          value={newLogBookTemplate}
          lists={["india", "us"]}
          onChange={(text: string) => {
            setNewLogBookTemplate(text);
          }}
        /> */}
      </div>
      <BorderButtonSecondary
        isLoading={isSavingOnLoading}
        text={"Save"}
        onClick={() => createNewLogBook({name: newLogBookName})}
        className="w-full lg:w-80 mt-16 mb-5"
      />
      <BorderButtonSquare
        text={"Cancel"}
        onClick={() => globalNavigate("/log-contacts")}
        className="w-full lg:w-80 mb-5 rounded-full"
      />
    </div>
  );
}

export default LogBookWrapper;
