import React from "react";
import Lottie from "lottie-react";
import Images from "assets/Images";
import LottieFiles from "assets/LottieFiles";
import { RouteLoadingProps } from "types/Component";

function RouteLoading({ text, noBg, subtext }: RouteLoadingProps) {
  return (
    <section className="relative w-full h-full flex justify-center items-center">
      {noBg ? null : (
        <img
          src={Images.WorldBg}
          className="absolute top-0 left-0 z-0 h-full w-full object-cover"
          alt=""
        />
      )}
      <div className="flex flex-col items-center relative">
        <div className="w-36 h-36">
          <Lottie animationData={LottieFiles.PlanetLoading} loop={true} />
        </div>
        <h3 className="text-white text-center font-[Play] font-bold text-xl mt-3 ">
          {text ? text : "Loading..."}
        </h3>
        {subtext ? (
          <p className="text-white text-center font-[Play] mt-3">{subtext}</p>
        ) : null}
      </div>
    </section>
  );
}

export default RouteLoading;
