import { useField } from "formik";
import { useEffect, useState } from "react";
import {
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import { RiInformationLine } from "react-icons/ri";
import { TitledRegionPickerProps } from "types/Component";
import TitledInput from "../TitledInput";

function TitledRegionPicker({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  title,
  error,
  props,
  value,
  tabindex,
  country,
  info,
  containerClass,
}: TitledRegionPickerProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  const [regionsAvailable, setRegionsAvailable] = useState(false);
  const [field] = useField({
    name: name ? name : "",
  });
  useEffect(() => {
    if (country) {
      const countryData = CountryRegionData.find(
        ([name, code]) => name === country || code === country
      );
      if (countryData && countryData[2]) {
        const regions = countryData[2]
          .split("|")
          .map((region) => region.split("~")[0]);
        setRegionsAvailable(regions.length > 0);
      } else {
        setRegionsAvailable(false);
      }
    } else {
      setRegionsAvailable(true); // Default to true if no country is selected
    }
  }, [country]);
  return (
    <div
      className={`${width ? width : "w-full"} ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="flex items-center">
        <label className="font-[Barlow] font-bold text-sm text-white truncate">
          {title}
        </label>

        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      {regionsAvailable ? (
        <RegionDropdown
          {...field}
          name={name}
          country={country ? country : ""}
          value={value ? value : ""}
          onChange={(txt) => setText(txt)}
          classes={`rcrs-region h-8 bg-[#23234C] px-3 text-sm font-[Barlow] text-white mt-1.5 w-full ${
            className ? className : ""
          } ${error ? "border border-orange-700 border-solid" : ""}`}
        />
      ) : (
        <input
          {...field}
          onChange={(event) => {
            setText(event.target.value);
          }}
          name={name}
          title={title}
          placeholder={"Their State"}
          tabIndex={tabindex}
          className={
            " h-8 bg-[#23234C] px-3 text-sm font-[Barlow] text-white mt-1.5 w-full"
          }
        />
      )}

      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px]  text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default TitledRegionPicker;
