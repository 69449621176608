import { UserProfile } from "types/Models";

export const GET_USER_DATA_BY_CALLSIGN = "GET_USER_DATA_BY_CALLSIGN";
export const STORE_USER_DATA_BY_CALLSIGN = "STORE_USER_DATA_BY_CALLSIGN";

export type ProfileActionPayload = {
  type: string;
  payload: string | UserProfile | null;
};

export type GuestActionPayload = {
  type: string;
  payload: any;
};

export type NewContacts = {
  [key: string]: boolean;
};

export function getUserProfileDataByCallSign(
  callSign: string,
  userId: string
): GuestActionPayload {
  return {
    type: GET_USER_DATA_BY_CALLSIGN,
    payload: {
      callSign: callSign,
      userId: userId,
    },
  };
}

export function storeUserProfileDataByCallSign(
  userProfile: UserProfile | null
): ProfileActionPayload {
  return {
    type: STORE_USER_DATA_BY_CALLSIGN,
    payload: userProfile,
  };
}
