import { MemberState } from "types/State";

import { RootState } from "store/Reducers";
import {
  MembersActionPayload,
  SAVE_GLOBE_MEMBERS_DATA,
  SAVE_MAP_MEMBERS_DATA,
  SAVE_MEMBERS_DATA,
} from "store/Actions/members.actions";
const defaultState: MemberState = {
  members: null,
  mapMembers: [],
  globeMembers: [],
};

export default function membersReducer(
  state = defaultState,
  action: MembersActionPayload
) {
  switch (action.type) {
    case SAVE_MEMBERS_DATA:
      return {
        ...state,
        members: action.payload,
      };
    case SAVE_MAP_MEMBERS_DATA:
      return {
        ...state,
        mapMembers: action.payload,
      };
    case SAVE_GLOBE_MEMBERS_DATA:
      return {
        ...state,
        globeMembers: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getMembersSelector = (state: RootState) => {
  return state.members.members;
};
export const getMapMembersSelector = (state: RootState) => {
  return state.members.mapMembers || [];
};
export const getGlobeMembersSelector = (state: RootState) => {
  return state.members.globeMembers || [];
};
