import { MemberShipFilter } from "types/Component";
import { UserProfile } from "types/Models";

export const GET_MEMBERS_DATA = "GET_MEMBERS_DATA";
export const SAVE_MEMBERS_DATA = "SAVE_MEMBERS_DATA";
export const SAVE_MAP_MEMBERS_DATA = "SAVE_MAP_MEMBERS_DATA";
export const SAVE_GLOBE_MEMBERS_DATA = "SAVE_GLOBE_MEMBERS_DATA";

export const GET_MEMBERS_DATA_WITH_PAGINATION =
  "GET_MEMBERS_DATA_WITH_PAGINATION";
export const LOADING_MEMBERS_DATA_STATUS = "LOADING_MEMBERS_DATA_STATUS";

export const FETCH_MAP_MEMBERS = "FETCH_MAP_MEMBERS";
export const FETCH_GLOBE_MEMBERS = "FETCH_GLOBE_MEMBERS";

export type MembersActionPayload = {
  type: string;
  payload?: any;
};

export type MembersData = {
  users: UserProfile[];
  count: any;
};

export const getMembersFromCollection = (
  conditions: MemberShipFilter
): MembersActionPayload => {
  return {
    type: GET_MEMBERS_DATA,
    payload: conditions,
  };
};

export const getMembersFromCollectionWithPagination = (
  data: any
): MembersActionPayload => {
  return {
    type: GET_MEMBERS_DATA_WITH_PAGINATION,
    payload: data,
  };
};

export const getMapMembers = (data: any): MembersActionPayload => {
  return {
    type: FETCH_MAP_MEMBERS,
    payload: data,
  };
};

export const getGlobeMembers = (data: any): MembersActionPayload => {
  return {
    type: FETCH_GLOBE_MEMBERS,
    payload: data,
  };
};
