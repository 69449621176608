import moment from "moment";

const getCurrentUTCtime = () => {
  const dateNow = new Date();
  const getHours =
    dateNow.getUTCHours() < 10
      ? `0${dateNow.getUTCHours()}`
      : dateNow.getUTCHours();
  const getMinutes =
    dateNow.getUTCMinutes() < 10
      ? `0${dateNow.getUTCMinutes()}`
      : dateNow.getUTCMinutes();
  const getSeconds =
    dateNow.getUTCSeconds() < 10
      ? `0${dateNow.getUTCSeconds()}`
      : dateNow.getUTCSeconds();
  return getHours + ":" + getMinutes + ":" + getSeconds;
};

const getCurrentDate = () => {
  const dateNow = new Date();
  const getMonth =
    dateNow.getUTCMonth() < 9
      ? `0${dateNow.getUTCMonth() + 1}`
      : dateNow.getUTCMonth() + 1;
  const getDate =
    dateNow.getUTCDate() < 10 ? `0${dateNow.getUTCDate()}` : dateNow.getUTCDate();
  return dateNow.getUTCFullYear() + "-" + getMonth + "-" + getDate;
};

const getCurrentDateString = () => {
  const dateNow = new Date();
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const month = months[dateNow.getUTCMonth()];
  const day = dateNow.getUTCDate();
  const year = dateNow.getUTCFullYear();
  return `${month} ${day}, ${year}`;
};

const getTimeFromNow = (dateTime: string) => {
  return moment.utc(dateTime).fromNow();
}

export { getCurrentUTCtime, getCurrentDate, getTimeFromNow, getCurrentDateString };
