import { db } from "firebase-config";
import { Award } from "types/Models";
import { Awards } from "constants/Collections";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
  writeBatch,
} from "firebase/firestore";

interface AwardServiceType {
  fetchAwards(): Promise<Award[]>;
  addAward(award: Award): Promise<boolean>;
}

const AwardService: AwardServiceType = {
  fetchAwards: async () => {
    try {
      const awardRef = query(collection(db, Awards));
      const award = await getDocs(awardRef);

      if (!award || award.docs.length === 0) {
        return [];
      }

      const awardData: any = award.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return awardData;
    } catch (e) {
      return [];
    }
  },

  addAward: async (award) => {
    try {
      const awardRef = doc(collection(db, Awards));
      await setDoc(awardRef, {
        ...award,
        timestamp: serverTimestamp(),
      });
      return true;
    } catch (e) {
      return false;
    }
  },
};

export default AwardService;
