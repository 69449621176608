import { UserProfile } from "firebase/auth";

export const GET_USERS_DATA_FOR_LEADERBOARD = "GET_USERS_DATA_FOR_LEADERBOARD";
export const GET_USERS_DATA_FOR_LEADERBOARD_WITH_MODE =
  "GET_USERS_DATA_FOR_LEADERBOARD_WITH_MODE";
export const SAVE_USERS_DATA_FOR_LEADERBOARD =
  "SAVE_USERS_DATA_FOR_LEADERBOARD";
export const SAVE_USERS_DATA_FOR_LEADERBOARD_WITH_MODE =
  "SAVE_USERS_DATA_FOR_LEADERBOARD_WITH_MODE";

export const GET_LEADER_BOARD_BY_WEEK = "GET_LEADER_BOARD_BY_WEEK";
export const GET_LEADER_BOARD_BY_WEEK_BY_MODE =
  "GET_LEADER_BOARD_BY_WEEK_BY_MODE";

export const SET_WEEK_LEADERS = "SET_WEEK_LEADERS";

export const SET_WEEK_LEADER_MODE = "SET_WEEK_LEADER_MODE";

export const SET_DISTANCE_LEADERBOARD = "SET_DISTANCE_LEADERBOARD";
export const GET_DISTANCE_LEADERBOARD = "GET_DISTANCE_LEADERBOARD";
export const GET_DX_LEADERBOARD = "GET_DX_LEADERBOARD";
export const SET_DX_LEADERBOARD = "SET_DX_LEADERBOARD";

export const GET_STATE_LEADER = "GET_STATE_LEADER";
export const SET_STATE_LEADER = "SET_STATE_LEADER";

export interface LeaderBoardPayload {
  type: string;
}

export type LeaderBoardActionPayload = {
  type: string;
  payload: any;
};

export function getLeaderBoardDataForOverAll(): LeaderBoardPayload {
  return {
    type: GET_USERS_DATA_FOR_LEADERBOARD,
  };
}

export function fetchLeaderBoardDataWithMode(
  mode: string
): LeaderBoardActionPayload {
  return {
    type: GET_USERS_DATA_FOR_LEADERBOARD_WITH_MODE,
    payload: mode,
  };
}

export function getLeaderBoardByWeek(data?: any) {
  return {
    type: GET_LEADER_BOARD_BY_WEEK,
    payload: data,
  };
}

export function getLeaderBoardByWeekByMode(
  week?: number,
  mode?: string,
  state?: string | null,
  country?: string | null
) {
  return {
    type: GET_LEADER_BOARD_BY_WEEK_BY_MODE,
    payload: { week, mode, state, country },
  };
}

export function setLeaderBoardByWeek(userProfile: UserProfile[]) {
  return {
    type: SET_WEEK_LEADERS,
    payload: userProfile,
  };
}

export function getDistanceLeaderBoard(data?: any) {
  return {
    type: GET_DISTANCE_LEADERBOARD,
    payload: data,
  };
}

export function getDXLeaderBoard(payload?: any) {
  return {
    type: GET_DX_LEADERBOARD,
    payload: payload,
  };
}

export function getStateLeaders(week?: any, state?: string) {
  return {
    type: GET_STATE_LEADER,
    payload: {
      week,
      state,
    },
  };
}
