import { GuestState } from "types/State";
import { RootState } from "store/Reducers";
import {
  GuestActionPayload,
  STORE_USER_DATA_BY_CALLSIGN,
} from "store/Actions/guest.action";
const defaultState: GuestState = {
  guestProfile: null,
};

export default function guestReducer(
  state = defaultState,
  action: GuestActionPayload
) {
  switch (action.type) {
    case STORE_USER_DATA_BY_CALLSIGN:
      return {
        ...state,
        guestProfile: action.payload,
      };
    default:
      return state;
  }
}

// GET STATE
export const getSelectedGuestUserSelector = (state: RootState) => {
  return state.guest.guestProfile;
};
