import React, { FC, useEffect, useMemo, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { useEditContact } from "hooks/useEditContact.hook";
import { useFormikContext } from "formik";

type ModalProps = {
  onClose: () => void;
};

export const LogbookModal: FC<ModalProps> = ({ onClose }) => {
  const { editContactModalData, logBooksList } = useEditContact();
  const { values, setValues } = useFormikContext<any>();

  const logbookOptions = useMemo(()=> logBooksList.map((logbook) => ({
    label: logbook.name,
    value: logbook.id,
  })), [logBooksList])

  const [logbookId, setlogbookId] = useState<string>(
    editContactModalData?.logBookId || ""
  );

  const onSaveClickHandler = () => {
    setValues((prev: any) => ({
      ...prev,
      logBookId: logbookId,
    }));

    onClose();
  };

  useEffect(() => {
    if (values?.logBookId) {
      setlogbookId(values?.logBookId);
    }
  }
  , [values?.logBookId]);

  return (
    <section className="absolute w-full h-full top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[20%] lg:px-[25%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full ">
          <div className="flex flex-col w-full bg-[#151541] text-white font-[Play] relative mt-20 border border-[#451836] px-[10%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <LogbookSettingDropdown
                list={logbookOptions}
                title="Logbook:"
                value={logbookId || ""}
                setValue={(value) => {
                  if (value === "add") {
                  } else {
                    setlogbookId(value);
                  }
                }}
                className="w-[80%] sm:w-[60%] md:w-[60%]"
              />
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                // isLoading={loading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
