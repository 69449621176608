import React, { useEffect } from "react";
import ContactMap from "components/Map/ContactMap";
import { Unsubscribe } from "firebase/firestore";
import useContest from "hooks/contest.hook";

import { useSearchParams } from "react-router-dom";

function ContestMap() {
  let [searchParams] = useSearchParams();
  const { fetchContestContacts, contestContactListener } = useContest();

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    fetchContestContacts(searchParams.get("contest_id")!);
    unsubscribe = contestContactListener(searchParams.get("contest_id")!);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full mt-10">
      <ContactMap
        className="w-full h-[75vh] relative"
        containerClassName="w-full"
        contest={true}
      />
    </div>
  );
}

export default ContestMap;
