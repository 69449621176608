import React, { useState } from "react";
import Icons from "assets/Icons";

import { useDispatch, useSelector } from "react-redux";
import {
  addRadios,
  deleteRadios,
  setAddAntennaModal,
  setAddRadioModal,
  setAddSpotModal,
} from "store/Actions/logBook.actions";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useSpotting from "hooks/spotting.hook";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { RadioBrands } from "constants/Config";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { Radios } from "types/Models";
import UploadService from "services/upload.service";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { getAddRadioModalSelector } from "store/Reducers/logBook.reducer";
const AddRadioModal = () => {
  const dispatch = useDispatch();
  const addRadioModal = useSelector(getAddRadioModalSelector);

  const [radioName, setRadioName] = useState(addRadioModal?.name || "");
  const [radioBrand, setRadioBrand] = useState(addRadioModal?.brand || "");
  const [radioModel, setRadioModel] = useState(addRadioModal?.model || "");
  const [radioImage, setRadioImage] = useState<any>();
  const [description, setDescription] = useState(
    addRadioModal?.description || ""
  );
  const [yearPurchased, setYearPurchased] = useState(
    addRadioModal?.yearPurchased || ""
  );
  const [yearManufactured, setYearManufactured] = useState(
    addRadioModal?.yearManufactured || ""
  );
  const [loading, setLoading] = useState(false);
  const { logbookSettingLoading } = useLogbookSetting();

  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const saveRadio = async () => {
    try {
      setLoading(true);
      if (radioBrand) {
        if (radioModel) {
          let imageUrl;
          if (radioImage && radioImage.length > 0) {
            imageUrl = await UploadService.handleUploadContestImage(
              radioImage[0],
              "radios"
            );
          }
          let radioData: Radios = {
            name: radioName,
            brand: radioBrand,
            model: radioModel,
            photoUrl: imageUrl || addRadioModal?.photoUrl || "",
            description: description,
            yearPurchased: yearPurchased,
            yearManufactured: yearManufactured,
            numberOfQSO: addRadioModal?.numberOfQSO || 0,
          };
          if (addRadioModal?.id) {
            radioData.id = addRadioModal.id;
          }
          dispatch(addRadios(radioData));
        } else {
          toast("Please enter radio model");
        }
      } else {
        toast("Please select radio brand");
      }
    } catch (e) {}
    setLoading(false);
  };

  const deleteRadio = () => {
    if (addRadioModal?.id) {
      //confirm delete with native browser confirmation alert
      if (window.confirm("Are you sure you want to delete this radio?")) {
        dispatch(deleteRadios(addRadioModal.id));
      }
    }
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[25%] lg:px-[25%] overflow-scroll">
      <div className=" w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] px-6 py-5 text-white font-[Play] relative mt-10 md:mt-0 border border-[#451836]">
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={() => {
                dispatch(setAddRadioModal(false));
              }}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
            <h5 className="font-[Play] font-bold text-white py-2 ml-3 w-full text-lg">
              Add Radios
            </h5>
            <div className="px-1 md:px-14 mt-10 w-full">
            <TitledInputDefault
                setText={(e) => {
                  setRadioName(e.target.value);
                }}
                name="name"
                title="Name:"
                placeHolder="Enter radio name"
                value={radioName}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <LogbookSettingDropdown
                list={RadioBrands}
                title="Brand:"
                value={radioBrand}
                className="w-[99.9%] sm:w-[99.9%] md:w-[99.9%] md:mr-14 sm:mr-10 relative mb-4"
                setValue={(value) => {
                  setRadioBrand(value);
                }}
              />

              <TitledInputDefault
                setText={(e) => {
                  setRadioModel(e.target.value);
                }}
                name="name"
                title="Model:"
                placeHolder="Enter radio model name"
                value={radioModel}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setRadioImage(e.target.files);
                }}
                name="name"
                title="Photo:"
                placeHolder="Enter station name"
                // value={radioImage}
                type="file"
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setDescription(e.target.value);
                }}
                name="name"
                title="Description:"
                placeHolder="Enter station name"
                value={description}
                multiple
                width={"w-[100%]"}
                className={"h-[100px] mt-[12px] mr-3 pt-2"}
                tabindex={20}
                error={null}
              />
              <div className="flex justify-between">
                <TitledInputDefault
                  setText={(e) => {
                    setYearPurchased(e.target.value);
                  }}
                  name="name"
                  title="Year Purchased:"
                  placeHolder="Enter station name"
                  value={yearPurchased}
                  type="date"
                  width={"w-[40%]"}
                  className={" mt-[12px] mr-3"}
                  tabindex={20}
                  error={null}
                />
                <TitledInputDefault
                  setText={(e) => {
                    setYearManufactured(e.target.value);
                  }}
                  name="name"
                  title="Year Manufactured (if known)::"
                  placeHolder="Enter station name"
                  value={yearManufactured}
                  type="date"
                  width={"w-[40%]"}
                  className={" mt-[12px] mr-3"}
                  tabindex={20}
                  error={null}
                />
              </div>
            </div>
            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={saveRadio}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
              {addRadioModal?.id ? (
                <BorderButtonSecondary
                  isLoading={loading || logbookSettingLoading}
                  text={"Delete"}
                  onClick={deleteRadio}
                  childClassName="text-sm"
                  className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3 ml-2"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddRadioModal;
