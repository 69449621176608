import React, { useMemo, useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, PolylineF } from "@react-google-maps/api";
import { mapStylesDark } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";
import { SpottingMapProps } from "types/Component";
import { MarkerLines, MarkerPoint } from "types/State";
import SpotMarker from "./SpotMarker";
import moment from "moment";

const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];

const CurvedLine = ({ start, end, lineData, setHoverArc }: any) => {
  const onLoad = (polyline: any) => {
    // console.log("polyline: ", polyline);
  };

  const options = {
    strokeColor: "#17F9DA",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#17F9DA",
    fillOpacity: 0.35,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
    geodesic: false,
  };

  const curvePath = [
    start,
    // { lat: curveMidPoint.lat(), lng: curveMidPoint.lng() },
    end,
  ];

  return (
    <PolylineF
      onLoad={onLoad}
      path={curvePath}
      options={options}
      onMouseOver={() => {
        if (setHoverArc) {
          setHoverArc(lineData);
        }
      }}
      onMouseOut={() => {
        if (setHoverArc) {
          setHoverArc(null);
        }
      }}
    />
  );
};

const SpottingMap = React.memo(
  ({ spotsData, spotLoading }: SpottingMapProps) => {
    const [mapMarkers, setMapMarkers] = useState<MarkerPoint[]>([]);
    const [polylines] = useState<MarkerLines[]>([]);
    const [isLoading] = useState<boolean>(false);
    const mapRef = useRef<any>(null);

    const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
      libraries: googleLibraries,
    });
    const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

    const onMapLoad = (map: any) => {
      mapRef.current = map;
    };


    useEffect(() => {
      const markers: any[] = [];
      // eslint-disable-next-line array-callback-return
      spotsData.forEach((contact) => {
        if (contact.theirCoordinates) {
          markers.push({
            lat: contact.theirCoordinates.latitude,
            lng: contact.theirCoordinates.longitude,
            contactInfo: {
              image: contact?.theirProfilePic,
              name: contact?.theirName,
              country: contact?.theirCountry,
              callSign: contact?.theirCallSign,
              frequency: contact?.frequency,
              mode: contact?.mode,
              lastSeen: moment(new Date(contact.lastSeen)).fromNow(),
              distance: "--",
            },
          });
        }
      });

      setMapMarkers(markers);
    }, [spotsData]);

    return (
      <div className="w-full h-[400px] relative rounded-2xl overflow-hidden mt-5 mb-4">
        {!isLoaded || isLoading || spotLoading ? (
          <RouteLoading />
        ) : (
          <GoogleMap
            mapContainerClassName="map-container no-close-button-on-info-windows"
            center={center}
            zoom={2}
            onLoad={onMapLoad}
            options={{
              styles: mapStylesDark,
              streetViewControl: false,
              rotateControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              scrollwheel: true,
            }}
          >
            {mapMarkers
              ? mapMarkers.map((position, index) => {
                  return (
                    <>
                      <SpotMarker
                        key={`${position.lat}-${position.lng}-${index}`}
                        position={position}
                        index={index}
                      />
                    </>
                  );
                })
              : null}
            {polylines.map((line: MarkerLines, index) => {
              return (
                <CurvedLine
                  key={`${line.start}-${line.end}-${index}`}
                  start={line.start}
                  end={line.end}
                  lineData={line}
                  setHoverArc={() => {}}
                />
              );
            })}
          </GoogleMap>
        )}
      </div>
    );
  }
);

export default SpottingMap;
