import React from "react";
import { BorderButtonSquareProps } from "types/Component";

function BorderButtonSquare({
  text,
  onClick,
  disabled,
  className,
}: BorderButtonSquareProps) {
  return (
    <div
      className={`relative h-8 w-40 mb-0 sm:mb-5 flex items-center border-solid border-[#eee] border ${
        disabled ? "pointer-events-none" : ""
      }${className ? className : ""}`}
      onClick={onClick}
    >
      <button className="w-full rounded-full relative text-white font-Play text-lg">
        {text}
      </button>
    </div>
  );
}

export default BorderButtonSquare;
