import { dxccCountryList } from "constants/DXCCCountryList";
import { useField } from "formik";
import { useMemo } from "react";
import { CountryDropdown } from "react-country-region-selector";
import { CountyPickerWithLabelProps } from "types/Component";

function CountyPickerWithLabel({
  multiline,
  label,
  placeholder,
  name,
  setText,
  error,
  value,
}: CountyPickerWithLabelProps) {
  const [field] = useField({
    name: name ? name : "",
  });
  const transformData = (data: any) => {
    return data.map((item: any) => ({
      value: item.country,
      label: item.country,
    }));
  };

  const options = useMemo(
    () => transformData(dxccCountryList),
    [dxccCountryList]
  );
  return (
    <div
      className={`w-full bg-[#FFFFFF0F] relative px-[15px] py-1 h-[55px] ${
        error ? "border border-orange-700 border-solid" : ""
      }`}
    >
      <p className="text-[#17F9DA] text-xs font-[Play] mb-1.5">{label}</p>
      <select
        {...field}
        name={name}
        value={value ? value : ""}
        className={
          "border-none w-full bg-transparent font-medium font-[Barlow] text-sm text-[#999]"
        }
        onChange={(e) => setText(e.target.value)}
      >
        <option value="" label="Select Country" />
        {options.map((item: any, index: number) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CountyPickerWithLabel;
