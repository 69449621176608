import { useEffect } from "react";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Header from "components/Header";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import SaveLocationTableCol from "components/Table/Table/SaveLocationTableCol";
import { NavigateBackButton } from "../NavigateBackButton";

const SavedLocations = () => {
  const { fetchUserSavedLocation, savedLocations, logbookSettingLoading } =
    useLogbookSetting();
  useEffect(() => {
    fetchUserSavedLocation();
  }, []);

  return (
    <main className="relative w-full lg:w-4/5 h-full">
      <Header title="Logbook" />
      <div className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className="h-full w-full overflow-x-hidden">
          <NavigateBackButton />
          <div className="glassmorphism w-full h-fit pb-6">
            <div className="px-3 md:px-7 py-5 flex items-center justify-between border-b border-slate-400 border-solid">
              <p className="font-[play] font-bold text-lg text-white">
                MY SAVED OPERATING LOCATIONS
              </p>
              <div className="w-[50%]">
                <div className="flex justify-end">
                  <div className="w-60 mr-5">
                    <BorderButtonSecondary
                      text={`+ New Operating Location`}
                      onClick={() => {
                        globalNavigate("/log-contacts/add-new-location");
                      }}
                      className="sm:mb-0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 mb-10 px-5 md:px-7 h-fit">
              <div className="w-full overflow-y-hidden">
                <table className="bg-white w-[655px] md:w-full">
                  <tbody>
                    <tr key="heading" className="bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
                        Name
                      </th>
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
                        State
                      </th>
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white flex items-center">
                        Country
                      </th>
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
                        Lat/Long
                      </th>
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
                        Grid Square
                      </th>
                      <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
                        Street Address
                      </th>
                      <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white">
                        Action
                      </th>
                    </tr>
                    {!logbookSettingLoading &&
                      savedLocations.map((item, index) => {
                        return (
                          <SaveLocationTableCol key={index} item={item} index={index} />
                        );
                      })}
                  </tbody>
                </table>
                {logbookSettingLoading ? <ActivityIndicator size={24} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SavedLocations;
