import React, { useRef } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { FilterDropDownProps } from "types/Component";

function FilterDropDown({
  placeHolder,
  list,
  width,
  className,
  onChange,
  value,
  multiple,
  arrow,
}: FilterDropDownProps) {
  const selectRef = useRef<HTMLSelectElement>(null);

  return (
    <select
      ref={selectRef}
      className={`bg-[#23234C] text-white text-sm font-[Barlow] px-2 ${
        width ? width : "w-full"
      } ${className ? className : ""}`}
      onChange={onChange}
      value={value || ""}
      multiple={multiple}
    >
      <option key="empty" value="">{placeHolder ? placeHolder : "Select"}</option>
      {list.map((item: any, index: number) => {
        return <option key={item.label} value={item.value}>{item.label}</option>;
      })}
    </select>
  );
}

export default FilterDropDown;
