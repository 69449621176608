import TableCol from "components/Table/TableContestEntries/TableCol";
import React, { useState } from "react";
import { TableContestEntriesProps } from "types/Component";

import { RiErrorWarningLine } from "react-icons/ri";
import AccessControl from "components/AccessControl";
import { PERMISSIONS } from "constants/Permissions";

function TableContestEntries({
  lists,
  live,
  userEnrolledContests,
  onEdit,
  onDelete,
  passed,
}: TableContestEntriesProps) {
  // const { members, getMembersWithPagination } = useMember();
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <>
      <div className="w-full overflow-y-hidden mt-6">
        <table className="w-[655px] md:w-full">
          <tbody>
            <tr className="bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
              {/* <th className="text-sm font-[Barlow] font-bold text-center pt-5 pb-2.5 text-white w-[60px]"></th> */}
              {!live ? (
                <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 text-white w-[50px]">
                  <div className="flex items-center justify-between pl-4">
                    Start Date
                  </div>
                </th>
              ) : null}
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 text-white w-[50px]">
                <div className="flex items-center justify-between">
                  End Date
                </div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 text-white w-[130px]">
                <div className="flex items-center justify-between">
                  Contest Name
                </div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 text-white w-[50px]">
                <div className="">Geo</div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left pl-4 pt-5 pb-2.5 text-white w-[110px]">
                <div className="">Enrollment</div>
              </th>
              <th className="text-xs font-[Barlow] font-bold text-left px-4 pt-5 pb-2.5 text-white w-[90px]">
                <div className="flex items-center">
                  <div className="flex items-center text-[#17F9DA]">
                    {live ? "Current Leader" : passed ? "Winner" : ""}
                  </div>
                  {passed ? (
                    <div
                      onMouseEnter={() => setInfoOpen(true)}
                      onMouseLeave={() => setInfoOpen(false)}
                      className="relative flex items-center text-[#17F9DA]"
                    >
                      <RiErrorWarningLine
                        color="orange"
                        className="ml-1"
                        size={14}
                      />
                      {infoOpen ? (
                        <div className="absolute top-[100%] right-4 w-[200px] z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
                          Notice: After careful review of each contact made
                          during the contest the selected Winner might change.
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </th>
              {onEdit ? (
                <AccessControl
                  permission={PERMISSIONS.editContest}
                  children={
                    <th className="text-xs font-[Barlow] font-bold text-left pl-4 pt-5 pb-2.5 text-white w-12">
                      <div className="" />
                    </th>
                  }
                />
              ) : null}
              {onDelete ? (
                <AccessControl
                  permission={PERMISSIONS.deleteContest}
                  children={
                    <th className="text-xs font-[Barlow] font-bold text-left pl-4 pt-5 pb-2.5 text-white w-12">
                      <div className="" />
                    </th>
                  }
                />
              ) : null}
            </tr>
            {lists.map((item: any, index: number) => {
              return (
                <TableCol
                  key={`${item.id}_${index}`}
                  item={item}
                  index={index}
                  live={live}
                  passed={passed}
                  userEnrolledContests={userEnrolledContests}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  className={`${
                    index % 2 ? "bg-[#151536]" : "bg-[#1F1F3F]"
                  } border-0 border-b border-slate-300 border-solid`}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TableContestEntries;
