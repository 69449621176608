import Icons from "assets/Icons";
import React from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import { HeaderNavBarProps } from "types/Component";

function HeaderNavBar({ className }: HeaderNavBarProps) {
  return (
    <div
      className={`${
        className ? className : ""
      } mt-6 px-5 relative flex justify-center h-[120px]`}
    >
      <img
        src={Icons.MainLogo}
        onClick={() => globalNavigate("/")}
        className="cursor-pointer object-contain w-[180px]"
        alt="logo"
        crossOrigin="anonymous"
      />
    </div>
  );
}

export default HeaderNavBar;
