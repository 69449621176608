import Icons from "assets/Icons";
import { WarningProps } from "types/Component";

function Warning({ message }: WarningProps) {
  return (
    <div className="flex w-[100%] items-center justify-center my-6">
      <div className="bg-[#010A41] flex flex-row justify-center px-8 py-1 rounded-xl">
        <img
          src={Icons.LeaderBoardWarning}
          alt=""
          className="mr-5 w-[30px] h-[30px]"
        />
        <p className="font-[Play] text-[#C8C5C5]">{message}</p>
      </div>
    </div>
  );
}

export default Warning;
