export const frequencyRanges = [
  {
    band: "2200",
    start: 0.1357,
    end: 0.1378,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "630",
    start: 0.472,
    end: 0.479,
    mode: "CW",
    subMode: "CW",
  },
    {
    band: "160",
    start: 1.840,
    end: 1.840,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "160",
    start: 1.8,
    end: 1.843,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "160",
    start: 1.843,
    end: 2.0,
    mode: "SSB",
    subMode: "LSB",
  },
  {
    band: "80",
    start: 3.5,
    end: 3.6,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "80",
    start: 3.573,
    end: 3.573,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "80",
    start: 3.568,
    end: 3.568,
    mode: "FT4",
    subMode: "FT4",
  },
  {
    band: "80",
    start: 3.6,
    end: 4.0,
    mode: "SSB",
    subMode: "LSB",
  },
  {
    band: "60",
    start: 5.3305,
    end: 5.5405,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "40",
    start: 7.0475,
    end: 7.0475,
    mode: "FT4",
    subMode: "FT4",
  },
    {
    band: "40",
    start: 7.074,
    end: 7.074,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "40",
    start: 7.0,
    end: 7.125,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "40",
    start: 7.125,
    end: 7.3,
    mode: "SSB",
    subMode: "LSB",
  },
  {
    band: "30",
    start: 10.1,
    end: 10.15,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "30",
    start: 10.140,
    end: 10.140,
    mode: "FT4",
    subMode: "FT4",
  },
{
    band: "30",
    start: 10.136,
    end: 10.136,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "20",
    start: 14.0,
    end: 14.15,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "20",
    start: 14.074,
    end: 14.074,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "20",
    start: 14.080,
    end: 14.080,
    mode: "FT4",
    subMode: "FT4",
  },
  {
    band: "20",
    start: 14.15,
    end: 14.35,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "17",
    start: 18.068,
    end: 18.11,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "17",
    start: 18.104,
    end: 18.104,
    mode: "FT4",
    subMode: "FT4",
  },
  {
    band: "17",
    start: 18.100,
    end: 18.100,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "17",
    start: 18.11,
    end: 18.168,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "15",
    start: 21.0,
    end: 21.2,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "15",
    start: 21.074,
    end: 21.074,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "15",
    start: 21.140,
    end: 21.140,
    mode: "FT4",
    subMode: "FT4",
  },
  {
    band: "15",
    start: 21.2,
    end: 21.4,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "12",
    start: 24.915,
    end: 24.915,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "12",
    start: 24.919,
    end: 24.919,
    mode: "FT4",
    subMode: "FT4",
  },
  {
    band: "12",
    start: 24.89,
    end: 24.99,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "10",
    start: 28.0,
    end: 28.3,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "10",
    start: 28.074,
    end: 28.074,
    mode: "FT8",
    subMode: "FT8",
  },
  {
    band: "10",
    start: 28.140,
    end: 28.140,
    mode: "FT4",
    subMode: "FT4",
  },
  {
    band: "10",
    start: 28.3,
    end: 29.6,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "10",
    start: 29.6,
    end: 29.7,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "6",
    start: 50.0,
    end: 50.1,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "6",
    start: 50.1,
    end: 52.05,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "6",
    start: 52.05,
    end: 54.0,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "2",
    start: 144.0,
    end: 144.1,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "2",
    start: 144.1,
    end: 144.6,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "2",
    start: 144.6,
    end: 148.0,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "1.25",
    start: 222.0,
    end: 222.34,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "1.25",
    start: 222.34,
    end: 225.0,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "0.7",
    start: 420.0,
    end: 432.0,
    mode: "TV",
    subMode: "TV",
  },
  {
    band: "0.7",
    start: 432.0,
    end: 432.125,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "0.7",
    start: 432.125,
    end: 450.0,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "0.33",
    start: 902.0,
    end: 904.0,
    mode: "CW",
    subMode: "CW",
  },
  {
    band: "0.33",
    start: 904.0,
    end: 906.0,
    mode: "DIG",
    subMode: "DIG",
  },
  {
    band: "0.33",
    start: 906.0,
    end: 907.0,
    mode: "SSB",
    subMode: "USB",
  },
  {
    band: "0.33",
    start: 907.0,
    end: 910.0,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "0.33",
    start: 910.0,
    end: 916.0,
    mode: "TV",
    subMode: "TV",
  },
  {
    band: "0.33",
    start: 916.0,
    end: 928.0,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "0.23",
    start: 1240,
    end: 1246,
    mode: "TV",
    subMode: "TV",
  },
  {
    band: "0.23",
    start: 1246,
    end: 1252,
    mode: "DIG",
    subMode: "DIG",
  },
  {
    band: "0.23",
    start: 1252,
    end: 1258,
    mode: "TV",
    subMode: "TV",
  },
  {
    band: "0.23",
    start: 1258,
    end: 1260,
    mode: "DIG",
    subMode: "DIG",
  },
  {
    band: "0.23",
    start: 1260,
    end: 1270,
    mode: "TV",
    subMode: "TV",
  },
  {
    band: "0.23",
    start: 1270,
    end: 1276,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "0.23",
    start: 1276,
    end: 1282,
    mode: "TV",
    subMode: "TV",
  },
  {
    band: "0.23",
    start: 1282,
    end: 1288,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "0.23",
    start: 1288,
    end: 1294,
    mode: "TV",
    subMode: "TV",
  },
  {
    band: "0.23",
    start: 1294,
    end: 1295,
    mode: "FM",
    subMode: "FM",
  },
  {
    band: "0.23",
    start: 1295,
    end: 1300,
    mode: "CW",
    subMode: "CW",
  },
];
