import { call, put, take, all, select } from "redux-saga/effects";

import {
  CONTEST_LOADING_STATUS,
  FETCH_ACTIVE_CONTEST_DATA,
  FETCH_CONTEST_DETAILS,
  FETCH_PASSED_CONTEST_DATA,
  FETCH_UPCOMING_CONTEST_DATA,
  SET_ACTIVE_CONTEST,
  SET_CONTEST_DETAILS,
  SET_PASSED_CONTEST,
  SET_UPCOMING_CONTEST,
  SHOW_ADD_CONTEST_MODAL,
  STORE_CONTEST_BY_ADMIN,
  SET_ENROLL_SUCCESS,
  ENROLL_CONTEST,
  SET_CONTESTANT_DETAIL,
  GET_USER_CONTEST_RANKING,
  SAVE_USER_CONTEST_RANKING,
  GET_CONTEST_LEADERBOARD,
  SET_CONTEST_LEADERBOARD,
  GET_CONTEST_CONTACTS,
  SET_CONTEST_CONTACTS,
  GET_CONTESTANT_DETAIL,
  SET_USER_CONTESTANT_DETAILS,
  FETCH_AWARD_CONTEST,
  SET_AWARD_CONTEST,
  EDIT_CONTEST,
  UPDATE_CONTEST,
  SET_CONTEST_ID_FOR_EDIT_MODAL,
} from "store/Actions/contest.actions";
import ContestService from "services/contest.service";
import {
  ContestLeadrBoard,
  ContestModel,
  ContestantUserDetail,
  LogBookContactModel,
  UserAwards,
  UserProfile,
} from "types/Models";
import UploadService from "services/upload.service";
import { toast } from "react-toastify";
import {
  ACTIVE_CONTEST_LOADING,
  CONTESTANT_DETAIL_LOADING,
  CONTESTANT_PROFILE_DETAIL_LOADING,
  CONTEST_CONTACTS_LOADING,
  CONTEST_DETAIL_LOADING,
  CONTEST_LEADER_BOARD_LOADING,
  ENROLL_CONTEST_LOADING,
  PASSED_CONTEST_LOADING,
  UPCOMING_CONTEST_LOADING,
} from "store/Actions/loading.action";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { authErrorHandler } from "helpers/ErrorHandler/auth";

import { getShowEditContestModalSelector } from "store/Reducers/contest.reducer";
import { DELETE_CONTEST } from "store/Actions/logBook.actions";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { getSavedLocationsSelector } from "store/Reducers/logBook.reducer";

//Workers

export function* workerStoreContestByAdmin(payload: any) {
  yield put({
    type: CONTEST_LOADING_STATUS,
    payload: true,
  });
  try {
    if (payload?.image?.name) {
      const profileImgUrl: null | string = yield call(() =>
        UploadService.handleUploadContestImage(payload?.image)
      );
      let data = { ...payload, image: profileImgUrl };
      yield call(() => ContestService.storeContest(data));
    } else {
      yield call(() => ContestService.storeContest(payload));
    }
    yield all([
      put({
        type: FETCH_ACTIVE_CONTEST_DATA,
      }),
      put({
        type: FETCH_UPCOMING_CONTEST_DATA,
      }),
      put({
        type: FETCH_PASSED_CONTEST_DATA,
      }),
      put({
        type: SHOW_ADD_CONTEST_MODAL,
        payload: false,
      }),
    ]);
    toast("Contest saved successfully");
  } catch (e) {
    toast("Unable to save contest");
  }
  yield put({
    type: CONTEST_LOADING_STATUS,
    payload: false,
  });
}

export function* workerFetchActiveContestData(payload: any) {
  yield put({
    type: ACTIVE_CONTEST_LOADING,
    payload: true,
  });
  try {
    const activeContest: ContestModel[] = yield call(
      ContestService.fetchActiveContest
    );
    if (activeContest) {
      yield put({
        type: SET_ACTIVE_CONTEST,
        payload: activeContest,
      });
    }
  } catch (e) {}
  yield put({
    type: ACTIVE_CONTEST_LOADING,
    payload: false,
  });
}

export function* workerFetchUpcomingContestData(payload: any) {
  yield put({
    type: UPCOMING_CONTEST_LOADING,
    payload: true,
  });
  try {
    const upcomingContest: ContestModel[] = yield call(
      ContestService.fetchUpcomingContest
    );
    if (upcomingContest) {
      yield put({
        type: SET_UPCOMING_CONTEST,
        payload: upcomingContest,
      });
    }
  } catch (e) {}
  yield put({
    type: UPCOMING_CONTEST_LOADING,
    payload: false,
  });
}

export function* workerFetchPassedContestData(payload: any) {
  yield put({
    type: PASSED_CONTEST_LOADING,
    payload: true,
  });
  try {
    const passedContest: ContestModel[] = yield call(
      ContestService.fetchPassedContest
    );
    if (passedContest) {
      yield put({
        type: SET_PASSED_CONTEST,
        payload: passedContest,
      });
    }
  } catch (e) {}
  yield put({
    type: PASSED_CONTEST_LOADING,
    payload: false,
  });
}

export function* workerFetchContestDetail(payload: any) {
  const { contestId, userId } = payload;

  yield put({
    type: CONTEST_DETAIL_LOADING,
    payload: true,
  });
  try {
    const contestDetail: ContestModel = yield call(() =>
      ContestService.fetchContestDetail(contestId)
    );
    yield put({
      type: SET_CONTEST_DETAILS,
      payload: contestDetail,
    });
    yield put({
      type: CONTEST_DETAIL_LOADING,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: CONTEST_DETAIL_LOADING,
      payload: false,
    });
  }

  try {
    yield put({
      type: CONTESTANT_DETAIL_LOADING,
      payload: true,
    });
    const contestantDetail: ContestModel = yield call(() =>
      ContestService.fetchContestantDetail(contestId, userId)
    );
    if (contestantDetail) {
      yield put({
        type: SET_CONTESTANT_DETAIL,
        payload: contestantDetail,
      });
    }
    yield put({
      type: CONTESTANT_DETAIL_LOADING,
      payload: false,
    });
  } catch (e) {
    yield put({
      type: CONTESTANT_DETAIL_LOADING,
      payload: false,
    });
  }
}

export function* workerEnrollContest(payload: ContestModel) {
  yield put({
    type: ENROLL_CONTEST_LOADING,
    payload: true,
  });
  try {
    let userProfile: UserProfile = yield select(getUserProfileSelector);
    let savedLocations: Array<any> = yield select(getSavedLocationsSelector);
    let locationDetails: any = null;

    if (userProfile?.savedLocationId){
      locationDetails = {
        ...savedLocations.find(
          (location: any) => location.id === userProfile?.savedLocationId
        ),
        isCustom: false,
      } as any;
    }

    const enrollContest: boolean = yield call(() =>
      ContestService.enrollContest(payload, userProfile, locationDetails)
    );
    if (enrollContest) {
      yield put({
        type: FETCH_CONTEST_DETAILS,
        payload: {
          contestId: payload.id,
          userId: userProfile.uid,
        },
      });
      yield put({
        type: SET_ENROLL_SUCCESS,
        payload: enrollContest,
      });
    } else {
      authErrorHandler({});
    }
  } catch (e) {
    authErrorHandler({});
  }
  yield put({
    type: ENROLL_CONTEST_LOADING,
    payload: false,
  });
}

export function* workerGetUserContestRanking(payload: any) {
  try {
    const contestRanking: ContestModel[] = yield call(() =>
      ContestService.fetchUserContestRanking(payload)
    );
    if (contestRanking) {
      yield put({
        type: SAVE_USER_CONTEST_RANKING,
        payload: contestRanking,
      });
    }
  } catch (e) {}
}

// export function* workerGetContestLeader(contestId: string) {
//   try {
//     // let activeContest: ContestModel[] = yield select(getActiveContestSelector);
//     // let passedContest: ContestModel[] = yield select(getPassedContestSelector);

//     // const contestIds: string[] = activeContest
//     //   .concat(passedContest)
//     //   .map((data) => data.id || "");

//     // yield put({
//     //   type: CONTEST_LEADER_LOADING,
//     //   payload: true,
//     // });
//     // const contestLeader: ContestLeader = yield call(() =>
//     //   ContestService.fetchContestLeader(contestIds)
//     // );
//     // yield put({
//     //   type: SET_CONTEST_LEADER_DATA,
//     //   payload: contestLeader,
//     // });
//   } catch (e) {

//   }
//   // yield put({
//   //   type: CONTEST_LEADER_LOADING,
//   //   payload: false,
//   // });
// }
export function* workerDeleteContest(payload: string) {
  try {
    const deleted: boolean = yield call(() =>
      ContestService.deleteContestById(payload)
    );
    if (deleted) {
      showToast({
        message: "Deleted Successfully",
        type: ToastTypes.SUCCESS,
      });
      yield put({
        type: FETCH_PASSED_CONTEST_DATA,
      });
      yield put({
        type: FETCH_ACTIVE_CONTEST_DATA,
      });
      yield put({
        type: FETCH_UPCOMING_CONTEST_DATA,
      });
    } else {
      showToast({
        message: "Unable to delete",
        type: ToastTypes.WARN,
      });
    }
  } catch (e) {
    showToast({
      message: "Unable to delete",
      type: ToastTypes.WARN,
    });
  }
}

export function* workerGetContestLeaderBoard(contestId: string) {
  try {
    yield put({
      type: CONTEST_LEADER_BOARD_LOADING,
      payload: true,
    });
    const contestLeaderBoard: ContestLeadrBoard[] = yield call(() =>
      ContestService.fetchContestLeaderBoard(contestId)
    );
    if (contestLeaderBoard) {
      yield put({
        type: SET_CONTEST_LEADERBOARD,
        payload: contestLeaderBoard,
      });
    }
  } catch (e) {}
  yield put({
    type: CONTEST_LEADER_BOARD_LOADING,
    payload: false,
  });
}

export function* workerGetContestContacts(contestId: string) {
  try {
    yield put({
      type: CONTEST_CONTACTS_LOADING,
      payload: true,
    });
    const contestLeaderBoard: LogBookContactModel[] = yield call(() =>
      ContestService.fetchContestContacts(contestId)
    );
    if (contestLeaderBoard) {
      yield put({
        type: SET_CONTEST_CONTACTS,
        payload: contestLeaderBoard,
      });
    }
  } catch (e) {}
  yield put({
    type: CONTEST_CONTACTS_LOADING,
    payload: false,
  });
}

export function* workerGetContestantDetail(payload: any) {
  try {
    yield put({
      type: CONTESTANT_PROFILE_DETAIL_LOADING,
      payload: true,
    });
    const userContestDetail: ContestantUserDetail = yield call(() =>
      ContestService.fetchContestantUserDetail(payload)
    );

    if (userContestDetail) {
      yield put({
        type: SET_USER_CONTESTANT_DETAILS,
        payload: userContestDetail,
      });
    }
  } catch (e) {}
  yield put({
    type: CONTESTANT_PROFILE_DETAIL_LOADING,
    payload: false,
  });
}

export function* workerGetAwardContest(payload: any) {
  try {
    const awardContest: UserAwards[] = yield call(() =>
      ContestService.fetchAwardContest()
    );

    if (awardContest) {
      yield put({
        type: SET_AWARD_CONTEST,
        payload: awardContest,
      });
    }
  } catch (e) {}
}
export function* workerEditContest(payload: {
  data: ContestModel;
  newImg: boolean;
}) {
  yield put({
    type: CONTEST_LOADING_STATUS,
    payload: true,
  });
  try {
    let editedData = payload?.data;
    if (payload?.newImg) {
      const profileImgUrl: null | string = yield call(() =>
        UploadService.handleUploadContestImage(payload?.data?.image)
      );
      editedData = { ...payload?.data, image: profileImgUrl || "" };
    }
    let selectedContest: ContestModel = yield select(
      getShowEditContestModalSelector
    );
    const newContestDetail: ContestantUserDetail = yield call(() =>
      ContestService.editContest(editedData, selectedContest?.id || "")
    );
    if (newContestDetail) {
      toast("Contest edit successfully");
      yield all([
        put({
          type: SET_CONTEST_ID_FOR_EDIT_MODAL,
          payload: null,
        }),
        put({
          type: SHOW_ADD_CONTEST_MODAL,
          payload: false,
        }),
        put({
          type: FETCH_PASSED_CONTEST_DATA,
        }),
        put({
          type: FETCH_ACTIVE_CONTEST_DATA,
        }),
        put({
          type: FETCH_UPCOMING_CONTEST_DATA,
        }),
      ]);
    } else {
      showToast({
        message: "Unable to edit contest",
        type: ToastTypes.WARN,
      });
    }
  } catch (e) {
    toast("Unable to edit contest");
  }
  yield put({
    type: CONTEST_LOADING_STATUS,
    payload: false,
  });
}

export function* workerUpdateContest(payload: ContestModel) {
  try {
    yield put({
      type: CONTESTANT_PROFILE_DETAIL_LOADING,
      payload: true,
    });
    const updateContest: boolean = yield call(() =>
      ContestService.updateContest(payload)
    );
    if (updateContest) {
      const contestDetail: ContestModel = yield call(() =>
        ContestService.fetchContestDetail(payload.id || "")
      );
      yield put({
        type: SET_CONTEST_DETAILS,
        payload: contestDetail,
      });
      yield put({
        type: CONTESTANT_PROFILE_DETAIL_LOADING,
        payload: false,
      });
      showToast({
        message: "Winner updated successfully!",
        type: ToastTypes.SUCCESS,
      });

      yield put({
        type: FETCH_PASSED_CONTEST_DATA,
      });
      yield put({
        type: FETCH_ACTIVE_CONTEST_DATA,
      });
      yield put({
        type: FETCH_UPCOMING_CONTEST_DATA,
      });
    }
  } catch (e) {}
  yield put({
    type: CONTESTANT_PROFILE_DETAIL_LOADING,
    payload: false,
  });
}

//Watchers
export function* watchStoreContestByAdmin() {
  while (true) {
    const { payload } = yield take(STORE_CONTEST_BY_ADMIN);
    yield call(workerStoreContestByAdmin, payload);
  }
}

export function* watchActiveFetchContest() {
  while (true) {
    const { payload } = yield take(FETCH_ACTIVE_CONTEST_DATA);
    yield call(workerFetchActiveContestData, payload);
  }
}

export function* watchUpcomingFetchContest() {
  while (true) {
    const { payload } = yield take(FETCH_UPCOMING_CONTEST_DATA);
    yield call(workerFetchUpcomingContestData, payload);
  }
}

export function* watchPassedFetchContest() {
  while (true) {
    const { payload } = yield take(FETCH_PASSED_CONTEST_DATA);
    yield call(workerFetchPassedContestData, payload);
  }
}

export function* watchFetchContestDetail() {
  while (true) {
    const { payload } = yield take(FETCH_CONTEST_DETAILS);
    yield call(workerFetchContestDetail, payload);
  }
}

export function* watchEnrollContest() {
  while (true) {
    const { payload } = yield take(ENROLL_CONTEST);
    yield call(workerEnrollContest, payload);
  }
}

export function* watchGetUserContestRanking() {
  while (true) {
    const { payload } = yield take(GET_USER_CONTEST_RANKING);
    yield call(workerGetUserContestRanking, payload);
  }
}

// export function* watchGetContestLeader() {
//   while (true) {
//     const { payload } = yield take(GET_CONTEST_LEADER);
//     yield call(workerGetContestLeader, payload);
//   }
// }
export function* watchDeleteContest() {
  while (true) {
    const { payload } = yield take(DELETE_CONTEST);
    yield call(workerDeleteContest, payload);
  }
}

export function* watchGetContestLeaderBoard() {
  while (true) {
    const { payload } = yield take(GET_CONTEST_LEADERBOARD);
    yield call(workerGetContestLeaderBoard, payload);
  }
}

export function* watchGetContestContacts() {
  while (true) {
    const { payload } = yield take(GET_CONTEST_CONTACTS);
    yield call(workerGetContestContacts, payload);
  }
}

export function* watchGetContestantDetail() {
  while (true) {
    const { payload } = yield take(GET_CONTESTANT_DETAIL);
    yield call(workerGetContestantDetail, payload);
  }
}

export function* watchGetAwardContest() {
  while (true) {
    const { payload } = yield take(FETCH_AWARD_CONTEST);
    yield call(workerGetAwardContest, payload);
  }
}
export function* watchEditContest() {
  while (true) {
    const { payload } = yield take(EDIT_CONTEST);
    yield call(workerEditContest, payload);
  }
}

export function* watchUpdateContest() {
  while (true) {
    const { payload } = yield take(UPDATE_CONTEST);
    yield call(workerUpdateContest, payload);
  }
}
