import Icons from "assets/Icons";
import TitledInput from "components/Inputs/TitledInput";
import { Tooltip } from "components/Tooltip";
import { Templates } from "constants/Config";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { showToast } from "helpers/Toast";
import { suggestionsFormatter } from "helpers/Utils";
import usePota from "hooks/pota.hook";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC, useEffect, useState } from "react";
import { ToastTypes } from "types/Component";
import { ParkReferenceModel } from "types/Models";
import { useDebouncedCallback } from "use-debounce";

export const PotaSettings: FC = () => {
  const { values, setValues } = useLogBookSettingsForm();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [parksAdded, setParksAdded] = useState<boolean>(false);
  const {
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    getParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    setParkByReference,
    getNearbyParkReferences,
    nearbyParkReferencesData,
  } = usePota();

  const debouncedParkReferenceChangeHandler = useDebouncedCallback(
    (searchString) => getParReferenceSuggestions(searchString),
    1000
  );

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) => getParkByReference(searchString),
    200
  );

  useEffect(() => {
    if (Object.keys(values?.myParks || {}).length && !parksAdded) {
      setFormData((prev: any) => ({ ...prev, myParks: values?.myParks }));
      setParksAdded(true);
    }
  }, [values?.myParks]);

  if (values?.logbookStyle !== Templates.POTA) return null;

  const resetParkReferenceSuggestions = (key: string) => {
    setTimeout(() => setParReferenceSuggestions({ key, value: null }), 200);
  };

  const hideParkSuggestions = () => {
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const showParkSuggestions = (key: string, setValues: any) => {
    if (!showSuggestions) return {};

    return {
      suggestions: formData?.[key]
        ? parkReferenceSuggestionsData?.[key]
        : nearbyParkReferencesData,
      suggestionsFormatter,
      onSuggestionClick: (suggestion: ParkReferenceModel) => {
        // Check if myParks has more than 4 keys
        if (Object.keys(formData?.myParks || {}).length >= 5) {
          showToast({
            message: "A maximum of 5 parks are allowed",
            type: ToastTypes.ERROR,
          });
          return; // Stop further execution
        }

        // after setting value remove suggestions
        resetParkReferenceSuggestions(key);

        setValues((prev: any) => {
          return {
            ...prev,
            myParks: { ...prev.myParks, [suggestion?.reference]: suggestion },
            myPark: "",
          };
        });

        // set formdata
        setFormData((prev: any) => {
          return {
            ...prev,
            myParks: { ...prev.myParks, [suggestion?.reference]: suggestion },
            myPark: "",
          };
        });

        // hide suggestions
        hideParkSuggestions();
      },
    };
  };

  const findMyParkHandler = async () => {
    const coordinates = await fetchCoordinates();

    if (coordinates?.latitude && coordinates?.longitude) {
      getNearbyParkReferences([coordinates?.latitude, coordinates?.longitude]);
      setShowSuggestions(true);
    }
  };

  const renderSelectedParks = (setValues: any) => {
    if (!Object.keys(formData?.myParks || {}).length) return null;

    const removePark = (key: string) => {
      //remove park from form data as well as key
      setFormData((prev: any) => {
        const { [key]: omit, ...rest } = prev.myParks;
        return { ...prev, myParks: rest };
      });

      // also update formit
      setValues((prev: any) => {
        const { [key]: omit, ...rest } = prev.myParks;
        return { ...prev, myParks: rest };
      });
    };

    return (
      <div className="flex flex-row text-white text-[11px] gap-2 -mt-[10px] flex-wrap">
        {Object.keys(formData.myParks).map((key, index) => (
          <div
            key={index}
            className="flex flex-row rounded-[10px] border border-neutral-400 gap-2 px-2 py-1 items-center bg-neutral-400 bg-opacity-25 "
          >
            <Tooltip
              message={`${formData.myParks[key]?.reference} - ${formData.myParks[key]?.parktypeDesc}`}
              className="w-[180px]"
            >
              <div>{`${formData.myParks[key]?.reference}`}</div>
            </Tooltip>

            <img
              src={Icons.CrossIcon}
              className="h-3 w-3"
              alt="close"
              onClick={() => removePark(key)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col text-white gap-4">
      <div className="flex flex-row items-center font-bold font-Play text-xl gap-2">
        <img src={Icons.PotaTree} className="h-6" alt="logbook name" />
        POTA Settings
      </div>
      <div className="flex flex-wrap">
        <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-4 w-full items-start">
          <div className="w-[68%]">
            <TitledInput
              setText={(e) => {
                setValues((prev: any) => {
                  return {
                    ...prev,
                    myPark: e.target.value.toUpperCase().trim(),
                  };
                });

                setFormData((prev: any) => ({
                  ...prev,
                  myPark: e.target.value.toUpperCase().trim(),
                }));

                if (e.target.value.length > 1) {
                  debouncedParkReferenceChangeHandler({
                    key: "myPark",
                    value: e.target.value.toUpperCase().trim(),
                  });
                }

                // reset park data on change
                setParkByReference({ key: "myPark", value: null });
              }}
              onBlur={() => {
                if (values.myPark) {
                  debouncedGetParkByReference({
                    value: values.myPark,
                    key: "myPark",
                  });
                }

                // remove suggestions on blur
                resetParkReferenceSuggestions("myPark");

                // hide suggestions
                hideParkSuggestions();
              }}
              onFocus={() => setShowSuggestions(true)}
              // status={
              //   parkReferenceData && parkReferenceData?.myPark
              //     ? "Park Match"
              //     : null
              // }
              // statusBg={
              //   parkReferenceData && parkReferenceData?.myPark
              //     ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
              //     : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
              // }
              isLoading={!!isParkReferenceDataLoading?.myPark}
              name="myPark"
              title="Select Your Park(s):"
              placeHolder="Type the name of the park here"
              width="w-full"
              tabindex={15}
              value={values.myPark}
              // error={
              //   errors?.myParks && touched?.myParks
              //     ? errors?.myParks
              //     : undefined
              // }
              containerClass={"m-0"}
              {...showParkSuggestions("myPark", setValues)}
              className={"h-10"}
            />

            {renderSelectedParks(setValues)}
          </div>

          <button
            type="button"
            onClick={findMyParkHandler}
            className="text-play text-white rounded-full px-4 py-1.5 border-2 border-emerald-500 mt-6"
          >
            Find My Park
          </button>
        </div>
      </div>
    </div>
  );
};
