import { combineReducers } from "redux";
import authorizationReducer from "store/Reducers/authorization.reducer";
import configReducer from "store/Reducers/config.reducer";
import guestReducer from "store/Reducers/guest.reducer";
import leaderBoardReducer from "store/Reducers/leaderBoard.reducer";
import loadingReducer from "store/Reducers/loading.reducer";
import logBookReducer from "store/Reducers/logBook.reducer";
import profileReducer from "store/Reducers/profile.reducer";
import subscriptionReducer from "store/Reducers/subscription.reducer";
import notificationReducer from "store/Reducers/notification.reducer";

import {
  AuthorizationState,
  ConfigState,
  GuestState,
  LeaderBoardState,
  LoadingState,
  LogBookState,
  ProfileState,
  SubscriptionState,
  NotificationState,
  MemberState,
  ContestState,
  SpottingState,
  PotaState,
  CallsignState,
  AwardState
} from "types/State";

import contestReducer from "./contest.reducer";
import spottingReducer from "./spotting.reducer";
import membersReducer from "store/Reducers/members.reducer";
import potaReducer from "./pota.reducer";
import callsignReducer from "./callsign.reducer";
import awardReducer from "./award.reducer";

const rootReducer = combineReducers({
  authorization: authorizationReducer,
  profile: profileReducer,
  config: configReducer,
  logBook: logBookReducer,
  loading: loadingReducer,
  leaderBoard: leaderBoardReducer,
  subscription: subscriptionReducer,
  guest: guestReducer,
  notification: notificationReducer,
  members: membersReducer,
  contest: contestReducer,
  spotting: spottingReducer,
  pota: potaReducer,
  callsign: callsignReducer,
  award: awardReducer,
});
export interface RootState {
  authorization: AuthorizationState;
  profile: ProfileState;
  config: ConfigState;
  logBook: LogBookState;
  loading: LoadingState;
  leaderBoard: LeaderBoardState;
  subscription: SubscriptionState;
  guest: GuestState;
  notification: NotificationState;
  members: MemberState;
  contest: ContestState;
  spotting: SpottingState;
  pota: PotaState;
  callsign: CallsignState;
  award: AwardState;
}

export default rootReducer;
