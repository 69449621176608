import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMenuVisibility,
  setPrimaryModalData,
} from "store/Actions/config.actions";
import {
  getIsMenuOpenSelector,
  getPrimaryModalSelector,
  getScreenShotImageSelector,
} from "store/Reducers/config.reducer";
import { PrimaryModalDataProps } from "types/State";
import { headerDropdownMenus } from "constants/NavMenus";
const useConfig = () => {
  let windowWidth: string = "";
  const dispatch = useDispatch();
  const primaryModalData = useSelector(getPrimaryModalSelector);
  const isMenuOpen: boolean = useSelector(getIsMenuOpenSelector);
  const screenShotImage = useSelector(getScreenShotImageSelector);

  const [headerDropDownNavMenu, setHeaderDropDownNavMenu] =
    useState(headerDropdownMenus);

  const handlePrimaryModal = (modalData: PrimaryModalDataProps | null) => {
    dispatch(setPrimaryModalData(modalData));
  };

  const getSubscriptionLevelButtonColor = (
    type: string,
    memberStatus: string
  ) => {
    if (type === "from") {
      if (memberStatus === "VIP") {
        return "#37A4BA";
      } else if (memberStatus === "PREMIUM") {
        return "#FFD80E";
      } else if (memberStatus === "FREE") {
        return "#9D9D9D";
      } else if (memberStatus === "MEMBER") {
        return "#4972C0";
      }
    } else if (type === "via") {
      if (memberStatus === "VIP") {
        return "#496780";
      } else if (memberStatus === "PREMIUM") {
        return "#FF724A";
      } else if (memberStatus === "FREE") {
        return "#9D9D9D";
      } else if (memberStatus === "MEMBER") {
        return "#497DC5";
      }
    } else if (type === "to") {
      if (memberStatus === "VIP") {
        return "#CF0971";
      } else if (memberStatus === "PREMIUM") {
        return "#2CD9FF";
      } else if (memberStatus === "FREE") {
        return "#9D9D9D";
      } else if (memberStatus === "MEMBER") {
        return "#4847AE";
      }
    }
  };

  const getSubscriptionLevelButtonBgColor = (memberStatus: string) => {
    if (memberStatus === "VIP") {
      return "bg-[#496780]";
    } else if (memberStatus === "PREMIUM") {
      return "bg-[#FF724A]";
    } else if (memberStatus === "FREE") {
      return "bg-[#9D9D9D]";
    } else if (memberStatus === "MEMBER") {
      return "bg-[#0243DD]";
    }
  };

  const handleWindowResize = (window: any) => {
    if (window?.target?.innerWidth < 1024 && windowWidth !== "Medium") {
      windowWidth = "Medium";
      dispatch(setMenuVisibility(false));
    } else if (window?.target?.innerWidth >= 1024 && windowWidth !== "Wide") {
      windowWidth = "Wide";
      dispatch(setMenuVisibility(true));
    }
  };

  const handleNavBarVisibility = (isVisible: boolean) => {
    dispatch(setMenuVisibility(isVisible));
  };

  useEffect(() => {
    handleWindowResize({ target: { innerWidth: window?.innerWidth } });
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMemberStatusText = (status: string) => {
    if (status === "basic") {
      return "MEMBER";
    } else if (status) {
      return status.toUpperCase();
    } else {
      return "FREE";
    }
  };

  return {
    windowWidth,
    handleNavBarVisibility,
    primaryModalData,
    handlePrimaryModal,
    isMenuOpen,
    getSubscriptionLevelButtonColor,
    getSubscriptionLevelButtonBgColor,
    headerDropDownNavMenu,
    setHeaderDropDownNavMenu,
    screenShotImage,
    getMemberStatusText,
  };
};

export default useConfig;
