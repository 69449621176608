import { useEditContact } from "hooks/useEditContact.hook";
import { useEffect } from "react";
import Icons from "assets/Icons";
import { EditContactForm } from "./EditContactForm";

export const EditContactModal = () => {
  const { setEditContactModalData, editContactModalData } = useEditContact();

  useEffect(() => {
    setEditContactModalData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!editContactModalData) return null;

  return (
    <main className="absolute w-full h-full z-50 glassmorphism">
      <div className="w-full h-full flex flex-col items-center p-4 md:p-20 overflow-x-hidden">
        <div className="bg-gradient-to-br from-fuchsia-500 via-violet-600 to-violet-900 p-[1px] inline-flex relative w-full shadow-lg">
          <div className="flex flex-col md:flex-row justify-between w-full bg-[#0B0B38] p-6 text-white">
            <div className="flex flex-col w-full gap-6">
              <div className=" font-bold font-Play text-xl">Edit Contact</div>

              <div className="">
                <EditContactForm />
              </div>
            </div>

            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setEditContactModalData(null)}
              tabIndex={55}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
