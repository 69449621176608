/* eslint-disable array-callback-return */
import { call, fork, put, select, take } from "redux-saga/effects";
import CallsignService from "services/callsign.service";
import {
  FETCH_CALLSIGN_FIRESTORE,
  FETCH_CALLSIGN_HAMDB,
  setCallSignData,
  setCallSignLoading,
  setCallSignSuggestionsData,
} from "store/Actions/callsign.actions";
import { CallSignLookUpData, CallsignModel } from "types/Models";

const prepareCallSignData = (data: any) => {
  return {
    callsign: data.call,
    class: data.class,
    grid: data.grid,
    latitude: data.lat,
    longitude: data.lon,
    fullName: `${data.fname} ${data.mi} ${data.name}`,
    firstName: data.fname,
    middleName: data.mi,
    lastName: data.name,
    address: data.addr1,
    city: data.addr2,
    state: data.state,
    zip: data.zip,
    country: data.country,
    status: data.status,
    expiryDate: data.expires,
  } as Partial<CallsignModel>;
};

export function* workerFetchCallsignFromHamDB(payload: any) {
  try {
    yield put(setCallSignLoading(true));

    const response: CallSignLookUpData | null = yield call(() =>
      CallsignService.fetchCallsignFromHamDB(payload)
    );

    const hamdb = response?.hamdb;
    const messages = hamdb?.messages;
    const callsign = hamdb?.callsign;

    const callsignData =
      callsign && messages?.status === "OK"
        ? prepareCallSignData(callsign)
        : null;

    yield put(setCallSignData(callsignData));
    yield put(setCallSignLoading(false));
  } catch (e: any) {
    console.log(e);
    yield put(setCallSignLoading(false));
    yield put(setCallSignData(null));
  }
}

export function* watchFetchCallsignFromHamDB() {
  while (true) {
    const { payload } = yield take(FETCH_CALLSIGN_HAMDB);
    yield fork(workerFetchCallsignFromHamDB, payload);
  }
}

export function* workerFetchCallsignFromFirestoreDB(payload: string) {
  try {
    yield put(setCallSignLoading(true));

    const callsignData: CallsignModel[] = yield call(() =>
      CallsignService.fetchCallsignFromFirestoreDB(payload)
    );
    
    yield put(setCallSignSuggestionsData(callsignData));
    yield put(setCallSignLoading(false));
  } catch (e: any) {
    console.log(e);
    yield put(setCallSignLoading(false));
    yield put(setCallSignSuggestionsData(null));
  }
}

export function* watchFetchCallsignFromFirestoreDB() {
  while (true) {
    const { payload } = yield take(FETCH_CALLSIGN_FIRESTORE);
    yield fork(workerFetchCallsignFromFirestoreDB, payload);
  }
}
