import { FC, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import Icons from "assets/Icons";
import { findCountryCode } from "helpers/Utils";
import { AddContactLogPopupProps } from "types/Component";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import { Tooltip } from "components/Tooltip";
import { UserAvatar } from "components/UserAvatar";
import { getCountryFromCallSign } from "firebase-config";
import CountryDxccFlag from "components/CountryDxccFlag";

const statsIcons: Record<string, string> = {
  distance: Icons.DoubleArrow,
  direction: Icons.CompassIcon,
  noOfContacts: Icons.NumberIcon,
  lastContact: Icons.CalenderIcon,
};

const statsTooltipMessages: Record<string, string> = {
  distance: "Distance to the contact",
  direction: "Direction/Azimuth to the contact",
  noOfContacts: "# of Times Contacted",
  lastContact: "Last Contacted Date",
};

export const AddContactLogPopup: FC<AddContactLogPopupProps> = ({ data }) => {
  const { addContactLogPopupSummary, userProfile, theirCallSign, flagCode } =
    data;
  const isCallSignMatch = addContactLogPopupSummary?.isCallSignMatch;
  const theirCallsign = addContactLogPopupSummary?.theirCallsign;
  const theirCountry = addContactLogPopupSummary?.theirCountry;
  const theirState = addContactLogPopupSummary?.theirState;
  const distance = addContactLogPopupSummary?.distance;
  const direction = addContactLogPopupSummary?.direction;
  const noOfContacts = addContactLogPopupSummary?.noOfContacts;
  const lastContact = addContactLogPopupSummary?.lastContact;

  const [countryFromCallSign, setCountryFromCallSign] = useState<string>("");
  const [theirCallSignFormValue, setTheirCallSignFormValue] =
    useState<string>("");

  useEffect(() => {
    setTheirCallSignFormValue(theirCallSign || "");
  }, [theirCallSign]);

  useEffect(() => {
    getCallSignInfo();
  }, [theirCallSignFormValue]);

  const getCallSignInfo = async () => {
    if (theirCallSignFormValue) {
      const countryData: any = await getCountryFromCallSign(
        theirCallSignFormValue
      );
      if (countryData && countryData?.data?.Country) {
        setCountryFromCallSign(countryData.Country);
      }
    } else {
      setCountryFromCallSign("");
    }
  };

  if (
    !isCallSignMatch &&
    !userProfile &&
    !noOfContacts &&
    !theirCallSignFormValue
  )
    return null;

  const renderStatsItem = (key: string, value?: number | string) => {
    if (!value) return null;

    return (
      <Tooltip message={statsTooltipMessages[key]}>
        <div className="justify-start items-center gap-2.5 flex">
          <div className="w-5 h-5 relative">
            <img
              className="w-5 h-5 left-[-0px] top-0 absolute"
              src={statsIcons[key]}
              alt=""
            />
          </div>
          <div className="text-white text-base font-normal font-Play">
            {key === "direction" ? `${value}\u00b0` : value}
          </div>
        </div>
      </Tooltip>
    );
  };

  const renderStats = () => {
    return (
      <>
        {renderStatsItem("distance", distance)}
        {renderStatsItem("direction", direction)}
        {renderStatsItem("noOfContacts", noOfContacts)}
        {renderStatsItem("lastContact", lastContact)}
      </>
    );
  };
  const renderCallSignInfo = () => {
    const countryName =
      theirCountry || userProfile?.country || countryFromCallSign || "";
    const stateName = theirState || userProfile?.state || "";
    const callSign =
      userProfile?.callSign || theirCallSignFormValue || theirCallsign || "";
    const profilePic = userProfile?.profilePic || "";

    return (
      <div
        className="justify-start items-center gap-[8px] flex cursor-pointer"
        onClick={() => {
          window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
            callSign
          )}?tab=Logbook&contact-tab=entries`;
        }}
      >
        {profilePic ? (
          <img
            className="w-[26px] h-[26px] rounded-[100px]"
            src={profilePic}
            alt=""
          />
        ) : (
          <UserAvatar className="w-7 h-7" />
        )}

        <div className="bg-gradient-to-r from-indigo-400 to-cyan-300 bg-clip-text text-transparent text-xl font-bold">
          {callSign}
        </div>
        <div className="w-5 h-5 py-[2.86px] justify-center items-center flex">
          <CountryDxccFlag
            countryCode={countryName}
            flagCode={flagCode || ""}
          />
        </div>
        {stateName ? (
          <div className="text-white text-base font-normal font-Play">
            {stateName}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="w-full fle text-white mt-2">
      <div className="bg-gradient-to-br from-fuchsia-500 via-violet-600 to-violet-800 p-[1px] inline-flex">
        <div className="p-3 pr-16 bg-[#151541] justify-start items-center gap-[25px] inline-flex flex-wrap">
          {renderCallSignInfo()}
          <div className="text-xl font-bold">:</div>
          {renderStats()}
        </div>
      </div>
    </div>
  );
};
