import { MY_PROFILE_ROUTE } from "constants/Links";
import useProfile from "hooks/profile.hook";
import { Link, useLocation } from "react-router-dom";
import { globalNavigate } from "routes/GlobalRoutes";
import { NavItemProps } from "types/Component";

function NavItem({
  color,
  text,
  icon,
  onPress,
  link,
  redirect,
  newFeature,
}: NavItemProps) {
  const location = useLocation();
  const { userProfile } = useProfile();

  if (text !== "Log out" && link && !redirect) {
    return (
      <div
        className="flex items-center relative my-5 cursor-pointer"
        onClick={() => {
          if (
            location.pathname.includes(MY_PROFILE_ROUTE) &&
            link.includes(MY_PROFILE_ROUTE)
          ) {
            window.location.href = link;
          } else if (link.includes(MY_PROFILE_ROUTE)) {
            globalNavigate(link, {
              state: userProfile?.uid,
            });
          } else {
            globalNavigate(link);
          }
        }}
      >
        <img
          src={icon}
          className="w-4 h-4 ml-10 lg:ml-5 xl:ml-10 mr-3.5"
          alt=""
        />
        <div className="flex justify-between items-center w-full mr-2">
          <h3
            className={`text-base xl:text-base font-Play ${
              color ? color : "text-white"
            }`}
          >
            {text}
          </h3>
          {newFeature ? (
            <div className="bg-[#9f0e5c] rounded-full items-center justify-center flex h-[17px] w-[45px]">
              <h2 className="font-[Play] text-[11px] text-white">New</h2>
            </div>
          ) : null}
        </div>
        {location?.pathname === link ? (
          <div className="w-1 bg-white h-8 absolute right-0" />
        ) : null}
      </div>
    );
  } else if (redirect && link) {
    return (
      <Link to={link} target="_blank" rel="noreferrer">
        <div className="flex items-center relative my-5">
          <img
            src={icon}
            className="w-4 h-4 ml-10 lg:ml-5 xl:ml-10 mr-3.5"
            alt=""
          />
          <h3
            className={`text-base xl:text-base font-Play ${
              color ? color : "text-white"
            }`}
          >
            {text}
          </h3>
          {location?.pathname === link ? (
            <div className="w-1 bg-white h-8 absolute right-0" />
          ) : null}
        </div>
      </Link>
    );
  } else {
    return (
      <div
        className="flex items-center relative my-5 cursor-pointer"
        onClick={onPress}
      >
        <img
          src={icon}
          className="w-4 h-4 ml-10 lg:ml-5 xl:ml-10 mr-3.5"
          alt=""
        />
        <h3
          className={`text-base xl:text-base font-Play ${
            color ? color : "text-white"
          }`}
        >
          {text}
        </h3>
        {location?.pathname === link ? (
          <div className="w-1 bg-white h-8 absolute right-0" />
        ) : null}
      </div>
    );
  }
}

export default NavItem;
